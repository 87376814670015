import * as React from 'react';
import { Svg } from '../svg';

function SvgMagnifying({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3 7.608L3 9.9V3.923h6L6.7 6.214l2.89 2.859-1.42 1.414L5.3 7.608zm12-1.394L15 3.924h6v5.975l-2.3-2.29-2.87 2.878-1.42-1.414 2.89-2.859zM9 21.851l-2.3-2.29 2.89-2.86-1.42-1.414-2.87 2.879-2.3-2.29v5.975h6zm9.7-3.685l2.3-2.29v5.975h-6l2.3-2.29-2.89-2.86 1.42-1.414 2.87 2.879z"
        fill="#fff"
        opacity={0.8}
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMagnifying);
export default ForwardRef;
