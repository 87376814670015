import {
  Button,
  Flex,
  Text,
  Box,
  Checkbox,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  Heading,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  TabList,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import {
  SvgMediaPost,
  SvgStartFromADraft,
  SvgStoryV2,
} from 'src/components/icons';
import { useState, useContext } from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import PageModal from 'src/components/footer/page-modal';
import Privacy from 'src/components/footer/privacy';
import Community from 'src/components/footer/community';

function PostingOptionModal({
  modal,
  reppl,
  episodeType,
}: {
  modal: any;
  reppl?: any;
  episodeType?: any;
}) {
  const [checked, setChecked] = useState(false);
  const { user } = useContext(UserContext) as UserContextType;

  const mediaPostLink = episodeType
    ? `/reppl/${reppl?.id}/episode/new?episode=${episodeType}&&type=mediaPost`
    : '/new?type=mediaPost';
  const storyPostLink = episodeType
    ? `/reppl/${reppl?.id}/episode/new?episode=${episodeType}`
    : '/new';

  const draftPostLink = episodeType
    ? `/${user.username}/drafts-submissions?rid=${reppl?.id}&&episode=${episodeType}`
    : `/${user.username}/drafts-submissions`;

  const [isOpen, setIsOpen] = useState(false);
  const [contentType, setContentType] = useState('');
  const modalHandle = (type) => {
    setIsOpen(true);
    setContentType(type);
  };

  return (
    <>
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW={480}>
          <ModalCloseButton />
          <Flex
            direction="column"
            alignItems="center"
            px="40px"
            pt="40px"
            pb="45px"
          >
            <Tabs>
              <Heading as="h3" textStyle="h3" color="$mode.900">
                Create a post
              </Heading>
              <Text textStyle="h5" color="$mode.500" pt="32px" pb="14px">
                Start creating your post:
              </Text>
              <ModalBody w="100%" px="0" py="9px">
                <TabList borderBottom="none" flexDirection="column">
                  <PostingOptionModalItem
                    postHeading="Story post"
                    postDescription={{
                      1: 'Built for complete storytelling.',
                      2: 'Share your ideas, thoughts or stories with no limitation.',
                    }}
                    icon={<SvgStoryV2 fontSize="32px" mr="16px" />}
                  />
                  <PostingOptionModalItem
                    postHeading="Media post"
                    postDescription={{
                      1: 'Built for nice and simple visual presentation.',
                      2: 'Upload images, or embed videos with short comments.',
                    }}
                    icon={<SvgMediaPost fontSize="32px" mr="16px" />}
                  />
                  <PostingOptionModalItem
                    isDisabled
                    postHeading="Start from a draft"
                    postDescription={{
                      1: 'Already have a draft?',
                      2: 'Select a draft post in your drafts and start editing.',
                    }}
                    icon={<SvgStartFromADraft fontSize="32px" mr="16px" />}
                  />
                </TabList>
              </ModalBody>

              <ModalFooter px="0" pt="14px" pb="0">
                <Flex direction="column">
                  <Flex>
                    <Checkbox
                      size="lg"
                      checked={checked}
                      alignItems="flex-start"
                      mb="47px"
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                    <Text textStyle="body2" ml="16px" color="$mode.500">
                      I agree with the{' '}
                      <span
                        className="text-denim-500 cursor-pointer mr-1"
                        aria-hidden="true"
                        onClick={() => {
                          modalHandle('privacy');
                        }}
                      >
                        Copyright and Content Policy
                      </span>
                      and{' '}
                      <span
                        className="text-denim-500 cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                          modalHandle('community');
                        }}
                      >
                        Community Guidelines
                      </span>
                    </Text>
                  </Flex>
                  <Flex justifyContent="center">
                    <Button
                      variant="ghost"
                      textStyle="buttonText"
                      fontSize="16px"
                      color="$mode.500"
                      onClick={modal.onClose}
                    >
                      Cancel
                    </Button>
                    <TabPanels maxW="max-content">
                      <TabPanel p={0}>
                        <Link
                          href={checked ? `${storyPostLink}` : null}
                          _hover={{ textDecoration: 'none' }}
                        >
                          <Button
                            textStyle="buttonText"
                            fontSize="16px"
                            borderRadius="40px"
                            isDisabled={!checked}
                          >
                            Create
                          </Button>
                        </Link>
                      </TabPanel>
                      <TabPanel p={0}>
                        <Link
                          href={checked ? `${mediaPostLink}` : null}
                          _hover={{ textDecoration: 'none' }}
                        >
                          <Button
                            textStyle="buttonText"
                            fontSize="16px"
                            borderRadius="40px"
                            isDisabled={!checked}
                          >
                            Create
                          </Button>
                        </Link>
                      </TabPanel>
                      <TabPanel p={0}>
                        <Link
                          href={checked ? draftPostLink : null}
                          _hover={{ textDecoration: 'none' }}
                        >
                          <Button
                            textStyle="buttonText"
                            fontSize="16px"
                            borderRadius="40px"
                            isDisabled={!checked}
                          >
                            Create
                          </Button>
                        </Link>
                      </TabPanel>
                    </TabPanels>
                  </Flex>
                </Flex>
              </ModalFooter>
            </Tabs>
          </Flex>
        </ModalContent>
      </Modal>

      <PageModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        contentType={contentType}
      >
        {contentType === 'community' && <Community />}
        {contentType === 'privacy' && <Privacy />}
      </PageModal>
    </>
  );
}

function PostingOptionModalItem({
  isDisabled = false,
  postHeading,
  postDescription,
  icon,
}: {
  isDisabled?: boolean;
  postHeading: string;
  postDescription: object;
  icon: object;
}) {
  return (
    <Box py="9px">
      <Tab
        isDisabled={isDisabled}
        p={0}
        w="100%"
        _selected={{
          '&>div': {
            borderColor: '#4EA1EC',
            '&>div>div>p': { color: '#4EA1EC' },
            '&>div>div>p:first-of-type': { color: '#0278E4' },
            cursor: 'pointer',
            background: '#E1EFFC',
          },
        }}
      >
        <Box
          border="1px solid"
          borderColor="$mode.300"
          borderRadius="4px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06)"
          backgroundColor="$mode.50"
          p="16px"
          w="100%"
          _hover={{
            borderColor: '#4EA1EC',
            '&>div>div>p': { color: '#4EA1EC' },
            '&>div>div>p:first-of-type': { color: '#0278E4' },
            cursor: 'pointer',
            background: '#E1EFFC',
          }}
        >
          <Flex>
            <Flex alignItems="center" justifyContent="center">
              {icon}
            </Flex>
            <Box textAlign="left">
              <Text textStyle="h5" color="$mode.900" mb="8px">
                {postHeading}
              </Text>
              {Object.entries(postDescription).map(([key, description]) => {
                return (
                  <Text
                    key={key}
                    textStyle="labelLight"
                    color="$mode.500"
                    fontWeight="500"
                  >
                    {description}
                  </Text>
                );
              })}
            </Box>
          </Flex>
        </Box>
      </Tab>
    </Box>
  );
}

export default PostingOptionModal;
