import { useTutorialMutation } from 'src/app/explore/use-tutorial';

export const HowToStartAReppl2Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Start a RE:PPL
      </h1>
      <img
        src="/images/assets/tutorial/how-to-start-a-reppl-2.png"
        alt="how to start a reppl 2"
        className="w-full"
      />
    </>
  );
};

export const HowToStartAReppl2Footer = ({
  closeModal,
  completed,
}: {
  closeModal?: any;
  completed: boolean;
}) => {
  const { mutateAsync: updateTutorial } = useTutorialMutation();
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Ready to Publish?
      </h5>

      <ul
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px', listStyle: 'disc inside none' }}
      >
        <li>Check if your settings and details information is filled out</li>
        <li>Set Rules and Guidelines for your RE:PPL</li>
        <li>Publish an episode to showcase your work</li>
      </ul>

      <button
        type="button"
        onClick={async () => {
          if (!completed) {
            await updateTutorial({ step: 'start_reppl' });
          }
          closeModal();
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Done
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        2 / 2
      </h6>
    </>
  );
};
