import * as React from 'react';
import { Svg } from '../svg';

function SvgCcSa({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.178.084c3.357 0 6.2 1.165 8.529 3.493 2.328 2.315 3.493 5.15 3.493 8.507 0 3.358-1.144 6.172-3.43 8.443-2.413 2.371-5.278 3.557-8.592 3.557-3.243 0-6.057-1.178-8.442-3.536C1.379 18.192.2 15.371.2 12.085c0-3.272 1.179-6.107 3.536-8.507C6.064 1.248 8.878.084 12.178.084zm.043 2.165c-2.728 0-5.035.964-6.921 2.893-1.958 1.986-2.936 4.3-2.936 6.942 0 2.672.971 4.972 2.914 6.9 1.942 1.943 4.257 2.914 6.942 2.914 2.671 0 5-.978 6.986-2.935 1.886-1.829 2.829-4.121 2.829-6.879 0-2.742-.957-5.057-2.871-6.942-1.9-1.93-4.215-2.893-6.943-2.893zM6.864 10.39c.228-1.471.82-2.61 1.778-3.418.957-.807 2.121-1.21 3.493-1.21 1.885 0 3.386.607 4.5 1.82 1.114 1.215 1.671 2.772 1.671 4.672 0 1.843-.578 3.375-1.735 4.596-1.158 1.221-2.657 1.832-4.5 1.832-1.358 0-2.53-.406-3.515-1.22-.986-.815-1.578-1.972-1.778-3.472H9.8c.071 1.457.95 2.186 2.636 2.186.842 0 1.521-.365 2.035-1.093.515-.728.772-1.7.772-2.914 0-1.272-.236-2.24-.707-2.904-.471-.664-1.15-.996-2.036-.996-1.6 0-2.5.707-2.7 2.12h.879L8.3 12.77l-2.378-2.38.942.001z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCcSa);
export default ForwardRef;
