import * as React from 'react';
import { Svg } from '../svg';

function SvgDown({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M.833.916L5 5.083 9.167.916H.833z" fill="#242526" />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgDown);
export default ForwardRef;
