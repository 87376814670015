import { useQueryClient, useMutation, useQuery } from 'react-query';
import { ArticlePostType, ArticleSource } from 'src/app/article/enum';
import type { FullArticle } from 'src/app/article/types';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import useProfileQuery from 'src/app/profile/use-profile-query';
import { CacheKey } from 'src/constants/cache-key';
import { useUploadImage } from 'src/hooks';
import { useContext } from 'react';
import { imageOptimize } from 'src/service/image';
import * as Service from './service';

export function useEpisodeQuery(id?: string) {
  return useQuery([CacheKey.article, id], () => Service.getEpisode(id), {
    enabled: !!id,
  });
}

export function useEpisodeFullQuery(id?: string) {
  return useQuery([CacheKey.article, id], () => Service.getFullEpisode(id), {
    enabled: !!id,
  });
}

export function useEpisodeMutation() {
  const queryClient = useQueryClient();

  const { user } = useContext(UserContext) as UserContextType;

  const { data: profile } = useProfileQuery(user?.username);
  return useMutation(Service.createOrUpdateEpisode, {
    onSuccess: (res, values) => {
      if (res.code === 1000) {
        queryClient.setQueryData<FullArticle>(
          [CacheKey.article, values.contentID],
          (p) => ({
            ...p,
            ...values,
            author: {
              userID: profile.id,
              firstName: profile.firstName,
              lastName: profile.lastName,
              isFollowed: profile.isFollowed,
              profileImgURL: imageOptimize(profile.profileImgURL),
              username: profile.username,
              userTitle: profile.title,
              socialMedia: profile.socialMedia,
              about: profile.about,
              count: {
                episode: 0,
                episodeEnthuse: 0,
                follower: 0,
                studio: 0,
                studioEnthuse: 0,
              },
            },
            postType: ArticlePostType.STORY,
            sourceID: values.sourceID,
            source: ArticleSource.REPPL,
            contentID: values.contentID,
            coCreators: [], // TODO: get from cache
          })
        );
      }
    },
  });
}

export function usePartialUpdateEpisodeMutation() {
  const queryClient = useQueryClient();
  return useMutation(Service.partialUpdateEpisode, {
    onSuccess: (res, values) => {
      if (res.code === 1000) {
        queryClient.setQueryData<FullArticle>(
          [CacheKey.article, values.contentID],
          (p) => ({
            ...p,
            values,
          })
        );
      }
    },
  });
}

export function useDeleteEpisodeMutation() {
  const queryClient = useQueryClient();

  return useMutation(Service.deleteEpisode, {
    onSuccess: (res, values) => {
      if (res.code === 1000) {
        queryClient.setQueryData([CacheKey.article, values], null);
      }
    },
  });
}

export function useEpisodeCoverMutation(eid: string) {
  const queryClient = useQueryClient();
  return useUploadImage(
    { areaType: 'EPISODE', imgType: 'episode_cover' },
    (res) => {
      queryClient.setQueryData<Service.EpisodeRequest>(
        [CacheKey.article, eid],
        (p) => ({
          ...p,
          coverImgURL: imageOptimize(res.imgURL),
        })
      );
    }
  );
}
