import {
  Box,
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { ElementRef, useEffect, useRef, useState } from 'react';
import { ArticleDetail } from 'src/app/article/detail';
import { ArticleSource } from 'src/app/article/enum';
import { useTranslation } from 'react-i18next';
import { useArticleFullQuery } from 'src/app/article/hooks/use-article-query';
import { Article, FullArticle } from 'src/app/article/types';
import { GeneralFeed } from 'src/app/explore/general-feed';
import ConfirmModal from 'src/app/article/confirm-modal';
import SubmissionModal from 'src/app/article/submission-modal';
import { useModal } from 'src/hooks/use-modal';
import * as ga from 'src/lib/ga';
import {
  approveSubmission,
  declineSubmission,
} from 'src/components/drafts-submissions/hooks';
import { useRouter } from 'next/router';

export function DetailModal({
  article,
  modal,
  isLoading,
  publish,
  id,
  submissionStatus = '',
  refetch,
  rid,
}: {
  id?: string;
  rid?: string;
  modal: UseDisclosureReturn;
  article: FullArticle;
  isLoading: boolean;
  publish?: boolean;
  submissionStatus?: string;
  refetch?: any;
}) {
  const modalRef = useRef<ElementRef<typeof ModalContent>>();
  const router = useRouter();

  const confirmModal = useModal();
  const submissionModal = useModal();
  const { t } = useTranslation();
  const [currArticle, setCurrArticle] = useState<any>();
  const [confirmModalData, setConfirmModalData] = useState<any>([
    t('confirmDeclineTitle'),
    'hidden',
    t('confirmDecline'),
  ]);
  const [submissionModalData, setSubmissionModalData] = useState<any>([
    t('declineTitle'),
    t('declineText'),
    t('declinePlaceHolder'),
    'declineSubmission',
    t('decline'),
    'true',
  ]);

  useEffect(() => {
    if (article) {
      setCurrArticle(article);
    }
  }, [article]);
  useEffect(
    function scrollToTopWhenOpeningModal() {
      window.scrollTo({
        top: window.scrollY + 1,
      });
      if (id && modalRef.current) {
        modalRef.current.scrollTop = 0;
      }
    },
    [id, modalRef]
  );
  function modalChange(data) {
    let link = '';
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { username, rid } = router.query;
    if (username) {
      link = `/${username}/${data['contentID']}`;
    } else {
      link = `/reppl/${rid}/episode/${data['contentID']}`;
    }

    router.push(link);
  }

  useEffect(() => {
    if (typeof currArticle === 'object') {
      if (Object.keys(currArticle).length > 0) {
        if (currArticle.classType === 'REPPL') {
          const data = {
            title: currArticle.title,
            location: `/reppl/${currArticle.sourceID}/episode/${currArticle.contentID}`,
            path: '/reppl/[rid]/episode/[eid]',
          };
          ga.manualpageview(data);
        } else {
          const data = {
            title: currArticle.title,
            location: `/${currArticle?.author?.username}/${currArticle.contentID}`,
            path: '/[username]/[sid]',
          };
          ga.manualpageview(data);
        }
      }
    }
  }, [currArticle, setCurrArticle]);

  const statusData = {
    APPROVED: {
      title: 'Approved',
      color: 'text-seagreen-500',
    },
    SUBMITTED: {
      title: 'Pending review',
      color: 'text-gray-900',
    },
    UNPUBLISHED: {
      title: 'Unpublished',
      color: 'text-outrageousOrange-900',
    },
    PUBLISHED: {
      title: 'Published',
      color: 'text-seagreen-500',
    },
    UNLISTED: {
      title: 'Unlisted',
      color: 'text-outrageousOrange-500',
    },
    REMOVED: {
      title: 'Removed',
      color: 'text-outrageousOrange-500',
    },
    DECLINED: {
      title: 'Declined',
      color: 'text-outrageousOrange-500',
    },
  };

  const submissionFunc = {
    approve: (data: any) => {
      const content = {
        eid: article?.contentID,
        rid,
        note: data,
      };
      approveSubmission(content);
      setConfirmModalData([
        t('confirmApproveTitle'),
        t('confirmApprove'),
        t('confirmApproveCancel'),
      ]);
      confirmModal.onSelect(currArticle);
    },
    decline: (data: any) => {
      const content = {
        eid: article?.contentID,
        rid,
        note: data,
      };
      declineSubmission(content);
      setConfirmModalData([
        t('confirmDeclineTitle'),
        'hidden',
        t('confirmDecline'),
      ]);
      // postReviews(eid, content);
      // userSubmission(rid, eid, 'REJECTED');
      confirmModal.onSelect(currArticle);
    },
  };

  return (
    <Modal
      isCentered
      autoFocus={false}
      {...modal}
      blockScrollOnMount
      motionPreset="none"
    >
      <ModalOverlay>
        <ModalContent
          ref={modalRef}
          borderRadius={{ base: '0', md: 'xl' }}
          maxWidth={currArticle?.postType === 'GENERAL' ? '624px' : '1162px'}
          h="auto"
          maxHeight={{ base: '100%' }}
        >
          {publish && <ModalCloseButton color="$mode.500" className="z-50" />}
          {!publish && (
            <Box pos="absolute" left="24px" top="24px">
              <Button
                rounded="full"
                size="sm"
                variant="outline"
                onClick={modal.onClose}
              >
                {t('Back to draft')}
              </Button>
            </Box>
          )}
          {currArticle?.postType !== 'GENERAL' ? (
            <ArticleDetail
              publish={publish}
              refetch={refetch}
              source={
                currArticle?.source ||
                currArticle?.classType ||
                currArticle?.articleType
              }
              article={currArticle}
              author={currArticle?.author}
              isLoading={isLoading}
              id={currArticle?.contentID}
              handleModalChange={(e) => modalChange(e)}
              modal={modal}
            />
          ) : (
            <GeneralFeed recentFeed={currArticle} modal />
          )}
          {submissionStatus !== '' && (
            <div className="w-full sticky bottom-0 z-50">
              <div className="w-full bg-white flex h-20 border-t border-gray-100 p-5">
                <div
                  style={{ height: 'fit-content' }}
                  className={`whitespace-nowrap border rounded-full text-xs font-bold shadow py-1 px-3 mt-1 ml-5 ${statusData[submissionStatus].color}`}
                >
                  {statusData[submissionStatus].title}
                </div>
                {submissionStatus === 'SUBMITTED' && (
                  <div className="flex absolute right-10">
                    <div
                      role="button"
                      tabIndex={0}
                      className="text-outrageousOrange-500 font-semibold m-auto px-4 mx-2"
                      onClick={() => {
                        setSubmissionModalData([
                          t('declineTitle'),
                          t('declineText'),
                          t('declinePlaceHolder'),
                          'decline',
                          t('decline'),
                          'true',
                        ]);
                        submissionModal.onSelect(true);
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Endter') {
                          setSubmissionModalData([
                            t('declineTitle'),
                            t('declineText'),
                            t('declinePlaceHolder'),
                            'decline',
                            t('decline'),
                            'true',
                          ]);
                          submissionModal.onSelect(true);
                        }
                      }}
                    >
                      Decline
                    </div>
                    <div
                      role="button"
                      tabIndex={0}
                      className="bg-blue-500 rounded-full border py-1 text-white font-semibold m-auto px-4 mx-2"
                      onClick={() => {
                        setSubmissionModalData([
                          t('approveTitle'),
                          t('approveText'),
                          t('approvePlaceHolder'),
                          'approve',
                          t('approve'),
                          'false',
                        ]);
                        submissionModal.onSelect(true);
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Endter') {
                          setSubmissionModalData([
                            t('approveTitle'),
                            t('approveText'),
                            t('approvePlaceHolder'),
                            'approve',
                            t('approve'),
                            'false',
                          ]);
                          submissionModal.onSelect(true);
                        }
                      }}
                    >
                      Approve
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {submissionModal.selected && (
            <SubmissionModal
              {...submissionModal.modal}
              title={`${submissionModalData[0]}`}
              text={`${submissionModalData[1]}`}
              placeHolder={`${submissionModalData[2]}`}
              onOk={(e: any) => submissionFunc[`${submissionModalData[3]}`](e)}
              okBtnText={`${submissionModalData[4]}`}
              isDecline={submissionModalData[5] === 'true'}
            />
          )}
          {confirmModal.selected && (
            <ConfirmModal
              {...confirmModal.modal}
              title={`${confirmModalData[0]}`}
              okBtnText={`${confirmModalData[1]}`}
              cancelBtnText={`${confirmModalData[2]}`}
              article={currArticle}
              rid={rid}
            />
          )}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export function ArticleDetailModal({
  modal,
  id,
  source = '',
  publish,
  defaultArticle,
  submissionStatus = '',
  rid,
}: {
  modal: UseDisclosureReturn;
  id?: string;
  rid?: string;
  source?: ArticleSource | any;
  publish?: boolean;
  defaultArticle?: Partial<Article>;
  submissionStatus?: string;
}) {
  const { data: article, status, refetch } = useArticleFullQuery(
    source,
    modal.isOpen && id
  );

  return (
    <DetailModal
      publish={publish}
      id={id}
      refetch={refetch}
      rid={rid}
      submissionStatus={submissionStatus}
      // @ts-ignore
      article={Object.assign(article || {}, defaultArticle)}
      isLoading={!defaultArticle && status === 'loading'}
      modal={modal}
    />
  );
}
