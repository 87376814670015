import Link from 'next/link';
import is from 'is_js';

interface NoResultProps {
  searchWord: string;
  suggestions?: Array<string>;
  className?: string;
}
const NoResult = ({
  searchWord,
  suggestions = [],
  className = '',
}: NoResultProps) => {
  return (
    <div className={className}>
      <p className="font-semibold text-sm mb-6 text-gray-900">
        We couldn&apos;t find any results matching {`"${searchWord}"`}
      </p>
      {is.not.empty(suggestions) && (
        <>
          <p className="text-sm mb-3 text-gray-500">
            Try searching something else, or check our trending topics
          </p>
          <div>
            {suggestions.map((ss, key) => {
              const index = `key_${key}`;
              return (
                <Link
                  href={{ pathname: '/search', query: { q: ss } }}
                  key={index}
                >
                  <a className="text-xs py-1 px-3 text-gray-500 font-bold	border rounded-3xl mr-2">
                    {ss}
                  </a>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
export default NoResult;
