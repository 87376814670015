import * as React from 'react';
import { Svg } from '../svg';

function SvgPrevSlide({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={20} cy={20.5} r={20} fill="#fff" />
      <path
        d="M22.842 25.991l-1.175 1.175L15 20.5l6.667-6.667 1.175 1.175-5.483 5.492 5.483 5.491z"
        fill="#242526"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPrevSlide);
export default ForwardRef;
