import * as React from 'react';
import { Svg } from '../svg';

function SvgPacmanBorder({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10 0a9.98 9.98 0 017.743 3.671L11.414 10l6.329 6.329A9.979 9.979 0 0110 20C4.477 20 0 15.523 0 10S4.477 0 10 0zm0 2a8 8 0 104.697 14.477l.208-.157L8.585 10l6.32-6.321-.208-.156a7.964 7.964 0 00-4.394-1.517L10 2zm0 1a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPacmanBorder);
export default ForwardRef;
