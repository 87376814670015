import * as React from 'react';
import { Svg } from '../svg';

function SvgCommentsGray({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9.334 15l-1.867-2.333H4A.667.667 0 013.334 12V4.735A.667.667 0 014 4.07h10.667a.667.667 0 01.667.666V12a.667.667 0 01-.667.667H11.2L9.334 15zm1.226-3.667H14V5.402H4.667v5.931h3.44l1.227 1.532 1.226-1.532zm-9.226-10h11.333v1.334H2V10H.667V2a.667.667 0 01.667-.667z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCommentsGray);
export default ForwardRef;
