import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Box,
  UseDisclosureReturn,
  CircularProgress,
  FormControl,
  useDisclosure,
  // Input,
  Tabs,
  TabList,
  Tab,
  useColorMode,
} from '@chakra-ui/react';
import SvgClose from 'src/components/icons/close';
import SvgCamera from 'src/components/icons/camera';
import SvgLink from 'src/components/icons/link';
import SvgUnsplash from 'src/components/icons/unsplash';
import SvgHashtag from 'src/components/icons/hashtag';
import SvgDiscussion from 'src/components/icons/discussion';
import SvgPoll from 'src/components/icons/poll';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'src/components/avatar/avatar';
import { useEditor } from 'src/lib/editor';
import { useState, useEffect, useRef, ElementRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useUploadImage, useSessionStorage } from 'src/hooks';
import { useQueryClient } from 'react-query';
import { ImageUploader } from 'src/components/image-uploader';
import { CacheKey } from 'src/constants/cache-key';
import { LIMIT_UPLOAD_IMAGE_FILE_ACCEPT } from 'src/constants/limit';
import {
  useStudioDeleteMutation,
  useGeneralMutation,
} from 'src/app/studio/hooks/use-studio-mutation';
import { useArticleForm } from 'src/app/article/hooks/use-article-form';
import { ArticleSource } from 'src/app/article/enum';

import { logger } from 'src/lib/logger';
import { GeneralFormValies } from 'src/app/article/settings-modal/types';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { Controller } from 'react-hook-form';
import { GeneralPreset } from 'src/components/select';
import { useMultipleSelectLimit } from 'src/components/multiple-select/use-multiple-select-limit';
import CreatableSelect from 'react-select/creatable';
import { useRouter } from 'next/router';
import { OneTmModal } from 'src/components/1tm-modal';
import { UnsplashSelect } from 'src/components/unsplash/unsplash-select';
import {
  ImageCropModal,
  useImageCropModal,
} from 'src/components/image-crop/image-crop';
import WarningModalWithoutCancel from 'src/app/article/warning-modal-without-cancel';
import { useIsTablet } from 'src/hooks/use-is-mobile';

function AgoraPostModal({
  isOpen,
  onClose,
  user,
  contentID,
  setUploadedFiles,
  uploadedFiles,
  imgProgress,
  imgUploading,
  hasCategory,
  editContent,
  hasHash,
}: UseDisclosureReturn & {
  user?: any;
  contentID: string;
  setUploadedFiles?: any;
  uploadedFiles?: Array<any>;
  imgProgress?: any;
  imgUploading?: any;
  hasCategory?: string;
  editContent?: any;
  hasHash?: any;
}) {
  const { t } = useTranslation();
  const newData = { blocks: [] };
  const [lastUploadedFile, setLastUploadedFile] = useState<any>();
  const [hashBar, setHashBar] = useState(!!hasHash);
  const [progress, setProgress] = useState<ProgressEvent | null>(null);
  const queryClient = useQueryClient();
  const { mutate: deleteStudio } = useStudioDeleteMutation(true);
  const methods = useArticleForm();
  const { mutateAsync: createOrEditProject } = useGeneralMutation();
  const [defaultTags, setDefaultTags] = useState([]);
  const { selectProps, typingOnMax } = useMultipleSelectLimit({
    max: 5,
    value: methods.watch('tags', []),
  });
  const router = useRouter();

  const [recentContent, setRecentContent] = useSessionStorage<any>(
    'generalContent',
    {}
  );
  const [loadedContent, setLoadedContent] = useState<any>();
  const uploadModal = useDisclosure();
  const unsplashModal = useDisclosure();
  const warningModal = useDisclosure();
  const [warningModalText, setWarningModalText] = useState([]);
  const [warningModalTitle, setWarningModalTitle] = useState('');

  useEffect(() => {
    setProgress(imgProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (editContent) {
      if (editContent['tags'].length > 0) {
        setHashBar(true);
        const editTags = editContent['tags'].map((tagsEdit) => {
          return { label: `#${tagsEdit}`, value: tagsEdit };
        });
        setDefaultTags(editTags);
      }
    }
    if (hasHash) {
      const hashData = hasHash.replace(/-/g, ' ');
      setDefaultTags([{ label: `#${hashData}`, value: hashData }]);
    }
    if (recentContent && !editContent) {
      setLoadedContent(recentContent);
      if (recentContent.tags && recentContent.tags.length > 0) {
        setHashBar(true);
        const cacheTags = recentContent.tags.map(
          (tags) => tags && { label: `#${tags}`, value: tags }
        );
        if (defaultTags.length > 0) {
          setDefaultTags([...defaultTags, ...cacheTags]);
        } else {
          setDefaultTags([...cacheTags]);
        }
      }
      if (recentContent?.content && recentContent?.content['uploadedFiles']) {
        setUploadedFiles(recentContent?.content['uploadedFiles']);
      }
    }
  }, []);

  const dropzone = useDropzone({
    noClick: true,
    onDrop: async (files) => {
      if (files[0]) {
        const formData = new FormData();
        formData.set('file', files[0]);
        getFileToCrop(files[0]);
        setLastUploadedFile(files[0]);
      }
    },
  });

  const { editor } = useEditor({
    uniqueId: contentID,
    data:
      (editContent?.content as any) ||
      (loadedContent?.content as any) ||
      newData,
    areaType: 'EPISODE',
    imgType: 'episode_img',
  });

  const onCreateOrEdit = async (value) => {
    try {
      const newVal = { ...value };
      newVal.tags = value.tags.map((mt) => {
        return mt ? mt.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '') : '';
      });
      newVal.tags = newVal.tags.filter((tg) => tg !== '');
      const createdProject = await createOrEditProject({
        ...newVal,
        source: ArticleSource.STUDIO,
        sourceID: contentID,
      });
      return createdProject;
    } catch (err) {
      logger.error(err);
    }
  };

  const { mutateAsync: uploadCover, isLoading: isUploading } = useUploadImage(
    {
      areaType: 'EPISODE',
      imgType: 'episode_img',
      updateProgress: setProgress,
    },
    (response) => {
      const updater = (prevCache) => ({
        ...prevCache,
        uploadedImg: response.imgURL,
      });

      if (setUploadedFiles) {
        setUploadedFiles([
          {
            type: 'image',
            id: generateUniqueID(),
            imgURL: response.imgURL,
            file: lastUploadedFile,
            title: null,
            description: null,
          },
          ...uploadedFiles,
        ]);
      }
      queryClient.setQueryData([CacheKey.article, contentID], updater);
    }
  );

  const isTablet = useIsTablet();
  const { cropper, getFileToCrop } = useImageCropModal({
    editor: {
      width: 900 / (isTablet ? 2 : 1),
      height: 600 / (isTablet ? 2 : 1),
      border: [0, 0],
      editorStyles: {
        margin: 'auto',
        width: '100%',
      },
    },
    onSave: async (file) => {
      if (
        file.type === 'image/png' ||
        file.type === 'image/gif' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/webp'
      ) {
        if (lastUploadedFile?.size > 5000000) {
          setWarningModalTitle('Image file size is too large');
          setWarningModalText([
            'Image file size should be under 5MB.',
            'Please check file size and try again.',
          ]);
          warningModal.onOpen();
        } else {
          uploadModal.onClose();
          const formData = new FormData();
          formData.set('file', file);
          await uploadCover({ data: formData });
        }
      } else {
        setWarningModalTitle('Image format not supported');
        setWarningModalText([
          'We supported images in JPEG/GIF/PNG/WebP.',
          'Please convert the image format and try again, or select another image in the supported formats.',
        ]);
        warningModal.onOpen();
      }
    },
  });

  function closePostModal() {
    if (uploadedFiles.length > 0) {
      deleteStudio(contentID);
    }
    if (setUploadedFiles) {
      setUploadedFiles([]);
    }
    onClose();
  }

  function imageHeight(data) {
    const constHeight = ['24rem', '12rem', '10rem', '8rem', '6rem'];

    return constHeight[data - 1];
  }

  const getContent = async () => {
    if (editContent) {
      return;
    }
    const data = methods.getValues();
    const content = await editor.save();
    // @ts-ignore
    content.uploadedFiles = uploadedFiles;
    if (content.blocks.length > 0 || uploadedFiles.length > 0) {
      const { tags: projectTags } = data;
      let tags =
        projectTags?.length > 0 ? projectTags.map(({ value }) => value) : [];
      if (tags.length > 0) {
        tags = tags.map((mt) => mt.toLowerCase().replace(/[^a-z\d]+/gi, ''));
        tags = tags.filter((tg) => tg !== '');
      }
      hasHash && !projectTags.includes(hasHash) && tags.push(hasHash);
      const values = {
        content,
        tags,
      };
      setRecentContent(values);
    } else {
      sessionStorage.removeItem('generalContent');
    }
  };

  const onSubmit = async (data: GeneralFormValies) => {
    if (!user) {
      return;
    }

    const content = await editor.save();

    // @ts-ignore
    content.uploadedFiles = uploadedFiles;

    const {
      copyright,
      categories: projectCategories,
      tags: projectTags,
    } = data;

    const categories =
      projectCategories?.length > 0
        ? projectCategories.map(({ value }) => value)
        : [];
    let savedTags =
      defaultTags?.length > 0 ? defaultTags.map(({ value }) => value) : [];
    if (savedTags.length > 0) {
      savedTags = savedTags.map((mt) =>
        mt
          .toLowerCase()
          .replace(/[^a-zA-Z0-9 ]/g, '')
          .trim()
      );
      savedTags = savedTags.filter((tg) => tg !== '');
    }
    let tags =
      projectTags?.length > 0 ? projectTags.map(({ value }) => value) : [];
    if (tags.length > 0) {
      tags = tags.map((mt) =>
        mt
          .toLowerCase()
          .replace(/[^a-zA-Z0-9 ]/g, '')
          .trim()
      );
      tags = tags.filter((tg) => tg !== '');
    }

    if (hasCategory) {
      const catData = hasCategory.replace(/-/g, ' ');
      !projectCategories.includes(catData) && categories.push(catData);
    }

    // hasHash && !projectTags.includes(hasHash) && tags.push(hasHash);

    !tags.includes(savedTags[0]) && tags.push(savedTags[0]);

    const values = {
      contentID,
      content,
      copyright: copyright || 'Attribution 4.0 International',
      categories,
      tags: [...tags],
    };

    try {
      const createdContent = await onCreateOrEdit(values);
      if (createdContent.code === 1000) {
        sessionStorage.removeItem('generalContent');
        if (setUploadedFiles) {
          setUploadedFiles([]);
        }
        onClose();
        router.reload();
      }
    } catch (err) {
      logger.error(err);
    }
  };

  const handleUpload = (data) => {
    uploadModal.onClose();
    if (data.value) {
      getFileToCrop(data.value);
    } else {
      dropzone.open();
    }
  };

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        getContent();
        closePostModal();
      }}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay>
        <ModalContent
          bg="$mode.50"
          pt="10px"
          px={{ base: '15px', md: '20px' }}
          pb="40px"
        >
          <Box pos="relative" overflowY="auto" maxHeight="26rem" zIndex="unset">
            <Box
              pos="absolute"
              right={2}
              top={3}
              cursor="pointer"
              zIndex="5"
              onClick={() => {
                closePostModal();
              }}
            >
              <SvgClose color="black" fontSize="16px" />
            </Box>
            <div className="relative py-2 text-2xl font-bold text-gray-500 mb-4">
              Create a post
            </div>
            <div className="flex">
              <div className="mr-2">
                <Avatar
                  name={user ? user?.username : ''}
                  data-testid={user ? user?.avatar : ''}
                  bg="$mode.400"
                  width={40}
                  height={40}
                  src={user ? user?.avatar : ''}
                  author={user}
                />
              </div>
              <div className="text-gray-900 font-medium my-auto dark:text-white">
                {getProfileFullNameOrUsername(user)}
              </div>
            </div>
            <div className="h-auto py-4 w-full overflow-hidden">
              <Box
                position="relative"
                px={1}
                id="editor"
                zIndex={1}
                m="auto"
                onPaste={(e) => e.stopPropagation()}
              />
            </div>
            <div className="flex my-3">
              {imgUploading && progress && (
                <div className="h-auto w-full bg-gray-200 mr-2">
                  <CircularProgress
                    position="absolute"
                    size="24px"
                    isIndeterminate
                  />
                </div>
              )}
              {uploadedFiles.map((files) => (
                <div
                  key={files.imgURL}
                  className="h-auto w-full bg-gray-200 mr-2"
                >
                  <ImageUploader
                    dropzone={dropzone}
                    uploading={isUploading}
                    bg="$mode.50"
                    border="1px solid"
                    borderColor="$mode.200"
                    borderRadius="2px"
                    fontSize="32px"
                    color="$mode.700"
                    isGeneral
                    src={files.imgURL}
                    style={{
                      height: imageHeight(uploadedFiles.length),
                      width: '100%',
                    }}
                    onDelete={() => {
                      if (setUploadedFiles) {
                        setUploadedFiles(
                          uploadedFiles.filter((item) => item.id !== files.id)
                        );
                      }
                    }}
                  >
                    <></>
                  </ImageUploader>
                </div>
              ))}
            </div>
          </Box>
          <ModalFooter px={0} pb={0} zIndex="unset">
            <div className="relative w-full">
              {hashBar && (
                <FormControl className="mb-2">
                  <Controller
                    name="tags"
                    control={methods.control}
                    render={({ value, onChange }) => (
                      <CreatableSelect
                        onKeyDown={(e) => {
                          if (!e.shiftKey && e.key === 'Enter') {
                            e.stopPropagation();
                          }
                        }}
                        isMulti
                        menuPlacement="top"
                        {...GeneralPreset({ error: typingOnMax })}
                        noOptionsMessage={() => null}
                        placeholder={t('Search')}
                        defaultValue={defaultTags || value}
                        onChange={onChange}
                        options={[]}
                        {...selectProps}
                      />
                    )}
                  />
                </FormControl>
              )}
              <div className="flex">
                <input
                  {...dropzone.getInputProps({
                    accept: LIMIT_UPLOAD_IMAGE_FILE_ACCEPT,
                  })}
                  data-testid="hidden-upload-input"
                />
                <div
                  className={`flex h-10 w-10 rounded-full border-gray-200 border mr-3 cursor-pointer collapse image ${
                    uploadedFiles?.length < 5 ? '' : 'bg-gray-100'
                  }`}
                  role="button"
                  onClick={() => {
                    if (uploadedFiles?.length < 5) {
                      uploadModal.onOpen();
                    }
                  }}
                  onKeyDown={() => {
                    if (uploadedFiles?.length < 5) {
                      uploadModal.onOpen();
                    }
                  }}
                  tabIndex={0}
                >
                  <div className="icon flex text-gray-700">
                    <SvgCamera
                      className="svgIcon"
                      color="gray.500"
                      width={24}
                      height={24}
                      margin="auto 0.5rem"
                    />
                    <div className="my-auto">Image</div>
                  </div>
                </div>
                <div
                  className="flex h-10 w-15 rounded-full border-gray-200 border mr-3 cursor-pointer collapse hashtag"
                  role="button"
                  onClick={() => setHashBar(!hashBar)}
                  onKeyDown={() => setHashBar(!hashBar)}
                  tabIndex={0}
                >
                  <div className="icon flex text-gray-700">
                    <SvgHashtag
                      className="svgIcon"
                      color="gray.500"
                      width={24}
                      height={24}
                      margin="auto 0.5rem"
                    />
                    <div className="my-auto">Hashtag</div>
                  </div>
                </div>
                <div
                  className="flex h-10 w-15 rounded-full border-gray-200 border mr-3 cursor-pointer collapse bg-gray-100 discussion"
                  role="button"
                  tabIndex={0}
                >
                  <div className="icon flex text-gray-700">
                    <SvgDiscussion
                      className="svgIcon"
                      color="gray.500"
                      width={24}
                      height={24}
                      margin="auto 0.5rem"
                    />
                    <div className="my-auto">Discussion</div>
                  </div>
                </div>
                <div
                  className="flex h-10 w-15 rounded-full border-gray-200 border mr-3 cursor-pointer collapse bg-gray-100 poll"
                  role="button"
                  tabIndex={0}
                >
                  <div className="icon flex text-gray-700">
                    <SvgPoll
                      className="svgIcon"
                      color="gray.500"
                      width={24}
                      height={24}
                      margin="auto 0.5rem"
                    />
                    <div className="my-auto">Poll</div>
                  </div>
                </div>
                <button
                  type="button"
                  className="bg-blue-500 text-white px-9 py-2 rounded-full mx-1 absolute right-0 font-medium"
                  onClick={methods.handleSubmit(onSubmit)}
                  onKeyDown={methods.handleSubmit(onSubmit)}
                >
                  {t('Post')}
                </button>
              </div>
            </div>
            <WarningModalWithoutCancel
              {...warningModal}
              title={warningModalTitle}
              text={warningModalText}
              okBtnText="Upload again"
            />
            <ImageCropModal
              title="Cover Image"
              {...cropper}
              content={{ maxW: 1020, maxH: '100%' }}
            />
            <UploadModal
              modal={uploadModal}
              clickHandle={(e) => handleUpload(e)}
            />
            <OneTmModal
              modal={unsplashModal}
              okText="Confirm"
              title="Add Image"
              showOk={false}
            >
              <UnsplashSelect
                height="420px"
                onChange={async (us) => {
                  if (us) {
                    unsplashModal.onClose();
                    getFileToCrop(us.url);
                  }
                }}
              />
            </OneTmModal>
          </ModalFooter>
          <style jsx global>{`
            .ce-toolbar__actions {
              visibility: hidden;
            }
            .codex-editor__redactor {
              padding-bottom: 0 !important;
            }
            .css-ivgeko-menu {
              margin: 0;
            }
            .mentionUserContainer {
              position: fixed !important;
            }
          `}</style>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export function UploadModal({
  modal,
  clickHandle,
}: {
  modal: UseDisclosureReturn;
  clickHandle: (value: any) => void;
}) {
  const modalRef = useRef<ElementRef<typeof ModalContent>>();
  // const [value, setValue] = useState('');
  // const handleChange = (event) => setValue(event.target.value);
  const tabTitle = ['Upload', 'Unsplash']; // 'Embed Link'
  const [recentTab, setRecentTab] = useState(0);
  const { colorMode } = useColorMode();
  const tabHandler = (event) => {
    setRecentTab(event);
  };

  const getTabInfo = (data) => {
    if (data === 'title') {
      if (recentTab === 0) {
        return 'Select Image';
      }
      return tabTitle[recentTab];
    }
    // if (recentTab === 1) {
    //   clickHandle({ type: 'Embed', value });
    // }
    if (recentTab === 0) {
      clickHandle({ type: tabTitle[recentTab] });
    }
  };

  const getIcon = (data) => {
    if (data === 'Upload') {
      return (
        <SvgCamera
          className="svgIcon"
          color="gray.500"
          width={24}
          height={24}
          margin="auto 0.5rem"
        />
      );
    }
    if (data === 'Embed Link') {
      return (
        <SvgLink
          className="svgIcon"
          color="gray.500"
          width={24}
          height={24}
          margin="auto 0.5rem"
        />
      );
    }
    return (
      <SvgUnsplash
        className="svgIcon"
        color="gray.500"
        width={24}
        height={24}
        margin="auto 0.5rem"
      />
    );
  };

  return (
    <Modal
      isCentered
      autoFocus={false}
      {...modal}
      blockScrollOnMount
      motionPreset="none"
    >
      <ModalOverlay>
        <ModalContent
          ref={modalRef}
          borderRadius="xl"
          className="p-2"
          minWidth="674px"
          maxWidth="674px"
          minHeight="168px"
          maxHeight="502px"
        >
          <div style={{ width: '470px' }} className="mx-auto">
            <Tabs index={recentTab} onChange={tabHandler}>
              <TabList marginTop={{ base: '7px', md: '12px' }} border="unset">
                {tabTitle.map((tab) => (
                  <Tab
                    key={`${tab}`}
                    _selected={
                      colorMode === 'light'
                        ? { color: '#242526' }
                        : { color: '#fff' }
                    }
                    padding={{ base: '0 0 11px 0', md: '0 0 16px 0' }}
                    width="100%"
                    textStyle="labelLight"
                    color="$mode.400"
                    fontSize={{ base: '14px', md: '16px' }}
                    fontWeight="bold"
                  >
                    {getIcon(tab)}
                    {tab}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </div>
          {/* }
          {recentTab === 1 && (
            <div className="px-4">
              <Input
                variant="flushed"
                placeholder="Paste the image link here"
                onChange={handleChange}
              />
            </div>
          )}
        */}
          {recentTab === 1 && (
            <UnsplashSelect
              height="420px"
              onChange={async (us) => {
                if (us) {
                  clickHandle({ type: 'Unsplash', value: us.url });
                }
              }}
            />
          )}
          {recentTab !== 1 && (
            <button
              type="button"
              className="m-auto font-semibold rounded my-2 cursor-pointer text-blue-500 border border-gray-200 px-5 py-2 w-max"
              onClick={() => getTabInfo('click')}
            >
              {getTabInfo('title')}
            </button>
          )}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

function generateUniqueID() {
  const day = new Date();
  return day.getTime();
}

export default AgoraPostModal;
