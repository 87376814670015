import Link from 'next/link';
import { useState, useContext, useEffect } from 'react';
import {
  Button,
  Link as ChakraLink,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

import SvgVisibilityOffOutlined from 'src/components/icons/visibility-off-outlined';
import SvgVisibilityOutlined from 'src/components/icons/visibility-outlined';
import { useTranslation } from 'react-i18next';
import { UserContext, UserContextType } from '../../../contexts/UserContext';
import { SignInRequest } from '../service';

interface SignInFormProps {
  toggleForm: () => void;
}

function ModalSignInForm({ toggleForm }: SignInFormProps) {
  const [visible, setVisible] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);

  const router = useRouter();
  const { register, handleSubmit, errors, watch } = useForm<SignInRequest>();

  const { signIn } = useContext(UserContext) as UserContextType;

  const toggleVisible = () => setVisible((prevVisible) => !prevVisible);

  const onSubmit = handleSubmit(async (data) => {
    setDisableBtn(true);
    const signInResponse: any = await signIn(data);
    if (signInResponse.data.code === 1000) {
      if (signInResponse.status === 401) {
        router.push({
          query: {
            page: 'notverifidet',
            email: data.email,
          },
        });
      } else if (router.query?.['return_to']) {
        const returnTo = router.query.return_to as string;
        router.replace(returnTo);
      } else if (router.query.page !== 'signIn') {
        router.push('/');
      }
    } else if (signInResponse.status === 401) {
      setErrorCode(signInResponse.data.code);
    } else {
      setErrorCode(signInResponse.data.code);
    }
    setDisableBtn(false);
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (watch('email')?.length === 0 || watch('password')?.length === 0) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [watch]);

  return (
    <form autoComplete="nope" onSubmit={onSubmit}>
      <Stack spacing={[3, 2, 4]}>
        {errorCode === 1001 && (
          <Text
            textAlign="center"
            color="#FF6633"
            fontSize="sm"
            lineHeight="20px"
          >
            {t('Invalid email or password')}
          </Text>
        )}
        <FormControl id="email">
          <FormLabel mx={0} mb={2}>
            <span className="text-gray-700 font-bold lg:font-medium text-xs lg:text-sm">
              {t('Email')}
            </span>
          </FormLabel>
          <Input
            border="1px solid"
            _focus={{ borderColor: 'gray.400' }}
            name="email"
            autoComplete="nope"
            type="email"
            background={
              errorCode === 1001 ? 'rgba(255, 102, 51, 0.1)' : 'white'
            }
            style={{
              fontFeatureSettings: `'calt' 0`,
            }}
            borderColor="gray.300"
            isInvalid={!!errors?.email || errorCode === 1001}
            ref={register({
              required: true,
            })}
          />
        </FormControl>

        <FormControl id="password">
          <FormLabel m={0} fontSize="sm">
            <Flex justifyContent="space-between" mb={2}>
              <Text>
                <span className="text-gray-700 font-bold lg:font-medium text-xs lg:text-sm">
                  {t('Password')}
                </span>
              </Text>
              <ChakraLink onClick={toggleForm}>
                <div>
                  <span className="font-medium text-xs">
                    {t('Forgot password?')}
                  </span>
                </div>
              </ChakraLink>
            </Flex>
          </FormLabel>
          <InputGroup>
            <Input
              border="1px solid"
              name="password"
              autoComplete="nope"
              _focus={{ borderColor: 'gray.400' }}
              type={visible ? 'text' : 'password'}
              background={
                errorCode === 1001 ? 'rgba(255, 102, 51, 0.1)' : 'white'
              }
              isInvalid={!!errors?.password || errorCode === 1001}
              borderColor="gray.300"
              ref={register({
                required: true,
              })}
            />
            <InputRightElement cursor="pointer">
              {visible ? (
                <Icon
                  as={SvgVisibilityOffOutlined}
                  boxSize="24px"
                  color="$mode.500"
                  onClick={toggleVisible}
                />
              ) : (
                <Icon
                  as={SvgVisibilityOutlined}
                  boxSize="24px"
                  color="$mode.500"
                  onClick={toggleVisible}
                />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </Stack>

      <Stack mt={4} spacing={3} alignItems={['center', 'flex-start']}>
        <div className="w-full flex justify-center lg:justify-start">
          <Button
            rounded="ellipse"
            type="submit"
            isDisabled={disableBtn}
            width="max-content"
            px="32px"
            py="10px"
          >
            <span className="text-sm lg:text-base">{t('Sign in')}</span>
          </Button>
        </div>

        <div className="w-full flex justify-center lg:justify-start">
          <Text textAlign="center">
            <span className="font-medium text-xs lg:text-sm">
              {t('Don’t have an account yet?')}{' '}
            </span>
            <Link href="/activate?noCode=true" passHref>
              <ChakraLink>
                <span className="font-medium text-xs lg:text-sm">
                  {t('Create one here.')}
                </span>
              </ChakraLink>
            </Link>
          </Text>
        </div>
      </Stack>
    </form>
  );
}

export default ModalSignInForm;
