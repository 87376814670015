import * as React from 'react';
import { Svg } from '../svg';

function SvgEnthuseGray({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8 15.333a5.45 5.45 0 003.771-1.498c1-.96 1.563-2.261 1.563-3.618 0-.59-.164-1.158-.356-1.685-1.185 1.124-2.086 1.685-2.702 1.685 2.84-4.775 1.28-6.822-2.987-9.55.356 3.41-1.988 4.962-2.943 5.823a5.07 5.07 0 00-1.551 2.614c-.233 1-.147 2.045.247 2.998a5.162 5.162 0 001.961 2.347A5.495 5.495 0 008 15.333zm.505-12.118c2.305 1.876 2.316 3.333.535 6.326-.54.91.144 2.04 1.236 2.04.489 0 .984-.136 1.507-.405a3.706 3.706 0 01-.844 1.516c-.4.437-.9.778-1.46.997A4.062 4.062 0 016 13.44a3.844 3.844 0 01-1.288-1.195 3.61 3.61 0 01-.38-3.327c.21-.544.548-1.035.989-1.433.09-.08.544-.468.563-.485.302-.259.55-.489.796-.74.874-.9 1.503-1.897 1.824-3.044h.001z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEnthuseGray);
export default ForwardRef;
