import { MenuItem } from '@chakra-ui/react';
import { Avatar } from 'src/components/avatar';
import { IconMenu } from 'src/components/icon-menu/icon-menu';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import Link from 'next/link';
import SvgFlagV2 from 'src/components/icons/flag-v2';
import type { TeamMember } from '../service';

interface MemberCardProps {
  info: TeamMember;
  isLoaded?: boolean;
  isMe?: boolean;
  isOwner?: boolean;
  hideSettings?: boolean;
  hideFollow?: boolean;
  isTooltip?: boolean;
  className?: string;
  isReppl?: string;
  onRemove?: () => void;
  onFollow?: () => void;
}

export function MemberCard({
  info,
  hideFollow,
  hideSettings,
  isTooltip,
  className = '',
  isOwner = false,
  isReppl,
  ...props
}: MemberCardProps) {
  const profileName = getProfileFullNameOrUsername(info);
  const passions = info['passions'] ? info['passions'].slice(0, 2) : [];
  return (
    <section
      className={`card creator-card bg-white rounded p-6 mt-4 mb-2 mx-2 ${className} relative flex-shrink-0 `}
    >
      {(info['role'] === '101' || info['role'] === '1') && (
        <div className="bg-olive-300 text-xs absolute -left-2 -top-5 text-white rounded-full px-3 py-2">
          <span className="align-text-bottom mr-1">
            <SvgFlagV2 className="inline" />
          </span>
          <span className="font-bold">Starter</span>
        </div>
      )}
      <div className="text-denim-500 text-xs absolute right-6 top-7 ">
        {(isOwner || props?.isMe) &&
          !(info['role'] === '101' || info['role'] === '1') &&
          info['role'] !== '104' && (
            <IconMenu portal color="gray.500" position="absolute" right={0}>
              <MenuItem onClick={() => props?.onRemove()}>
                {props?.isMe ? 'Leave the team' : 'Remove from the team'}
              </MenuItem>
            </IconMenu>
          )}
      </div>
      <Link
        href={{
          pathname: `${
            isReppl
              ? `/reppl/${isReppl}/team/${info['username']}`
              : `/${info['username']}`
          }`,
        }}
        prefetch={false}
      >
        <a className="flex flex-col justify-center items-center h-full">
          {info['profileImgURL'] === '' && (
            <Avatar
              defaultImg
              author={info}
              className="block"
              width={56}
              height={56}
            />
          )}
          {info['profileImgURL'] !== '' && (
            <img
              className="w-14 rounded-full"
              alt={info['username']}
              src={info['profileImgURL']}
            />
          )}

          <div className="font-bold mt-4 mb-2">{profileName}</div>
          <p className="motto font-normal text-gray-400 mb-3 text-sm leading-4.5 line-clamp-2">
            {info['title']}
          </p>
          <div className="font-bold text-gray-400 mt-4 text-xs flex">
            {passions.map((passion, key) => {
              const mkey = `key_${key}`;
              const css = key === 1 ? 'overflow-hidden overflow-ellipsis' : '';
              return (
                <span
                  key={mkey}
                  className={`bg-denim-50 text-denim-500 whitespace-nowrap py-1 px-3 font-bold rounded-full mr-1 ${css}`}
                >
                  {passion}
                </span>
              );
            })}
          </div>
        </a>
      </Link>
    </section>
  );
}
