import * as React from 'react';
import { Svg } from '../svg';

function SvgFlag({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={12} cy={12} r={12} fill="#E4B202" />
      <path
        d="M7 7.5h9.569a.25.25 0 01.218.374L15 11l1.787 3.126a.25.25 0 01-.218.374H8V17H7V7.5z"
        fill="#fff"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgFlag);
export default ForwardRef;
