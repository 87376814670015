export const Creations2Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Create with 1TM
      </h1>
      <img
        src="/images/assets/tutorial/creations-2.png"
        alt="creations 2"
        className="w-full"
      />
    </>
  );
};

export const Creations2Footer = ({ setNextModal }: { setNextModal?: any }) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Ready to Go?
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Second, create your post. Add a title, cover photo, and fill up a post
        with all those ideas bursting from within. When the post is finished,
        hit the Publish button and share it with the world! Not satisfied yet?
        Simply click ‘Save’ and finish up later.
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('creations3');
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Next
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        2 / 4
      </h6>
    </>
  );
};
