import * as React from 'react';
import { Svg } from '../svg';

function SvgTwitterV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M0 20C0 8.951 8.951 0 20 0s20 8.951 20 20-8.951 20-20 20C8.951 39.977 0 31.026 0 20z"
        fill="currentColor"
      />
      <path
        d="M16.486 28.909c8.124 0 12.568-6.734 12.568-12.573 0-.191-.004-.382-.013-.571a8.983 8.983 0 002.203-2.289 8.803 8.803 0 01-2.536.696 4.435 4.435 0 001.942-2.444 8.857 8.857 0 01-2.805 1.073 4.419 4.419 0 00-7.527 4.03 12.54 12.54 0 01-9.105-4.617 4.41 4.41 0 00-.598 2.22c0 1.534.78 2.887 1.966 3.679a4.385 4.385 0 01-2.001-.553v.056a4.42 4.42 0 003.543 4.332 4.423 4.423 0 01-1.995.076 4.422 4.422 0 004.127 3.069 8.86 8.86 0 01-5.487 1.892 8.96 8.96 0 01-1.053-.062 12.5 12.5 0 006.77 1.985"
        fill="#242526"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTwitterV3);
export default ForwardRef;
