import type { OutputData } from '@editorjs/editorjs';

import { ListType } from 'src/service/creation';
import { axios } from 'src/lib/axios';
import { fetchProjectListByType } from 'src/service/project';
import type { AuthorCountInfo } from 'src/app/article/types';
import type { Label } from 'src/app/label/services';
import type {
  BasicPageRequestQuery,
  RestPageResponse,
  RestResponse,
  SortType,
} from 'src/types';

import type { SocialMedia } from 'src/app/profile/types';

import type { Access, RepplType, Schedule } from './types';

export interface RepplRequest {
  type: RepplType;
  coverImgURL?: string;
  access: Access;
  title: string;
  headline: string;
  copyright: string;
  summary: string;
  schedule?: Schedule;
  description?: OutputData;
  guidelines?: OutputData & { edit?: boolean };
  guidelinesDraft?: OutputData & { edit?: boolean };
  descriptionDraft?: OutputData;
  categories?: string[];
  submitStart?: Date;
  submitEnd?: Date;
  isOpen: boolean;
  isOfficial: boolean;
  canSubmit: boolean;
  canSubmitOfficial: boolean;
  announcement?: Announcement;
}

type CreateRepplResponse = RestResponse<{
  id: string;
}>;

export function getSubmissions(rid) {
  return axios
    .get<RestResponse<any>>(`/creations/project/${rid}/SUBMITTED`)
    .then((res) => res.data);
}

export function getCountPendingSubmissions(rid) {
  return axios
    .get<RestResponse<any>>(`/reppl/${rid}/submission/pending-count`)
    .then((res) => res.data);
}

export const createReppl = (data: RepplRequest) =>
  axios.post<CreateRepplResponse>('/reppl', data).then((res) => res.data);

type UpdateRepplRequest = {
  id: string;
  data: Partial<RepplRequest>;
  notify?: boolean;
};

export interface TeamMember {
  about?: string;
  id?: number;
  userID?: number;
  username?: string;
  role?: string;
  profileImgURL?: string;
  coverImgURL?: string;
  firstName?: string;
  lastName?: string;
  numOfEpisodes?: number;
  numOfCollections?: number;
  episodeImageURLs?: string[];
  isFollowed?: boolean;
  isFollowing?: boolean;
  joinedDate?: Date;
}

export interface AnnouncementProps {
  isOpen: boolean;
  headline: string;
  description: string;
}

export interface Announcement {
  official: AnnouncementProps;
  open: AnnouncementProps;
}

export interface Reppl extends RepplRequest {
  id: string;
  coverImgURL: string;
  avatarImgURL: string;
  summary: string;
  isOwner: boolean;
  isOpen: boolean;
  isOfficial: boolean;
  draft: boolean;
  episodeCount: number;
  followerCount: number;
  isFollowed: boolean;
  createdAt: Date;
  feature: Label[];
  team: TeamMember[];
  invitation?: any;
  userRole: string;
  canSubmit: boolean;
  canSubmitOfficial: boolean;
  announcement?: Announcement;
  defaultSortingType?: string;
}

export interface RepplCreator {
  userID: number;
  username: string;
  profileImgURL: string;
  userTitle: string;
  firstName: string;
  lastName: string;
  motto: string;
  about: string;
  count: AuthorCountInfo;
  isFollowed: boolean;
}

export interface RepplResponse {
  creator: RepplCreator;
  reppl: Reppl;
}

export const getReppl = (id: string) => {
  return axios
    .get<RestResponse<RepplResponse>>(`/reppl/${id}`)
    .then((res) => res.data)
    .then((res) => res.data);
};

export const updateReppl = (data: UpdateRepplRequest) =>
  axios.put<RestResponse>('/reppl', data).then((res) => res.data);

type GetRepplPublishableResponse = RestResponse<{
  code: number;
  isPublishable: boolean;
}>;

export const getRepplPublishable = (id: string) =>
  axios
    .get<GetRepplPublishableResponse>(`/reppl/${id}/publishable`)
    .then((res) => res.data)
    .then((res) => res.data);

interface UpdateRepplStatusRequest {
  id: string;
  draft: boolean;
}

// publish status
export const updateRepplStatus = (data: UpdateRepplStatusRequest) =>
  axios.put<RestResponse>('/reppl/publish', data).then((res) => res.data);

export interface RepplEpisodeRequest {
  repplId: string;
  pageNum?: number;
  sortType?: SortType;
  keyword?: string;
}

export type RepplEpisodeResponse = RestPageResponse<ListType[]>['data'] & {
  pageNum?: number;
};

export async function getRepplEpisodes({
  repplId,
  pageNum,
  sortType,
  ...params
}: RepplEpisodeRequest) {
  if (!repplId) {
    const emptyEpisodes: RepplEpisodeResponse = {
      data: [],
      next: false,
      total: 0,
      pageNum: undefined,
    };
    return Promise.resolve(emptyEpisodes);
  }

  const p = pageNum || 1;
  const s = sortType || 'RECENCY';

  return fetchProjectListByType({
    id: repplId,
    params: {
      ...params,
      pageNum: p,
      sortType: s,
    },
  }).then((res) => ({
    ...res,
    pageNum: res.next ? pageNum + 1 : false,
  }));
}

export type LookupUserRequest = BasicPageRequestQuery & { tags?: string };
export interface LookupUserResonse {
  id: number;
  username: string;
  profileImgURL: string;
  coverImgURL: string;
  firstName: string;
  lastName: string;
  title: string;
  motto: string;
  about: string;
  country: string;
  city: string;
  websiteURL: string;
  numOfEpisodes: number;
  numOfFollowers: number;
  numOfFollowings: number;
  episodeEnthuse: number;
  studioEnthuse: number;
  jobs: string[];
  skills: string[];
  socialMedia: SocialMedia;
}

export function getLookupUsers(rid: string, params?: LookupUserRequest) {
  // ref: https://www.notion.so/1tmio/API-doc-e45b8b2b2cf54533bb59fb1c3b140e0d?p=996f3b7c5ca84425b54dc66abe2a1597
  return axios
    .get<RestPageResponse<LookupUserResonse[]>>(
      `/user/lookup/${params?.keyword ?? ''}/tags/${
        params?.tags ?? ''
      }/project/${rid}`,
      { params }
    )
    .then((res) => res.data)
    .then((res) => res.data);
  // .then((res) => ({
  //   ...res,
  //   pageNum: res.next ? params.pageNum + 1 : false,
  // }));
  // this is the code for pagination
}

export function getUser(username: string) {
  // ref: https://www.notion.so/1tmio/API-doc-e45b8b2b2cf54533bb59fb1c3b140e0d?p=996f3b7c5ca84425b54dc66abe2a1597
  return axios
    .get<RestPageResponse<any[]>>(`/user/lookup/${username}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export function createInvitation({
  rid,
  invitedIDs,
  message,
}: {
  rid: string;
  invitedIDs: number[];
  message: string;
}) {
  return axios
    .post<CreateRepplResponse>(`/project/${rid}/invite`, {
      projectType: 'REPPL',
      roleType: 'EDITOR', // TODO: for now just only editor
      invitedUserId: invitedIDs,
      message,
    })
    .then((res) => res.data);
}

export function deleteDeclinedInvitation({
  rid,
  invitedID,
}: {
  rid: string;
  invitedID: number;
}) {
  return axios
    .delete<RestResponse>(`/project/${rid}/invite/${invitedID}`)
    .then((res) => res.data);
}

export interface InvitationInfo {
  id: number;
  userID: number;
  roleID: number;
  newRoe: number;
  isConsumed: boolean;
  isDeclined: boolean;
  message: string;
  respondDate: Date;
  createdAt: Date;
  inviterUsername;
  inviteeInfo: {
    userID: number;
    username: string;
    profileImgURL: string;
    coverImgURL: string;
    firstName: string;
    lastName: string;
    role: string;
  };
}

export function getInvitationList({ rid }: { rid: string }) {
  return axios
    .get<RestResponse<InvitationInfo[]>>(`/invites/${rid}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export function getInvitesList({ rid }: { rid: string }) {
  return axios
    .get<RestResponse<InvitationInfo[]>>(`/user/invites/${rid}`)
    .then((res) => res.data)
    .then((res) => res.data);
}
