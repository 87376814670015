import { Stack, Text, Box } from '@chakra-ui/react';
import { Avatar } from 'src/components/avatar';
import { NextChakraLink } from 'src/components/next-chakra-link';
import { useRouterQuery } from 'src/hooks/use-router-query';
import { useProjectListByTypeQuery } from 'src/service/project';
import Carousel from 'nuka-carousel';
import SvgArrowLeft from 'src/components/icons/arrow-left';
import SvgArrowRight from 'src/components/icons/arrow-right';
import { useIsMobile, useIsLg } from 'src/hooks/use-is-mobile';
import { useState, useEffect } from 'react';
import { useRepplQuery } from '../../hooks';
import {
  ArticleGridRowView,
  ArticleRowItem,
} from '../../../article/components/grid-view/grid-row-view';

export function EpisodeMoreFrom({
  type = 'grid',
  modalChange,
  sourceID,
}: {
  type?: string;
  modalChange?: (value: any) => void;
  sourceID?: string;
}) {
  const { rid, eid } = useRouterQuery('rid', 'eid');
  const { data: projects } = useProjectListByTypeQuery({
    id: sourceID || rid,
    params: {
      pageNum: 0,
    },
  });

  const projectsExpectCurrent = projects?.data?.filter(
    (p) => p.contentID !== eid
  );
  const projectLength = projectsExpectCurrent?.length ?? 0;
  const { data: repplData } = useRepplQuery(sourceID || rid);

  const isMobile = useIsMobile();
  const isLg = useIsLg();

  const [showEpisodesCount, setShowEpisodesCount] = useState(5);

  useEffect(() => {
    if (isLg) {
      setShowEpisodesCount(3);
    } else {
      setShowEpisodesCount(5);
    }
  }, [isLg]);

  if (projectLength === 0) {
    return null;
  }

  return (
    <>
      {type === 'grid' && (
        <ArticleGridRowView
          type="REPPL"
          articleList={projectsExpectCurrent.slice(0, 4)}
          header={
            <>
              <Stack direction="row" spacing={3} align="center">
                <Text textStyle="h5" color="$mode.900">
                  More From
                </Text>
                <NextChakraLink href={`/reppl/${repplData?.reppl.id}`}>
                  <Stack direction="row" spacing="12px" align="center">
                    <Avatar
                      width={40}
                      height={40}
                      isLoading={!repplData?.reppl}
                      name={repplData?.reppl?.title}
                      src={repplData?.reppl?.avatarImgURL}
                    />
                    <Text
                      textStyle="buttonText"
                      color="$mode.900"
                      noOfLines={1}
                    >
                      {repplData?.reppl?.title}
                    </Text>
                  </Stack>
                </NextChakraLink>
              </Stack>
            </>
          }
        />
      )}
      {type === 'carousel' && (
        <Box
          className="more-from-reppl-carousel"
          px={isMobile ? 0 : '35px'}
          py="32px"
          bg="gray.100"
        >
          <Text
            textStyle="h5"
            color="$mode.900"
            py="18px"
            pr={isMobile ? '24px' : '55px'}
            pl={isMobile ? '24px' : 0}
          >
            More From{' '}
            <NextChakraLink
              className="more-from-link"
              color="$mode.900"
              href={`/reppl/${repplData?.reppl.id}`}
            >
              {repplData?.reppl?.title}
            </NextChakraLink>
          </Text>
          {isMobile ? (
            <div className="grid grid-cols-2">
              {projectsExpectCurrent.map((episode) => {
                return (
                  <Box key={episode?.contentID}>
                    <ArticleRowItem
                      cardData={episode}
                      onModalChange={modalChange}
                    />
                  </Box>
                );
              })}
            </div>
          ) : (
            <Carousel
              slidesToShow={showEpisodesCount}
              cellSpacing={24}
              renderCenterLeftControls={({ previousSlide }) => (
                <SvgArrowLeft
                  _hover={{ cursor: 'pointer' }}
                  onClick={previousSlide}
                  w="25px"
                  h="auto"
                />
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <SvgArrowRight
                  _hover={{ cursor: 'pointer' }}
                  onClick={nextSlide}
                  w="25px"
                  h="auto"
                />
              )}
              renderBottomCenterControls={() => null}
            >
              {projectsExpectCurrent.map((episode) => {
                return (
                  <Box key={episode?.contentID}>
                    <ArticleRowItem
                      cardData={episode}
                      onModalChange={modalChange}
                    />
                  </Box>
                );
              })}
            </Carousel>
          )}
          <style jsx global>{`
            .more-from-reppl-carousel .slider-control-centerright {
              top: -30px !important;
              right: 0 !important;
            }
            .more-from-reppl-carousel .slider-control-centerleft {
              top: -30px !important;
              right: 30px !important;
              left: auto !important;
            }
            .more-from-link {
              color: #242526 !important;
              text-decoration: none !important;
            }
          `}</style>
        </Box>
      )}
    </>
  );
}
