import { useTutorialMutation } from 'src/app/explore/use-tutorial';

export const Creations4Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Create with 1TM
      </h1>
      <img
        src="/images/assets/tutorial/creations-4.png"
        alt="creations 4"
        className="w-full"
      />
    </>
  );
};

export const Creations4Footer = ({
  closeModal,
  completed,
}: {
  closeModal?: any;
  completed: boolean;
}) => {
  const { mutateAsync: updateTutorial } = useTutorialMutation();
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Published Successfully!
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Now your work is published! You can find it in your Studio, and it will
        appear in Feed and Topics as well. If you opted for Save as Draft, it
        will be listed under “Drafts and Submissions”.
      </p>
      <button
        type="button"
        onClick={async () => {
          if (!completed) {
            await updateTutorial({ step: 'create_post' });
          }
          closeModal();
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Done
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        4 / 4
      </h6>
    </>
  );
};
