import { useTutorialMutation } from 'src/app/explore/use-tutorial';

export const SidePanelsBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Meet Agora
      </h1>
      <img
        src="/images/assets/tutorial/side-panels.png"
        alt="side panels"
        className="w-full"
      />
    </>
  );
};

export const SidePanelsFooter = ({
  closeModal,
  completed,
}: {
  closeModal?: any;
  completed: boolean;
}) => {
  const { mutateAsync: updateTutorial } = useTutorialMutation();
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Trending Widgets
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        See what’s trending! The widgets on the side show the latest trending
        posts based on Enthuses, Follows, and comments. See what the hype is
        about and discover something new.
      </p>
      <button
        type="button"
        onClick={async () => {
          if (!completed) {
            await updateTutorial({ step: 'whats_agora' });
          }
          closeModal();
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Done
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        3 / 3
      </h6>
    </>
  );
};
