export const HowToStartARepplStartBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Start a RE:PPL
      </h1>
      <img
        src="/images/assets/tutorial/how-to-start-a-reppl-start.png"
        alt="how to start a reppl"
        className="w-full"
      />
    </>
  );
};

export const HowToStartARepplStartFooter = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        How to start a RE:PPL?
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Click on the “Start a RE:PPL” button at the top of the RE:PPL index page
        and embark on the quest to build your team.
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('howToStartAReppl1');
        }}
        className="px-9 py-2.5 mb-6 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Start
      </button>
    </>
  );
};
