import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  UseDisclosureReturn,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useReducer } from 'react';
// import { Transition } from '@headlessui/react';
import { OneTmModal } from 'src/components/1tm-modal';
import type { Options } from 'src/app/reppl/types';

// import SvgInfo from 'src/components/icons/info';
import { useTranslation } from 'react-i18next';
import { LICENSE_FEATURES } from './types';

type Licence = keyof typeof LICENSE_FEATURES;
export const isLicence = (copyright?: string): copyright is Licence =>
  copyright in LICENSE_FEATURES;

interface Props {
  copyright: string;
  isLoaded?: boolean;
  changeable?: boolean;
  sharable?: boolean;
  handleChange?: (copyright: string) => void;
}

export function CurrentLicense({
  changeable = true,
  isLoaded = true,
  copyright,
  ...props
}: Props) {
  const { t } = useTranslation();
  const licenseModal = useDisclosure();

  if (!isLoaded) {
    return null;
  }

  const license = isLicence(copyright) && LICENSE_FEATURES[copyright];
  // const [licenseTipOpen, setLicenseTipOpen] = useState(false);
  return (
    <Flex
      py={3}
      px={4}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="4px"
      layerStyle="layer3"
      boxShadow="0px 1px 4px rgba(0, 0, 0, 0.06)"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Flex direction="column">
        {copyright !== t('All Rights Reserved') ? (
          <>
            <Stack spacing={2} direction="row" width="fit-content">
              {license &&
                license.icons.map((icon, i) => (
                  <Icon
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    borderRadius="50%"
                    color="black"
                    bg="white"
                    as={icon}
                    boxSize="40px"
                  />
                ))}
            </Stack>
            <div className="text-sm text-gray-500 mt-2">
              <Link href={license.url} isExternal>
                {t(license.license)}
              </Link>

              {/* <span
                className="relative"
                onClick={() => {
                  setLicenseTipOpen(!licenseTipOpen);
                }}
                aria-hidden="true"
              >
                <SvgInfo className="inline ml-1" />
                <Transition
                  show={CurrentLicense}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <span className="p-4 absolute z-10 text-xs rounded-md license-tip tip block right-0 bg-white">
                    This license requires that reusers give credit to the
                    creator. It allows reusers to copy and distribute the
                    material in any medium or format in unadapted form and for
                    noncommercial purposes only.
                  </span>
                </Transition>
              </span> */}
            </div>
          </>
        ) : (
          t('All Rights Reserved')
        )}
      </Flex>
      {changeable && (
        <Button variant="link" onClick={() => licenseModal.onOpen()}>
          {t('Change')}
        </Button>
      )}
      {changeable && isLoaded && (
        <LicenseModal
          modal={licenseModal}
          copyright={copyright}
          handleChange={props?.handleChange}
        />
      )}
    </Flex>
  );
}

type Action =
  | { type: 'Sharing'; state: Options }
  | { type: 'Adaptations'; state: Options }
  | { type: 'Commercial'; state: Options }
  | { type: 'ALL'; sharing: string; adaptations: Options; commercial: Options };

export type Sharing = Record<'sharing' | 'adaptations' | 'commercial', Options>;

const initState: Sharing = {
  sharing: 'yes',
  adaptations: 'yes',
  commercial: 'yes',
};

function reducer(state: Sharing, action: Action) {
  switch (action.type) {
    case 'Sharing':
      return {
        ...state,
        sharing: action.state,
      };
    case 'Adaptations':
      return {
        ...state,
        adaptations: action.state,
      };
    case 'Commercial':
      return {
        ...state,
        commercial: action.state,
      };

    default:
      return state;
  }
}

function getLicense({ sharing, adaptations, commercial }: Sharing) {
  if (sharing === 'yes') {
    const result = Object.keys(LICENSE_FEATURES)
      .map((l: keyof typeof LICENSE_FEATURES) => {
        if (
          LICENSE_FEATURES[l].adaptations === adaptations &&
          LICENSE_FEATURES[l].commercial === commercial
        ) {
          return LICENSE_FEATURES[l];
        }
        return undefined;
      })
      .filter(Boolean);

    if (result.length === 0) {
      // If not found the license, given the default license
      return {
        license: 'Attribution 4.0 International',
      };
    }

    return result[0];
  }

  return {
    license: 'All Rights Reserved',
  };
}

function LicenseModal({
  modal,
  copyright,
  handleChange,
}: {
  modal: UseDisclosureReturn;
  copyright: string;
  handleChange?: (c: string) => void;
}) {
  const sharable = copyright !== 'All Rights Reserved';
  const [licenseState, dispatch] = useReducer(reducer, {
    ...initState,
    sharing: sharable ? 'yes' : 'no',
    // If not shareable, given the default value
    adaptations: sharable ? LICENSE_FEATURES[copyright]?.adaptations : 'yes',
    commercial: sharable ? LICENSE_FEATURES[copyright]?.commercial : 'yes',
  });
  const isAllowSharing = licenseState.sharing === 'yes';
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: isAllowSharing,
  });
  const { t } = useTranslation();
  return (
    <OneTmModal
      modal={modal}
      title={t('Change License Type')}
      okText={t('Update')}
      onOk={() => {
        if (handleChange) {
          const { license } = getLicense(licenseState);
          handleChange(license);
          modal.onClose();
        }
      }}
    >
      <Box p={6} overflowY="scroll">
        <Flex
          mt={6}
          alignItems="center"
          justifyContent="space-between"
          height="24px"
        >
          <Text
            fontWeight="bold"
            fontSize="sm"
            color="$mode.600"
            lineHeight="19px"
          >
            {t('Allow sharing?')}
          </Text>
          <Switch
            isChecked={isAllowSharing}
            onChange={(event) => {
              onToggle();

              if (event.target.checked) {
                dispatch({ type: 'Sharing', state: 'yes' });
              } else {
                dispatch({ type: 'Sharing', state: 'no' });
              }
            }}
          />
        </Flex>

        <Flex mt={2}>
          <Text textStyle="body3" color="$mode.500">
            {t('shareMessage1')}
          </Text>
        </Flex>

        <Collapse startingHeight={20} in={isOpen}>
          <VStack spacing={5} mt={5} px="4px">
            <Flex direction="column" width="100%" maxHeight="100%">
              <Text
                fontWeight="bold"
                fontSize="sm"
                color="$mode.600"
                lineHeight="19px"
              >
                {t('shareMessage2')}
              </Text>
              <RadioGroup
                mt={3}
                onChange={(state: Options) =>
                  dispatch({ type: 'Adaptations', state })
                }
                value={licenseState.adaptations}
              >
                <Stack direction={{ base: 'column', md: 'row' }} spacing={3}>
                  <Radio size="lg" value="yes">
                    <Box fontSize="sm" fontWeight="bold" lineHeight="18px">
                      {t('Yes')}
                    </Box>
                  </Radio>
                  <Radio size="lg" value="no">
                    <Box fontSize="sm" fontWeight="bold" lineHeight="18px">
                      {t('No')}
                    </Box>
                  </Radio>
                  <Radio size="lg" value="others">
                    <Box fontSize="sm" fontWeight="bold" lineHeight="18px">
                      {t('shareLike')}
                    </Box>
                  </Radio>
                </Stack>
              </RadioGroup>
            </Flex>

            <Flex direction="column" width="100%" maxHeight="100%">
              <Text
                fontWeight="bold"
                fontSize="sm"
                color="$mode.600"
                lineHeight="19px"
              >
                {t('allowCommercial')}
              </Text>
              <RadioGroup
                mt={3}
                onChange={(state: Options) =>
                  dispatch({ type: 'Commercial', state })
                }
                value={licenseState.commercial}
              >
                <Radio size="lg" value="yes" mr={3}>
                  <Box fontSize="sm" fontWeight="bold" lineHeight="18px">
                    {t('Yes')}
                  </Box>
                </Radio>
                <Radio size="lg" value="no">
                  <Box fontSize="sm" fontWeight="bold" lineHeight="18px">
                    {t('No')}
                  </Box>
                </Radio>
              </RadioGroup>
            </Flex>
          </VStack>
        </Collapse>

        <Flex mt={5} direction="column" width="100%">
          <Text
            fontWeight="bold"
            fontSize="sm"
            color="$mode.600"
            lineHeight="19px"
            mb={2}
          >
            {t('selectedlicense')}
          </Text>
          <CurrentLicense
            changeable={false}
            sharable={isAllowSharing}
            copyright={t(getLicense(licenseState).license)}
          />
        </Flex>
      </Box>
    </OneTmModal>
  );
}
