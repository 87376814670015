import { QueryKey, useMutation, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';

import { useContext } from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';

import { createFollow, deleteFollow } from 'src/app/follow/service';
import { logger } from 'src/lib/logger';
import type { FollowRequest } from 'src/app/follow/types';

export function useFollowUserMutation(queryKey: QueryKey) {
  const queryClient = useQueryClient();
  const router = useRouter();

  const { isLogin: isAuth } = useContext(UserContext) as UserContextType;
  return useMutation(
    (values: FollowRequest) => {
      if (!isAuth) {
        router.push({ query: { ...router.query, page: 'signIn' } });
        return;
      }

      return values.follow ? createFollow(values) : deleteFollow(values);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err) => {
        logger.error(err);
      },
    }
  );
}
