import * as React from 'react';
import { Svg } from '../svg';

function SvgLock({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12 5.334h1.333A.667.667 0 0114 6v8a.666.666 0 01-.667.667H2.667A.666.666 0 012 14V6a.667.667 0 01.667-.666H4v-.667a4 4 0 018 0v.667zm-4.667 5.154V12h1.334v-1.512a1.333 1.333 0 10-1.334 0zm3.334-5.154v-.667a2.667 2.667 0 00-5.334 0v.667h5.334z"
        fill="#fff"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLock);
export default ForwardRef;
