import React, { Fragment, ReactNode, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

interface DropdowsProps {
  buttonComponent: ReactNode;
  items: Array<any>;
  className?: string | undefined;
  dataID?: string;
  width?: string;
  textClass?: string;
  isClicked?: () => void;
}
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Dropdowns = (props: DropdowsProps) => {
  const {
    items,
    buttonComponent,
    width = '14rem',
    className = '',
    dataID = '',
    textClass = 'text-gray-700',
  } = props;

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen && props.isClicked) {
      props.isClicked();
    }
  }, [isOpen, setIsOpen]);

  return (
    <div className={`${className}`}>
      <Menu as="div" className="z-10 relative inline-block text-left">
        {({ open }) => (
          <>
            {setIsOpen(open)}
            <div>
              <Menu.Button className="inline-flex">
                {buttonComponent}
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                style={{ width }}
                className="menu-list z-50 origin-top-right absolute right-0 mt-2 rounded-md overflow-hidden shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {items.map((item, key) => {
                  const divKey = `${key}_${key}`;
                  return (
                    <div key={divKey} className="dark:bg-gray-900">
                      {item.map((i) => {
                        return (
                          <Menu.Item key={i.id}>
                            {({ active }) => {
                              if (i.type === 'link') {
                                return (
                                  <a
                                    href={i.path}
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : `${textClass}`,
                                      'block px-4 py-2 dark:text-white'
                                    )}
                                  >
                                    {/* <span className="align-bottom mr-2">
                                      {i.icon && i.icon}
                                    </span> */}
                                    {i.displayName}
                                  </a>
                                );
                              }
                              return (
                                <span
                                  onClick={i.action}
                                  aria-hidden="true"
                                  data-id={dataID}
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : `${textClass}`,
                                    'block px-4 py-2 cursor-pointer dark:text-white'
                                  )}
                                  role="menuitem"
                                >
                                  <span className="align-bottom ">
                                    {/* {i.icon && i.icon} */}
                                  </span>
                                  {t(i.displayName)}
                                </span>
                              );
                            }}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
export default React.memo(Dropdowns);
