import React, { useState } from 'react';
import {
  Icon,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

import SvgLinkedinV2 from 'src/components/icons/linkedin-v3';
import SvgInstagramV2 from 'src/components/icons/instagram-v3';
import SvgFacebookV2 from 'src/components/icons/facebook-v3';
// import SvgDiscord from 'src/components/icons/discord';
import Svg1TMSite from 'src/components/icons/1tm-site-footer';
import SvgTwitter from 'src/components/icons/twitter-v3';
import SvgLogoSm from 'src/components/icons/logo-sm';
import { useRouter } from 'next/router';
import PageModal from './page-modal';

import Community from './community';
import Terms from './terms';
import Copyright from './copyright';
import Privacy from './privacy';

function Footer() {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [contentType, setContentType] = useState('');
  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeContent, setIframeContent] = useState('');
  const modalHandle = (type) => {
    setIsOpen(true);
    setContentType(type);
  };
  const iframeHandle = (type) => {
    setIframeOpen(true);
    setIframeContent(type);
  };
  const companyData = {
    title: 'COMPANY',
    items: [
      { click: 'modal', value: 'Terms of Service' },
      { click: 'modal', value: 'Community Guidelines' },
      { click: 'modal', value: 'Privacy Policy' },
      { click: 'iframe', value: 'Careers' },
    ],
  };
  const officialData = {
    title: 'OFFICIAL RE:PPL',
    items: [
      // {
      //   click: 'link',
      //   value: 'Collaboration - A 1TM Story',
      //   link: 'https://www.1tm.io/reppl/ddbe7a70-1003-418e-87b8-e0c7458b936g',
      // },
      // {
      //   click: 'link',
      //   value: 'Meet the Creators',
      //   link: 'https://www.1tm.io/reppl/e8ef83b2-e74e-4712-9bcd-24a14298c018',
      // },
      {
        click: 'link',
        value: 'Together we create!',
        link: 'https://www.1tm.io/reppl/95bd9788-8c7d-46da-b52f-741465138e12',
      },
    ],
  };
  const helpData = {
    title: 'HELP & SUPPORT',
    items: [
      {
        click: 'link',
        value: 'Feedback and suggestions',
        link:
          'https://www.1tm.io/1TMofficial/5cb4ddd8-5aed-4e9c-9966-bf0a296879f3',
      },
      // { click: 'iframe', value: 'Help Center' },
      {
        click: 'link',
        value: 'Contact us at',
        link: 'mailto:workwithus@1tm.io',
      },
    ],
  };

  const checkClick = (data) => {
    if (data.click === 'modal') {
      modalHandle(data.value);
    } else if (data.click === 'iframe') {
      iframeHandle(data.value);
    } else if (data.click === 'link') {
      router.push(data.link);
    }
  };

  return (
    <div className="relative px-6 md:h-72 md:px-24 bg-gray-900 border-t border-gray-300 py-10 mx-auto md:flex justify-between items-center text-gray-400">
      <div className="text-sm flex-1 md:absolute md:top-7">
        <span className="mr-2 relative">
          <SvgLogoSm w="32px" h="32px" className="text-gray-400" />
          <div
            style={{ top: '-5px', left: '40px', fontSize: '11px' }}
            className="text-gray-400 font-bold absolute"
          >
            BETA
          </div>
        </span>
      </div>
      <div className="text-xs mb-24">
        <div className="mb-2">One team! Together we create.</div>
      </div>
      <div className="mb-16 md:flex md:w-6/12 md:pt-20 justify-end">
        <div className="text-xs mb-10 md:mr-16">
          <span className="text-gray-700 font-bold block">
            {companyData.title}
          </span>
          {companyData.items.map((items) => (
            <span
              aria-hidden="true"
              className="cursor-pointer block pt-4"
              onClick={() => {
                checkClick(items);
              }}
            >
              {items.value}
            </span>
          ))}
        </div>
        <div className="hidden text-xs mb-10 md:mr-16">
          <span className="text-gray-700 font-bold block">
            {officialData.title}
          </span>
          {officialData.items.map((items) => (
            <span
              aria-hidden="true"
              className="cursor-pointer block pt-4"
              onClick={() => {
                checkClick(items);
              }}
            >
              {items.value}
            </span>
          ))}
        </div>
        <div className="text-xs mb-10 md:mr-16">
          <span className="text-gray-700 font-bold block">
            {helpData.title}
          </span>
          {helpData.items.map((items) => (
            <span
              aria-hidden="true"
              className="cursor-pointer block pt-4"
              onClick={() => {
                checkClick(items);
              }}
            >
              {items.value === 'Contact us at' && (
                <div>
                  {items.value}
                  <span className="text-blue-500 pl-1">workwithus@1tm.io</span>
                </div>
              )}
              {items.value !== 'Contact us at' && <div>{items.value}</div>}
            </span>
          ))}
        </div>
      </div>
      <div className="text-xs flex-1 md:absolute md:bottom-14">
        <div className="py-4">
          <div className="py-1">Follow us on</div>
          <Link isExternal href="https://linkedin.com/company/join1tm">
            <Icon color="$mode.500" as={SvgLinkedinV2} boxSize="20px" />
          </Link>
          <Link isExternal href="https://www.facebook.com/oneteam1tm">
            <Icon
              color="$mode.500"
              as={SvgFacebookV2}
              className="mx-2"
              boxSize="20px"
            />
          </Link>
          <Link isExternal href="https://www.instagram.com/1tm.io">
            <Icon color="$mode.500" as={SvgInstagramV2} boxSize="20px" />
          </Link>
          {/* <Link isExternal href="https://discord.gg/ekD38GHNwN">
            <Icon
              color="$mode.500"
              className="mx-2"
              as={SvgDiscord}
              boxSize="20px"
            />
          </Link> */}
          <Link
            isExternal
            href="https://www.1tm.io/reppl/95bd9788-8c7d-46da-b52f-741465138e12"
          >
            <Icon
              color="$mode.500"
              className="mx-2"
              as={Svg1TMSite}
              boxSize="20px"
            />
          </Link>
          <Link isExternal href="https://twitter.com/1CUx1TM">
            <Icon color="$mode.500" as={SvgTwitter} boxSize="20px" />
          </Link>
        </div>
        <span className="">All rights reserved by 1TM ©</span>
      </div>
      <PageModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        contentType={contentType}
      >
        {contentType === 'Terms of Service' && <Terms />}
        {contentType === 'Community Guidelines' && <Community />}
        {contentType === 'copyright' && <Copyright />}
        {contentType === 'Privacy Policy' && <Privacy />}
      </PageModal>
      <IframeModal isOpen={iframeOpen} setIsOpen={setIframeOpen}>
        {iframeContent === 'Careers' && (
          <iframe
            title="careers"
            src="https://career.1tm.io/"
            width="100%"
            height="100%"
          />
        )}
        {iframeContent === 'Help Center' && (
          <iframe
            title="help"
            src="https://www.1tm.io/start-doing-together/"
            width="100%"
            height="100%"
          />
        )}
      </IframeModal>
    </div>
  );
}

const IframeModal = ({ children, isOpen, setIsOpen }) => {
  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay>
        <ModalCloseButton />
        <ModalContent bg="$mode.50" w="70%" maxW="1056px" h="90%" maxH="996px">
          <div className="w-full h-full">{children}</div>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default Footer;
