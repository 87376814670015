import { useContext, useState } from 'react';
import { Box, SkeletonCircle, SkeletonText, Stack } from '@chakra-ui/react';
import { CommentBlock } from 'src/components/comment';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { Comment, OnEnthuseType } from './types';

export interface ArticleCommentProps {
  isModal?: boolean;
  loading?: boolean;
  commentCount?: number;
  comments: Comment[];
  /**
   * Handling when the enthuse icon is clicked.
   */
  onEnthuse?: ({
    targetParentId,
    targetId,
    targetType,
    isEnthused,
  }: OnEnthuseType) => void;

  /**
   * Handling when the user typing something.
   */
  onComment?: ({
    keyEvent,
    blockId,
    value,
    commentID,
  }: {
    keyEvent?: any;
    blockId?: string;
    value: string;
    commentID?: any;
  }) => void;
}

export function ArticleComment({
  isModal,
  loading,
  comments,
  commentCount,
  onComment,
  onEnthuse,
}: ArticleCommentProps) {
  const { user } = useContext(UserContext) as UserContextType;
  const handleCommentEnthuse = ({
    targetId,
    targetParentId,
    isEnthused,
    targetOwnerId,
  }: Omit<OnEnthuseType, 'targetType'>) => {
    onEnthuse({
      targetId,
      targetParentId,
      targetType: 'COMMENT',
      isEnthused,
      targetOwnerId,
    });
  };

  const handleComment = ({
    event,
    blockId,
    commentID,
  }: {
    event: any;
    blockId?: string;
    commentID?: any;
  }) => {
    const { value } = event;
    const keyEvent = event.event;
    if (value !== '' && keyEvent.key === 'Enter') {
      onComment({ keyEvent, blockId, value, commentID });

      // event.currentTarget.blur();
      // eslint-disable-next-line no-param-reassign
      // event.currentTarget.value = '';
    }
  };

  const [childReply, setChildReply] = useState<any>();

  return (
    <>
      {loading ? (
        <Stack spacing={3} pl={3} direction="row">
          <SkeletonCircle size="40px" />
          <SkeletonText width="100%" noOfLines={4} spacing={3} />
        </Stack>
      ) : (
        commentCount > 0 && (
          <Stack spacing={4}>
            {comments.map((comment, index) => (
              <Box mt="0px!important" key={comment.id}>
                <CommentBlock
                  isModal={isModal}
                  comment={comment}
                  viewer={user}
                  index={`${index}`}
                  isReply={childReply}
                  onKeyDown={(event, commentID) =>
                    handleComment({ event, blockId: comment.id, commentID })
                  }
                  isEnthused={comment.isEnthused}
                  onEnthuse={() => {
                    handleCommentEnthuse({
                      targetId: comment.id,
                      targetParentId: comment.parentID,
                      isEnthused: comment.isEnthused,
                      targetOwnerId: comment.commenter.id,
                    });
                  }}
                >
                  {comment.child?.length > 0 &&
                    comment.child.map((subComment, index2) => (
                      <CommentBlock
                        comment={subComment}
                        key={subComment.id}
                        asSubcomment
                        onKeyDown={(event, commentID) =>
                          handleComment({
                            event,
                            blockId: comment.id,
                            commentID,
                          })
                        }
                        index={`${index2}`}
                        hideReply
                        viewer={user}
                        childReply={childReply}
                        setChildReply={(e) => setChildReply(e)}
                        isEnthused={subComment.isEnthused}
                        onEnthuse={() => {
                          handleCommentEnthuse({
                            targetId: subComment.id,
                            targetParentId: subComment.parentID,
                            isEnthused: subComment.isEnthused,
                            targetOwnerId: comment.commenter.id,
                          });
                        }}
                      />
                    ))}
                </CommentBlock>
              </Box>
            ))}
          </Stack>
        )
      )}
    </>
  );
}
