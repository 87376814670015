import * as React from 'react';
import { Svg } from '../svg';

function SvgEllipse({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 3 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={1.5} cy={1.5} r={1.5} fill="currentColor" />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEllipse);
export default ForwardRef;
