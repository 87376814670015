import {
  Box,
  Flex,
  Heading,
  Skeleton,
  Text,
  useDisclosure,
  Image,
  Button,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import dayHepler from 'src/heplers/day';
import { EditorRenderer } from '@hexx/renderer';
import { useRef, useState, useEffect, useContext } from 'react';
import { ArticleComment } from 'src/app/article/article-comment';
import { PublishIn } from 'src/app/article/components/publisher/author';
import { StickyIconButtonList } from 'src/app/article/components/sticky-icon-button-list/sticky-icon-button-list';
import {
  useArticleCommentMutation,
  useArticleEditCommentMutation,
} from 'src/app/article/hooks/use-article-comment';
import { useCommentEnthuseMutation } from 'src/app/article/hooks/use-enthuse';
import { Article, Author, FullArticle } from 'src/app/article/types';
import { Avatar } from 'src/components/avatar';
import { Commenter, CommenterHandler } from 'src/components/comment';
import { DEFAULT_EPISODE_COVER } from 'src/constants/default-image';
import { rendererBlockMaps } from 'src/lib/hexx/render-blocks';
import { arrayHasData } from 'src/utils/array';

import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { MemberCard } from 'src/app/reppl/team/member-card';
import { getUser } from 'src/app/reppl/service';
import { useFollowUserMutation } from 'src/app/profile/use-follow-user.mutation';
import { logger } from 'src/lib/logger/logger.server';
import { CacheKey } from 'src/constants/cache-key';
import type { TeamMember } from 'src/app/reppl/service';

import ReactPlayer from 'react-player';
import Carousel from 'nuka-carousel';
import { useRouter } from 'next/router';
import SvgNextSlide from 'src/components/icons/next-slide';
import SvgPrevSlide from 'src/components/icons/prev-slide';
import SvgMagnifying from 'src/components/icons/magnifying';
import Link from 'next/link';
import SvgArrowLeft from 'src/components/icons/arrow-left';
import SvgArrowRight from 'src/components/icons/arrow-right';
import SvgFlag from 'src/components/icons/flag';
import SvgCommentsWhite from 'src/components/icons/comments-white';
import SvgEnthuseWhite from 'src/components/icons/enthuse-white';
import SvgCommentsGray from 'src/components/icons/comments-gray';
import SvgEnthuseGray from 'src/components/icons/enthuse-gray';
import algolia from 'src/lib/algolia';
import { PopupCard } from 'src/app/article/popup-card';
import { useIsLg, useIsMobile } from 'src/hooks/use-is-mobile';
import { imageOptimize } from 'src/service/image';
import ShowMoreText from 'react-show-more-text';
import SvgBookmark from 'src/components/icons/bookmark';
import { getTaggedMention } from 'src/app/article/utils';
import { userMention } from 'src/app/explore/service';
import { AddToLabelModal } from '../label/components/add-to-label-modal';
import { getProfileFullNameOrUsername } from '../profile/utils';
import { CopyrightFooter } from '../reppl/copyright-footer';
import { EpisodeMoreFrom } from '../reppl/episode/more-from';
import { StudioMoreFrom } from '../studio/components/studio-more-from';
import { Creator, CreatorRole } from './components/publisher/creators-grid';
import { ArticleSource } from './enum';

export function ArticleDetail({
  id,
  article,
  publish,
  onEnthuse,
  isLoading,
  source,
  modal,
  handleModalChange,
  refetch,
  ...props
}: {
  id: string;
  article?: Article | FullArticle;
  author: Author;
  modal: UseDisclosureReturn;
  onEnthuse?: () => void;
  isLoading?: boolean;
  source: ArticleSource;
  handleModalChange?: (value: any) => void;
  refetch?: any;
  publish?: boolean; // TODO: for view episode, we should split component for view and preview
}) {
  /* eslint-disable */
  const router = useRouter();
  const labelModal = useDisclosure();
  const [selectedArticle, setSelectedArticle] = useState<any>();
  const { user: me, isLogin } = useContext(UserContext) as UserContextType;
  const addToLabelModal = useDisclosure();
  const type = source === ArticleSource.REPPL ? 'EPISODE' : 'STUDIO';
  const commentRef = useRef<CommenterHandler>();
  const { mutateAsync: createComment } = useArticleCommentMutation(id);
  const { mutateAsync: updateComment } = useArticleEditCommentMutation(id);
  const { mutateAsync: enthuseComment } = useCommentEnthuseMutation(id);
  const [collectData, setCollectData] = useState([]);
  const { rid } = router.query;
  const urlLoadingGif = '/images/assets/loading-gif2.gif';

  const lightroomModal = useDisclosure();

  const isLg = useIsLg();
  const isMobile = useIsMobile();

  const mentionUsers = (mention) => {
    const taggedUsers = {
      userNames: getTaggedMention(mention.data),
      postID: mention.id,
      place: "COMMENT",
    };

    userMention(taggedUsers);
  }

  // TODO: make it inside commenter instead
  const handleComment = ({
    event,
    blockId,
  }: {
    event: any;
    blockId?: string;
  }) => {
    const currValue = event.value;
    const currEvent = event.event;

    if (currValue !== '' && currEvent.key === 'Enter') {
      toComment({
        parentId: blockId,
        objectId: id,
        objectType: blockId ? 'COMMENT' : 'EPISODE',
        content: currValue,
      });
    }
  };

  const toComment = async (data) => {
    const createdComment = await createComment(data);
    if (createdComment.code === 1000) {
      mentionUsers({data: createdComment.data, id: article.contentID});
    }
  }

  const [activeSlide, setActiveSlide] = useState({
    description: checkUploaded('description'),
    title: checkUploaded('title'),
    createdBy: checkUploaded('file'),
    index: 1,
  });
  const [activeLightroomSlide, setActiveLightroomSlide] = useState({
    description: checkUploaded('description'),
    title: checkUploaded('title'),
    createdBy: checkUploaded('file'),
    index: 1,
  });

  useEffect(() => {
    setActiveSlide({
      description: checkUploaded('description'),
      title: checkUploaded('title'),
      createdBy: checkUploaded('file'),
      index: 1,
    });
  }, [article]);

  let isEnthuse = false;
  let creators: Creator[] = [];
  let ownerID = props.author?.userID;
  const [isUser, setIsUser] = useState<any>();
  const [currReppl, setCurrReppl] = useState<any>('');
  useEffect(() => {
    setIsUser(article?.author?.username);
    setCurrReppl(article);
    if (typeof article.content === 'string' && article.content) {
      // eslint-disable-next-line no-param-reassign
      article.content = JSON.parse(article.content);
    }
  }, [article]);

  if (article && 'enthuseCount' in article && 'isEnthused' in article) {
    // enthuseCount = article.enthuseCount;
    isEnthuse = article.isEnthused;
    if (article?.coCreators) {
      creators = [
        ...(article?.coCreators.map((c) => ({
          ...c,
          role: CreatorRole.coCreator,
        })) ?? []),
        ...(article?.contributors?.map((c) => ({
          ...c,
          role: CreatorRole.contributor,
        })) ?? []),
      ];
      ownerID = article.author?.userID;
    }
  }

  const [toolTip, setToolTip] = useState(false);
  const [projToolTip, setProjToolTip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ x: 0, y: 0 });
  const [currUser, setCurrUser] = useState<TeamMember>({});
  const [currProj, setCurrProj] = useState('');
  const [isShowMore, setIsShowMore] = useState(false);

  const { mutate: followUser } = useFollowUserMutation([
    CacheKey.profile,
    currUser?.username,
  ]);

  const toolTipRef = useRef(null);
  const projToolTipRef = useRef(null);

  const executeOnClick = (isExpanded) => {
    setIsShowMore(isExpanded);
  };

  async function attachToolTip(data) {
    const users = await getUser(data.innerHTML.substring(1));
    let proj;
    let isProject = false;
    const regTest = /\/reppl\/(.)*/;
    if (regTest.test(data.href)) {
      isProject = true;
      proj = await algolia.search(
        data.innerHTML.substring(1),
        30,
        'filterType:Project'
      );
    }
    data.addEventListener('mouseover', (e) => {
      if (users.data.length > 0 || proj) {
        if (isProject) {
          // setProjToolTip(true);
        } else {
          // setToolTip(true);
        }

        const posX = e.clientX;
        let posY = e.clientY;
        if (e.clientY > window.innerHeight / 2) {
          if (isProject) {
            posY -= projToolTipRef.current.offsetHeight;
          } else {
            posY -= toolTipRef.current.offsetHeight;
          }
        }

        if (isProject) {
          proj.forEach((list) => {
            if (list.objectID === data.href.split('reppl/')[1]) {
              setCurrProj(list.objectID);
            }
          });
        } else {
          setCurrUser(users.data[0]);
        }
        setToolTipPos({ x: posX, y: posY });
      }
    });
  }

  function hasId(data) {
    let has = false;
    if (data === 'toolTipUser' || data === 'toolTipProj') {
      has = true;
    }
    return has;
  }

  if (typeof document !== 'undefined') {
    const anchors = document
      .querySelectorAll('#studioForm')[0]
      ?.getElementsByTagName('a');
    for (let i = 0; i < anchors?.length; i++) {
      if (!anchors[i].hasAttribute('addListener')) {
        anchors[i].setAttribute('target', '_blank');
        anchors[i].classList.add('anchorLink');
        if (anchors[i].innerHTML.substring(0, 1) === '@') {
          attachToolTip(anchors[i]);
          const toolTipClass = document.querySelectorAll('.toolTip');
          toolTipClass.forEach((tooltip) => {
            tooltip?.addEventListener('mouseleave', () => {
              setToolTip(false);
              setProjToolTip(false);
            });
          });
          anchors[i].addEventListener('mouseleave', (e) => {
            let checkToElement = false;
            if ('toElement' in e) {
              checkToElement = true;
            }
            const toElement = checkToElement ? e['toElement'] : false;
            if (checkToElement && !hasId(toElement.parentNode.id)) {
              setToolTip(false);
              setProjToolTip(false);
            }
          });
          anchors[i].setAttribute('addListener', 'true');
        }
      }
    }
  }

  /* eslint-enable */

  function callBackData(data) {
    setCollectData(data);
  }
  function checkUploaded(filter) {
    if (
      article.content?.uploadedFiles &&
      article.content?.uploadedFiles.length > 0
    ) {
      if (filter === 'file') {
        if (article.content?.uploadedFiles[0]?.file?.createdBy) {
          return article.content?.uploadedFiles[0]?.file?.createdBy;
        }
      } else if (article.content?.uploadedFiles[0][filter]) {
        return article.content?.uploadedFiles[0][filter];
      }
      return null;
    }
    return null;
  }

  return (
    <Box
      id={isMobile ? '' : 'studioForm'}
      m="0"
      w="100%"
      bgColor="white"
      opacity={isShowMore && 0}
    >
      <Flex
        className="dark:bg-gray-800"
        direction="column"
        display="flex"
        width="100%"
      >
        <Box className="w-full">
          {article?.postType === 'MEDIA' ? (
            <Box className={`${isMobile ? '' : 'mb-10'}`}>
              <Box
                className={`w-full mb-4 px-6 flex flex-col ${
                  isMobile ? '' : 'items-center'
                } pt-10`}
                p={isMobile && '40px 6.6% 24px'}
                mb={isMobile && '0'}
              >
                <Text
                  className="cover-image-box-heading font-extrabold text-gray-900 mb-2"
                  fontSize={isMobile ? '24px!important' : '32px!important'}
                  fontWeight={isMobile && '600!important'}
                  p={isMobile && '0px 0 24px!important'}
                  mb={isMobile && '0'}
                >
                  {article?.title}
                </Text>
                <div className="inline-flex items-center">
                  {article?.createdAt && (
                    <span className="text-gray-500 text-xs">
                      {dayHepler.localizedFormat(article.createdAt, 'll')}
                    </span>
                  )}
                  {isMobile && (
                    <div className="inline-flex items-center mx-1.5">
                      <SvgEnthuseGray
                        fontSize="14px"
                        color="gray.500"
                        mr="2.5px"
                      />
                      <span className="text-gray-500 text-xs">
                        {article?.enthuseCount}
                      </span>
                    </div>
                  )}
                  {isMobile && (
                    <div className="inline-flex items-center mx-1.5">
                      <SvgCommentsGray
                        fontSize="14px"
                        color="gray.500"
                        mr="2.5px"
                      />
                      <span className="text-gray-500 text-xs">
                        {article?.commentCount}
                      </span>
                    </div>
                  )}
                </div>
              </Box>

              {article.content?.uploadedFiles?.length === 1 &&
                article.content?.uploadedFiles[0].type === 'image' && (
                  <Box
                    overflow="hidden"
                    position="relative"
                    borderRadius="7px"
                    w="100%"
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      position="absolute"
                      opacity={0}
                      w="100%"
                      h="100%"
                      left={0}
                      top={0}
                      onClick={lightroomModal.onOpen}
                    />
                    <Image
                      margin="auto"
                      alt="cover-image"
                      src={imageOptimize(
                        article.content.uploadedFiles[0].imgURL ||
                          DEFAULT_EPISODE_COVER
                      )}
                      fallbackSrc={urlLoadingGif}
                      objectFit="cover"
                      layout="fill"
                    />
                  </Box>
                )}
              {article.content?.uploadedFiles?.length === 1 &&
                article.content?.uploadedFiles[0].type === 'video' && (
                  <Box
                    background="#000000"
                    overflow="hidden"
                    borderRadius="7px"
                    minHeight={{ base: '2.5rem', md: '70vh' }}
                    position="relative"
                    w="100%"
                    h={{ base: '25vh', md: '650px' }}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      position="absolute"
                      opacity={0}
                      w="100%"
                      h="100%"
                      left={0}
                      top={0}
                      onClick={lightroomModal.onOpen}
                    />
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      url={article.content.uploadedFiles[0].videoURL}
                      light
                      playing
                      controls
                    />
                  </Box>
                )}
              {article.content?.uploadedFiles?.length === 1 &&
                article.content?.uploadedFiles[0].type === 'spotify' && (
                  <Box
                    background="#000000"
                    overflow="hidden"
                    borderRadius="7px"
                    minHeight={{ base: '2.5rem', md: '70vh' }}
                    position="relative"
                    w="100%"
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      position="absolute"
                      opacity={0}
                      w="100%"
                      h="100%"
                      left={0}
                      top={0}
                      onClick={lightroomModal.onOpen}
                    />
                    <iframe
                      title={article.content?.uploadedFiles[0].id}
                      src={`https://open.spotify.com/embed/track/${article.content?.uploadedFiles[0].id}`}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allow="encrypted-media"
                    />
                  </Box>
                )}
              {article.content?.uploadedFiles?.length > 1 &&
                (isMobile ? (
                  <Box w="100%">
                    {article.content.uploadedFiles.map((file) => {
                      if (file.type === 'spotify') {
                        return (
                          <Box w="100%" my="12px" key={file.id}>
                            <Box
                              background="#000000"
                              width="100%"
                              overflow="hidden"
                              position="relative"
                            >
                              <SvgMagnifying
                                position="absolute"
                                left={2}
                                top={2}
                                fontSize="24px"
                              />
                              <Button
                                position="absolute"
                                w="100%"
                                h="100%"
                                left={0}
                                top={0}
                                opacity={0}
                                onClick={lightroomModal.onOpen}
                              />
                              <iframe
                                title={file.id}
                                src={`https://open.spotify.com/embed/track/${file.id}`}
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                allow="encrypted-media"
                              />
                            </Box>
                            <Box p="16px">
                              <Box
                                w="100%"
                                borderLeft="2px solid #E6E8E9"
                                px="12px"
                              >
                                <Text
                                  color="gray.900"
                                  fontWeight="600"
                                  fontSize="14px"
                                  lineHeight="20px"
                                  mb="8px"
                                >
                                  {file?.title}
                                </Text>
                                <Text
                                  color="gray.900"
                                  fontWeight="normal"
                                  fontSize="14px"
                                  lineHeight="20px"
                                >
                                  {file?.description}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        );
                      }
                      if (file.type === 'video') {
                        return (
                          <Box w="100%" my="12px" key={file.id}>
                            <Box
                              background="#000000"
                              width="100%"
                              overflow="hidden"
                              position="relative"
                            >
                              <SvgMagnifying
                                position="absolute"
                                left={2}
                                top={2}
                                fontSize="24px"
                              />
                              <Button
                                position="absolute"
                                w="100%"
                                h="100%"
                                left={0}
                                top={0}
                                opacity={0}
                                onClick={lightroomModal.onOpen}
                              />
                              <ReactPlayer
                                width="100%"
                                url={file.videoURL}
                                light
                                playing
                                controls
                              />
                            </Box>
                            <Box p="16px">
                              <Box
                                w="100%"
                                borderLeft="2px solid #E6E8E9"
                                px="12px"
                              >
                                <Text
                                  color="gray.900"
                                  fontWeight="600"
                                  fontSize="14px"
                                  lineHeight="20px"
                                  mb="8px"
                                >
                                  {file?.title}
                                </Text>
                                <Text
                                  color="gray.900"
                                  fontWeight="normal"
                                  fontSize="14px"
                                  lineHeight="20px"
                                >
                                  {file?.description}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        );
                      }
                      return (
                        <Box
                          w="100%"
                          my="12px"
                          mb={isMobile && '40px'}
                          key={file.id}
                        >
                          <Box overflow="hidden" position="relative">
                            <SvgMagnifying
                              position="absolute"
                              left={2}
                              top={2}
                              fontSize="24px"
                            />
                            <Button
                              position="absolute"
                              opacity={0}
                              w="100%"
                              h="100%"
                              left={0}
                              top={0}
                              onClick={lightroomModal.onOpen}
                            />
                            <Image
                              width="100%"
                              alt="cover-image"
                              w="100%"
                              src={imageOptimize(
                                file.imgURL || DEFAULT_EPISODE_COVER
                              )}
                              fallbackSrc={urlLoadingGif}
                              objectFit="cover"
                              layout="fill"
                            />
                          </Box>
                          <Box p={{ base: '0 16px 0px', md: '16px' }}>
                            <Box
                              w="100%"
                              borderLeft="2px solid #E6E8E9"
                              px="12px"
                            >
                              <Text
                                color="gray.900"
                                fontWeight={{ base: '500', md: '600' }}
                                fontSize="14px"
                                lineHeight="20px"
                                mb={{ base: '0px', md: '8px' }}
                                p={isMobile && '16px 0 8px!important'}
                              >
                                {file?.title}
                              </Text>
                              <Text
                                color="gray.900"
                                fontWeight="normal"
                                fontSize="14px"
                                lineHeight="20px"
                                p={isMobile && '0px!important'}
                              >
                                {file?.description}
                              </Text>
                              {file?.file?.createdBy && (
                                <Text
                                  fontSize="14px"
                                  lineHeight="18px"
                                  mt={{ base: '0px', md: '8px' }}
                                  color="gray.500"
                                  p={isMobile && '0px!important'}
                                  pt={isMobile && '8px!important'}
                                >
                                  Photo by{' '}
                                  <span className="underline">
                                    {file?.file?.createdBy}
                                  </span>{' '}
                                  on <span className="underline">Unsplash</span>
                                </Text>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Carousel
                    slidesToShow={1.25}
                    cellAlign="center"
                    wrapAround
                    cellSpacing={21}
                    opacityScale={0.5}
                    renderCenterLeftControls={({ previousSlide }) => (
                      <SvgPrevSlide
                        _hover={{ cursor: 'pointer' }}
                        onClick={previousSlide}
                        w="40px"
                        h="40px"
                      />
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                      <SvgNextSlide
                        _hover={{ cursor: 'pointer' }}
                        onClick={nextSlide}
                        w="40px"
                        h="40px"
                      />
                    )}
                    renderBottomCenterControls={() => null}
                    afterSlide={(slideIndex) => {
                      setActiveSlide({
                        description:
                          article.content.uploadedFiles[slideIndex].description,
                        title: article.content.uploadedFiles[slideIndex].title,
                        createdBy:
                          article.content.uploadedFiles[slideIndex]?.file
                            ?.createdBy,
                        index: slideIndex + 1,
                      });
                      setActiveLightroomSlide({
                        description:
                          article.content.uploadedFiles[slideIndex].description,
                        title: article.content.uploadedFiles[slideIndex].title,
                        createdBy:
                          article.content.uploadedFiles[slideIndex]?.file
                            ?.createdBy,
                        index: slideIndex + 1,
                      });
                    }}
                  >
                    {article.content.uploadedFiles.map((file) => {
                      if (file.type === 'spotify') {
                        return (
                          <Box
                            key={file.id}
                            background="#000000"
                            width="100%"
                            height="650px"
                            overflow="hidden"
                            borderRadius="7px"
                            position="relative"
                          >
                            <SvgMagnifying
                              position="absolute"
                              left={2}
                              top={2}
                              fontSize="24px"
                            />
                            <Button
                              position="absolute"
                              w="100%"
                              h="100%"
                              left={0}
                              top={0}
                              opacity={0}
                              onClick={lightroomModal.onOpen}
                            />
                            <iframe
                              title={file.id}
                              src={`https://open.spotify.com/embed/track/${file.id}`}
                              width="100%"
                              height="100%"
                              frameBorder="0"
                              allow="encrypted-media"
                            />
                          </Box>
                        );
                      }
                      if (file.type === 'video') {
                        return (
                          <Box
                            key={file.id}
                            background="#000000"
                            width="100%"
                            height="650px"
                            overflow="hidden"
                            borderRadius="7px"
                            position="relative"
                          >
                            <SvgMagnifying
                              position="absolute"
                              left={2}
                              top={2}
                              fontSize="24px"
                            />
                            <Button
                              position="absolute"
                              w="100%"
                              h="100%"
                              left={0}
                              top={0}
                              opacity={0}
                              onClick={lightroomModal.onOpen}
                            />
                            <ReactPlayer
                              width="100%"
                              height="100%"
                              url={file.videoURL}
                              light
                              playing
                              controls
                            />
                          </Box>
                        );
                      }
                      return (
                        <Box
                          key={file.id}
                          overflow="hidden"
                          borderRadius="7px"
                          position="relative"
                        >
                          <SvgMagnifying
                            position="absolute"
                            left={2}
                            top={2}
                            fontSize="24px"
                          />
                          <Button
                            position="absolute"
                            opacity={0}
                            w="100%"
                            h="100%"
                            left={0}
                            top={0}
                            onClick={lightroomModal.onOpen}
                          />
                          <Image
                            width="100%"
                            alt="cover-image"
                            w="100%"
                            fallbackSrc={urlLoadingGif}
                            src={imageOptimize(file.imgURL)}
                            objectFit="cover"
                            layout="fill"
                          />
                        </Box>
                      );
                    })}
                  </Carousel>
                ))}
              {!isMobile && (
                <Flex justifyContent="center">
                  <Box
                    display="grid"
                    w="858px"
                    maxW="100%"
                    px="24px"
                    gridTemplateColumns="80px 1fr"
                    mt={4}
                  >
                    <Flex
                      color="$mode.700"
                      fontSize="14px"
                      fontWeight="bold"
                      lineHeight="18px"
                      h="100%"
                      pr={3}
                      mr={3}
                      borderRight={
                        article.content?.uploadedFiles?.length === 1 ||
                        (article.content?.uploadedFiles &&
                          !article.content?.uploadedFiles[activeSlide.index - 1]
                            ?.title &&
                          !article.content?.uploadedFiles[activeSlide.index - 1]
                            ?.description)
                          ? 'none'
                          : '2px solid #4F5356'
                      }
                    >
                      {article.content?.uploadedFiles?.length > 1 && (
                        <>
                          <Text>
                            {activeSlide.index < 10
                              ? `0${activeSlide.index}`
                              : activeSlide.index}
                          </Text>
                          <Text color="$mode.500">
                            &nbsp;/&nbsp;
                            {article?.content?.uploadedFiles?.length < 10
                              ? `0${article.content.uploadedFiles.length}`
                              : article?.content?.uploadedFiles?.length}
                          </Text>
                        </>
                      )}
                    </Flex>
                    <Box>
                      {(activeSlide.title || activeSlide.description) && (
                        <>
                          {activeSlide.title && (
                            <Text textStyle="h6Light" mb={2}>
                              {activeSlide.title}
                            </Text>
                          )}
                          {activeSlide.description && (
                            <Text
                              color="$mode.700"
                              fontSize="14px"
                              fontWeight="500"
                              lineHeight="18px"
                            >
                              {activeSlide.description}
                            </Text>
                          )}
                        </>
                      )}
                      {activeSlide?.createdBy && (
                        <Text
                          fontSize="14px"
                          mt={`${
                            !activeSlide.title && !activeSlide.description
                              ? 0
                              : '8px'
                          }`}
                          lineHeight="18px"
                          color="gray.500"
                        >
                          Photo by{' '}
                          <span className="underline">
                            {activeSlide?.createdBy}
                          </span>{' '}
                          on <span className="underline">Unsplash</span>
                        </Text>
                      )}
                    </Box>
                  </Box>
                </Flex>
              )}
              {article?.pinned && (
                <div
                  className={`hidden w-full flex justify-center ${
                    isMobile ? 'mb-3 mt-3' : 'mt-4'
                  }`}
                >
                  <div className="max-w-full w-max flex items-center px-4 py-1.5 bg-olive-300">
                    <SvgBookmark fontSize="25px" fill="white" color="white" />
                    <span className="text-xs font-bold text-white">
                      STARTER’S PICK
                    </span>
                  </div>
                </div>
              )}
            </Box>
          ) : (
            <Box className="cover-image-box w-full relative">
              <Box
                className="cover-image-box-body bg-black bg-opacity-30 w-full h-full flex justify-center items-end px-7"
                h={isMobile && '70vh'}
                px={isMobile && '6.6%'}
              >
                <Box
                  className="cover-image-box-content w-full py-7"
                  py={isMobile && '15px'}
                >
                  <Text
                    className="cover-image-box-heading font-extrabold text-white"
                    p={{ base: '0px!important', md: '0 0 8px!important' }}
                    fontSize={isMobile && '24px!important'}
                    fontWeight={isMobile && '600!important'}
                  >
                    {article?.title}
                  </Text>
                  <Box
                    className="inline-flex items-center"
                    py={isMobile && '16px'}
                  >
                    {article?.createdAt && (
                      <span className="text-white text-xs">
                        {dayHepler.localizedFormat(article.createdAt, 'll')}
                      </span>
                    )}
                    {isMobile && (
                      <div className="inline-flex items-center mx-1.5">
                        <SvgEnthuseWhite
                          color="white"
                          fontSize="14px"
                          mr="2.5px"
                        />
                        <span className="text-white text-xs">
                          {article?.enthuseCount}
                        </span>
                      </div>
                    )}
                    {isMobile && (
                      <div className="inline-flex items-center mx-1.5">
                        <SvgCommentsWhite
                          color="white"
                          fontSize="14px"
                          mr="2.5px"
                        />
                        <span className="text-white text-xs">
                          {article?.commentCount}
                        </span>
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
              {article?.pinned && (
                <div className="max-w-full w-max absolute flex items-center left-0 right-0 mx-auto -bottom-4 px-4 py-1.5 bg-olive-300">
                  <SvgBookmark fontSize="25px" fill="white" color="white" />
                  <span className="text-xs font-bold text-white">
                    STARTER’S PICK
                  </span>
                </div>
              )}
            </Box>
          )}
          <Box
            className="w-full flex justify-center"
            py={isMobile && '24px'}
            pt={isMobile && article?.postType === 'MEDIA' ? '0px' : '24px'}
          >
            <Box
              className={`flex py-10 w-full ${isMobile ? 'flex-col' : ''}`}
              py={isMobile && '0px'}
            >
              <Box
                className={`episode-content ${
                  isMobile ? 'px-6' : 'pr-8 pl-32'
                } relative ${isMobile ? 'mobile' : ''} ${
                  isLg && !isMobile ? 'tablet' : ''
                }`}
                px={isMobile && '6.6%'}
              >
                {publish && (
                  <StickyIconButtonList
                    left="20px"
                    commentable={article.commentable}
                    authorID={ownerID}
                    refetch={refetch}
                    authorUsername={article?.author?.username}
                    onCommentClick={() => commentRef.current.focus()}
                    onSaveClick={() =>
                      isLogin
                        ? addToLabelModal.onOpen()
                        : router.push({
                            query: { ...router.query, page: 'signIn' },
                          })
                    }
                    contentID={article?.contentID}
                    isEnthused={isEnthuse}
                    articleType={type}
                    collection={collectData}
                    data={article}
                  />
                )}

                <Skeleton isLoaded={!!article.content}>
                  {article?.content && (
                    <Box
                      fontSize={isMobile && '14px'}
                      lineHeight={isMobile && '20px'}
                      fontWeight={isMobile && '400'}
                      color={isMobile && 'gray.900'}
                    >
                      <EditorRenderer
                        maxWidth="100%"
                        blocks={article.content.blocks}
                        blockMap={rendererBlockMaps}
                      />
                      {isMobile && article?.postType === 'STORY' && (
                        <Box className="flex justify-center mt-5 mb-3.5">
                          <div className="divider w-full max-w-xs" />
                        </Box>
                      )}
                      {isMobile && article?.postType === 'MEDIA' && (
                        <Box className="flex justify-center my-6">
                          <div className="divider w-full max-w-xs" />
                        </Box>
                      )}
                    </Box>
                  )}
                </Skeleton>
                {isMobile && (
                  <Box
                    className={`episode-data relative ${
                      isMobile ? 'mobile' : ''
                    } ${isLg && !isMobile ? 'tablet' : ''}`}
                  >
                    <div className="sticky top-16">
                      <div>
                        <h1 className="text-xs font-bold text-gray-400 mb-3">
                          Created by
                        </h1>
                        <Link href={`/${props?.author?.username}`}>
                          <div className="flex items-center relative cursor-pointer">
                            <SvgFlag
                              fontSize="24px"
                              position="absolute"
                              top="15px"
                              left="-5px"
                              zIndex="999"
                            />
                            <Avatar
                              src={imageOptimize(props?.author?.profileImgURL)}
                              name={props?.author?.username}
                              author={props?.author}
                              width={40}
                              height={40}
                              wrapperProps={{
                                m: 'auto',
                              }}
                            />
                            <div className="ml-2">
                              <Text
                                className="text-sm text-gray-900 mb-1"
                                p="0!important"
                                fontWeight="600"
                              >
                                {getProfileFullNameOrUsername(props?.author)}
                              </Text>
                              <Text
                                className="text-xs text-gray-400"
                                p="0!important"
                              >
                                Author
                              </Text>
                            </div>
                          </div>
                        </Link>
                      </div>
                      {!article?.isInDraftReppl &&
                        article?.classType === 'REPPL' && (
                          <div>
                            <div className="flex justify-center my-6">
                              <div className="divider w-full max-w-xs" />
                            </div>
                            <PublishIn reppl={currReppl?.sourceID} />
                          </div>
                        )}
                      {creators.length > 0 && (
                        <>
                          <div className="flex justify-center my-6">
                            <div className="divider w-full max-w-xs" />
                          </div>
                          <div>
                            <h1 className="text-xs font-bold text-gray-400 mb-3">
                              Collaborators
                            </h1>
                            {creators.map((creator) => (
                              <Link
                                key={creator?.userID}
                                href={`/${creator?.username}`}
                              >
                                <div className="block user-data mb-4">
                                  <div className="flex items-center">
                                    <Avatar
                                      src={imageOptimize(
                                        creator?.profileImgURL
                                      )}
                                      name={creator?.username}
                                      author={creator}
                                      width={40}
                                      height={40}
                                      wrapperProps={{
                                        m: 'auto',
                                      }}
                                    />
                                    <div className="ml-2">
                                      <Text
                                        className="text-sm  text-gray-900 mb-1"
                                        p="0!important"
                                        fontWeight="600"
                                      >
                                        {getProfileFullNameOrUsername(creator)}
                                      </Text>
                                      <Text
                                        className="text-xs text-gray-400"
                                        p="0!important"
                                      >
                                        {creator?.role}
                                      </Text>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            ))}
                          </div>
                        </>
                      )}
                      {arrayHasData(article?.categories) && (
                        <>
                          <div className="flex justify-center my-6">
                            <div className="divider w-full max-w-xs" />
                          </div>
                          <div className="flex flex-wrap w-full">
                            {article.categories.map((s) => (
                              <div
                                className="rounded-full bg-blue-50 px-2.5 font-medium py-1.5 mx-0.5 my-1 text-xs text-blue-500 cursor-pointer"
                                key={s}
                                role="button"
                                onClick={() => {
                                  if (router.pathname === '/agora/[tribe]') {
                                    modal.onClose();
                                  }
                                  const name = s.replace(/ /g, '-');
                                  router.push(`/agora/${name}`);
                                }}
                                onKeyDown={() => {
                                  const name = s.replace(/ /g, '-');
                                  router.push(`/agora/${name}`);
                                }}
                                tabIndex={0}
                              >
                                {s}
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      {arrayHasData(article?.tags) && (
                        <>
                          <div className="flex justify-center my-6">
                            <div className="divider w-full max-w-xs" />
                          </div>
                          <div className="flex flex-wrap w-full">
                            {article.tags.map((s) => (
                              <div
                                className="px-1 font-medium py-1.5 mx-1 my-1 text-xs text-blue-500 cursor-pointer"
                                key={s}
                                role="button"
                                onClick={() => {
                                  if (router.pathname === '/agora/[tribe]') {
                                    modal.onClose();
                                  }
                                  const name = s.replace(/ /g, '-');
                                  router.push(`/agora/${name}`);
                                }}
                                onKeyDown={() => {
                                  const name = s.replace(/ /g, '-');
                                  router.push(`/agora/${name}`);
                                }}
                                tabIndex={0}
                              >
                                #{s}
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </Box>
                )}
                <Box className="w-full">
                  <div className="flex justify-center my-8">
                    <div className="divider w-full max-w-xs" />
                  </div>
                  {article?.description && (
                    <>
                      <Text
                        className="text-xs font-bold mb-4 text-center	text-gray-400 "
                        py="8px!important"
                        mb="0px!important"
                      >
                        A few words from {props.author?.username}
                      </Text>

                      <div className="italic Montserrat">
                        <div
                          style={{ width: 'fit-content' }}
                          className="flex mx-auto"
                        >
                          <div className="text-6.5xl relative quotation text-gray-400">
                            “
                          </div>
                          <Text
                            className="pb-5 flex-1 px-4 font-medium whitespace-pre-wrap text-gray-700 text-xl"
                            textAlign="center"
                            p="12px!important"
                            pb="8px!important"
                            color="gray.900"
                          >
                            {article.description}
                          </Text>
                          <div className="text-6.5xl relative quotation text-gray-400">
                            ”
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-center my-8">
                        <div className="divider w-full max-w-xs" />
                      </div>
                    </>
                  )}
                  <div>
                    {article?.commentable && me && (
                      <Commenter
                        disabled={!publish}
                        ref={commentRef}
                        user={me}
                        editorIndex="editorDetail"
                        onKeyDown={(event) => handleComment({ event })}
                      />
                    )}
                    {'comments' in article && (
                      <ArticleComment
                        comments={article.comments}
                        commentCount={article.commentCount}
                        onComment={({ blockId, value, commentID }) => {
                          if (commentID) {
                            updateComment({
                              commentID,
                              Assets: [],
                              content: value,
                              parentId: blockId,
                            });
                          } else {
                            toComment({
                              objectId: id,
                              objectType: blockId ? 'COMMENT' : 'EPISODE',
                              parentId: blockId,
                              content: value,
                            });
                          }
                        }}
                        onEnthuse={({
                          targetId,
                          targetParentId,
                          targetType,
                          isEnthused,
                          targetOwnerId,
                        }) => {
                          enthuseComment({
                            objectId: targetId,
                            objectParentId: targetParentId,
                            objectType: targetType,
                            enthuse: !isEnthused,
                            ownerID: targetOwnerId,
                          });
                        }}
                      />
                    )}
                  </div>
                </Box>
              </Box>

              {!isMobile && (
                <Box
                  className={`episode-data relative px-8 ${
                    isMobile ? 'mobile' : ''
                  } ${isLg && !isMobile ? 'tablet' : ''}`}
                >
                  <div className="sticky top-16">
                    <div>
                      <h1 className="text-xs font-bold text-gray-400 mb-3">
                        Created by
                      </h1>
                      <Link href={`/${props?.author?.username}`}>
                        <div className="flex items-center relative cursor-pointer">
                          <SvgFlag
                            fontSize="24px"
                            position="absolute"
                            top="15px"
                            left="-5px"
                            zIndex="999"
                          />
                          <Avatar
                            src={imageOptimize(props?.author?.profileImgURL)}
                            name={props?.author?.username}
                            author={props?.author}
                            width={40}
                            height={40}
                            wrapperProps={{
                              m: 'auto',
                            }}
                          />
                          <div className="ml-2">
                            <p className="text-sm font-bold text-gray-900 mb-1">
                              {getProfileFullNameOrUsername(props?.author)}
                            </p>
                            <p className="text-xs text-gray-400">Author</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {creators.length > 0 && (
                      <>
                        <div className="flex justify-center my-6">
                          <div className="divider w-full max-w-xs" />
                        </div>
                        <div>
                          <h1 className="text-xs font-bold text-gray-400 mb-3">
                            Collaborators
                          </h1>
                          {creators.map((creator) => (
                            <Link
                              key={creator?.userID}
                              href={`/${creator?.username}`}
                            >
                              <div className="block user-data mb-4">
                                <div className="flex items-center">
                                  <Avatar
                                    src={imageOptimize(creator?.profileImgURL)}
                                    name={creator?.username}
                                    author={creator}
                                    width={40}
                                    height={40}
                                    wrapperProps={{
                                      m: 'auto',
                                    }}
                                  />
                                  <div className="ml-2">
                                    <p className="text-sm font-bold text-gray-900 mb-1">
                                      {getProfileFullNameOrUsername(creator)}
                                    </p>
                                    <p className="text-xs text-gray-400">
                                      {creator?.role}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </>
                    )}
                    {arrayHasData(article?.categories) && (
                      <>
                        <div className="flex justify-center my-6">
                          <div className="divider w-full max-w-xs" />
                        </div>
                        <div className="flex flex-wrap w-full">
                          {article.categories.map((s) => (
                            <div
                              className="bg-blue-50 px-2.5 py-1.5 mx-0.5 my-1 text-xs text-blue-500 cursor-pointer rounded-full"
                              key={s}
                              role="button"
                              onClick={() => {
                                if (router.pathname === '/agora/[tribe]') {
                                  modal.onClose();
                                }
                                const name = s.replace(/ /g, '-');
                                router.push(`/agora/${name}`);
                              }}
                              onKeyDown={() => {
                                const name = s.replace(/ /g, '-');
                                router.push(`/agora/${name}`);
                              }}
                              tabIndex={0}
                            >
                              {s}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    {arrayHasData(article?.tags) && (
                      <>
                        <div className="flex justify-center my-6">
                          <div className="divider w-full max-w-xs" />
                        </div>
                        <div className="flex flex-wrap w-full">
                          {article.tags.map((s) => (
                            <div
                              className="bg-blue-50 px-2.5 py-1.5 mx-0.5 my-1 text-xs text-blue-500 cursor-pointer rounded-full"
                              key={s}
                              role="button"
                              onClick={() => {
                                if (router.pathname === '/agora/[tribe]') {
                                  modal.onClose();
                                }
                                const name = s.replace(/ /g, '-');
                                router.push(`/agora/${name}`);
                              }}
                              onKeyDown={() => {
                                const name = s.replace(/ /g, '-');
                                router.push(`/agora/${name}`);
                              }}
                              tabIndex={0}
                            >
                              #{s}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    {!article?.isInDraftReppl &&
                      article?.classType === 'REPPL' && (
                        <div>
                          <div className="flex justify-center my-6">
                            <div className="divider w-full max-w-xs" />
                          </div>
                          <PublishIn reppl={currReppl?.sourceID} />
                        </div>
                      )}
                    <div>
                      <div className="flex justify-center my-6">
                        <div className="divider w-full max-w-xs" />
                      </div>
                      <Copyright
                        copyright={article?.copyright}
                        article={currReppl}
                      />
                    </div>
                  </div>
                </Box>
              )}

              <Box
                ref={toolTipRef}
                id="toolTipUser"
                className="toolTip"
                position="fixed"
                top={`${toolTipPos.y}px`}
                left={`${toolTipPos.x}px`}
                zIndex="99"
                width="326px"
                display={toolTip ? 'inherit' : 'none'}
              >
                <MemberCard
                  isTooltip
                  hideSettings
                  isLoaded
                  info={currUser}
                  onFollow={async () => {
                    try {
                      followUser({
                        targetID: currUser?.id,
                        group: 'USER',
                        follow: !currUser?.isFollowed,
                      });
                    } catch (err) {
                      logger.error(err);
                    }
                  }}
                />
              </Box>
              <Box
                ref={projToolTipRef}
                id="toolTipProj"
                className="toolTip"
                position="fixed"
                top={`${toolTipPos.y}px`}
                left={`${toolTipPos.x}px`}
                zIndex="99"
                width="326px"
                display={projToolTip ? 'block' : 'none'}
              >
                {currProj && <PopupCard rid={currProj} />}
              </Box>
            </Box>
          </Box>
          {router?.route !== '/reppl/[rid]/submissions' && (
            <Box className="more-from">
              {type === 'EPISODE' ? (
                <Box>
                  <StudioMoreFrom
                    type="carousel"
                    author={isUser}
                    modalChange={handleModalChange}
                  />
                  <EpisodeMoreFrom
                    type="carousel"
                    modalChange={handleModalChange}
                    sourceID={currReppl?.sourceID}
                  />
                </Box>
              ) : (
                <StudioMoreFrom
                  type="carousel"
                  author={isUser}
                  modalChange={handleModalChange}
                />
              )}
            </Box>
          )}
          {isMobile && (
            <Box pb="70px" px="6.6%" textAlign="center">
              <Copyright copyright={article?.copyright} article={currReppl} />
            </Box>
          )}
        </Box>
        <style jsx global>{`
          .cover-image-box {
            background-image: url('${imageOptimize(article?.coverImgURL) ||
            DEFAULT_EPISODE_COVER}');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }
          .cover-image-box-body {
            min-height: 343px;
          }
          .cover-image-box-content {
            max-width: 898px;
          }
          .cover-image-box-heading {
            font-size: 32px;
            line-height: 38px;
          }
          .episode-content {
            width: calc(100% - 300px);
            min-height: 350px;
          }
          .episode-content.tablet {
            width: calc(100% - 250px);
          }
          .episode-content.mobile {
            width: 100%;
          }
          .episode-content ul,
          .episode-content ol {
            left: 0;
            padding-left: 16px;
          }
          .episode-data {
            width: 300px;
          }
          .episode-data.tablet {
            width: 250px;
          }
          .episode-data.mobile {
            width: 100%;
            border-left: none;
          }
          .divider {
            border-top: 2px dotted #e6e8e9;
          }
          .user-data {
            text-decoration: none !important;
          }
          .slider-slide {
            opacity: 0.5;
          }
          .slide-current {
            opacity: 1;
          }
          .more-from .slider-slide {
            opacity: 1;
          }
          .slider-control-centerright {
            right: calc(10% - 20px) !important;
          }
          .slider-control-centerleft {
            left: calc(10% - 20px) !important;
          }
        `}</style>

        <AddToLabelModal
          {...addToLabelModal}
          areaID={String(me?.id)}
          areaType="STUDIO"
          collectionItem={article}
          collectionData={(e) => callBackData(e)}
          dataChecker={collectData}
        />
      </Flex>
      <AddToLabelModal
        {...labelModal}
        areaID={`${rid}`}
        areaType="REPPL"
        collectionItem={selectedArticle}
        onClose={() => {
          labelModal.onClose();
          setSelectedArticle(undefined);
        }}
      />

      {article?.postType === 'MEDIA' && (
        <Modal
          isOpen={lightroomModal.isOpen}
          onClose={lightroomModal.onClose}
          isCentered
        >
          <ModalContent
            w="100vw"
            maxW="100vw"
            h="100vh"
            position="fixed"
            background="rgba(25,25,25,0.9)"
            borderRadius="0"
            py="45px"
          >
            <ModalCloseButton
              color="$mode.50"
              _focus={{ outline: 'none' }}
              zIndex="99"
              onClick={() => setIsShowMore(false)}
            />
            <ModalBody p={0}>
              {!isMobile && (
                <Heading
                  as="h2"
                  textStyle="h2"
                  textAlign="center"
                  fontWeight="800"
                  noOfLines={4}
                  color="$mode.bg"
                  mb="20px"
                  className="text-white dark:text-white"
                >
                  {article?.title}
                </Heading>
              )}
              <Box w="100%" h={{ base: '100%', md: '650px' }}>
                {article.content?.uploadedFiles?.length === 1 &&
                  article.content?.uploadedFiles[0].type === 'image' && (
                    <Box
                      overflow="hidden"
                      position={isMobile ? 'absolute' : 'relative'}
                      borderRadius={isMobile ? '0px' : '7px'}
                      w="100%"
                      opacity={isMobile && isShowMore && 0.5}
                      display="flex"
                      justifyContent="center"
                      alignItems={isMobile ? 'center' : 'stretch'}
                      bottom={isMobile && 'calc(15vh + 40px)'}
                      top={isMobile && 'calc(10vh)'}
                    >
                      <Image
                        alt="cover-image"
                        src={imageOptimize(
                          article.content.uploadedFiles[0].imgURL
                        )}
                        fallbackSrc={urlLoadingGif}
                        objectFit="cover"
                        layout="fill"
                        w="100%"
                        h="66vw !important"
                      />
                    </Box>
                  )}
                {article.content?.uploadedFiles?.length === 1 &&
                  article.content?.uploadedFiles[0].type === 'spotify' && (
                    <Box
                      background="#000000"
                      overflow="hidden"
                      borderRadius="7px"
                      minHeight={{ base: '2.5rem', md: '70vh' }}
                      position="relative"
                      w="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <iframe
                        title={article.content?.uploadedFiles[0].id}
                        src={`https://open.spotify.com/embed/track/${article.content?.uploadedFiles[0].id}`}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allow="encrypted-media"
                      />
                    </Box>
                  )}
                {article.content?.uploadedFiles?.length === 1 &&
                  article.content?.uploadedFiles[0].type === 'video' && (
                    <Box
                      background="#000000"
                      overflow="hidden"
                      borderRadius="7px"
                      minHeight={{ base: '2.5rem', md: '70vh' }}
                      className="absolute md:relative inset-0"
                      w="100%"
                      h={{ base: '25vh', md: '100%' }}
                      m="auto"
                      display="flex"
                      justifyContent="center"
                    >
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        url={article.content.uploadedFiles[0].videoURL}
                        light
                        playing
                        controls
                      />
                    </Box>
                  )}
                {article.content?.uploadedFiles?.length > 1 && (
                  <Carousel
                    slideIndex={activeLightroomSlide.index - 1}
                    slidesToShow={isMobile ? 1 : 1.25}
                    cellAlign="center"
                    wrapAround
                    cellSpacing={21}
                    opacityScale={0.5}
                    renderCenterLeftControls={({ previousSlide }) => (
                      <SvgArrowLeft
                        onClick={previousSlide}
                        w="70%"
                        h="inherit"
                        className="animate-fadeOut cursor-pointer text-white"
                      />
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                      <SvgArrowRight
                        onClick={nextSlide}
                        w="70%"
                        h="inherit"
                        className="animate-fadeOut cursor-pointer text-white"
                        margin="0 0 0 auto"
                      />
                    )}
                    renderBottomCenterControls={() => null}
                    afterSlide={(slideIndex) => {
                      setActiveLightroomSlide({
                        description:
                          article.content.uploadedFiles[slideIndex].description,
                        title: article.content.uploadedFiles[slideIndex].title,
                        createdBy:
                          article.content.uploadedFiles[slideIndex]?.file
                            ?.createdBy,
                        index: slideIndex + 1,
                      });
                    }}
                  >
                    {article.content.uploadedFiles.map((file) => {
                      if (file.type === 'spotify') {
                        return (
                          <Box
                            key={file.id}
                            background="#000000"
                            width="100%"
                            height="100%"
                            overflow="hidden"
                            borderRadius="7px"
                            position="relative"
                            pt={isMobile && 'calc(10vh)'}
                          >
                            <iframe
                              title={file.id}
                              src={`https://open.spotify.com/embed/track/${file.id}`}
                              width="100%"
                              height="100%"
                              frameBorder="0"
                              allow="encrypted-media"
                            />
                          </Box>
                        );
                      }
                      if (file.type === 'video') {
                        return (
                          <Box
                            key={file.id}
                            background={!isMobile && 'black'}
                            width="100%"
                            height="100%"
                            overflow="hidden"
                            borderRadius="7px"
                            position="relative"
                            pt={isMobile && 'calc(10vh)'}
                          >
                            <ReactPlayer
                              width="100%"
                              height="100%"
                              url={file.videoURL}
                              light
                              playing
                              controls
                            />
                          </Box>
                        );
                      }
                      return (
                        <Box
                          key={file.id}
                          overflow="hidden"
                          borderRadius="10px"
                          position="relative"
                          h="100vh"
                          pt={isMobile && 'calc(10vh)'}
                          display={isMobile ? 'flex' : 'block'}
                          alignItems="center"
                        >
                          <Image
                            src={imageOptimize(file.imgURL)}
                            fallbackSrc={urlLoadingGif}
                            objectFit="cover"
                            layout="fill"
                            opacity={isMobile && isShowMore && '0.5'}
                            w={isMobile ? '100%' : 'auto'}
                            h={isMobile ? '66vw !important' : '100% !important'}
                          />
                        </Box>
                      );
                    })}
                  </Carousel>
                )}
                {isMobile ? (
                  <Box
                    w="100%"
                    maxW="849px"
                    mx="auto"
                    pt={3}
                    pb={6}
                    px="6.6%"
                    pos="absolute"
                    bottom="0"
                    background="rgba(36,37,38,0.5)"
                  >
                    <Text
                      textStyle="h6Light"
                      lineHeight="20px"
                      mb={2}
                      color="white"
                    >
                      {activeLightroomSlide.title}
                    </Text>
                    <ShowMoreText
                      /* Default options */
                      lines={1}
                      more="more"
                      less="less"
                      className="content-css"
                      anchorClass="my-anchor-css-class"
                      onClick={executeOnClick}
                      expanded={false}
                      width={280}
                    >
                      <Text
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="18px"
                        color="white"
                      >
                        {activeLightroomSlide.description}
                      </Text>
                    </ShowMoreText>
                    {activeLightroomSlide?.createdBy && (
                      <Text
                        fontSize="14px"
                        mt={`${
                          !activeLightroomSlide.title &&
                          !activeLightroomSlide.description
                            ? 0
                            : '8px'
                        }`}
                        lineHeight="18px"
                        color="white"
                      >
                        Photo by{' '}
                        <span className="underline">
                          {activeLightroomSlide?.createdBy}
                        </span>{' '}
                        on <span className="underline">Unsplash</span>
                      </Text>
                    )}
                  </Box>
                ) : (
                  <Box
                    display="grid"
                    w="100%"
                    gridTemplateColumns="80px 1fr"
                    maxW="849px"
                    mx="auto"
                    pt={4}
                    pb={4}
                  >
                    <Flex
                      color="$mode.200"
                      fontSize="14px"
                      fontWeight="bold"
                      lineHeight="18px"
                      h="100%"
                      pr={3}
                      mr={3}
                      borderRight={
                        article.content?.uploadedFiles?.length === 1 ||
                        (article.content?.uploadedFiles &&
                          !article.content?.uploadedFiles[
                            activeLightroomSlide.index - 1
                          ].title &&
                          !article.content?.uploadedFiles[
                            activeLightroomSlide.index - 1
                          ].description)
                          ? 'none'
                          : '2px solid #E6E8E9'
                      }
                    >
                      {article.content?.uploadedFiles?.length > 1 && (
                        <>
                          <Text>
                            {activeLightroomSlide.index < 10
                              ? `0${activeLightroomSlide.index}`
                              : activeLightroomSlide.index}
                          </Text>
                          <Text color="$mode.400">
                            &nbsp;/&nbsp;
                            {article?.content?.uploadedFiles?.length < 10
                              ? `0${article.content.uploadedFiles.length}`
                              : article?.content?.uploadedFiles?.length}
                          </Text>
                        </>
                      )}
                    </Flex>
                    <Box>
                      <Text textStyle="h6Light" mb={2} color="$mode.400">
                        {activeLightroomSlide.title}
                      </Text>
                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="18px"
                        color="$mode.400"
                      >
                        {activeLightroomSlide.description}
                      </Text>
                      {activeLightroomSlide?.createdBy && (
                        <Text
                          fontSize="14px"
                          mt={`${
                            !activeLightroomSlide.title &&
                            !activeLightroomSlide.description
                              ? 0
                              : '8px'
                          }`}
                          lineHeight="18px"
                          color="gray.500"
                        >
                          Photo by{' '}
                          <span className="underline">
                            {activeLightroomSlide?.createdBy}
                          </span>{' '}
                          on <span className="underline">Unsplash</span>
                        </Text>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
              <style jsx global>{`
                .slider-list {
                  height: 100% !important;
                }
                .slider-slide {
                  opacity: 0.5;
                  height: 650px !important;
                }
                .slider-slide > div {
                  width: 100%;
                  height: 100%;
                }
                .slider-slide > div img {
                  height: 100%;
                  margin: 0 auto;
                }
                .slide-current {
                  opacity: 1;
                }
                .slider-control-centerright {
                  right: 0 !important;
                  position: fixed !important;
                  height: 100%;
                  width: 10%;
                }
                .slider-control-centerleft {
                  left: 0 !important;
                  position: fixed !important;
                  height: 100%;
                  width: 10%;
                }
                .my-anchor-css-class {
                  color: #74787d;
                  font-size: 14px;
                  font-weight: 400;
                }
                .content-css {
                  color: #74787d;
                  font-weight: 400;
                  color: white;
                  font-size: 14px;
                }
                .chakra-modal__content {
                  position: relative;
                  padding: 0;
                }
                .chakra-modal__close-btn {
                  position: absolute;
                }
              `}</style>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

function Copyright(props: { copyright?: string; article?: any }) {
  if (!props.article?.sourceID && !props.article?.contentID) {
    return null;
  }

  return <CopyrightFooter pt="32px" pb="40px" copyright={props.copyright} />;
}
