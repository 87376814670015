import { useTutorialMutation } from 'src/app/explore/use-tutorial';

export const BuildYourTeam2Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Start a RE:PPL
      </h1>
      <img
        src="/images/assets/tutorial/build-your-team-2.png"
        alt="build your team 2"
        className="w-full"
      />
    </>
  );
};

export const BuildYourTeam2Footer = ({
  closeModal,
  completed,
}: {
  closeModal?: any;
  completed: boolean;
}) => {
  const { mutateAsync: updateTutorial } = useTutorialMutation();

  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Search for Friends
      </h5>

      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Use the invite editor tool to find friends and acquaintances to join
        your team! Please note that once a user has declined an invitation, they
        can no longer be added as an editor.
      </p>

      <button
        type="button"
        onClick={async () => {
          if (!completed) {
            await updateTutorial({ step: 'build_team' });
          }
          closeModal();
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Done
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        2 / 2
      </h6>
    </>
  );
};
