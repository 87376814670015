export const Creations3Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Create with 1TM
      </h1>
      <img
        src="/images/assets/tutorial/creations-3.png"
        alt="creations 3"
        className="w-full"
      />
    </>
  );
};

export const Creations3Footer = ({ setNextModal }: { setNextModal?: any }) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Fine Tune the Post
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        In addition to adding category tags and hashtags to your work, you can
        also tag the people that you collaborated with and set a copyright for
        this work!
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('creations4');
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Next
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        3 / 4
      </h6>
    </>
  );
};
