import {
  Box,
  Center,
  Flex,
  FlexProps,
  Grid,
  Image,
  Text,
} from '@chakra-ui/react';
import { CacheKey } from 'src/constants/cache-key';
import { UnsplashResponse } from 'src/pages/api/unsplash';
import { useDebounce } from 'use-debounce';
import { useQuery } from 'react-query';
import { ReactNode, useState } from 'react';
import SvgCheck from 'src/components/icons/check';

import { SearchInput } from '../search-input/search-input';

const searchImages = (term: string): Promise<{ images: UnsplashResponse[] }> =>
  fetch(`/api/unsplash?term=${term}`).then((res) => res.json());

function useUnsplash(term: string) {
  return useQuery([CacheKey.unsplash, term], () => searchImages(term), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
}

export function UnsplashSelect({
  onChange,
  hidden,
  children,
  ...props
}: {
  onChange?: (us: UnsplashResponse | null) => void;
  hidden?: boolean;
  children?: ReactNode;
} & Omit<FlexProps, 'onChange'>) {
  const [selected, setSelected] = useState<UnsplashResponse>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const { data } = useUnsplash(debouncedSearchTerm);

  return (
    <Flex
      overflow="hidden"
      direction="column"
      flex={1}
      hidden={hidden}
      {...props}
    >
      <Box px={{ base: '24px', md: '40px' }}>
        <SearchInput
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          onKeyDown={(e) => e.stopPropagation()}
          wrapperProps={{
            mt: '24px',
            pb: 3,
            display: 'block',
          }}
          clear={() => setSearchTerm('')}
        />
      </Box>
      <Flex overflowY="scroll" px={{ base: '24px', md: '40px' }}>
        <Grid
          templateColumns="repeat(auto-fill, minmax(140px, 1fr))"
          flex={1}
          gap={2}
        >
          {data?.images?.map((image) => (
            <Box
              key={image.id}
              fontSize="12px"
              pos="relative"
              p={2}
              cursor="pointer"
              onClick={() => {
                const newState = selected?.id === image.id ? null : image;
                setSelected(newState);
                onChange?.(newState);
              }}
            >
              <Image
                bg={image.color}
                borderRadius="4px"
                src={image.thumb}
                height="90px"
                w="100%"
                objectFit="cover"
              />
              <Flex align="center">
                <Text textStyle="labelLight" color="$mode.500" as="span">
                  by
                </Text>
                <Text
                  ml={1}
                  color="$mode.900"
                  textStyle="labelLight"
                  as="span"
                  noOfLines={1}
                >
                  {image.author}
                </Text>
              </Flex>
              {selected?.id === image.id && (
                <Center
                  pos="absolute"
                  top={0}
                  right={0}
                  left={0}
                  bottom={0}
                  borderRadius="4px"
                  bg="rgba(179, 215, 247, 0.34)"
                >
                  <SvgCheck color="white" fontSize="32px" />
                </Center>
              )}
            </Box>
          ))}
        </Grid>
      </Flex>
      {children}
    </Flex>
  );
}
