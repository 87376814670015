export const EnableSubmissionsStartBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Start a RE:PPL
      </h1>
      <img
        src="/images/assets/tutorial/enable-submissions-start.png"
        alt="enable submissions"
        className="w-full"
      />
    </>
  );
};

export const EnableSubmissionsStartFooter = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Allowing Submissions
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        RE:PPL’s are collective efforts, so let the community join the ride by
        allowing other creators to submit their work!
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('enableSubmissions1');
        }}
        className="px-9 py-2.5 mb-6 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Start
      </button>
    </>
  );
};
