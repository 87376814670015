import React, { useContext } from 'react';
import {
  RecommendContext,
  RecommendContextType,
} from 'src/contexts/search/RecommendContext';
import {
  SearchContext,
  SearchContextType,
} from 'src/contexts/search/SearchContext';
import is from 'is_js';
import Router from 'next/router';
import SvgSearch from 'src/components/icons/search';
import SvgClose from 'src/components/icons/close';
import SearchPanelRecommendKeywords from './search-panel-recommend-keywords';
// import KeywordsRecord from './keywords-record';
// import Link from 'next/link';
// import Suggestion from './suggestion';
import NoResult from './no-result';

const SearchPanel = ({ modal }: { modal?: any }) => {
  const {
    inputKeyword,
    resultLite,
    searchHandle,
    trendingSuggestions,
    isFetching,
  } = useContext(SearchContext) as SearchContextType;
  const { recommend, trend } = useContext(
    RecommendContext
  ) as RecommendContextType;
  const searchWord =
    inputKeyword.current && inputKeyword.current.value
      ? inputKeyword.current.value
      : '';

  function handleKeyPress(key) {
    if (key.code === 'Enter') {
      Router.push(`/search/${inputKeyword.current.value}`);
      document.body.style.overflow = 'auto';
    }
  }
  function handleSearch() {
    Router.push(`/search/${inputKeyword.current.value}`);
    document.body.style.overflow = 'auto';
  }
  return (
    <div style={{ padding: '0 10px 10px 10px' }}>
      <div className="relative px-4 bg-white">
        <input
          id="search-input"
          type="text"
          className="ml-6 mr-3.5 px-1"
          placeholder="Search 1TM.io"
          style={{ height: '60px', width: 'calc(100% - 38px)' }}
          ref={inputKeyword}
          onKeyUp={() => searchHandle('LITE')}
          onKeyPress={(e) => handleKeyPress(e)}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
        <span
          aria-hidden="true"
          className="text-gray-500 cursor-pointer pt-0.5 absolute top-0 flex items-center left-4"
          style={{ height: '60px' }}
          onClick={() => handleSearch()}
        >
          <SvgSearch className="dark:text-gray-500 text-2xl" />
        </span>
        <span
          aria-hidden="true"
          className="text-gray-500 cursor-pointer pt-0.5 absolute right-4 top-0 flex items-center"
          style={{ height: '60px' }}
          onClick={modal.onClose}
        >
          <SvgClose className="text-lg inline" fontSize="14px" />
        </span>
      </div>
      <div
        className="bg-white px-4 py-8 max-h-96 overflow-hidden rounded-b"
        style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06)' }}
      >
        <div className="w-full max-h-80 overflow-y-hidden">
          <div className="overflow-y-auto search-result hide-scrollbar max-h-96">
            {(is.not.empty(resultLite) || searchWord === '') && (
              <div className="flex flex-wrap flex-1">
                <SearchPanelRecommendKeywords
                  searchResult={resultLite}
                  recenct={trend}
                  recommended={recommend}
                  className="flex-1"
                />
              </div>
            )}

            {is.empty(resultLite) && !isFetching && searchWord !== '' && (
              <NoResult
                searchWord={searchWord}
                suggestions={trendingSuggestions}
              />
            )}
            {isFetching && <div>loading...</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(SearchPanel);
