import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  SystemStyleObject,
  useBreakpointValue,
  useTheme,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { ReactNode, RefObject, useState, useEffect } from 'react';
import {
  ContentEditable,
  ContentEditableHandler,
  usePlainTextPaste,
} from 'src/components/content-editable';
import SvgExcerptsInfo from 'src/components/icons/excerpts-info';

import BodyPadding from 'src/components/layout/body-padding';
import { Theme } from 'src/theme';
import { useRouter } from 'next/router';
import { EDITOR_CONFIG } from './config';

export interface EditorProps extends FlexProps {
  id?: string;
  titleRef?: RefObject<ContentEditableHandler>;
  title?: string;
  coverImgURL?: string;
  titleProps?: BoxProps;
  showTitle?: boolean;
  children?: ReactNode;
  editorChildren?: ReactNode;
  readOnly?: boolean;
  editorSx?: SystemStyleObject;
  full?: boolean;
  holderId?: string;
  minHeight?: string;
  cover?: ReactNode;
  updateDescriptionText?: (value: any) => void;
  updateExcerpts?: (value: any) => void;
  description?: any;
  excerpts?: any;
  requiredFieldsWarningStatus?: any;
}

export function Editor({
  id,
  title,
  titleRef,
  children,
  readOnly,
  editorSx,
  full = false,
  titleProps,
  editorChildren,
  holderId,
  minHeight,
  showTitle = true,
  cover,
  updateDescriptionText,
  updateExcerpts,
  description,
  excerpts,
  requiredFieldsWarningStatus,
  ...props
}: EditorProps) {
  usePlainTextPaste(titleRef?.current?.getRef());
  const [titleLength, setTitleLength] = useState(0);
  const [useDescription, setDescription] = useState('');
  const [useExcerpts, setExcerpts] = useState('');
  const router = useRouter();

  useEffect(() => {
    if (updateExcerpts) {
      updateExcerpts(useExcerpts);
    }
  }, [useExcerpts]);

  useEffect(() => {
    if (updateDescriptionText) {
      updateDescriptionText(useDescription);
    }
  }, [useDescription]);

  useEffect(() => {
    if (description) {
      setDescription(description);
    }
    if (excerpts) {
      setExcerpts(excerpts);
    }
    if (titleRef?.current?.getText().length !== 0) {
      setTitleLength(titleRef?.current?.getText().length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (router.query.type === 'mediaPost') {
    return (
      <BodyPadding px={full ? 0 : '40px'} pt="25px" pb="65px" {...props}>
        <Flex justifyContent="center" w="100%">
          <Flex
            position="relative"
            direction="column"
            width="100%"
            maxW="1056px"
          >
            {children}
            <Flex
              display="inline-flex"
              w="100%"
              borderRadius="4px"
              zIndex={0}
              flex={1}
              position="relative"
            >
              <Box mr="13px" w="39%">
                {!readOnly && showTitle && (
                  <Box mb="24px">
                    <Flex
                      justifyContent="space-between"
                      alignItems="flex-end"
                      mb={2}
                    >
                      <Text textStyle="h6Light" color="$mode.600">
                        * Title
                      </Text>
                    </Flex>
                    <Box position="relative">
                      <Text
                        fontSize="12px"
                        textStyle="labelLight"
                        color={
                          titleLength === 70 ||
                          requiredFieldsWarningStatus?.title
                            ? 'warning'
                            : '$mode.500'
                        }
                        position="absolute"
                        right="20px"
                        bottom="10px"
                      >
                        {70 - titleLength}
                      </Text>
                      <ContentEditable
                        singleLineTitle
                        singleLine
                        fontSize="sm"
                        maxLen={70}
                        maxW="401px"
                        bg="white"
                        py="31px"
                        px="24px"
                        fontWeight="500"
                        lineHeight="19px"
                        ref={titleRef as any}
                        defaultValue={title}
                        border="1px solid"
                        borderColor={
                          titleLength === 70 ||
                          requiredFieldsWarningStatus?.title
                            ? 'warning'
                            : '$mode.200'
                        }
                        borderRadius="4px"
                        placeholder="Enter a title..."
                        {...titleProps}
                        onInput={() => {
                          setTitleLength(titleRef?.current?.getText().length);
                        }}
                        _hover={{
                          borderColor:
                            titleLength === 70 ||
                            requiredFieldsWarningStatus?.title
                              ? 'warning'
                              : '#D3D6D8',
                        }}
                      />
                    </Box>
                  </Box>
                )}
                <Flex direction="column">
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mb={2}
                    w="100%"
                  >
                    <Text textStyle="h6Light" color="$mode.600" mr={2}>
                      * Description
                    </Text>
                  </Flex>
                  <Box position="relative" mb="24px">
                    <Text
                      fontSize="12px"
                      textStyle="labelLight"
                      color={
                        useDescription.length === 5000 ||
                        requiredFieldsWarningStatus?.description
                          ? 'warning'
                          : '$mode.500'
                      }
                      position="absolute"
                      right="20px"
                      bottom="16px"
                      zIndex="999"
                    >
                      {5000 - useDescription.length}
                    </Text>
                    <Textarea
                      className="description-scrollbar"
                      id={holderId || 'editor'}
                      placeholder="Summary of your story"
                      fontSize="sm"
                      minH="343px"
                      py="24px"
                      pl="16px"
                      pr="25px"
                      fontWeight="500"
                      lineHeight="19px"
                      border="1px solid"
                      borderColor={
                        useDescription.length === 5000 ||
                        requiredFieldsWarningStatus?.description
                          ? 'warning'
                          : '$mode.200'
                      }
                      borderRadius="4px"
                      resize="none"
                      _focus={{
                        border: '1px solid #E6E8E9',
                        borderColor:
                          useDescription.length === 5000 ||
                          requiredFieldsWarningStatus?.description
                            ? 'warning'
                            : '#E6E8E9',
                        '::placeholder': {
                          color: 'rgba(0,0,0,0)',
                        },
                      }}
                      _hover={{
                        border: '1px solid #E6E8E9',
                        borderColor:
                          useDescription.length === 5000 ||
                          requiredFieldsWarningStatus?.description
                            ? 'warning'
                            : '#E6E8E9',
                      }}
                      value={useDescription}
                      onChange={(e) => {
                        setDescription((e.target as HTMLTextAreaElement).value);
                      }}
                      maxLength={5000}
                    />
                  </Box>
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mb={2}
                    w="100%"
                  >
                    <Flex
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      wrap="wrap"
                    >
                      <Text
                        textStyle="h6Light"
                        display="flex"
                        alignItems="center"
                        color="$mode.600"
                      >
                        Excerpts{' '}
                        <SvgExcerptsInfo
                          title="Excerpts are a summary of your post. It will be displayed in a post grid."
                          _hover={{ cursor: 'pointer' }}
                          ml="10px"
                          fontSize="19px"
                        />
                      </Text>
                    </Flex>
                  </Flex>
                  <Box position="relative" h="130px">
                    <Text
                      fontSize="12px"
                      textStyle="labelLight"
                      color={
                        useExcerpts.length === 260 ? 'warning' : '$mode.500'
                      }
                      position="absolute"
                      right="20px"
                      bottom="10px"
                      zIndex="999"
                    >
                      {260 - useExcerpts.length}
                    </Text>
                    <Textarea
                      className="description-scrollbar"
                      placeholder="Summary of your story"
                      fontSize="sm"
                      h="100%"
                      minH="130px"
                      py="24px"
                      pl="16px"
                      pr="25px"
                      fontWeight="500"
                      lineHeight="19px"
                      border="1px solid"
                      borderColor={
                        useExcerpts.length === 260 ? 'warning' : '$mode.200'
                      }
                      borderRadius="4px"
                      resize="none"
                      _focus={{
                        border: '1px solid #E6E8E9',
                        borderColor:
                          useExcerpts.length === 260 ? 'warning' : '$mode.200',
                        '::placeholder': {
                          color: 'rgba(0,0,0,0)',
                        },
                      }}
                      value={useExcerpts}
                      onChange={(e) => {
                        setExcerpts((e.target as HTMLTextAreaElement).value);
                      }}
                      maxLength={260}
                    />
                  </Box>
                </Flex>
              </Box>
              <Box ml="13px" w="61%">
                <Flex
                  justifyContent="space-between"
                  alignItems="flex-end"
                  mb={2}
                  w="100%"
                >
                  <Text textStyle="h6Light" color="$mode.600">
                    * Media
                  </Text>
                </Flex>
                <Box
                  w="100%"
                  h="calc(100% - 27px)"
                  borderRadius="4px"
                  border="1px solid"
                  borderColor={
                    requiredFieldsWarningStatus?.uploadedFiles
                      ? 'warning'
                      : '$mode.200'
                  }
                  background="$mode.50"
                >
                  {cover}
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <style jsx global>{`
          .hide-scrollbar::-webkit-scrollbar {
            display: none !important;
          }
          .hide-scrollbar.title {
            resize: none;
            white-space: nowrap;
            overflow-x: scroll;
          }
          .description-scrollbar::-webkit-scrollbar {
            width: 12px;
          }
          .description-scrollbar::-webkit-scrollbar-thumb {
            background: #e6e8e9;
            border-radius: 2px;
            border-right: 8px solid #fff;
            border-top: 8px solid #fff;
            border-bottom: 8px solid #fff;
          }
          .description-scrollbar::-webkit-scrollbar-thumb:hover {
            background: #b6b6b6;
          }
        `}</style>
      </BodyPadding>
    );
  }
  return (
    <BodyPadding px={full ? 0 : '40px'} pt="25px" pb="100px" {...props}>
      <Flex justifyContent="center" w="100%">
        <Flex position="relative" direction="column" width="100%" maxW="1056px">
          {children}
          <Flex
            display="inline-flex"
            w="100%"
            borderRadius="4px"
            zIndex={0}
            flex={1}
            position="relative"
          >
            <Box mr="13px" w="39%" position="relative">
              {!readOnly && showTitle && (
                <Box mb="24px">
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mb={2}
                  >
                    <Text textStyle="h6Light" color="$mode.600">
                      * Title
                    </Text>
                  </Flex>
                  <Box position="relative">
                    <Text
                      fontSize="12px"
                      textStyle="labelLight"
                      color={
                        titleLength === 70 || requiredFieldsWarningStatus?.title
                          ? 'warning'
                          : '$mode.500'
                      }
                      position="absolute"
                      right="20px"
                      bottom="10px"
                    >
                      {70 - titleLength}
                    </Text>
                    <ContentEditable
                      singleLineTitle
                      singleLine
                      fontSize="sm"
                      maxLen={70}
                      maxW="401px"
                      bg="white"
                      py="31px"
                      px="24px"
                      fontWeight="500"
                      lineHeight="19px"
                      ref={titleRef as any}
                      defaultValue={title}
                      border="1px solid"
                      borderColor={
                        titleLength === 70 || requiredFieldsWarningStatus?.title
                          ? 'warning'
                          : '$mode.200'
                      }
                      borderRadius="4px"
                      placeholder="Enter a title..."
                      {...titleProps}
                      onInput={() => {
                        setTitleLength(titleRef?.current?.getText().length);
                      }}
                      _hover={{
                        borderColor:
                          titleLength === 70 ||
                          requiredFieldsWarningStatus?.title
                            ? 'warning'
                            : '#D3D6D8',
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Flex justifyContent="space-between" alignItems="flex-end" mb={2}>
                <Text textStyle="h6Light" color="$mode.600">
                  * Cover image
                </Text>
              </Flex>
              <Box mb="24px">{cover}</Box>
              <Flex
                justifyContent="space-between"
                alignItems="flex-end"
                mb={2}
                w="100%"
              >
                <Flex
                  w="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="wrap"
                >
                  <Text
                    textStyle="h6Light"
                    display="flex"
                    alignItems="center"
                    color="$mode.600"
                  >
                    Excerpts{' '}
                    <SvgExcerptsInfo
                      title="Excerpts are a summary of your post. It will be displayed in a post grid."
                      _hover={{ cursor: 'pointer' }}
                      ml="10px"
                      fontSize="19px"
                    />
                  </Text>
                </Flex>
              </Flex>
              <Box position="relative" h="130px">
                <Text
                  fontSize="12px"
                  textStyle="labelLight"
                  color={useExcerpts.length === 260 ? 'warning' : '$mode.500'}
                  position="absolute"
                  right="20px"
                  bottom="10px"
                  zIndex="999"
                >
                  {260 - useExcerpts.length}
                </Text>
                <Textarea
                  className="description-scrollbar"
                  placeholder="Summary of your story"
                  fontSize="sm"
                  h="100%"
                  minH="130px"
                  py="24px"
                  pl="16px"
                  pr="25px"
                  fontWeight="500"
                  lineHeight="19px"
                  border="1px solid"
                  borderColor={
                    useExcerpts.length === 260 ? 'warning' : '$mode.200'
                  }
                  borderRadius="4px"
                  resize="none"
                  _focus={{
                    border: '1px solid #E6E8E9',
                    borderColor:
                      useExcerpts.length === 260 ? 'warning' : '$mode.200',
                    '::placeholder': {
                      color: 'rgba(0,0,0,0)',
                    },
                  }}
                  value={useExcerpts}
                  onChange={(e) => {
                    setExcerpts((e.target as HTMLTextAreaElement).value);
                  }}
                  maxLength={260}
                />
              </Box>
            </Box>
            <Box ml="13px" w="61%" position="relative">
              <Flex justifyContent="space-between" alignItems="flex-end" mb={2}>
                <Text textStyle="h6Light" color="$mode.600">
                  * Story
                </Text>
              </Flex>
              <Box
                bg="white"
                minH="calc(100% - 75px)"
                className={readOnly ? 'read-only' : ''}
                position="relative"
                id={holderId || 'editor'}
                pl={full ? 0 : 4}
                pr={full ? 0 : 4}
                py={full ? 0 : 4}
                zIndex={0}
                onPaste={(e) => e.stopPropagation()}
                maxWidth={EDITOR_CONFIG.maxWidth}
                sx={editorSx}
                boxSizing="content-box"
                border="1px solid"
                borderColor={
                  requiredFieldsWarningStatus?.content ? 'warning' : '$mode.200'
                }
              >
                {editorChildren}
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <GlobalEditorStyle full={full} />
      <style jsx global>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none !important;
        }
        .hide-scrollbar.title {
          resize: none;
          white-space: nowrap;
          overflow-x: scroll;
        }
        .description-scrollbar::-webkit-scrollbar {
          width: 12px;
        }
        .description-scrollbar::-webkit-scrollbar-thumb {
          background: #e6e8e9;
          border-radius: 2px;
          border-right: 8px solid #fff;
          border-top: 8px solid #fff;
          border-bottom: 8px solid #fff;
        }
        .description-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #b6b6b6;
        }
        .codex-editor__redactor {
          padding-bottom: 100px !important;
        }
        .ce-block__content {
          padding: 5px 35px !important;
        }
        .codex-editor--narrow .ce-toolbar__actions {
          right: 0 !important;
          top: 0 !important;
        }
        .image-tool__image {
          width: 100% !important;
        }
      `}</style>
    </BodyPadding>
  );
}

function GlobalEditorStyle(props: { full?: boolean }) {
  const theme = useTheme<Theme>();
  const { bodyQuote, bodyQuote0, body4 } = theme.textStyles;
  const isMobile = useBreakpointValue({
    base: true,
    ml: false,
  });
  const quote = useBreakpointValue({
    base: bodyQuote0,
    md: bodyQuote,
  });

  const isMobileFull = isMobile && !!props.full;

  return (
    <style jsx global>{`
      .image-tool__image {
        width: fit-content;
      }
      .image-tool__row.svelte-mydto2 {
        margin-bottom: unset !important;
      }

      .ce-delimiter::before {
        color: rgb(196, 196, 196);
        content: '・・・' !important;
      }
      .ce-header {
        padding: 8px 0 !important;
      }
      .ce-header,
      .cdx-quote,
      .ce-paragraph,
      .link-tool,
      .cdx-list {
        padding-left: ${isMobileFull ? '24px !important' : 0};
        padding-right: ${isMobileFull ? '24px' : 0};
        max-width: ${EDITOR_CONFIG.articleMaxWidth};
        margin: auto !important;
      }
      .ce-paragraph[data-placeholder]:empty::before {
        color: ${theme.colors.gray['300']};
      }
      .cdx-list {
        position: relative;
        left: 18px;
      }
      .ce-paragraph {
        font-size: ${theme.fontSizes[body4.fontSize]};
        line-height: ${body4.lineHeight};
        letter-spacing: ${body4.letterSpacing};
      }
      .cdx-block,
      .cdx-list__item {
        padding-left: 0;
      }

      .ce-toolbar .ce-toolbox {
        border-radius: 4px;
        overflow: hidden;
      }
      .cdx-quote__text {
        min-height: unset !important;
        border: none;
        border-left: 2px solid;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 0;
        font-size: ${theme.fontSizes[quote?.fontSize] || '20px'};
        line-height: ${quote?.lineHeight || '32px'};
        font-style: italic;

        padding-left: 16px;
      }
      .cdx-quote__caption {
        display: none;
      }
      .ce-inline-tool,
      .ce-inline-toolbar__dropdown:hover,
      .ce-conversion-toolbar,
      .ce-toolbar .ce-toolbox,
      .ce-inline-tool:hover,
      .ce-conversion-tool:hover,
      .ce-conversion-tool__icon,
      .ce-settings,
      .cdx-settings-button,
      .cdx-settings-button:hover,
      .ce-settings__button,
      .ce-settings__button:hover,
      .ce-toolbox__button,
      .ce-inline-toolbar {
        background-color: ${theme.colors.gray['900']};
        color: #dadada;
      }
      .ce-block__content {
        max-width: ${EDITOR_CONFIG.articleMaxWidth};
      }
      .ce-toolbar__content {
        max-width: ${EDITOR_CONFIG.articleMaxWidth};
      }
      .codex-editor__redactor {
        margin-right: 0px !important;
      }
      .read-only
        .codex-editor--empty
        .ce-block:first-child
        .ce-paragraph[data-placeholder]:empty::before {
        opacity: 0;
      }
      .ce-toolbar__plus--hidden {
        display: inline-flex;
      }
      .image-tool__caption:focus {
        display: block !important;
      }
      .ce-settings__button--focused {
        background: unset !important;
        box-shadow: unset;
      }
      .cdx-settings-button--active {
        background: rgba(34, 186, 255, 0.08) !important;
        box-shadow: inset 0 0 0 1px rgb(7 161 227 / 8%);
      }

      div[data-tune='addImages'] {
        background: unset !important;
        box-shadow: unset !important;
      }
    `}</style>
  );
}
