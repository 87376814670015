import * as React from 'react';
import { Svg } from '../svg';

function SvgAddMediaIcons({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 133 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M21.833 28l-6.349-11.64L20.5 8l10.667 20h-9.334zM11.167 13.333l8 14.667h-16l8-14.667zm-3.334-2.666a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667z"
        fill="#D3D6D8"
      />
      <path
        d="M40.718 20.398h1.56v-2.66h2.66v-1.56h-2.66v-2.66h-1.56v2.66h-2.66v1.56h2.66v2.66z"
        fill="#BBC0C5"
      />
      <g clipPath="url(#add-media-icons_svg__clip0)">
        <path
          d="M53.167 5.324A1.333 1.333 0 0154.489 4h24.022c.73 0 1.322.593 1.322 1.324v21.352A1.333 1.333 0 0178.511 28H54.489a1.324 1.324 0 01-1.322-1.324V5.324zm11.496 5.896a.534.534 0 00-.83.443v8.674a.534.534 0 00.83.443l6.505-4.336a.534.534 0 000-.888l-6.507-4.336h.002z"
          fill="#D3D6D8"
        />
      </g>
      <path
        d="M90.718 20.398h1.56v-2.66h2.66v-1.56h-2.66v-2.66h-1.56v2.66h-2.66v1.56h2.66v2.66z"
        fill="#BBC0C5"
      />
      <g clipPath="url(#add-media-icons_svg__clip1)">
        <path
          d="M117.833 12.226a3.999 3.999 0 102.667 3.773V3.277c5.409 1.698 9.333 6.752 9.333 12.722 0 7.364-5.969 13.334-13.333 13.334s-13.333-5.97-13.333-13.334S109.136 2.666 116.5 2.666c.449 0 .895.023 1.333.067v9.493z"
          fill="#D3D6D8"
        />
      </g>
      <defs>
        <clipPath id="add-media-icons_svg__clip0">
          <path fill="#fff" transform="translate(50.5)" d="M0 0h32v32H0z" />
        </clipPath>
        <clipPath id="add-media-icons_svg__clip1">
          <path fill="#fff" transform="translate(100.5)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgAddMediaIcons);
export default ForwardRef;
