import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

function BodyPadding({
  children,
  ...props
}: { children: React.ReactNode } & FlexProps) {
  return (
    <Flex
      layerStyle="layer2"
      height="100%"
      minWidth="100%"
      maxWidth="100vw"
      px={{ base: '40px', md: '100px', lg: '250px' }}
      {...props}
    >
      {children}
    </Flex>
  );
}

export default BodyPadding;
