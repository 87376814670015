import { Grid, GridItem, Box, Text, Image } from '@chakra-ui/react';
import { Avatar } from 'components/avatar';
import dayHepler from 'src/heplers/day';
import { imageOptimize } from 'src/service/image';
import { GetLink } from './getsubtext';

export function NotificationCard(props) {
  const notif = props?.notif;
  let notifDetails = {
    gridPadding: 0,
    avatarMargin: '2px 0.5rem 2px 4px',
    subTextMargin: '0px',
  };
  if (props.cardCenter) {
    notifDetails = {
      gridPadding: 2,
      avatarMargin: '0 4px',
      subTextMargin: '3px',
    };
  }

  const notifClicked = (data) => {
    if (props.clicked) {
      props.clicked(data);
    }
  };

  const author = { username: notif.destination.username };

  return (
    <GetLink notif={notif} clicked={notifClicked}>
      <Grid
        p={{ base: '5px 2px', md: notifDetails.gridPadding }}
        m={{ base: '2px 1px', md: 1 }}
        w="inherit"
        gridTemplateRows="repeat(2, 1fr)"
        gap={0}
      >
        <GridItem
          rowSpan={2}
          display="flex"
          maxWidth={props.cardCenter ? '' : '87%'}
          className="relative"
        >
          {!notif?.read && (
            <Box
              height="fit-content"
              className="absolute m-auto top-0 bottom-0 text-5xl"
              color="#FF5F3B"
            >
              &#8226;
            </Box>
          )}
          <Box
            w={props.boxWidth}
            m={notifDetails.avatarMargin}
            padding={{ base: '0 1px 0 8px', md: '0 0.5rem 0 1rem' }}
            color="white"
          >
            <Avatar
              name=""
              data-testid="user avatar"
              bg="$mode.400"
              width={40}
              height={40}
              src={imageOptimize({
                url: notif?.headPicURL,
                imgContainer: 'avatar',
              })}
              author={author}
              mr={1}
            />
          </Box>
          <Text
            marginLeft={notifDetails.subTextMargin}
            noOfLines={3}
            maxHeight="5rem"
            width="100%"
            textStyle="labelLight"
            color="$mode.500"
            fontSize={{ base: '12px' }}
            fontWeight="700"
            display="inline"
            dangerouslySetInnerHTML={{ __html: notif.copy }}
          />
          {notif?.thumbnailURL && props.cardCenter && (
            <Box color="white" width="155px" height="60px" overflow="hidden">
              <Image
                src={imageOptimize({
                  url: notif?.thumbnailURL,
                  imgContainer: 'avatar',
                })}
                objectFit="cover"
              />
            </Box>
          )}
        </GridItem>
        <GridItem>
          <Box px={{ base: '70px', md: '70px' }} py={0}>
            {notif?.date && (
              <div className="text-xs font-normal text-gray-400 dark:text-white">
                {dayHepler.fromNow(notif?.createdAt)}
              </div>
            )}
          </Box>
        </GridItem>
      </Grid>
    </GetLink>
  );
}
