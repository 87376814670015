import * as React from 'react';
import { Svg } from '../svg';

function SvgLinkedinV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={20} cy={21} r={15.426} fill="#242526" />
      <g clipPath="url(#linkedin-v3_svg__clip0_5778_227032)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.225 30.39v-7.808c0-4.183-2.234-6.13-5.211-6.13-2.403 0-3.48 1.322-4.08 2.25v-1.93h-4.526c.06 1.278 0 13.618 0 13.618h4.527v-7.605c0-.406.029-.813.149-1.104.327-.813 1.071-1.655 2.321-1.655 1.639 0 2.294 1.249 2.294 3.079v7.286h4.526v-.001zM12.639 14.913c1.578 0 2.562-1.046 2.562-2.354-.03-1.335-.984-2.352-2.532-2.352s-2.56 1.016-2.56 2.352c0 1.308.982 2.354 2.502 2.354h.028zM20 40.965c-11.045 0-20-8.954-20-20s8.955-20 20-20 20 8.954 20 20-8.955 20-20 20zM14.903 30.39V16.772h-4.526V30.39h4.526z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="linkedin-v3_svg__clip0_5778_227032">
          <path fill="#fff" transform="translate(0 .965)" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLinkedinV3);
export default ForwardRef;
