/* eslint-disable no-fallthrough */
import React, { createContext, useState, useEffect } from 'react';
import algolia from 'src/lib/algolia';

export interface RecommendContextType {
  recommend: Array<string>;
  trend: Array<string>;
}

interface Props {
  children: React.ReactNode;
}

export const RecommendContext = createContext<RecommendContextType | undefined>(
  undefined
);

const RecommendProvider = ({ children }: Props) => {
  const [recommend, setRecommend] = useState<RecommendContextType['recommend']>(
    []
  );
  const [trend, setTrend] = useState<RecommendContextType['trend']>([]);

  useEffect(() => {
    async function suggest() {
      const suggestions = await algolia.suggestions('');
      setRecommend(suggestions);
      setTrend(suggestions);
    }
    suggest();
  }, []);

  return (
    <RecommendContext.Provider
      value={{
        recommend,
        trend,
      }}
    >
      {children}
    </RecommendContext.Provider>
  );
};
export default RecommendProvider;
