import dayHepler from 'src/heplers/day';

import {
  Box,
  Flex,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { KeyboardEvent, useState, useEffect } from 'react';
import Link from 'next/link';

import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
// import { toRem } from 'src/theme/tools';
import type { Comment } from 'src/app/article/types';

import SvgEnthuse from 'src/components/icons/enthuse';
import SvgEnthused from 'src/components/icons/enthused';
import SvgDeleteEditComment from 'src/components/icons/delete-edit-comment';
import { rendererBlockMaps } from 'src/lib/hexx/render-blocks';
import { EditorRenderer } from '@hexx/renderer';
import { imageOptimize } from 'src/service/image';
import { useModal } from 'src/hooks/use-modal';
import { GalleryModal } from 'src/lib/hexx/blocks/gallery/gallery-renderer';
import { useDeleteCommentMutation } from 'src/app/article/hooks/use-article-comment';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { Avatar } from '../avatar';
import { Commenter } from './commenter';

export type CommentBlockProps = {
  isModal?: boolean;
  /**
   * Render block as sub comment.
   */
  asSubcomment?: boolean;

  /**
   * Render reply icon.
   */
  hideReply?: boolean;

  /**
   * Display viewer information.
   */
  viewer: any;

  /**
   * Handling input onKeyDown event.
   */
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>, commentID?: any) => void;

  /**
   * Handling if comment enthused
   */

  isEnthused?: boolean;

  /**
   * Handling click enthuse icon action.
   */
  onEnthuse?: () => void;

  onComment?: ({
    keyEvent,
    blockId,
    value,
    commentID,
  }: {
    keyEvent?: any;
    blockId?: string;
    value: string;
    commentID?: any;
  }) => void;

  index?: string;

  childReply?: any;
  setChildReply?: (value: any) => void;
  isReply?: any;

  assets?: any; // TODO: not yet ready
  children?: any;
} & { comment: Comment };

export function CommentBlock(props: CommentBlockProps) {
  const [isEditing, setIsEditing] = useState(false);

  const { mutateAsync: deleteComment } = useDeleteCommentMutation();

  const {
    asSubcomment,
    comment: { commenter, content, enthuseCount, createdAt, id, parentID },
    viewer,
    hideReply,
    onKeyDown,
    isEnthused = false,
    onEnthuse,
    index,
    isModal,
    isReply,
    setChildReply,
  } = props;

  const commentId = props.comment.id;
  const isMobile = useIsMobile();

  const galleryModal = useModal();
  const [currAsset, setCurrAsset] = useState<any>();
  const [replyCommenter, setReplyCommenter] = useState<any>();

  useEffect(() => {
    if (isReply) {
      if (isReply.id === id) {
        setIsEditing(true);
        setReplyCommenter(isReply.commenter);
      }
    }
  }, [isReply]);

  const [hideComment, setHideComment] = useState(false);
  const [showEditComment, setShowEditComment] = useState(false);

  if (hideComment) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing="12px"
      px="0px"
      borderLeft={
        // eslint-disable-next-line no-nested-ternary
        isMobile ? 'none' : asSubcomment ? '4px solid #ECEEED' : 'none'
      }
      borderRadius="4px"
      pt={asSubcomment ? isMobile && '12px' : 3}
      ml={asSubcomment && !isMobile && 7}
    >
      {!isMobile && asSubcomment && <Box w="2px" h="100%" />}

      <Avatar
        name={getProfileFullNameOrUsername(commenter)}
        src={commenter?.profileImgURL}
        width={40}
        height={40}
        author={commenter}
      />

      <Stack width="100%" direction="column" spacing={1} position="relative">
        {/* commenter */}
        <Flex color="$mode.500" fontSize="sm" alignItems="center">
          <Link href={`/${commenter?.username}`} passHref>
            <Text color="$mode.700" fontWeight="700" lineHeight="18px">
              {getProfileFullNameOrUsername(commenter)}
            </Text>
          </Link>
          <Text as="span">・</Text>
          <Text as="span" lineHeight="20px" color="gray.500">
            {dayHepler?.fromNow(createdAt)}
          </Text>
        </Flex>

        {/* comment */}
        {showEditComment && (
          <Commenter
            user={viewer}
            editorIndex={`edit-${commentId}`}
            commentContent={{ blocks: props.comment.content.blocks }}
            commentUploadedFiles={props.comment.content.uploadedFiles}
            onKeyDown={(event) => {
              onKeyDown(event, commentId);
              setShowEditComment(false);
            }}
          />
        )}
        {!showEditComment &&
          (content?.blocks ? (
            <Box
              fontSize={{ base: 'xs', md: 'md' }}
              lineHeight="18px"
              fontWeight={{ base: '500', md: '200' }}
              color="gray.900"
              mt={isMobile && '0px!important'}
              className="editor-render"
              wordBreak="break-all"
            >
              <EditorRenderer
                maxWidth="100%"
                blocks={content.blocks}
                blockMap={rendererBlockMaps}
              />
              <style jsx global>{`
                .editor-render p {
                  padding: 0px !important;
                }
              `}</style>
            </Box>
          ) : (
            <Text fontSize="xs" mb={1}>
              {content}
            </Text>
          ))}

        {content?.uploadedFiles?.length > 0 &&
          !showEditComment &&
          content?.uploadedFiles?.map((contentImages) => (
            <>
              <div
                role="button"
                aria-label="gallery view"
                tabIndex={0}
                style={{ height: '6rem', width: '8.7rem' }}
                className="comment-box m-2 cursor-pointer rounded-lg"
                onClick={() => {
                  setCurrAsset(content?.uploadedFiles);
                  galleryModal.onSelect(true);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    setCurrAsset(content?.uploadedFiles);
                    galleryModal.onSelect(true);
                  }
                }}
              />
              <style jsx>
                {`
                  .comment-box {
                    background-image: url('${imageOptimize(
                      contentImages.imgURL
                    )}');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                  }
                `}
              </style>
            </>
          ))}

        {/* Enthuse and reply */}

        <Flex direction="column">
          {!showEditComment && (
            <Flex color="$mode.500" alignItems="center">
              {isEnthused ? (
                <SvgEnthused
                  className="text-gray-900"
                  fontSize={{ base: '14px', md: '24px' }}
                  cursor={viewer ? 'pointer' : 'default'}
                  onClick={viewer && onEnthuse}
                />
              ) : (
                <SvgEnthuse
                  fontSize={{ base: '14px', md: '24px' }}
                  cursor={viewer ? 'pointer' : 'default'}
                  onClick={viewer && onEnthuse}
                />
              )}
              <Text ml={1} mr={2} as="span" fontSize="xs" fontWeight="bold">
                {enthuseCount} •
              </Text>

              {!hideReply && viewer && (
                <Text
                  as="span"
                  cursor="pointer"
                  color="$mode.500"
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight="bold"
                  onClick={() => setIsEditing(true)}
                >
                  Reply
                </Text>
              )}
              {hideReply && (
                <Text
                  as="span"
                  cursor="pointer"
                  color="$mode.500"
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight="bold"
                  onClick={() => setChildReply({ commenter, id: parentID })}
                >
                  Reply
                </Text>
              )}
            </Flex>
          )}

          {props.children}
          {!showEditComment && (
            <Commenter
              editorIndex={
                isModal ? `modalsub${index}${id}` : `sub${index}${id}`
              }
              asSubcomment
              user={viewer}
              userReply={replyCommenter}
              commenter={commenter}
              onKeyDown={onKeyDown}
              onReply={isEditing}
              stackStyle={{
                display: isEditing ? 'inline-flex' : 'none',
              }}
            />
          )}

          {galleryModal.selected && (
            <GalleryModal
              {...galleryModal.modal}
              assets={currAsset}
              data={currAsset}
            />
          )}
        </Flex>

        {viewer.id === commenter.id && (
          <Menu>
            <MenuButton position="absolute" right="0" top="0" width="auto">
              <SvgDeleteEditComment />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setShowEditComment(true);
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  await deleteComment({ commentId });
                  setHideComment(true);
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Stack>
    </Stack>
  );
}
