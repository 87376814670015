/* eslint-disable no-fallthrough */
import React, {
  createContext,
  useRef,
  useState,
  useEffect,
  useReducer,
} from 'react';
import is from 'is_js';
import debounce from 'lodash.debounce';
import { setLocalStorage, getLocalStorage } from 'src/heplers/localStorage';
import algolia from 'src/lib/algolia';
import { genreSort } from 'src/heplers/sortData';
import { imageOptimize } from 'src/service/image';

export interface IResult {
  totalResult: number;
  data: object;
  collections?: Array<object>;
  creations?: Array<object>;
  creators?: Array<object>;
  projects?: Array<object>;
  tribes?: Array<object>;
}
export interface ICondition {
  filterGenre:
    | 'ALL'
    | 'CREATORS'
    | 'PROJECTS'
    | 'CREATIONS'
    | 'COLLECTIONS'
    | 'TRIBES';
  filterCategory: Array<number>;
  filterSkills: Object;
  filterService: Array<number>;
  filterLanguage: Array<number>;
  filterHandel: (type: string, vaule: any, groupId?: any) => void;
}
export interface SearchContextType {
  similarSuggestions: Array<string>;
  trendingSuggestions: Array<string>;
  inputKeyword: React.MutableRefObject<HTMLInputElement>;
  searchWord: string;
  isFetching: boolean;
  resultLite: Array<string>;
  result: IResult;
  totalResult: number;
  filterGenre: ICondition['filterGenre'];
  filterCategory: ICondition['filterCategory'];
  filterSkills: ICondition['filterSkills'];
  filterService: ICondition['filterService'];
  filterLanguage: ICondition['filterLanguage'];
  sortModle: Array<any>;
  sortTypeSelect: string;
  searchHandle: (type: string, more?: boolean) => void;
  clear: (type: string) => void;
  filterHandel: ICondition['filterHandel'];
}

export interface SuggestionData {
  link: string;
  title: string;
  subTitle: string;
  type: string;
  imgUrl?: string;
}
interface Props {
  children: React.ReactNode;
}

export const SearchContext = createContext({} as SearchContextType);

const setInit = () => {
  return {
    creators: [],
    projects: [],
    creations: [],
    collections: [],
    tribes: [],
  };
};
const initSearchState = {
  resultLite: [],
  result: setInit(),
  totalResult: 0,
  // trendingSuggestions: [],
  similarSuggestions: [],
};

function searchReducer(state, action) {
  switch (action.type) {
    case 'SET_LITE_SEARCH':
      return {
        ...state,
        resultLite: action.updateData,
      };
    case 'SET_SEARCH':
      return {
        ...state,
        totalResult: action.total || state.totalResult,
        result: action.updateData,
      };

    case 'CLEAR_LITE_SEARCH':
      return {
        ...state,
        resultLite: [],
      };

    case 'CLEAR_SEARCH':
      return {
        ...state,
        totalResult: 0,
        result: setInit(),
      };
    case 'SET_SIMILAR_SUGGESTIONS':
      return {
        ...state,
        similarSuggestions: action.updateData,
      };
    default:
      return state;
  }
}
const filterNameMapping = {
  GENRE: 'Genre',
  CATEGORY: 'Category',
  SKILL: 'Skills',
  SERVICE: 'Service',
  LANGUAGE: 'Language',
};
function filterReducer(state, action) {
  const update = {};

  if (action.type === 'ALL') {
    return { ...state, ...action.updateData };
  }

  if (action.type === 'SKILL') {
    if (action.updateData < 0 && action.groupId < 0) {
      update['filterSkills'] = {};
    } else if (action.updateData < 0) {
      if (state.filterSkills[action.groupId]) {
        update['filterSkills'][action.groupId] = [];
      }
    } else if (state.filterSkills[action.groupId]) {
      const index = state.filterSkills[action.groupId].indexOf(
        action.updateData
      );
      if (index < 0) {
        state.filterSkills[action.groupId].push(action.updateData);
      } else {
        state.filterSkills[action.groupId].splice(index, 1);
      }
    } else {
      const newSelectGroup = {};
      newSelectGroup[action.groupId] = [action.updateData];
      update['filterSkills'] = Object.assign(
        state.filterSkills,
        newSelectGroup
      );
    }
  } else if (action.type === 'GENRE') {
    update['filterGenre'] = action.updateData.toUpperCase();
  } else if (['CATEGORY', 'SERVICE', 'LANGUAGE'].includes(action.type)) {
    if (action.updateData >= 0) {
      const index = state[`filter${filterNameMapping[action.type]}`].indexOf(
        action.updateData
      );
      if (index < 0) {
        state[`filter${filterNameMapping[action.type]}`].push(
          action.updateData
        );
      } else {
        state[`filter${filterNameMapping[action.type]}`].splice(index, 1);
      }
    } else {
      update[`filter${filterNameMapping[action.type]}`] = [];
    }
  }

  return { ...state, ...update };
}

const SearchProvider = ({ children }: Props) => {
  const [searchState, searchDispatch] = useReducer(
    searchReducer,
    initSearchState
  );
  const [filterState, filterDispatch] = useReducer(filterReducer, {
    filterGenre: 'ALL',
    filterSkills: {},
    filterCategory: [],
    filterService: [],
    filterLanguage: [],
  });

  const filterHandel = (type, vaule, groupId = null) => {
    filterDispatch({
      type: type.toUpperCase(),
      updateData: vaule,
      groupId,
    });
  };

  const inputKeyword = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [trendingSuggestions, setTrendingSuggestions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    async function suggest() {
      const suggestions = await algolia.suggestions('');
      searchDispatch({
        type: 'SET_SIMILAR_SUGGESTIONS',
        updateData: suggestions,
      });
      searchDispatch({
        type: 'RECOMMEND_KEYWORDS',
        updateData: suggestions,
      });
      setTrendingSuggestions(suggestions);
    }
    suggest();
    return function cleanup() {
      suggest();
    };
  }, []);

  const clear = (type = 'CLEAR') => {
    const clearType = type === 'LIET ' ? 'CLEAR_LITE_SEARCH' : 'CLEAR_SEARCH';
    searchDispatch({ type: clearType });
    setIsFetching(false);
    inputKeyword.current.value = '';
  };
  const search = async (keyword, more = false) => {
    const hits = await algolia.search(keyword);
    const tags = await algolia.search(keyword, 1000, '', 'facetFilters');
    const searchData = setInit();
    if (is.not.empty(tags)) {
      tags?.forEach((hit: any) => {
        const newHit = {
          ...hit,
        };

        searchData.tribes.push(newHit);
      });
    }
    if (is.not.empty(hits)) {
      hits.forEach((hit: any) => {
        if (hit.filterType === 'User') {
          const newHit = {
            creationsImageUrl: [],
            creations: 0,
            ...hit,
          };

          searchData.creators.push(newHit);
        } else if (hit.filterType === 'Project') {
          const newHit = {
            followers: hit.followers || 0,
            status: hit.status || ' ',
            ...hit,
            avatarUrl: [hit.avatarURL],
          };

          searchData.projects.push(newHit);
        } else if (hit.filterType === 'Post') {
          const team = [hit.author, ...hit.coCreators];
          const newHit = {
            media: [
              {
                type: 'img',
                coverUrl: imageOptimize(hit.coverImgURL),
              },
            ],
            ...hit,
            avatarUrl: team.map((t) => imageOptimize(t.profileImgURL)),
          };
          searchData.creations.push(newHit);
        } else if (hit.filterType === 'Collection') {
          searchData.collections.push(hit);
        }
      });
    }
    sortData(searchData);
    searchDispatch({
      type: 'SET_SEARCH',
      updateData: searchData,
      total: hits.length + tags?.length,
      more,
    });
    setIsFetching(false);
  };

  const searchLite = async (keyword) => {
    const lite = [];
    const hits = await algolia.search(keyword);
    if (is.not.empty(hits)) {
      hits.forEach((hit) => {
        if (hit['filterType'] === 'User') {
          lite.push(hit['username']);
        }
        if (
          (hit['title'] &&
            hit['title'].toLowerCase().includes(keyword.toLowerCase())) ||
          (hit['tags'] && hit['tags'].includes(keyword))
        )
          lite.push(hit['title']);
      });
    }
    searchDispatch({
      type: 'SET_LITE_SEARCH',
      updateData: lite,
    });
    setIsFetching(false);
  };

  const searchHandle = debounce((type, more = false) => {
    if (isFetching) return;
    setIsFetching(true);
    const input = inputKeyword.current?.value;

    if (input === '' || input?.replaceAll(' ', '') === '') {
      clear();
      return;
    }

    // update recent search keyword
    const keywordsRecord = getLocalStorage('search_keywords_record', true);
    const update = [input].concat(keywordsRecord || []);
    setLocalStorage('search_keywords_record', update.slice(0, 10), true);
    if (type === 'ALL') {
      search(input, more);
    } else if (type === 'LITE') {
      searchLite(input);
    }
  }, 300);

  const [sortTypeSelect, setSortTypeSelect] = useState('Recently');

  const sortData = (data) => {
    let afterSort = {};
    Object.keys(data).forEach((genreKey) => {
      if (is.not.empty(data[genreKey])) {
        afterSort[genreKey] = [];
        afterSort = genreSort(data[genreKey], genreKey, sortTypeSelect);
      }
    });
    return afterSort;
  };

  const sortHandle = (type) => {
    setSortTypeSelect(type);

    searchDispatch({
      type: 'SET_SEARCH',
      updateData: sortData(searchState.result),
      more: false,
    });
  };

  const sortModle = [
    [
      {
        id: 0,
        type: 'click',
        path: '',
        displayName: 'Recently',
        action: () => {
          sortHandle('Recently');
        },
      },
      {
        id: 1,
        type: 'click',
        path: '',
        displayName: 'Popularity',
        action: () => {
          sortHandle('Popularity');
        },
      },
    ],
  ];

  return (
    <SearchContext.Provider
      value={{
        ...searchState,
        ...filterState,
        sortTypeSelect,
        sortModle,
        inputKeyword,
        searchHandle,
        isFetching,
        trendingSuggestions,
        clear,
        filterHandel,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
export default SearchProvider;
