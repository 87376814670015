import { useTutorialMutation } from 'src/app/explore/use-tutorial';

export const EnableSubmissions2Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Start a RE:PPL
      </h1>
      <img
        src="/images/assets/tutorial/enable-submissions-2.png"
        alt="enable submissions 2"
        className="w-full"
      />
    </>
  );
};

export const EnableSubmissions2Footer = ({
  closeModal,
  completed,
}: {
  closeModal?: any;
  completed: boolean;
}) => {
  const { mutateAsync: updateTutorial } = useTutorialMutation();
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Curation vs Collect, or both!
      </h5>

      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Official episodes and open episodes allow for different types of
        activity, so remember to activate and allow submissions for official or
        open episodes, or even better, both!
      </p>

      <button
        type="button"
        onClick={async () => {
          if (!completed) {
            await updateTutorial({ step: 'enable_submissions' });
          }
          closeModal();
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Done
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        2 / 2
      </h6>
    </>
  );
};
