import * as React from 'react';
import { Svg } from '../svg';

function SvgInstagramV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={20} cy={21} r={15.611} fill="#242526" />
      <g
        clipPath="url(#instagram-v3_svg__clip0_5778_227031)"
        fill="currentColor"
      >
        <path d="M23.828 20.965a3.828 3.828 0 11-7.656 0 3.828 3.828 0 017.656 0z" />
        <path d="M28.952 14.192a3.558 3.558 0 00-.859-1.32 3.556 3.556 0 00-1.32-.86c-.405-.157-1.013-.344-2.133-.395-1.21-.055-1.574-.067-4.64-.067-3.067 0-3.43.012-4.641.067-1.12.051-1.728.238-2.133.396-.498.184-.95.477-1.32.859-.381.37-.675.821-.86 1.32-.156.405-.344 1.013-.395 2.133-.055 1.21-.067 1.574-.067 4.64 0 3.067.012 3.43.067 4.641.051 1.12.239 1.728.396 2.133.184.499.477.95.859 1.32.37.382.821.675 1.32.86.405.157 1.013.344 2.133.395 1.21.055 1.574.067 4.64.067 3.067 0 3.43-.012 4.641-.067 1.12-.051 1.728-.238 2.133-.396a3.803 3.803 0 002.18-2.18c.156-.404.344-1.012.395-2.132.055-1.21.067-1.574.067-4.64 0-3.067-.012-3.43-.067-4.641-.051-1.12-.238-1.728-.396-2.133zM20 26.862a5.897 5.897 0 11-.001-11.794 5.897 5.897 0 010 11.795zm6.13-10.649a1.378 1.378 0 110-2.756 1.378 1.378 0 010 2.756z" />
        <path d="M20 .965c-11.044 0-20 8.956-20 20s8.956 20 20 20 20-8.956 20-20-8.956-20-20-20zM31.415 25.7c-.055 1.223-.25 2.057-.534 2.788a5.872 5.872 0 01-3.358 3.359c-.73.283-1.566.478-2.788.533-1.225.056-1.616.07-4.735.07s-3.51-.014-4.735-.07c-1.222-.055-2.057-.25-2.787-.533a5.627 5.627 0 01-2.035-1.325 5.628 5.628 0 01-1.324-2.034c-.284-.73-.478-1.565-.534-2.788-.056-1.224-.07-1.616-.07-4.735s.014-3.51.07-4.735c.055-1.222.25-2.057.533-2.787.289-.766.741-1.46 1.325-2.034a5.624 5.624 0 012.034-1.325c.731-.284 1.566-.478 2.788-.534 1.225-.056 1.616-.069 4.735-.069s3.51.013 4.735.07c1.222.055 2.057.25 2.788.533.766.288 1.46.74 2.034 1.325a5.626 5.626 0 011.324 2.034c.285.73.479 1.565.534 2.787.056 1.225.07 1.616.07 4.735 0 3.12-.014 3.51-.07 4.735z" />
      </g>
      <defs>
        <clipPath id="instagram-v3_svg__clip0_5778_227031">
          <path fill="#fff" transform="translate(0 .965)" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgInstagramV3);
export default ForwardRef;
