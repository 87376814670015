import { axios } from 'src/lib/axios';
import { RestResponse, PageRequestQuery, RestPageResponse } from 'src/types';
import { CacheKey } from 'src/constants/cache-key';
import { useInfiniteQuery } from 'react-query';

export function unlistSubmission(data, queryClient) {
  const { rid } = data;
  const { eid } = data;
  return axios
    .delete<RestResponse<any>>(`/project/${rid}/remove/episode/${eid}`)
    .then((res) => res.data)
    .then(() => {
      queryClient.invalidateQueries(CacheKey.creationList);
      queryClient.invalidateQueries(CacheKey.article);
    });
}

export function cancelSubmission(eid) {
  return axios
    .post<RestResponse<any>>(`post/${eid}/cancel-submission`)
    .then((res) => res.data);
}

export function archivePost(eid) {
  return axios
    .post<RestResponse<any>>(`post/${eid}/archive`)
    .then((res) => res.data);
}

export function approveSubmission(data) {
  const { eid, rid, note } = data;
  const submission = {
    postID: eid,
    note,
  };
  return axios
    .post<RestResponse<any>>(`reppl/${rid}/submission/approve`, submission)
    .then((res) => res.data);
}

export function declineSubmission(data) {
  const { eid, rid, note } = data;
  const submission = {
    postID: eid,
    note,
  };
  return axios
    .post<RestResponse<any>>(`reppl/${rid}/submission/decline`, submission)
    .then((res) => res.data);
}

export function fetchReviews(eid) {
  return axios
    .get<RestResponse<any>>(`/publish-review/${eid}`)
    .then((res) => res.data);
}

export function postReviews(eid, content) {
  const data = {
    objectID: eid,
    objectType: 'EPISODE',
    parentID: '',
    content,
    assets: [],
  };
  return axios
    .post<RestResponse<any>>(`/publish-review`, data)
    .then((res) => res.data);
}

export function userSubmission(rid, eid, status) {
  return axios
    .post<RestResponse<any>>(`/creations/project/${rid}/${eid}`, {
      status,
    })
    .then((res) => res.data);
}

export const fetchUserSubmission = ({
  params,
}: {
  params: PageRequestQuery & {
    projectID?: string;
  };
}) =>
  axios
    .get<RestPageResponse<any>>(`submission-history/user`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => ({
      ...res,
      pageNum: params.pageNum,
    }));

export function useFetchUserSubmission(req: any) {
  return useInfiniteQuery(
    [CacheKey.userSubmission, req],
    ({ pageParam = 1 }) => {
      return fetchUserSubmission({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: true,
      getNextPageParam: ({ pageNum = 1 }) => {
        // add next to props
        // if (next) {
        //   return pageNum + 1;
        // }
        // return undefined;
        return pageNum + 1;
      },
    }
  );
}

export const fetchRepplSubmission = ({ rid }: { rid: string }) =>
  axios
    .get<RestPageResponse<any>>(`submission-history/reppl/${rid}`)
    .then((res) => res.data);

export function useFetchRepplSubmission(req: any) {
  return useInfiniteQuery(
    [CacheKey.repplSubmission, req.rid],
    () => {
      return fetchRepplSubmission({
        ...req,
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: true,
      // getNextPageParam: ({ pageNum = 1 }) => {
      // add next to props
      // if (next) {
      //   return pageNum + 1;
      // }
      // return undefined;
      // },
    }
  );
}

export function postSubmissionHistory(eid) {
  return axios
    .get<RestPageResponse<any>>(`submission-history/post/${eid}`)
    .then((res) => res.data.data);
}

export function repplSubmissionHistory({ eid, rid }) {
  return axios
    .get<RestPageResponse<any>>(
      `submission-history/reppl/${rid}/episode/${eid}`
    )
    .then((res) => res.data.data);
}
