import * as React from 'react';
import { Svg } from '../svg';

function SvgStoryV2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="url(#story-v2_svg__pattern0)" d="M0 0h32v32H0z" />
      <defs>
        <pattern
          id="story-v2_svg__pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use xlinkHref="#story-v2_svg__image0" transform="scale(.01042)" />
        </pattern>
        <image
          id="story-v2_svg__image0"
          width={96}
          height={96}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAFMElEQVR4nO2dz08cZRjHv+/M7C6wy25pSilIKUgbTayJeuCilyqNsfHozZi0iU1MTPyZ2HgSb9WD9NDEaDwZ68k/wARIkYRGiiFpgibWwuIChbrbsiywv2ceD1wEZnxnZhdfd/b5HN95vi/PvN/3fed9ZjYBYBiGYRiGYRiGYRjmH1ybLV31qx2dKY00ut6v1i2aNIJwxc9NjM6URoTAJw2vP2TkBgCeTTiQfKPrDxF3BgCub8Ix+UbXHxLuDQCkNyFNvtH1h4A3AwDHm3CdfKPr64x3A4ADN+E5+UbX1xEhC7h2u0RO14jwKQD4Tb4R9O8NRaRjVAs1GdAMHLYB/rYgpm6wAYphAxTDBiiGDVAMG6AYNkAxbIBi2ADFGPXu8LMffq93l3u48toTNf1dJ70qeAUohg1QDBugGH4bKoHfhgYcNkAxbIBilNcBtZ7rvcJ1ALMHNkAxbIBiuA6QwHVAwGEDFMMGKOY/rwPqde732w/XAcwe2ADFsAGK4TpAAtcBAYcNUAwboBjl3wNk7D+319o/1wHMHtgAxbABiuE6QALXAQGHDVAMG6AY/3UAAZkCYXmLsFki5CuAJoBEBDgR1fDUMYGBhObiKfP/ZmyxYvcMzAFIATRHEDduDRjjI0JYfvr39RDeLBHuPLCQlTyfO9sELgwa6Ik1uAty5i1Bb748EJ7xKvS8BS1mCVMpUzr4AJDOE76dr+D2mq/J0Uic1UhMjy2U3/cq9GTAvQ0L82kTXs6lRMDEUhU/r5oeU2s4dAjxxXiy8pEXkWsDMgXCbw/9z+TJlImFjcCvBBDh6sRS9RW38a4MqFqEuXUTnqb+/sQA/LhoomQGvq4TpkVfjy1Qwk2wKwOSWUKxan/NNC2sr2Zw99cl3J1PYn0lDcu0n+m5MmFuPfirQAC9JKpvu4mVGmARkMzaD5pZNfHnvRVsZDZhVk2YpoWNhzkk/1hFtWq/5/+yZiH4iwAQwLs3k9Qii5MakCkQig7Pz/vLaZSKlQPt5VIZa8t/2Wq2K4QlB0MDxnFLmOdkQVID0nn76ZrfLmA7t+Oo287lsbNTtL2WyjXBEgBgWVS7AY8K9oOV3diSJpB7lLNtX8k1xQoACTwvi5Ea4HRqKeyUpAnkHWK2bKv74KER9UhjZAElh/2/WnY4FrmIyculgYAgOmUxUgNMC84bvYzAvwKqHTd1QMauUQ/pUqHhEBMNufirgUA8kEW4+f8B63bNbTHpERdt0Vbb9lioWZYGrcki5AYIumXXnOiIS6VHjrbbtvfGm+M7EBGmZTHSkSBNTNi1R2MtiLbbz3AAiMWjaI3ar5L+RHOsAE1gXBojC6Bi2wQA272s52QXwpGDG3qkJYyevuO2/cXCAn3NsQLul01jShYkHYnJS6II4LrdNSOkY+BML44cjUM3dOiGjo7OBE6d7oWu23c91K1Bb44FMHrhjJAWS66+CVvl8nUtEn4LhMf2X9N0Dd0nO9EN6ZEX8YjAM13NMPsp1VoOfeUm0tVoTF7qyGpEF1HLFwEBvDqoIxL86W8JTVx84Ukhf1cDD1/Exl+PjQP0sZ+MBIDhPgOnEoGf/QRBHw73h266FXieji99n/+AiD4HIK/EsOvw+ccNPBf8racKonfOD4a/9CLytR+8eCM/BNA3AJ7+t7gT0d2fpXRFg73tCOAOiC4PD4ZnfWj9MTJC2k+nd85BE28IwrMEMdBqoD0eBrpjAmePabvHzWCOfRZAiiBmhaDvpvuNKb8/zGIYhmEYhmEYhmGY5uJv1r1xpdCXRG0AAAAASUVORK5CYII="
        />
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgStoryV2);
export default ForwardRef;
