import * as React from 'react';
import { Svg } from '../svg';

function SvgFeather({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M3.94 12.036c-.233.624-.43 1.2-.606 1.783.96-.697 2.101-1.139 3.418-1.304 2.513-.314 4.746-1.973 5.876-4.058l-1.456-1.455 1.413-1.415 1-1.001c.43-.43.915-1.224 1.428-2.368-5.593.867-9.018 4.292-11.074 9.818h.001zM14 7.001L15 8c-1 3-4 6-8 6.5-2.669.334-4.336 2.167-5.002 5.5H0C1 14 3 0 18 0c-1 2.997-1.998 4.996-2.997 5.997L14 7.001z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgFeather);
export default ForwardRef;
