import * as React from 'react';
import { Svg } from '../svg';

function Svg1TmSiteFooter({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M0 10C0 4.476 4.476 0 10 0s10 4.476 10 10-4.476 10-10 10c-5.524-.012-10-4.487-10-10z"
        fill="currentColor"
      />
      <path
        d="M9.938 5.575V14.3H6.996V7.794H5.5v-2.22h4.438zM12.854 10.573c-.473 0-.868-.15-1.184-.448-.307-.308-.461-.698-.461-1.172s.154-.856.461-1.147c.316-.3.71-.449 1.184-.449s.865.15 1.172.449c.316.29.474.673.474 1.147s-.158.864-.474 1.172c-.307.299-.698.448-1.171.448zm0 3.852c-.473 0-.868-.15-1.184-.448-.307-.308-.461-.699-.461-1.172 0-.474.154-.856.461-1.147.316-.3.71-.449 1.184-.449s.865.15 1.172.449c.316.29.474.673.474 1.147 0 .473-.158.864-.474 1.172-.307.299-.698.448-1.171.448z"
        fill="#242526"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(Svg1TmSiteFooter);
export default ForwardRef;
