import { Icon, Link, Stack, StackProps, Text } from '@chakra-ui/react';

import { useIsMobile } from 'src/hooks/use-is-mobile';
import { LICENSE_FEATURES } from './types';
import { isLicence } from './license';

export function CopyrightFooter({
  copyright,
  ...props
}: { copyright?: string } & StackProps) {
  const isMobile = useIsMobile();

  if (!copyright || !isLicence(copyright)) {
    return null;
  }
  const copyrightData = LICENSE_FEATURES[copyright];
  return (
    <Stack {...props} py={0}>
      <Stack direction="row" justifyContent={isMobile && 'center'}>
        {copyrightData.icons.map((icon, i) => (
          <Icon
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            borderRadius="50%"
            bg="white"
            as={icon}
            color="gray.500"
            boxSize="24px"
          />
        ))}
      </Stack>
      <Text textStyle="labelLight" fontSize="12px" color="$mode.500">
        This episode is licensed under a{' '}
        <Link isExternal href={copyrightData.url}>
          {copyrightData.license}
        </Link>
        .
      </Text>
    </Stack>
  );
}
