import * as React from 'react';
import { Svg } from '../svg';

function SvgAddImage({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M21.333 28.603l-6.35-11.666L20 8.557l10.667 20.046h-9.333zm-10.667-14.7l8 14.7h-16l8-14.7zM7.333 11.23a3.33 3.33 0 01-2.357-.978 3.345 3.345 0 010-4.725 3.33 3.33 0 014.714 0 3.345 3.345 0 010 4.725 3.33 3.33 0 01-2.357.978z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgAddImage);
export default ForwardRef;
