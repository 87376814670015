import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { getTutorial } from 'src/app/explore/service';
import { useRouter } from 'next/router';

// import SvgChecklistDiscoverAgora from 'src/components/icons/checklist-discover-agora';
import SvgChecklistBuildYourStudio from 'src/components/icons/checklist-build-your-studio';
import SvgChecklistJoinReppl from 'src/components/icons/checklist-join-reppl';
import SvgChecklistStartReppl from 'src/components/icons/checklist-start-reppl';
import SvgCheckedCircle from 'src/components/icons/checked-circle';
import SvgNotCheckedCircle from 'src/components/icons/not-checked-circle';
import SvgArrowRight from 'src/components/icons/arrow-right';

import BuildYourTeam from './build-your-team';
import CheckOutAndJoinAReppl from './check-out-and-join-a-reppl';
import Collections from './collections';
import Creations from './creations';
import EnableSubmissions from './enable-submissions';
import HowToStartAReppl from './how-to-start-a-reppl';
import SetUpYourProfile from './set-up-your-profile';
import SubmitAnEpisode from './submit-an-episode';
import WhatIsAgora from './what-is-agora';

const TutorialChecklistModal = ({
  modal,
  username,
  tutorialStep,
  user,
}: {
  modal: any;
  username?: string;
  tutorialStep?: any;
  user?: string;
}) => {
  const [tutorialData, setTutorialData] = useState([]);

  const buildYourTeamModal = useDisclosure();
  const checkOutAndJoinARepplModal = useDisclosure();
  const collectionsModal = useDisclosure();
  const creationsModal = useDisclosure();
  const enableSubmissionsModal = useDisclosure();
  const howToStartARepplModal = useDisclosure();
  const setUpYourProfileModal = useDisclosure();
  const submitAnEpisodeModal = useDisclosure();
  const whatIsAgoraModal = useDisclosure();

  const router = useRouter();
  /* eslint-disable */
  useEffect(() => {
    switch (tutorialStep) {
      case 'setUpYourProfile':
        modal.onClose();
        setUpYourProfileModal.onOpen();
        break;
      case 'createAPost':
        modal.onClose();
        creationsModal.onOpen();
        break;
      case 'createACollection':
        modal.onClose();
        collectionsModal.onOpen();
        break;
      case 'checkOutAndJoinAReppl':
        modal.onClose();
        checkOutAndJoinARepplModal.onOpen();
        break;
      case 'submitAnEpisode':
        modal.onClose();
        submitAnEpisodeModal.onOpen();
        break;
      case 'howToStartAReppl':
        modal.onClose();
        howToStartARepplModal.onOpen();
        break;
      case 'buildYourTeam':
        modal.onClose();
        buildYourTeamModal.onOpen();
        break;
      case 'enableSubmissions':
        modal.onClose();
        enableSubmissionsModal.onOpen();
        break;
      case 'whatIsAgora':
        modal.onClose();
        whatIsAgoraModal.onOpen();
    }
  }, [modal.isOpen])

  /* eslint-enable */

  useEffect(() => {
    const getTutorialData = async () => {
      const data = await getTutorial();
      setTutorialData(data);
    };

    if (modal.isOpen) {
      getTutorialData();
    }
  }, [modal.isOpen]);

  let progress = 0;

  tutorialData.forEach((step) => {
    if (step.step !== 'whats_agora') {
      progress += 12.5;
    }
  });

  const [completedSteps, setCompletedSteps] = useState({
    whats_agora: false,
    setup_profile: false,
    create_post: false,
    create_collection: false,
    join_reppl: false,
    submit_episode: false,
    start_reppl: false,
    build_team: false,
    enable_submissions: false,
  });

  const [completedStepsLength, setCompletedStepsLength] = useState({
    discoverAgora: 0,
    buildYourStudio: 0,
    joinReppl: 0,
    startReppl: 0,
  });

  useEffect(() => {
    setCompletedStepsLength({
      discoverAgora: 0,
      buildYourStudio: 0,
      joinReppl: 0,
      startReppl: 0,
    });

    tutorialData.forEach(async (step) => {
      /* eslint-disable */
      switch (step.step) {
        case "whats_agora":
          setCompletedStepsLength(completedStepsLength => ({ ...completedStepsLength, discoverAgora: completedStepsLength.discoverAgora + 1 }));
          setCompletedSteps(completedSteps => ({ ...completedSteps, whats_agora: true }));
          break;
        case "setup_profile":
          setCompletedStepsLength(completedStepsLength => ({ ...completedStepsLength, buildYourStudio: completedStepsLength.buildYourStudio + 1 }));
          setCompletedSteps(completedSteps => ({ ...completedSteps, setup_profile: true }));
          break;
        case "create_post":
          setCompletedStepsLength(completedStepsLength => ({ ...completedStepsLength, buildYourStudio: completedStepsLength.buildYourStudio + 1 }));
          setCompletedSteps(completedSteps => ({ ...completedSteps, create_post: true }));
          break;
        case "create_collection":
          setCompletedStepsLength(completedStepsLength => ({ ...completedStepsLength, buildYourStudio: completedStepsLength.buildYourStudio + 1 }));
          setCompletedSteps(completedSteps => ({ ...completedSteps, create_collection: true }));
          break;
        case "join_reppl":
          setCompletedStepsLength(completedStepsLength => ({ ...completedStepsLength, joinReppl: completedStepsLength.joinReppl + 1 }));
          setCompletedSteps(completedSteps => ({ ...completedSteps, join_reppl: true }));
          break;
        case "submit_episode":
          setCompletedStepsLength(completedStepsLength => ({ ...completedStepsLength, joinReppl: completedStepsLength.joinReppl + 1 }));
          setCompletedSteps(completedSteps => ({ ...completedSteps, submit_episode: true }));
          break;
        case "start_reppl":
          setCompletedStepsLength(completedStepsLength => ({ ...completedStepsLength, startReppl: completedStepsLength.startReppl + 1 }));
          setCompletedSteps(completedSteps => ({ ...completedSteps, start_reppl: true }));
          break;
        case "build_team":
          setCompletedStepsLength(completedStepsLength => ({ ...completedStepsLength, startReppl: completedStepsLength.startReppl + 1 }));
          setCompletedSteps(completedSteps => ({ ...completedSteps, build_team: true }));
          break;
        case "enable_submissions":
          setCompletedStepsLength(completedStepsLength => ({ ...completedStepsLength, startReppl: completedStepsLength.startReppl + 1 }));
          setCompletedSteps(completedSteps => ({ ...completedSteps, enable_submissions: true }));
          break;
      }
      /* eslint-enable */
    });
  }, [tutorialData]);

  /* eslint-disable */
  return (
    <>
      <BuildYourTeam modal={{
        ...buildYourTeamModal, openChecklistModal: modal.onOpen, completed: completedSteps.build_team, onClose: async () => {
          await router.push({
            pathname: `/reppl`,
          })
          buildYourTeamModal.onClose();
          // modal.onOpen();
        }
      }} />
      <CheckOutAndJoinAReppl modal={{
        ...checkOutAndJoinARepplModal, openChecklistModal: modal.onOpen, completed: completedSteps.join_reppl, onClose: async () => {
          await router.push({
            pathname: `/reppl`,
          })
          checkOutAndJoinARepplModal.onClose();
          // modal.onOpen();
        }
      }} />
      <Collections modal={{
        ...collectionsModal, openChecklistModal: modal.onOpen, completed: completedSteps.create_collection, onClose: async () => {
          await router.push({
            pathname: `/${router.query.username}/collections`,
          })
          collectionsModal.onClose();
          // modal.onOpen();
        }
      }} />
      <Creations modal={{
        ...creationsModal, openChecklistModal: modal.onOpen, completed: completedSteps.create_post, onClose: async () => {
          await router.push({
            pathname: `/${router.query.username}`,
          })
          creationsModal.onClose();
          // modal.onOpen();
        }
      }} />
      <EnableSubmissions modal={{
        ...enableSubmissionsModal, openChecklistModal: modal.onOpen, completed: completedSteps.enable_submissions, onClose: async () => {
          await router.push({
            pathname: `/reppl`,
          })
          enableSubmissionsModal.onClose();
          // modal.onOpen();
        }
      }} />
      <HowToStartAReppl modal={{
        ...howToStartARepplModal, openChecklistModal: modal.onOpen, completed: completedSteps.start_reppl, onClose: async () => {
          await router.push({
            pathname: `/reppl`,
          })
          howToStartARepplModal.onClose();
          // modal.onOpen();
        }
      }} />
      <SetUpYourProfile modal={{
        ...setUpYourProfileModal, openChecklistModal: modal.onOpen, completed: completedSteps.setup_profile, onClose: async () => {
          await router.push({
            pathname: `/${router.query.username}`,
          })
          setUpYourProfileModal.onClose();
          // modal.onOpen();
        }
      }} />
      <SubmitAnEpisode modal={{
        ...submitAnEpisodeModal, openChecklistModal: modal.onOpen, completed: completedSteps.submit_episode, onClose: async () => {
          await router.push({
            pathname: `/reppl`,
          })
          submitAnEpisodeModal.onClose();
          // modal.onOpen();
        }
      }} />
      <WhatIsAgora modal={{
        ...whatIsAgoraModal, openChecklistModal: modal.onOpen, completed: completedSteps.whats_agora, onClose: async () => {
          await router.push({
            pathname: `/agora`,
          })
          whatIsAgoraModal.onClose();
          // modal.onOpen();
        }
      }} />

      <Modal
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        motionPreset="slideInRight"
      >
        <ModalOverlay />
        <ModalContent
          position="absolute"
          right={0}
          top="60px"
          borderRadius={0}
          maxW="480px"
        >
          <ModalCloseButton color="white" />
          <ModalBody p={0}>
            <div
              className="p-10"
              style={{
                background:
                  'linear-gradient(90deg, #221FDE -6.46%, #379FFF 112.64%',
              }}
            >
              <h1 className="font-medium text-white text-xl mb-3">
                Your 1TM Checklist!
              </h1>
              <h5 className="font-medium text-white text-sm mb-5">
                Hi {username}, Ready to start your 1TM tour? Let’s go 🚀
              </h5>
              <div className="w-full flex items-center">
                <div className="w-full h-1.5 bg-white bg-opacity-40">
                  <div
                    className="h-full"
                    style={{ background: '#2BC3A8', width: `${progress}%` }}
                  />
                </div>
                <div className="w-8 pl-2 flex justify-center text-white text-xs	font-bold">
                  {progress}%
                </div>
              </div>
            </div>
            <div className="p-10">
              {/* <div className="mb-8">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <SvgChecklistDiscoverAgora fontSize="24px" mr={2} />
                    <h5 className="text-base font-medium text-gray-900">
                      Discover Agora
                    </h5>
                  </div>
                  <div className="text-gray-400 text-xs font-bold">{completedStepsLength.discoverAgora} / 1</div>
                </div>
                <div className="pl-9 flex items-center">
                  {completedSteps.whats_agora ? (
                    <SvgCheckedCircle fontSize="24px" mr={2} />
                  ) : (
                    <SvgNotCheckedCircle fontSize="24px" mr={2} />
                  )}
                  <p
                    onClick={() => {
                      modal.onClose();
                      whatIsAgoraModal.onOpen();
                    }}
                    className="cursor-pointer text-sm text-gray-900 font-medium mr-2"
                  >
                    What is Agora?
                  </p>
                  <SvgArrowRight fontSize="24px" color="#242526" />
                </div>
              </div> */}
              <div className="mb-8">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <SvgChecklistBuildYourStudio fontSize="24px" mr={2} />
                    <h5 className="text-base font-medium text-gray-900">
                      Build your Studio
                    </h5>
                  </div>
                  <div className="text-gray-400 text-xs font-bold">{completedStepsLength.buildYourStudio} / 3</div>
                </div>
                <div className="pl-9 flex items-center mb-5">
                  {completedSteps.setup_profile ? (
                    <SvgCheckedCircle fontSize="24px" mr={2} />
                  ) : (
                    <SvgNotCheckedCircle fontSize="24px" mr={2} />
                  )}
                  <p
                    onClick={() => {
                      router.push({
                        pathname: `/${user}`,
                        query: { tutorialStep: 'setUpYourProfile' }
                      });
                      modal.onClose();
                    }}
                    className="cursor-pointer text-sm text-gray-900 font-medium mr-2"
                  >
                    Set up your profile
                  </p>
                  <SvgArrowRight fontSize="24px" color="#242526" />
                </div>
                <div className="pl-9 flex items-center mb-5">
                  {completedSteps.create_post ? (
                    <SvgCheckedCircle fontSize="24px" mr={2} />
                  ) : (
                    <SvgNotCheckedCircle fontSize="24px" mr={2} />
                  )}
                  <p
                    onClick={() => {
                      router.push({
                        pathname: `/${user}`,
                        query: { tutorialStep: 'createAPost' }
                      });
                      modal.onClose();
                    }}
                    className="cursor-pointer text-sm text-gray-900 font-medium mr-2"
                  >
                    Create a Post
                  </p>
                  <SvgArrowRight fontSize="24px" color="#242526" />
                </div>
                <div className="pl-9 flex items-center">
                  {completedSteps.create_collection ? (
                    <SvgCheckedCircle fontSize="24px" mr={2} />
                  ) : (
                    <SvgNotCheckedCircle fontSize="24px" mr={2} />
                  )}
                  <p
                    onClick={() => {
                      router.push({
                        pathname: `/${user}/collections`,
                        query: { tutorialStep: 'createACollection' }
                      });
                      modal.onClose();
                    }}
                    className="cursor-pointer text-sm text-gray-900 font-medium mr-2"
                  >
                    Create a Collection
                  </p>
                  <SvgArrowRight fontSize="24px" color="#242526" />
                </div>
              </div>
              <div className="mb-8">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <SvgChecklistJoinReppl fontSize="24px" mr={2} />
                    <h5 className="text-base font-medium text-gray-900">
                      Join a RE:PPL
                    </h5>
                  </div>
                  <div className="text-gray-400 text-xs font-bold">{completedStepsLength.joinReppl} / 2</div>
                </div>
                <div className="pl-9 flex items-center mb-5">
                  {completedSteps.join_reppl ? (
                    <SvgCheckedCircle fontSize="24px" mr={2} />
                  ) : (
                    <SvgNotCheckedCircle fontSize="24px" mr={2} />
                  )}
                  <p
                    onClick={() => {
                      router.push({
                        pathname: `/projects`,
                        query: { tutorialStep: 'checkOutAndJoinAReppl' }
                      });
                      modal.onClose();
                    }}
                    className="cursor-pointer text-sm text-gray-900 font-medium mr-2"
                  >
                    Check out and join a RE:PPL
                  </p>
                  <SvgArrowRight fontSize="24px" color="#242526" />
                </div>
                <div className="pl-9 flex items-center mb-5">
                  {completedSteps.submit_episode ? (
                    <SvgCheckedCircle fontSize="24px" mr={2} />
                  ) : (
                    <SvgNotCheckedCircle fontSize="24px" mr={2} />
                  )}
                  <p
                    onClick={() => {
                      router.push({
                        pathname: `/projects`,
                        query: { tutorialStep: 'submitAnEpisode' }
                      });
                      modal.onClose();
                    }}
                    className="cursor-pointer text-sm text-gray-900 font-medium mr-2"
                  >
                    Submit an episode
                  </p>
                  <SvgArrowRight fontSize="24px" color="#242526" />
                </div>
              </div>
              <div>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <SvgChecklistStartReppl fontSize="24px" mr={2} />
                    <h5 className="text-base font-medium text-gray-900">
                      Start a RE:PPL
                    </h5>
                  </div>
                  <div className="text-gray-400 text-xs font-bold">{completedStepsLength.startReppl} / 3</div>
                </div>
                <div className="pl-9 flex items-center mb-5">
                  {completedSteps.start_reppl ? (
                    <SvgCheckedCircle fontSize="24px" mr={2} />
                  ) : (
                    <SvgNotCheckedCircle fontSize="24px" mr={2} />
                  )}
                  <p
                    onClick={() => {
                      router.push({
                        pathname: `/projects`,
                        query: { tutorialStep: 'howToStartAReppl' }
                      });
                      modal.onClose();
                    }}
                    className="cursor-pointer text-sm text-gray-900 font-medium mr-2"
                  >
                    How to start a RE:PPL?
                  </p>
                  <SvgArrowRight fontSize="24px" color="#242526" />
                </div>
                <div className="pl-9 flex items-center mb-5">
                  {completedSteps.build_team ? (
                    <SvgCheckedCircle fontSize="24px" mr={2} />
                  ) : (
                    <SvgNotCheckedCircle fontSize="24px" mr={2} />
                  )}
                  <p
                    onClick={() => {
                      router.push({
                        pathname: `/projects`,
                        query: { tutorialStep: 'buildYourTeam' }
                      });
                      modal.onClose();
                    }}
                    className="cursor-pointer text-sm text-gray-900 font-medium mr-2"
                  >
                    Build your team
                  </p>
                  <SvgArrowRight fontSize="24px" color="#242526" />
                </div>
                <div className="pl-9 flex items-center">
                  {completedSteps.enable_submissions ? (
                    <SvgCheckedCircle fontSize="24px" mr={2} />
                  ) : (
                    <SvgNotCheckedCircle fontSize="24px" mr={2} />
                  )}
                  <p
                    onClick={() => {
                      router.push({
                        pathname: `/projects`,
                        query: { tutorialStep: 'enableSubmissions' }
                      });
                      modal.onClose();
                    }}
                    className="cursor-pointer text-sm text-gray-900 font-medium mr-2"
                  >
                    Enable submissions
                  </p>
                  <SvgArrowRight fontSize="24px" color="#242526" />
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
  /* eslint-enable */
};

export default TutorialChecklistModal;
