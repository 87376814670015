import { isBrowser } from 'src/constants/env';

export async function importAllEditorTools() {
  if (isBrowser()) {
    const [
      EmbedLink,
      Gallery,
      Delimiter,
      Header,
      EditorJS,
      InlineCode,
      List,
      Marker,
      Quote,
      UnderLine,
      Paragraph,
      Undo,
      Mention,
      CodeTool,
      Warning,
    ] = await Promise.all([
      import('@1tmio/editor-embed-link').then((s) => s.default),
      import('@1tmio/editor-gallery').then((s) => s.default),
      import('@editorjs/delimiter').then((s) => s.default),
      import('@editorjs/header').then((s) => s.default),
      import('@editorjs/editorjs').then((s) => s.default),
      import('@editorjs/inline-code').then((s) => s.default),
      import('@1tmio/editorjs-list').then((s) => s.default),
      import('@editorjs/marker').then((s) => s.default),
      import('@editorjs/quote').then((s) => s.default),
      import('@editorjs/underline').then((s) => s.default),
      import('@1tmio/editor-paragraph-with-alignment').then((s) => s.default),
      import('@1tmio/editorjs-undo').then((s) => s.default),
      import('src/lib/editor/mention').then((s) => s.default),
      import('@editorjs/code').then((s) => s.default),
      import('@editorjs/warning').then((s) => s.default),
    ]);
    return {
      EmbedLink,
      Gallery,
      Delimiter,
      Header,
      EditorJS,
      InlineCode,
      List,
      Marker,
      Quote,
      UnderLine,
      Paragraph,
      Undo,
      Mention,
      CodeTool,
      Warning,
    };
  }
  return undefined;
}
