import { Text } from '@chakra-ui/react';
import { useState } from 'react';

export function useMultipleSelectLimit({
  min,
  max,
  hint,
  value,
}: {
  min?: number;
  max?: number;
  value: any[];
  hint?: string;
}) {
  const [inputValue, setInputValue] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const noMax = typeof max === 'undefined';
  const canAddItem = noMax || (value?.length ?? 0) < max;
  const typingOnMax = !canAddItem && !!inputValue;
  const aboveMin = (value?.length ?? 0) >= min;
  const msg = typingOnMax ? (
    <Text textStyle="labelLight" color="error" pt="4px">
      You have reached the maximum number of attributes.
    </Text>
  ) : (
    hint && (
      <Text textStyle="labelLight" color="$mode.500" pt="4px">
        {hint}
      </Text>
    )
  );

  return {
    aboveMin,
    typingOnMax,
    msg,
    selectProps: {
      menuIsOpen: showMenu,
      onMenuOpen: () => {
        if (canAddItem) {
          setShowMenu(true);
        }
      },
      onMenuClose: () => setShowMenu(false),
      openMenuOnClick: canAddItem,
      inputValue,
      onInputChange: setInputValue,
      isClearable: true,
    },
  };
}
