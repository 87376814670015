import { useQuery } from 'react-query';
import { CacheKey } from 'src/constants/cache-key';
import { axios } from 'src/lib/axios';
import { logger } from 'src/lib/logger';
import { RestResponse } from 'src/types';
import { ArticleSource } from '../enum';
import { FullArticle } from '../types';

export const fetchFullArticle = (source: ArticleSource, id: string) => {
  let type = 'POST';

  if (source === ArticleSource.REPPL) {
    type = 'EPISODE';
  }

  return axios
    .get<RestResponse<FullArticle>>(`/${type.toLowerCase()}/${id}`)
    .then((res) => res.data)
    .then((res) => {
      if (res.code === 1000) {
        return res.data;
      }
    });
};

export function useArticleFullQuery(source: ArticleSource, id?: string) {
  return useQuery([CacheKey.article, id], () => fetchFullArticle(source, id), {
    enabled: !!id,
    onError: (error) => {
      logger.error(error);
    },
  });
}
