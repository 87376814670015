import {
  Box,
  IconButton,
  Stack,
  useBreakpointValue,
  Fade,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/react';

import { Children, useContext, useState, useEffect } from 'react';
import { ConditionalWrap } from 'src/components/conditional-wrap';

import SvgBookmark from 'src/components/icons/bookmark';
import SvgComment from 'src/components/icons/comment';
import SvgArrow from 'src/components/icons/arrow';

// import { useSafeModalContext } from 'src/hooks/use-safe-modal-context';
// import { useScrollDelta } from 'src/hooks/use-scroll-delta';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import SvgMenu2 from 'src/components/icons/menu-2';
import WarningModal from 'src/app/article/warning-modal';
import {
  useUnpublishEpisodeMutation,
  useUnlistEpisodeMutation,
} from 'src/app/studio/hooks/use-studio-mutation';
import { useRouter } from 'next/router';
import { NoteModal } from 'src/app/article/components/note-modal';
import copy from 'copy-to-clipboard';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { useArticlePin, useArticlePick } from 'src/app/article/hooks/use-pin';
import { AddToLabelModal } from 'src/app/label/components/add-to-label-modal';
import { EnthuseIcon } from '../enthuse-button';
import { useArticleEnthuseMutation } from '../../hooks/use-enthuse';

interface StickyIconButtonListProps {
  authorID: number;
  authorUsername: string;
  contentID: string;
  isEnthused?: boolean;
  onCommentClick?: () => void;
  onSaveClick?: () => void;
  articleType: 'STUDIO' | 'EPISODE';
  collection?: any[];
  commentable?: boolean;
  left?: string;
  data?: any;
  refetch?: any;
}

export function StickyIconButtonList(props: StickyIconButtonListProps) {
  const { commentable } = props;
  const { mutateAsync: enthuse } = useArticleEnthuseMutation(props.contentID);

  const { user } = useContext(UserContext) as UserContextType;
  // const modalCtx = useSafeModalContext();
  // const { dir } = useScrollDelta(modalCtx?.dialogRef?.current);
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const [collect, setCollect] = useState([]);
  const isMobile = useIsMobile();

  const { mutate: unpublishEpisode } = useUnpublishEpisodeMutation();
  const { mutate: unlistEpisode } = useUnlistEpisodeMutation();
  const router = useRouter();
  const [isShareButtonClicked, setIsShareButtonClicked] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onClikcShareBtn = () => {
    const shareURL = `${`${window.location.origin}/${props.authorUsername}/${props.contentID}`}`;
    copy(shareURL);
    setIsShareButtonClicked(true);
  };

  const onOpenPopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const onClosePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
    setIsShareButtonClicked(false);
  };

  useEffect(() => {
    setCollect(props.collection);
  }, [props.collection]);

  const unpublishEpisodeModal = useDisclosure();
  const editEpisodeModal = useDisclosure();
  const unlistEpisodeModal = useDisclosure();
  const unlistNoteModal = useDisclosure();
  const creatorsPicksNoteModal = useDisclosure();
  const failedToUnlistModal = useDisclosure();

  const [unlistNoteText, setUnlistNoteText] = useState('');
  const [creatorsPicksNoteText, setCreatorsPicksNoteText] = useState('');

  function LinkHandle() {
    if (props.data?.postType === 'MEDIA') {
      if (props.data?.classType === 'REPPL') {
        router.push({
          pathname: `/reppl/${props.data?.sourceID}/episode/${props.data?.contentID}/edit`,
          query: { type: 'mediaPost' },
        });
      } else {
        router.push({
          pathname: `/${props.data?.author.username}/${props.data?.contentID}/edit`,
          query: { type: 'mediaPost' },
        });
      }
    } else if (props.data?.classType === 'REPPL') {
      router.push({
        pathname: `/reppl/${props?.data.sourceID}/episode/${props.data?.contentID}/edit`,
      });
    } else {
      router.push({
        pathname: `/${props.data?.author.username}/${props.data?.contentID}/edit`,
      });
    }
  }

  const labelModal = useDisclosure();

  const { mutateAsync: createPick } = useArticlePick();
  const { mutateAsync: createPin } = useArticlePin();

  const ICONS = [
    <button
      type="button"
      className={`flex justify-center items-center overflow-hidden relative ${
        isMobile ? 'w-4 h-6' : 'w-14 h-14'
      }`}
      aria-label="enthuse"
      style={{
        borderRadius: '25px',
        border: isMobile ? 'none' : '1px solid #E6E8E9',
      }}
      onClick={() => {
        enthuse({
          enthuse: !props.isEnthused,
          objectId: props.contentID,
          objectType: props.articleType,
          ownerID: props.authorID,
        });
      }}
    >
      <Box
        w="100%"
        h="100%"
        position="absolute"
        left={0}
        top={0}
        background="rgba(36,37,38,0.95)"
        color="white"
        display="flex"
        justifyContent="center"
        alignItems="center"
        opacity={0}
        _hover={{ opacity: 1 }}
      >
        <span>{props.data?.enthuseCount}</span>
      </Box>
      <EnthuseIcon
        isEnthuse={props.isEnthused}
        fontSize="18px"
        color="gray.900"
      />
    </button>,

    <button
      type="button"
      className={`flex justify-center items-center overflow-hidden relative ${
        isMobile ? 'w-4 h-6' : 'w-14 h-14'
      }`}
      aria-label="save"
      style={{
        borderRadius: '25px',
        border: isMobile ? 'none' : '1px solid #E6E8E9',
      }}
      onClick={props.onSaveClick}
    >
      {/* <Box
        w="100%"
        h="100%"
        position="absolute"
        left={0}
        top={0}
        background="rgba(36,37,38,0.95)"
        color="white"
        display="flex"
        justifyContent="center"
        alignItems="center"
        opacity={0}
        _hover={{ opacity: 1 }}
      >
        <SvgBookmark
          fontSize={18}
          fill={collect && collect.length > 0 ? 'white' : 'none'}
        />
      </Box> */}
      <SvgBookmark
        fontSize={isMobile ? 15 : 18}
        fill={collect && collect.length > 0 ? 'black' : 'none'}
      />
    </button>,
  ];

  if (props.data?.publishStatus === 'PUBLISHED') {
    ICONS.push(
      <Popover
        placement="right"
        gutter={14}
        autoFocus={false}
        onClose={onClosePopover}
        onOpen={onOpenPopover}
      >
        <PopoverTrigger>
          <button
            type="button"
            className={`flex justify-center items-center overflow-hidden relative ${
              isMobile ? 'w-4 h-6' : 'w-14 h-14'
            }`}
            aria-label="save"
            style={{
              borderRadius: '25px',
              border: isMobile ? 'none' : '1px solid #E6E8E9',
            }}
          >
            <Box
              w="100%"
              h="100%"
              position="absolute"
              left={0}
              top={0}
              background="rgba(36,37,38,0.95)"
              color="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
              opacity={0}
              _hover={{ opacity: 1 }}
              zIndex={50}
            >
              <SvgArrow
                fontSize={isMobile ? 15 : 18}
                fill={collect && collect.length > 0 ? 'white' : 'none'}
              />
            </Box>
            <SvgArrow
              fontSize={isMobile ? 15 : 18}
              fill={collect && collect.length > 0 ? 'black' : 'none'}
            />
          </button>
        </PopoverTrigger>
        <PopoverContent bg="white">
          <PopoverArrow
            style={{
              width: '20px',
              height: '10px',
              boxShadow: '0px 1px 0px #E6E8E9, 0px 3px 6px rgba(0, 0, 0, 0.08)',
            }}
          />
          <PopoverBody bg="white" paddingTop="0" paddingBottom="0" opacity="1">
            <div className="flex flex-col justify-center items-center bg-white">
              <h1
                style={{
                  width: '140px',
                  fontSize: '14px',
                  lineHeight: '18px',
                }}
                className="text-center font-semibold mt-6 mb-3 text-gray-700"
              >
                Share this post
              </h1>
              <Button
                className="font-semibold h-auto py-2 px-7 mb-6"
                style={{
                  color: '#fff',
                  fontSize: '14px',
                  lineHeight: '18px',
                  borderRadius: '2px',
                  maxWidth: '123px',
                }}
                onClick={() => onClikcShareBtn()}
                disabled={!!isShareButtonClicked}
              >
                {isShareButtonClicked ? 'Link copied' : 'Copy Link'}
              </Button>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  if (commentable) {
    ICONS.splice(
      1,
      0,
      <button
        type="button"
        className={`flex justify-center items-center overflow-hidden relative ${
          isMobile ? 'w-4 h-6' : 'w-14 h-14'
        }`}
        aria-label="comment"
        style={{
          borderRadius: '25px',
          border: isMobile ? 'none' : '1px solid #E6E8E9',
        }}
        onClick={props.onCommentClick}
      >
        <Box
          w="100%"
          h="100%"
          position="absolute"
          left={0}
          top={0}
          background="rgba(36,37,38,0.95)"
          color="white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          opacity={0}
          _hover={{ opacity: 1 }}
        >
          <span className="text-sm font-medium">
            {props.data?.commentCount}
          </span>
        </Box>
        <SvgComment fontSize={isMobile ? 15 : 18} />
      </button>
    );
  }

  if (
    !isMobile &&
    props?.data.classType === 'STUDIO' &&
    props.data?.author?.userID === user.id
  ) {
    ICONS.push(
      <>
        <div className="ellipsis-menu">
          <Menu placement="right-end">
            <MenuButton
              as={IconButton}
              width="54px"
              height="54px"
              p={0}
              border="1px solid #E6E8E9"
              borderRadius="25px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              position="relative"
              bg="transparent"
              _hover={{ background: 'rgba(36,37,38,0.95)' }}
              colorScheme="whiteAlpha"
              icon={<SvgMenu2 fontSize={24} />}
            />
            <MenuList p={0} fontSize={14}>
              {props.data?.status !== 'NEVER' && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={() => {
                    router.push({
                      query: {
                        ...router.query,
                        submissionHistoryModal: 'open',
                        episode: props.data.contentID,
                      },
                    });
                  }}
                >
                  Submission History
                </MenuItem>
              )}
              <MenuItem
                style={{ padding: '10px 1.5rem 0.3rem' }}
                className="fontWeight-500"
                onClick={() => {
                  if (
                    props?.data?.classType === 'STUDIO' ||
                    props?.data?.publishStatus === 'DRAFT'
                  ) {
                    LinkHandle();
                  } else {
                    editEpisodeModal.onOpen();
                  }
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                style={{ padding: '10px 1.5rem 0.3rem' }}
                className="fontWeight-500"
                onClick={unpublishEpisodeModal.onOpen}
              >
                Unpublish
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
        <WarningModal
          {...unpublishEpisodeModal}
          isFooterCaption
          title="Unpublish your post"
          text="Are you sure you want to unpublish this post? Your post will be not available for other users."
          onOk={async () => {
            await unpublishEpisode(props.contentID);
            router.back();
          }}
          okBtnText="Unpublish"
          cancelBtnText="No, keep it published."
          cancelBtnColor="#9B9FA4"
          cancelBtnScheme="#9B9FA4"
          footerCaption="* All comments and enthuses will remain intact."
        />
      </>
    );
  }

  if (
    !isMobile &&
    props?.data.classType === 'REPPL' &&
    (props.data?.author?.userID === user.id ||
      props?.data.roleInReppl === 'STARTER' ||
      props?.data.roleInReppl === 'EDITOR')
  ) {
    ICONS.push(
      <>
        <div className="ellipsis-menu">
          <Menu placement="right-end">
            <MenuButton
              as={IconButton}
              width="54px"
              height="54px"
              p={0}
              border="1px solid #E6E8E9"
              borderRadius="25px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              position="relative"
              bg="transparent"
              _hover={{ background: 'rgba(36,37,38,0.95)' }}
              colorScheme="whiteAlpha"
              icon={<SvgMenu2 fontSize={24} />}
            />
            <MenuList p={0} fontSize={14}>
              {props.data?.sourceID && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={() => {
                    router.push({
                      query: {
                        ...router.query,
                        submissionHistoryModal: 'open',
                        episode: props.data.contentID,
                      },
                    });
                  }}
                >
                  Submission History
                </MenuItem>
              )}
              {props.data?.author?.userID === user.id && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={() => {
                    if (
                      props?.data.roleInReppl === 'STARTER' ||
                      props?.data.roleInReppl === 'EDITOR'
                    ) {
                      LinkHandle();
                    } else {
                      editEpisodeModal.onOpen();
                    }
                  }}
                >
                  Edit
                </MenuItem>
              )}
              {props?.data.roleInReppl === 'STARTER' && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={creatorsPicksNoteModal.onOpen}
                >
                  {props?.data.pinned
                    ? 'Remove from Starter’s Picks'
                    : 'Feature in Starter’s Picks'}
                </MenuItem>
              )}
              {(props?.data.roleInReppl === 'STARTER' ||
                props?.data.roleInReppl === 'EDITOR') && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={async () => {
                    await createPick({
                      dataset: [
                        {
                          contentID: props?.data.contentID,
                          pick: !props?.data.pick,
                          type: 'pick',
                        },
                      ],
                      projectID: props?.data.sourceID,
                      contentType: 'EPISODE',
                    });
                    if (props.refetch) {
                      await props.refetch();
                    }
                  }}
                >
                  {props?.data.pick ? 'Remove from Home' : 'Feature on Home'}
                </MenuItem>
              )}
              {(props?.data.roleInReppl === 'STARTER' ||
                props?.data.roleInReppl === 'EDITOR') && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={() => {
                    labelModal.onOpen();
                  }}
                >
                  Add to RE:PPL collections
                </MenuItem>
              )}
              {(props.data?.author?.userID === user.id ||
                props?.data.roleInReppl === 'STARTER') && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={() => {
                    if (
                      props?.data.pick &&
                      props?.data.roleInReppl === 'STARTER'
                    ) {
                      failedToUnlistModal.onOpen();
                    } else {
                      unlistEpisodeModal.onOpen();
                    }
                  }}
                >
                  Unlist
                </MenuItem>
              )}
              {props.data?.author?.userID === user.id && (
                <MenuItem
                  style={{ padding: '10px 1.5rem 0.3rem' }}
                  className="fontWeight-500"
                  onClick={unpublishEpisodeModal.onOpen}
                >
                  Unpublish
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </div>
        {labelModal.isOpen && (
          <AddToLabelModal
            key={props?.data.contentID}
            {...labelModal}
            areaID={props?.data.sourceID}
            areaType={props?.data.classType}
            collectionItem={props?.data}
            onClose={() => {
              labelModal.onClose();
            }}
          />
        )}
        <WarningModal
          {...unpublishEpisodeModal}
          isFooterCaption
          title="Unpublish your post"
          text="Are you sure you want to unpublish this post?Your post will be not available for other users."
          onOk={async () => {
            await unpublishEpisode(props.contentID);
            router.back();
          }}
          okBtnText="Unpublish"
          cancelBtnText="No, keep it published."
          cancelBtnColor="#9B9FA4"
          cancelBtnScheme="#9B9FA4"
          footerCaption="* All comments and enthuses will remain intact."
        />

        <WarningModal
          {...editEpisodeModal}
          isFooterCaption
          title="Edit a published post"
          text="This post is published in RE:PPL. If you edit the content, the reviewer will receive a notification for the change."
          onOk={LinkHandle}
          okBtnText="Edit"
          cancelBtnText="No, keep it as it is"
          cancelBtnColor="#9B9FA4"
          cancelBtnScheme="#9B9FA4"
          footerCaption="* Please be reminded that the reviewer might unlist your post if he/she thinks the change is inappropriate."
        />
        <WarningModal
          {...unlistEpisodeModal}
          title="Unlist your post"
          text="Are you sure you want to remove your post from the RE:PPL? Your post will be moved to your studio, and all interaction records will be kept."
          onOk={() => {
            unlistNoteModal.onOpen();
          }}
          okBtnText="Unlist"
          cancelBtnText="Maybe later"
          cancelBtnColor="#9B9FA4"
          cancelBtnScheme="#9B9FA4"
        />
        <WarningModal
          {...failedToUnlistModal}
          onOk={failedToUnlistModal.onClose}
          title="Failed to unlist"
          text="This episode is featured in the home tab. Please unfeature it first to unlist from the RE:PPL."
          okBtnText="Got it"
          warningType="warning2"
          onOkBtnColor="#FFD557"
          noCancel
        />
        <NoteModal
          modal={unlistNoteModal}
          noteModalTitle="Unlist this episode"
          noteModalSubtitle="Let the creator know why you want to unlist this episode."
          okButtonText="Unlist"
          placeholder="This content of episode no longer fits the theme of RE:PPL..."
          updateNoteText={setUnlistNoteText}
          onOk={async () => {
            await unlistEpisode({
              episodeID: props.contentID,
              repplID: props.data?.sourceID,
              note: unlistNoteText,
            });
            router.back();
          }}
        />
        <NoteModal
          modal={creatorsPicksNoteModal}
          noteModalTitle="Feature this episode"
          noteModalSubtitle="Let the creator know why you want to feature this episode."
          okButtonText="Feature"
          placeholder="This episode is impressive, because..."
          updateNoteText={setCreatorsPicksNoteText}
          onOk={async () => {
            await createPin({
              dataset: [
                {
                  contentID: props?.data.contentID,
                  pinned: !props?.data.pinned,
                  pinnedMessage: creatorsPicksNoteText,
                  type: 'pin',
                },
              ],
              projectID: props?.data.sourceID,
              contentType: 'EPISODE',
            });
            if (props.refetch) {
              await props.refetch();
            }
          }}
        />
      </>
    );
  }

  return (
    <Fade in={!!user}>
      <ConditionalWrap
        condition={isDesktop}
        wrap={(c) => (
          <Box
            position="absolute"
            zIndex={50}
            left={props?.left || '-62px'}
            height="100%"
          >
            {c}
          </Box>
        )}
      >
        <Stack
          direction="column"
          spacing={4}
          top={{
            base: 'unset',
            lg: '60px',
          }}
          height="max-content"
          bottom={{
            base: '16px',
            lg: 'unset',
          }}
          position={{
            base: 'fixed',
            lg: 'sticky',
          }}
          {...(!isDesktop && {
            direction: 'row',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06)',
            borderRadius: '28px',
            maxHeight: '48px',
            bg: '$mode.bg',
            zIndex: 99,
            paddingY: '12px',
            paddingX: '24px',
            width: 'fit-content',
            willChange: 'transform',
            transition: 'transform 0.2s ease-in',
            transform: 'translateX(-50%)',
            left: '50%',
            style: {
              transform: 'translateX(-50%)',
            },
          })}
        >
          {Children.toArray(ICONS)}
        </Stack>
      </ConditionalWrap>
    </Fade>
  );
}
