import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import SvgClose from 'src/components/icons/close';
import { NotificationContext } from 'src/hooks/use-notification';
import { useRouter } from 'next/router';
import {
  useColorModeValue,
  useMediaQuery,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import SignInModal from 'src/app/auth/sign-in/sign-in-modal';
import SvgLogo from '../icons/logo-beta'; // '../icons/logo'

interface NavigationPanelProps {
  setDisplayPanel: Function;
  navItems: Array<any>;
  siteItems: Array<any>;
  siteSubItems: object;
  isLoggedIn?: Boolean;
}
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavigationPanel = ({
  setDisplayPanel,
  navItems,
  siteItems,
  siteSubItems,
  isLoggedIn,
}: NavigationPanelProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const color = useColorModeValue('text-gray-900', 'text-white-900');
  const bg = useColorModeValue('bg-white', 'bg-gray-900');
  const [isLessThan370] = useMediaQuery('(max-width: 370px)');

  const [notification] = useContext(NotificationContext);
  const newnotif = notification?.data?.filter(function filterNotif(notifData) {
    return notifData.read === false;
  });

  const badgeNumber = notification.notifCount > 99 ? '99+' : newnotif?.length;

  const [displaySubItems, setDisplaySubItems] = useState('');
  const panelHandle = (type) => {
    if (type === displaySubItems) {
      setDisplaySubItems('');
    } else {
      setDisplaySubItems(type);
    }
  };

  const signInModal = useDisclosure();

  return (
    <Box
      zIndex="99"
      className={classNames(
        bg,
        color,
        'fixed inset-0 bg-br text-white h-screen '
      )}
    >
      <header className="text-right pt-5 px-6 flex justify-between items-center">
        <a href="/" className="md:ml-0">
          <SvgLogo
            alt="1TM Logo"
            className="inline"
            height="60px"
            width="85px"
          />
        </a>
        <span
          aria-hidden="true"
          onClick={() => {
            setDisplayPanel(false);
          }}
        >
          <SvgClose alt="close" className="cursor-pointer inline text-lg" />
        </span>
      </header>
      <ul className="font-black mt-10">
        {siteItems.map((sitem, itemKey) => {
          const liKey = `${itemKey}_${sitem.id}`;
          const currentKey = displaySubItems === sitem.displayName;
          const isNotify =
            router.pathname.includes('/notification') &&
            sitem.path.includes('/notification');
          return (
            <li
              className={classNames(
                router.pathname === sitem.path && 'text-gray-500important',
                isNotify && 'text-gray-500important',
                isLessThan370 && 'py-0.5',
                'px-6'
              )}
              key={liKey}
            >
              {sitem.type === 'link' && (
                <a
                  href={sitem.path}
                  className={classNames(
                    color,
                    'block mb-6 font-medium text-base',
                    router.pathname === sitem.path && 'opacity-60',
                    isNotify && 'opacity-60',
                    isLessThan370 && 'text-xs'
                  )}
                  onClick={() => {
                    setDisplayPanel(false);
                  }}
                  aria-hidden="true"
                >
                  {t(sitem.displayName)}
                  {sitem.displayName === 'NOTIFICATIONS' && (
                    <span
                      className={classNames(
                        isNotify && 'opacity-60',
                        'bg-outrageousOrange-500 ml-6 font-bold text-sm py-1 px-2 text-white rounded-full'
                      )}
                    >
                      {badgeNumber}
                    </span>
                  )}
                </a>
              )}
              {sitem.type === 'click' && (
                <div aria-hidden="true" className="block mb-7">
                  <span
                    aria-hidden="true"
                    className={`${currentKey ? 'text-gray-500' : ''}`}
                    onClick={() => {
                      panelHandle(sitem.displayName);
                    }}
                  >
                    {t(sitem.displayName)}
                  </span>
                  <div
                    className={`accordion ml-6 ${
                      currentKey
                        ? 'accordion-max-hgight'
                        : 'max-h-0 overflow-hidden'
                    } `}
                  >
                    {siteSubItems[sitem.displayName].map((item, key) => {
                      const mkey = `m-${key}`;
                      return (
                        <a key={mkey} className="block my-7" href={item.link}>
                          {item.title}
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
            </li>
          );
        })}
      </ul>
      {!isLoggedIn && (
        <div className="flex flex-col justify-center w-full absolute bottom-6 px-6">
          <a
            href="/activate?noCode=true"
            className="bg-denim-500 text-white px-8 py-2.5 rounded-full text-center font-semibold text-base mb-3"
            style={{
              lineHeight: '20px',
            }}
          >
            Get Started
          </a>
          <button
            type="button"
            onClick={signInModal.onOpen}
            className="text-base text-denim-500 font-medium"
          >
            Sign In
          </button>
          <SignInModal
            modal={{
              ...signInModal,
              onClose: () => {
                router.push({ query: { ...router.query, page: '' } });
                signInModal.onClose();
              },
            }}
          />
        </div>
      )}
      {isLoggedIn && (
        <ul className="border-t border-beaver-100 mt-4 pt-4 absolute bottom-16 w-screen">
          {navItems.map((item, itemKey) => {
            const liKey = `${itemKey}_${item.id}`;
            // if (item['displayName'] === 'Create a Studio Post') return null;
            return (
              <li
                className={classNames(
                  isLessThan370 ? 'py-2 px-8' : 'py-4 px-10'
                )}
                key={liKey}
              >
                {item.type === 'link' && (
                  <a
                    href={item.path}
                    className={classNames(
                      isLessThan370 ? 'block text-xs' : 'block'
                    )}
                    onClick={() => {
                      setDisplayPanel(false);
                    }}
                    aria-hidden="true"
                  >
                    {t(item.displayName)}
                  </a>
                )}
                {item.type === 'desktop-only' && (
                  <div className="flex items-center">
                    <span
                      className={classNames(
                        isLessThan370 ? 'pr-2 text-xs' : 'pr-3',
                        'text-gray-400'
                      )}
                      onClick={item.action}
                      aria-hidden="true"
                    >
                      {t(item.displayName)}
                    </span>
                    <div
                      className={classNames(
                        isLessThan370
                          ? 'h-6 pt-1 pb-1.5 px-3'
                          : 'h-7 pt-1.5 pb-1 px-3',
                        'text-xs rounded-sm text-gray-700 bg-gray-400'
                      )}
                    >
                      Desktop only
                    </div>
                  </div>
                )}
                {item.type === 'click' && (
                  <span
                    onClick={item.action}
                    aria-hidden="true"
                    className={classNames(
                      isLessThan370 ? 'block text-xs' : 'pr-3',
                      'block '
                    )}
                  >
                    {t(item.displayName)}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </Box>
  );
};

export default NavigationPanel;
