import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { CacheKey } from 'src/constants/cache-key';
import { PageRequestQuery, RestPageResponse, RestResponse } from 'src/types';
import { axios } from 'src/lib/axios';

import { standaloneToast } from 'src/components/toast';
import { ListType } from './creation';

interface Owner {
  userID: number;
  username: string;
  profileImgURL: string;
  firstName: string;
  lastName: string;
}

interface ProjectCard {
  owner: Owner;
  id: string;
  type: string;
  access: string;
  title: string;
  headline: string;
  summary: string;
  category: string;
  avatarURL: string;
  coverImgURL: string;
  draft?: boolean;
  isOpen: boolean;
  createdAt: Date;
  updatedAt: Date;
  projectType: string;
  episodeCount: number;
  followerCount: number;
  team?: Array<any>;
}

export async function fetchProjectListByUser({
  userID,
  params,
}: {
  userID: number;
  params?: PageRequestQuery;
}) {
  return axios
    .get<RestPageResponse<ProjectCard[]>>(`project-list/user/${userID}`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => ({
      ...res.data,
      pageNum: params.pageNum,
    }));
}

export function useProjectListByUserQuery(req: {
  userID: number;
  params: PageRequestQuery;
}) {
  return useInfiniteQuery(
    [CacheKey.projects, req],
    ({ pageParam = 1 }) =>
      fetchProjectListByUser({
        userID: req.userID,
        params: { ...req.params, pageNum: pageParam },
      }),
    {
      enabled: !!req.params && !!req.userID,
      getNextPageParam: ({ next, pageNum = 1 }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

type ProjectType = 'REPPL';

type ProjectListByTypeRequest = {
  type?: ProjectType;
  id: string;
  params?: PageRequestQuery;
};

export async function fetchProjectListByType<T = ListType>({
  type = 'REPPL',
  id,
  params = {},
}: ProjectListByTypeRequest) {
  return axios
    .get<RestPageResponse<T[]>>(`project/${type}/${id}`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => res.data);
}

export function useProjectListByTypeQuery(req: ProjectListByTypeRequest) {
  return useQuery(
    [CacheKey.projectList, req],
    () => fetchProjectListByType(req),
    {
      enabled: !!req.id,
    }
  );
}

type Biography = 'USER' | 'REPPL';

export interface BiographyRequest {
  type: Biography;
  id: string | number;
}

type BiographyCommonFields = {
  followerCount: number;
  episodeCount: number;
  isFollowed: boolean;
};

export interface BiographyUserResponse extends BiographyCommonFields {
  user_id: number;
  username: string;
  profileImgURL: string;
  userTitle: string;
  firstName: string;
  lastName: string;
  motto: string;
  about: string;
  studioCount: number;
  episodeEnthuseCount: number;
  studioEnthuseCount: number;
}

export interface BiographyRepplResponse extends BiographyCommonFields {
  id: string;
  type: 'SOLO' | 'TEAM' | 'OPEN'; // TODO: follow up types
  title: string;
  headline: string;
  avatarURL: string;
}

export function getProjectBio<T>(req: BiographyRequest) {
  return axios
    .get<RestResponse<T>>(`/bio/${req.type}/${req.id}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export function useProjectBioQuery<T>({
  id,
  type,
  enabled = false,
}: {
  id: string | number;
  type: Biography;
  enabled?: boolean;
}) {
  return useQuery(
    [CacheKey.projectBio, { id, type }],
    () => getProjectBio<T>({ id, type }),
    { enabled }
  );
}

const removeCollectionFromProject = async ({
  id,
  repplId,
}: {
  id: number;
  repplId: string;
}) => {
  return axios
    .delete(`/project/${repplId}/remove/label/${id}`)
    .then((res) => res.data);
};

export function useRemoveProjectLabel(repplId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id }: { id: number }) => removeCollectionFromProject({ id, repplId }),
    {
      onSuccess: (res) => {
        if (res.code === 1000) {
          standaloneToast({
            position: 'bottom-right',
            title: '🎉 Collection removed!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(CacheKey.labelList);
      },
      onError: () => {
        standaloneToast({
          title: 'Remove collection failed 😢',
          status: 'error',
        });
      },
    }
  );
}

export interface DeleteProjectInvitationParameter {
  id: string;
  inviteID: number;
}

export function deleteProjectInvitation({
  id,
  inviteID,
}: DeleteProjectInvitationParameter) {
  return axios
    .delete<RestResponse>(`/project/${id}/invite/${inviteID}`)
    .then((res) => res.data);
}

export function useDeleteProjectInvitationMutation({
  onMutate,
}: {
  onMutate: any;
}) {
  return useMutation(deleteProjectInvitation, {
    onSuccess: () => {
      standaloneToast({
        position: 'bottom-right',
        title: '🎉 Delete invitation successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },

    onError: () => {
      standaloneToast({
        position: 'bottom-right',
        title: '😢 Delete invitation failure, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },

    onMutate,
  });
}

export interface RemoveProjectEditorParams {
  id: string;
  editorID: number;
}

export function removeProjectEditor({
  id,
  editorID,
}: RemoveProjectEditorParams) {
  return axios
    .delete<RestResponse>(`/project/${id}/remove/editor/${editorID}`)
    .then((res) => res.data);
}

export function useRemoveProjectEditorMutation() {
  const queryClient = useQueryClient();

  return useMutation(removeProjectEditor, {
    onSuccess: () => {
      standaloneToast({
        position: 'bottom-right',
        title: '🎉 Remove editor successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },

    onError: () => {
      standaloneToast({
        position: 'bottom-right',
        title: '😢 Remove editor failure, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries(CacheKey.reppl);
    },
  });
}

export function leaveProject({ id }: { id: string }) {
  return axios
    .post<RestResponse>(`/project/${id}/leave`)
    .then((res) => res.data);
}

export function useLeaveProjectMutation() {
  const queryClient = useQueryClient();

  return useMutation(leaveProject, {
    onSuccess: () => {
      standaloneToast({
        position: 'bottom-right',
        title: '🎉 Leave project successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },

    onError: () => {
      standaloneToast({
        position: 'bottom-right',
        title: '😢 Leave project failure, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries(CacheKey.reppl);
    },
  });
}

export function removeEpisode({
  spaceID,
  episodeID,
}: {
  spaceID: string;
  episodeID: string;
}) {
  return axios
    .delete<RestResponse>(`/project/${spaceID}/remove/episode/${episodeID}`)
    .then((res) => res.data);
}

export function useRemoveEpisodeMutation() {
  const queryClient = useQueryClient();

  return useMutation(removeEpisode, {
    onSuccess: () => {
      standaloneToast({
        position: 'bottom-right',
        title: '🎉 Remove episode successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },

    onError: () => {
      standaloneToast({
        position: 'bottom-right',
        title: '😢 Remove episode failure, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries(CacheKey.reppl);
      queryClient.invalidateQueries(CacheKey.episodeList);
      queryClient.invalidateQueries(CacheKey.creationList);
    },
  });
}
