import * as React from 'react';
import { Svg } from '../svg';

function SvgUnlink({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.333 10.334h3.333v1.333h-2v2h-1.333v-3.333zM3.666 3.667H.333V2.334h2v-2h1.333v3.333zm7.576 5.691l-.942-.944.942-.943a3.334 3.334 0 10-4.714-4.714l-.942.944-.944-.943.944-.943a4.667 4.667 0 116.6 6.6l-.943.943zm-1.885 1.885l-.943.943a4.667 4.667 0 01-6.6-6.6l.943-.943.943.944-.943.943a3.333 3.333 0 104.714 4.714l.943-.943.943.943zm-.472-7.071l.943.943L5.114 9.83l-.943-.943 4.714-4.713v-.001z"
        fill="#000"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgUnlink);
export default ForwardRef;
