import { Box, BoxProps, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer';
import { ReactNode, useEffect } from 'react';
import EmailVerificationModal from 'src/app/auth/sign-in/email-verification-modal';
import Toast from 'src/components/common/toast/toast';
import { useRouter } from 'next/router';
import SiteNavigation from '../header/siteNavigation';

export function Layout({
  children,
  header,
  footerVisible = true,
  boxProps,
}: {
  children?: ReactNode;
  header?: ReactNode;
  footerVisible?: boolean;
  boxProps?: BoxProps;
}) {
  const emailVerificationModal = useDisclosure();
  const router = useRouter();

  useEffect(() => {
    if (router.query?.page) {
      if (router.query?.page === 'notverifidet') {
        emailVerificationModal.onOpen();
      }
    }
  }, [router.query]);

  return (
    <Box {...boxProps}>
      {header || (
        <>
          <SiteNavigation />
        </>
      )}
      <EmailVerificationModal modal={emailVerificationModal} />
      {/* minHeight equals to 100vh minus header height */}
      <main id="main" className="bg-mintCream dark:bg-gray-800 m-auto">
        {children}
      </main>
      <Toast />
      {footerVisible && <Footer />}
    </Box>
  );
}
