import * as React from 'react';
import { Svg } from '../svg';

function SvgPlusDoc({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M20 5.334H6.667v21.333h18.666v-16H20V5.334zM4 3.99c0-.731.596-1.323 1.332-1.323h16.001L28 9.334V27.99a1.334 1.334 0 01-1.324 1.343H5.324A1.334 1.334 0 014 28.01V3.99zm10.667 10.677v-4h2.666v4h4v2.667h-4v4h-2.666v-4h-4v-2.667h4z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPlusDoc);
export default ForwardRef;
