import * as React from 'react';
import { Svg } from '../svg';

function SvgCube({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M20.2 6.999V17a1 1 0 01-1 1h-8V6.999h9zm-11 6V18h-8a1 1 0 01-1-1v-4.001h9zM9.2 0v10.999h-9V1a1 1 0 011-1h8zm10 0a1 1 0 011 1v3.999h-9V0h8z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCube);
export default ForwardRef;
