import * as React from 'react';
import { Svg } from '../svg';

function SvgNextSlide({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={20.5} cy={20} r={20} fill="#fff" />
      <path
        d="M17.658 14.508l1.175-1.174L25.5 20l-6.667 6.667-1.175-1.175L23.141 20l-5.483-5.492z"
        fill="#242526"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNextSlide);
export default ForwardRef;
