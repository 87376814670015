import * as React from 'react';
import { Svg } from '../svg';

function SvgFacebookV3({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M20 .558c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20z"
        fill="currentColor"
      />
      <path
        d="M21.778 21.48h3.996l.627-4.058h-4.623v-2.219c0-1.686.551-3.181 2.128-3.181h2.535V8.48c-.446-.06-1.387-.191-3.167-.191-3.716 0-5.894 1.962-5.894 6.433v2.7h-3.82v4.06h3.819v11.156c.756.112 1.523.19 2.31.19.71 0 1.404-.065 2.09-.158V21.48z"
        fill="#242526"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgFacebookV3);
export default ForwardRef;
