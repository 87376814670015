import Image, { ImageProps } from 'next/image';
import { AspectRatio, Box, BoxProps, Grid, GridItem } from '@chakra-ui/react';
import { ReactNode, memo } from 'react';

import { ImageBackground, ImageBackgroundRatio } from '../image-background';

const defaultImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATAAAAEwCAYAAAAw+y3zAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARvSURBVHgB7daxbdVgAEXh3zYN3RNigBSA0hGyESPAQhkBmIARoEz5NnipHQfjKEvkSN/X3QWO7jQOV9fXV2+enu7GmG6OeRoAr9U0fmzr+v18mF7i9e/PEC6g47I9rrfL+9O7u6NoNwOg4+28zJ+nDx8/7QOg5zIPgKaTgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkCVgQJaAAVkCBmQJGJAlYECWgAFZAgZkCRiQJWBAloABWQIGZAkYkPUcsIcBEHQEbP87AGqm8WveluXr8MKAlsu2rt/m8/39eVvmL2MfPwfA6/Yw9v339rjeng//AWGBM0Yw+2OYAAAAAElFTkSuQmCC';

export const ImageBox = memo(function ImageBox({
  src,
  children,
  ratio,
  gradientBg = 'linear-gradient(180deg, rgba(0, 0, 0, 0) 64.75%, rgba(0, 0, 0, 0.45) 100%)',
  imageProps,
  ...props
}: {
  src?: string | string[];
  children?: ReactNode;
  ratio?: number;
  gradientBg?: string;
  imageProps?: Partial<ImageProps>;
} & BoxProps) {
  let Wrapper: any = ratio
    ? ImageBackgroundRatio
    : (ImageBackground as typeof ImageBackgroundRatio);

  if (Array.isArray(src)) {
    Wrapper = GridImageBackground;
  }
  return (
    <Box
      borderRadius="4px"
      pos="relative"
      overflow="hidden"
      h="100%"
      {...props}
    >
      <Wrapper ratio={ratio} src={src || defaultImage} imageProps={imageProps}>
        {gradientBg && (
          <Box
            h="100%"
            className="image-box"
            bg={gradientBg}
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
          />
        )}
        {children}
      </Wrapper>
    </Box>
  );
});

function GridImageBackground(props: {
  children: ReactNode;
  src: string[];
  ratio?: number;
}) {
  return (
    <AspectRatio ratio={props.ratio} bg="black">
      <Box>
        <Grid
          templateRows="repeat(1, fr)"
          templateColumns="repeat(2, 1fr)"
          position="absolute"
          top={0}
          right={0}
          left={0}
          bottom={0}
        >
          {props?.src?.slice(0, 3).map((_, i) => {
            const length = props?.src?.slice(0, 3).length;

            if (length === 0) {
              return <GridItem key={_} rowSpan={1} colSpan={2} bg="black" />;
            }

            if (length === 1) {
              return (
                <GridItem rowSpan={1} colSpan={2} pos="relative" key={_}>
                  <Image
                    objectFit="cover"
                    src={props.src[i]}
                    quality={50}
                    layout="fill"
                  />
                </GridItem>
              );
            }

            if (length === 2) {
              return (
                <GridItem rowSpan={1} colSpan={2} pos="relative" key={_}>
                  <Image
                    objectFit="cover"
                    src={props.src[i]}
                    quality={50}
                    layout="fill"
                  />
                </GridItem>
              );
            }

            if (length === 3) {
              return (
                <GridItem
                  rowSpan={1}
                  colSpan={i === 0 ? 2 : 1}
                  pos="relative"
                  key={_}
                >
                  <Image
                    objectFit="cover"
                    src={props.src[i]}
                    quality={50}
                    layout="fill"
                  />
                </GridItem>
              );
            }
            return null;
          })}
        </Grid>
        {props.children}
      </Box>
    </AspectRatio>
  );
}
