import * as React from 'react';
import { Svg } from '../svg';

function SvgPacman({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.6 0a9.98 9.98 0 017.743 3.671L12.014 10l6.329 6.329A9.979 9.979 0 0110.6 20c-5.523 0-10-4.477-10-10s4.477-10 10-10zm0 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPacman);
export default ForwardRef;
