import { EnthuseButton } from 'src/app/article/components/enthuse-button/enthuse-button';
import {
  useAgoraFeedEnthuseMutation,
  useFeedCommentEnthuseMutation,
} from 'src/app/article/hooks/use-enthuse';
import { GalleryModal } from 'src/lib/hexx/blocks/gallery/gallery-renderer';
import { useModal } from 'src/hooks/use-modal';
import { useState, useContext, useRef, useEffect } from 'react';
import Link from 'next/link';
import dayHepler from 'src/heplers/day';
import { Avatar } from 'src/components/avatar/avatar';
import SvgComments from 'src/components/icons/comments';
import SvgArrow from 'src/components/icons/arrow';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { Commenter, CommenterHandler } from 'src/components/comment';
import {
  useArticleCommentMutation,
  useGeneralCommentMutation,
  useArticleEditCommentMutation,
} from 'src/app/article/hooks/use-article-comment';
import { rendererBlockMaps } from 'src/lib/hexx/render-blocks';
import { EditorRenderer } from '@hexx/renderer';

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/react';

import { ArticleComment } from 'src/app/article/article-comment';
import SvgIcSettings from 'src/components/icons/ic-settings';
import Dropdowns from 'src/components/common/dropdowns/dropdowns';
import { imageOptimize } from 'src/service/image';
import copy from 'copy-to-clipboard';

export function GeneralFeed({
  recentFeed,
  modal = false,
  isEdit,
  isDelete,
}: {
  recentFeed: any;
  modal?: boolean;
  isEdit?: (value: any) => void;
  isDelete?: (value: any) => void;
}) {
  const { user } = useContext(UserContext) as UserContextType;
  const commentRef = useRef<CommenterHandler>();
  const galleryModal = useModal();
  const [currAsset, setCurrAsset] = useState<any>();
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const toggleComment = () => setIsCommentOpen((comment) => !comment);
  const [newData, setNewData] = useState({
    enthuseCount: recentFeed['enthuseCount'],
    isEnthused: recentFeed['isEnthused'],
  });
  const { mutateAsync: createComment } = useArticleCommentMutation(
    recentFeed.contentID
  );
  const { mutateAsync: createGeneral } = useGeneralCommentMutation();
  const { mutateAsync: updateComment } = useArticleEditCommentMutation(
    recentFeed.contentID
  );
  const { mutateAsync: enthuseComment } = useFeedCommentEnthuseMutation(
    recentFeed
  );

  const { mutateAsync: enthuse } = useAgoraFeedEnthuseMutation(
    newData,
    setNewData
  );

  const [currComments, setCurrComments] = useState([]);

  useEffect(() => {
    setCurrComments(recentFeed.comments);
  }, [recentFeed]);

  const [isShareButtonClicked, setIsShareButtonClicked] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onClikcShareBtn = () => {
    const shareURL = `${`${window.location.origin}/${recentFeed?.author?.username}/${recentFeed?.contentID}`}`;
    copy(shareURL);
    setIsShareButtonClicked(true);
  };

  const onOpenPopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const onClosePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
    setIsShareButtonClicked(false);
  };

  function getImgContent(img, idx, len) {
    if (len > 3) {
      if (idx <= 1) {
        return (
          <>
            <div className="cover-image-box my-2 mx-1 h-48 w-48 cursor-pointer" />
            <style jsx>
              {`
                .cover-image-box {
                  background-image: url('${imageOptimize(img.imgURL)}');
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                }
              `}
            </style>
          </>
        );
      }
      if (idx === 2) {
        return (
          <>
            <div className="relative">
              <div className="cover-image-box my-2 mx-1 h-48 w-48 cursor-pointer" />
              <div
                style={{ height: 'fit-content' }}
                className="absolute text-white font-bold top-0 bottom-0 right-0 left-0 m-auto w-min text-2xl"
              >
                +{len - 3}
              </div>
            </div>
            <style jsx>
              {`
                .cover-image-box {
                  background-image: url('${imageOptimize(img.imgURL)}');
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                }
              `}
            </style>
          </>
        );
      }
    } else {
      return (
        <>
          <div className="cover-image-box my-2 mx-1 h-48 w-48 cursor-pointer" />
          <style jsx>
            {`
              .cover-image-box {
                background-image: url('${imageOptimize(img.imgURL)}');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
              }
            `}
          </style>
        </>
      );
    }
  }

  async function enthuseCommentForwarder(data) {
    enthuseComment(data);
    const enthusedComment = [...currComments];
    enthusedComment.forEach((parentComment) => {
      if (data.objectParentId) {
        parentComment.child.forEach((comment) => {
          if (comment.id === data.objectId) {
            // eslint-disable-next-line no-param-reassign
            comment.isEnthused = !comment.isEnthused;

            // eslint-disable-next-line no-param-reassign
            comment.enthuseCount = !comment.isEnthused
              ? comment.enthuseCount - 1
              : comment.enthuseCount + 1;
          }
        });
      } else if (parentComment.id === data.objectId) {
        // eslint-disable-next-line no-param-reassign
        parentComment.isEnthused = !parentComment.isEnthused;

        // eslint-disable-next-line no-param-reassign
        parentComment.enthuseCount = !parentComment.isEnthused
          ? parentComment.enthuseCount - 1
          : parentComment.enthuseCount + 1;
      }
    });

    setCurrComments(enthusedComment);
  }

  async function handleComment({
    event,
    blockId,
    value = '',
    commentID,
  }: {
    event: any;
    blockId?: string;
    value?: string;
    commentID?: string;
  }) {
    let currValue = value;
    let currEvent = event;

    if (event?.value) {
      currValue = event.value;
      currEvent = event.event;
    }
    if (currValue !== '' && currEvent.key === 'Enter') {
      // if (event.currentTarget && event.currentTarget.value) {
      //   event.currentTarget.blur();
      //   // eslint-disable-next-line no-param-reassign
      //   event.currentTarget.value = '';
      // }

      if (!modal) {
        if (commentID) {
          updateComment({
            commentID,
            Assets: [],
            content: currValue,
            parentId: blockId,
          });
        } else {
          const currComement = await createGeneral({
            parentId: blockId,
            objectId: recentFeed.contentID,
            objectType: blockId ? 'COMMENT' : 'EPISODE',
            content: currValue,
          });

          const newComment = {
            assets: null,
            child: [],
            commenter: {
              firstName: user.firstName,
              id: user.id,
              lastName: user.lastName,
              profileImgURL: user.avatar,
              username: user.username,
            },
            content: currValue,
            createdAt: currComement.data.createdAt,
            enthuseCount: 0,
            id: currComement.data.id,
            isEnthused: false,
            parentID: '',
          };

          if (value !== '') {
            const subComment = [...currComments];
            subComment.forEach((sub) => {
              if (sub.id === blockId) {
                sub.child.push(newComment);
              }
            });

            setCurrComments([...subComment]);
          } else if (currComments.length === 0) {
            setCurrComments([newComment]);
          } else {
            setCurrComments([...currComments, newComment]);
          }
        }
      } else if (commentID) {
        updateComment({
          commentID,
          Assets: [],
          content: currValue,
          parentId: blockId,
        });
      } else {
        createComment({
          objectId: recentFeed.contentID,
          objectType: blockId ? 'COMMENT' : 'EPISODE',
          parentId: blockId,
          content: currValue,
        });
      }
    }
  }

  const deleteGeneral = () => {
    isDelete(recentFeed['contentID']);
  };

  const editGeneral = () => {
    isEdit(recentFeed);
  };

  const fns = [
    {
      action: editGeneral,
      displayName: 'Edit',
      path: '',
      type: 'click',
    },
    {
      action: deleteGeneral,
      displayName: 'Delete',
      path: '',
      type: 'click',
    },
  ];

  const getTagLink = (data) => {
    return data.replace(/ /g, '-');
  };

  return (
    <div
      className={`p-4 sm:p-5 xl:p-8 relative sm:rounded shadow-sm w-full text-gray-900 bg-white dark:bg-gray-900 dark:text-white ${
        modal ? 'my-0' : 'my-4 sm:my-6'
      }`}
    >
      <div className="">
        <div className="sm:flex relative">
          <div className="flex justify-start items-center">
            <Link href={`/${recentFeed?.author?.username}`}>
              <div className="cursor-pointer">
                <Avatar
                  name={recentFeed?.author?.username}
                  data-testid={recentFeed?.author?.profileImgURL}
                  bg="$mode.400"
                  width={40}
                  height={40}
                  author={recentFeed?.author}
                  src={recentFeed?.author?.profileImgURL}
                  mr={1}
                />
              </div>
            </Link>
            <Link href={`/${recentFeed?.author?.username}`}>
              <div className="sm:m-auto pl-1 cursor-pointer">
                <div className="font-medium text-xs sm:text-lg">
                  {getProfileFullNameOrUsername(recentFeed?.author)}
                </div>
              </div>
            </Link>
          </div>
          <div className="flex sm:absolute right-2 my-auto">
            <div className="m-auto text-sm w-full sm:w-auto">
              {dayHepler.localizedFormat(recentFeed?.createdAt, 'MMMM DD')} at{' '}
              {dayHepler.localizedFormat(recentFeed?.createdAt, 'h:mm A')}
            </div>
          </div>
        </div>
        <div>
          <div className="py-1">
            <div className="text-bold text-base sm:text-2xl xl:text-4xl">
              {recentFeed?.title}
            </div>
          </div>
          <div className="pb-2">
            <EditorRenderer
              maxWidth="100%"
              blocks={recentFeed?.content?.blocks}
              blockMap={rendererBlockMaps}
            />
          </div>
        </div>
        {recentFeed?.tags?.length > 0 && (
          <div className="flex mb-1">
            {recentFeed?.tags?.map((tag) => (
              <Link key={`tag${tag}`} href={`/agora/${getTagLink(tag)}`}>
                <div className="bg-blue-50 text-blue-500 px-2 py-0.5 rounded-full text-sm mr-2 cursor-pointer">
                  {tag.substring(0, 1) === '#' ? tag : `#${tag}`}
                </div>
              </Link>
            ))}
          </div>
        )}
        <div
          className="flex mx-auto"
          onClick={() => {
            setCurrAsset(recentFeed?.content?.uploadedFiles);
            galleryModal.onSelect(true);
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              setCurrAsset(recentFeed?.content?.uploadedFiles);
              galleryModal.onSelect(true);
            }
          }}
          role="button"
          tabIndex={0}
        >
          {recentFeed?.content?.uploadedFiles?.length > 0 &&
            recentFeed?.content?.uploadedFiles?.map((generalImgs, genIndex) =>
              getImgContent(
                generalImgs,
                genIndex,
                recentFeed?.content?.uploadedFiles?.length
              )
            )}
        </div>
        <div className="mb-2" />
        {galleryModal.selected && (
          <GalleryModal
            {...galleryModal.modal}
            assets={currAsset}
            data={currAsset}
          />
        )}
      </div>
      <div className="">
        <div className="flex relative">
          <div className="flex">
            <div className="flex text-xs font-medium my-auto p-2">
              <EnthuseButton
                width="16px"
                height="16px"
                fontSize="16px"
                minWidth="auto"
                svgClass="text-gray-900"
                isEnthuse={newData['isEnthused']}
                onEnthuse={() => {
                  enthuse({
                    objectId: recentFeed['contentID'],
                    objectType: 'EPISODE',
                    ownerID: recentFeed['author']?.['userId'],
                  });
                }}
                likesCount={newData.enthuseCount}
              />
            </div>
            <div
              role="button"
              tabIndex={0}
              className="flex px-2 text-xs font-medium m-auto cursor-pointer p-2"
              onClick={() => toggleComment()}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  toggleComment();
                }
              }}
            >
              <SvgComments fontSize="18px" />
              <span className="m-auto px-1">{recentFeed?.commentCount}</span>
            </div>
          </div>
          <div className="hidden flex absolute right-2 h-auto">
            <div
              className={`flex justify-center items-center p-2 rounded-2xl cursor-pointer ${
                isPopoverOpen ? 'bg-teal-100' : 'bg-white'
              } `}
            >
              <Popover
                placement="top"
                gutter={20}
                autoFocus={false}
                onClose={onClosePopover}
                onOpen={onOpenPopover}
              >
                <PopoverTrigger>
                  <div>
                    <SvgArrow
                      fontSize="16px"
                      color={isPopoverOpen ? '#9B9FA4' : 'black'}
                    />
                  </div>
                </PopoverTrigger>
                <PopoverContent bg="white">
                  <PopoverArrow
                    style={{
                      width: '20px',
                      height: '10px',
                      boxShadow:
                        '0px 1px 0px #E6E8E9, 0px 3px 6px rgba(0, 0, 0, 0.08)',
                    }}
                  />
                  <PopoverBody bg="white" paddingTop="0" paddingBottom="0">
                    <div className="flex flex-col justify-center items-center">
                      <h1
                        style={{
                          width: '140px',
                          fontSize: '14px',
                          lineHeight: '18px',
                        }}
                        className="text-center font-semibold mt-6 mb-3 text-gray-700"
                      >
                        Share this post
                      </h1>
                      <Button
                        className="font-semibold h-auto py-2 px-7 mb-6"
                        style={{
                          color: '#fff',
                          fontSize: '14px',
                          lineHeight: '18px',
                          borderRadius: '2px',
                          maxWidth: '123px',
                        }}
                        onClick={() => onClikcShareBtn()}
                        disabled={!!isShareButtonClicked}
                      >
                        {isShareButtonClicked ? 'Link copied' : 'Copy Link'}
                      </Button>
                    </div>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
        {isCommentOpen && (
          <div>
            <Commenter
              disabled={false}
              ref={commentRef}
              user={user}
              editorIndex={
                modal
                  ? `editorMainModal${recentFeed['contentID']}`
                  : `editorMain${recentFeed['contentID']}`
              }
              onKeyDown={(event) => handleComment({ event })}
            />
            {currComments?.length > 0 && (
              <ArticleComment
                isModal
                comments={currComments}
                commentCount={recentFeed?.commentCount}
                onComment={({ keyEvent, blockId, value, commentID }) => {
                  const event = keyEvent;
                  handleComment({ event, blockId, value, commentID });
                }}
                onEnthuse={({
                  targetId,
                  targetParentId,
                  targetType,
                  isEnthused,
                  targetOwnerId,
                }) => {
                  enthuseCommentForwarder({
                    objectId: targetId,
                    objectParentId: targetParentId,
                    objectType: targetType,
                    enthuse: !isEnthused,
                    ownerID: targetOwnerId,
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
      {recentFeed['author'].userID === user.id && !modal && (
        <div className="absolute right-1 top-3 z-50">
          <Dropdowns
            className="text-sm font-medium"
            items={[fns]}
            dataID={
              recentFeed['source'] === 'REPPL'
                ? recentFeed['contentID']
                : recentFeed['objectID']
            }
            buttonComponent={
              <SvgIcSettings
                fontSize="20px"
                className="inline mr-2 align-text-bottom text-gray-900"
              />
            }
          />
        </div>
      )}
    </div>
  );
}
