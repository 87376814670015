export const EnableSubmissions1Body = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Start a RE:PPL
      </h1>
      <img
        src="/images/assets/tutorial/enable-submissions-1.png"
        alt="enable submissions 1"
        className="w-full"
      />
    </>
  );
};

export const EnableSubmissions1Footer = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Official vs Open Episodes
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        Official episodes are works curated by the editors, therefore your
        submission will be reviewed before being approved. Open episodes are
        free for all creators to leave their mark!
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('enableSubmissions2');
        }}
        className="px-9 py-2.5 mb-2 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Next
      </button>
      <h6 className="text-center text-blue-400 font-medium font-medium">
        1 / 2
      </h6>
    </>
  );
};
