import { Box, BoxProps, useEventListener, useTheme } from '@chakra-ui/react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Theme } from 'src/theme';

interface Props extends BoxProps {
  disabled?: boolean;
  maxLen?: number;
  singleLine?: boolean;
  singleLineTitle?: boolean;
}

export interface ContentEditableHandler {
  getText: () => string;
  focus: () => void;
  clear: () => void;
  getRef: () => HTMLElement;
}

export function usePlainTextPaste(el: HTMLElement, maxLen: number = 70) {
  useEventListener(
    // @ts-ignore
    'paste',
    (e) => {
      // cancel paste
      e.preventDefault();

      // get text representation of clipboard
      // @ts-ignore
      const text = (e.originalEvent || e).clipboardData.getData('text/plain');

      // insert text manually
      document.execCommand(
        'insertHTML',
        false,
        String(text).substring(0, maxLen)
      );
    },
    el
  );
}

export const ContentEditable = forwardRef<ContentEditableHandler, Props>(
  (
    {
      disabled,
      defaultValue = '',
      maxLen,
      singleLine,
      singleLineTitle,
      ...contentEditableProps
    },
    ref
  ) => {
    const theme = useTheme<Theme>();
    const [contentHTML, setContentHTML] = useState(defaultValue);
    const contentRef = useRef<HTMLDivElement>(null);
    const [, forceUpdate] = useReducer((s) => s + 1, 0);

    useEffect(() => {
      const html = contentRef.current;
      return () => {
        setContentHTML(html?.innerHTML || '');
      };
    }, [setContentHTML]);

    useImperativeHandle(ref, () => ({
      getText: () => {
        if (maxLen) {
          return contentRef.current?.textContent?.substring(0, maxLen);
        }
        return contentRef.current?.textContent;
      },
      focus: () => {
        contentRef.current?.focus();
      },
      clear: () => {
        setContentHTML('');
        if (contentRef.current) {
          contentRef.current.innerHTML = '';
        }
        forceUpdate();
      },
      getRef: () => contentRef.current,
    }));

    return (
      <>
        <Box
          className={`content-editable ${
            singleLineTitle ? 'hide-scrollbar title' : ''
          }`}
          contentEditable={!disabled}
          data-disabled={disabled}
          dangerouslySetInnerHTML={{ __html: contentHTML as string }}
          ref={contentRef as any}
          onKeyDown={(e) => {
            if (singleLine && e.key === 'Enter') {
              e.preventDefault();
            }
            if (maxLen) {
              if (e.currentTarget.textContent) {
                if (
                  e.currentTarget.textContent.length >= maxLen &&
                  e.key !== 'Backspace'
                ) {
                  e.preventDefault();
                }
              }
            }
          }}
          {...contentEditableProps}
        />
        <style jsx global>{`
          .content-editable[data-disabled] {
            overflow: hidden;
          }
          .content-editable[data-disabled][placeholder]:empty::before {
            content: '';
          }
          .content-editable:focus {
            outline: none;
          }
          .content-editable[placeholder]:empty::before {
            content: attr(placeholder);
            color: ${theme.colors.$mode['300']};
          }
          .content-editable:empty:focus::before {
            content: '';
          }
        `}</style>
      </>
    );
  }
);
