import { useCallback, useRef, useEffect } from 'react';
import { useWindowEventListener } from './use-window-event-listener';

/**
 * This hook listen the 'beforeunload' event and prompt if user is on creating or editing page
 */
export const useUnloadPrompt = (enabled: boolean, cb?: () => void) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = cb;
  });

  useWindowEventListener(
    'beforeunload',
    useCallback(
      (e) => {
        if (enabled) {
          if (
            savedCallback.current &&
            typeof savedCallback.current === 'function'
          ) {
            savedCallback.current();
          }
          e.preventDefault();

          // Chrome requires returnValue to be set
          // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
          e.returnValue = '';
        }
      },
      [enabled]
    )
  );
};
