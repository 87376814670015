import { Author } from 'src/app/article/types';
import { CacheKey } from 'src/constants/cache-key';
import { PageRequestQuery, RestPageResponse } from 'src/types';
import { axios } from 'src/lib/axios';
import { useInfiniteQuery } from 'react-query';
import { ArticlePostType, ArticleSource } from 'src/app/article/enum';

export type CreationType = 'STUDIO' | 'EPISODE' | 'REPPL' | 'ALL';

type CreationListRequest = {
  username: string;
  creationType: CreationType;
  params: PageRequestQuery & {
    projectID?: string;
    trait?: string;
    status?: string;
  };
};

export type ListType = {
  contentID: string;
  coverImgURL: string;
  title: string;
  description: string;
  enthuseCount?: number;
  tags?: string[];
  categories?: string[];
  createdAt?: Date;
  author?: Pick<
    Author,
    'username' | 'profileImgURL' | 'firstName' | 'lastName'
  >;
  // FIXME:
  owner?: Pick<Author, 'username' | 'profileImgURL' | 'firstName' | 'lastName'>;
  isEnthused?: boolean;
  pinned?: boolean;
  pick?: boolean;
  draft?: boolean;
  /** username or reppl id */
  parent?: string;
  articleType?: ArticleSource;
  areaID?: string; // COLLECTION only
  parentID?: string; // COLLECTION only
  source?: ArticleSource;
  sourceID?: string;
  postType?: ArticlePostType;
  official?: string;
  trendCount?: number;
};

export const fetchCreationList = ({
  username,
  creationType,
  params,
}: {
  username: string;
  creationType: CreationType;
  params: PageRequestQuery & {
    projectID?: string;
  };
}) =>
  axios
    .get<RestPageResponse<ListType[]>>(`creation/${username}/${creationType}`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => ({
      ...res.data,
      pageNum: params.pageNum,
    }));

export function useCreationListQuery(req: CreationListRequest) {
  return useInfiniteQuery(
    [CacheKey.creationList, req],
    ({ pageParam = 1 }) => {
      return fetchCreationList({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: !!req.username && !!req.creationType,
      getNextPageParam: ({ next, pageNum = 1 }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}
