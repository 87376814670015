import {
  IconButton,
  IconButtonProps,
  ButtonGroup,
  Text,
} from '@chakra-ui/react';
import { ComponentProps } from 'react';

import SvgEnthuse from 'src/components/icons/enthuse';
import SvgEnthused from 'src/components/icons/enthused';
import SvgEnthuseV1 from 'src/components/icons/enthuse-v1';
import SvgEnthuseV2 from 'src/components/icons/enthuse-v2';

import { formatNumber } from 'src/lib/formatNumber';

export interface EnthuseButtonProps
  extends Omit<IconButtonProps, 'aria-label'> {
  onEnthuse: () => void;
  isEnthuse?: boolean;
  likesCount?: number;
  fontSize?: string;
  minWidth?: string;
  svgClass?: string;
  textClass?: string;
  contentView?: boolean;
  isFeedPost?: boolean;
}

export function EnthuseButton({
  isEnthuse,
  onEnthuse,
  likesCount,
  fontSize = '20px',
  minWidth = '2.5rem',
  svgClass = '',
  textClass = '',
  contentView = false,
  isFeedPost = false,
  ...props
}: EnthuseButtonProps) {
  return (
    <ButtonGroup
      onClick={(e) => {
        e.preventDefault();
        onEnthuse();
      }}
      display={{ base: 'block', md: 'inline-flex' }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      verticalAlign="middle"
      spacing={0}
    >
      <div
        className={`justify-center flex items-center ${
          contentView && 'md:contents'
        }`}
      >
        <IconButton
          aria-label="enthuse"
          variant="ghost"
          color={{
            base: `${textClass ? 'gray.500' : 'blue.500'}`,
            md: 'blue.500',
          }}
          colorScheme="white"
          isRound
          minWidth={minWidth}
          icon={
            <EnthuseIcon
              isEnthuse={isEnthuse}
              fontSize={fontSize}
              svgClass={svgClass}
              isFeedPost={isFeedPost}
            />
          }
          {...props}
        />
        <Text
          className={`${textClass || 'ml-1'}`}
          fontWeight="500"
          lineHeight="20px"
          fontSize="14px"
          display="inline"
          color={`${isFeedPost ? 'white' : 'gray.500'}`}
        >
          {formatNumber({ number: likesCount })}
        </Text>
      </div>
    </ButtonGroup>
  );
}

export interface EnthuseIconProps extends ComponentProps<typeof SvgEnthuse> {
  isEnthuse?: boolean;
  fontSize?: string;
  svgClass?: string;
  isFeedPost?: boolean;
}

export function EnthuseIcon({
  isEnthuse,
  fontSize,
  svgClass,
  isFeedPost,
  ...props
}: EnthuseIconProps) {
  if (isFeedPost) {
    return isEnthuse ? (
      <SvgEnthused className={svgClass} fontSize={fontSize} {...props} />
    ) : (
      <SvgEnthuseV2 className={svgClass} fontSize={fontSize} {...props} />
    );
  }
  return isEnthuse ? (
    <SvgEnthused className={svgClass} fontSize={fontSize} {...props} />
  ) : (
    <SvgEnthuseV1 className={svgClass} fontSize={fontSize} {...props} />
  );
}
