import * as React from 'react';
import { Svg } from '../svg';

function SvgNotification({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 1.75c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v1.17C13.64 3.6 16 6.4 16 9.75v6l2 2v1H0v-1l2-2v-6C2 6.4 4.36 3.6 7.5 2.92V1.75zm1.5 3c2.76 0 5 2.24 5 5v7H4v-7c0-2.76 2.24-5 5-5zM7.01 19.76c0 1.1.89 1.99 1.99 1.99s1.99-.89 1.99-1.99H7.01z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgNotification);
export default ForwardRef;
