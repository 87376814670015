import React, { useEffect } from 'react';
import debounce from 'lodash.debounce';
import getConfig from 'next/config';
import nprogress from 'nprogress';
import { ChakraProvider } from '@chakra-ui/react';
import { Hydrate } from 'react-query/hydration';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RewriteFrames } from '@sentry/integrations';
import Router, { useRouter } from 'next/router';
import { Provider } from 'jotai';
import { useIOSResizeHeight } from 'src/hooks/use-ios-resize-height';
import * as Sentry from '@sentry/node';
import { Layout as DefaultLayout } from 'components/layout';
import 'tailwindcss/tailwind.css';
import { DefaultSEO } from 'src/app/metadata/default-seo';
import { NotificationContextProvider } from 'src/hooks/use-notification';
import { getLocalStorage } from 'src/heplers/localStorage';
import { switchThemeMode } from 'src/heplers/theme';
import * as ga from 'src/lib/ga';
import theme from '../theme';
import '../assets/css/globals.css';
import 'src/i18n';
import UserProvider from '../contexts/UserContext';
import ToastProvider from '../contexts/ToastContext';

// Only show nprogress after 500ms (slow loading)
const start = debounce(nprogress.start, 500);
const queryClient = new QueryClient();

// sentry
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          // eslint-disable-next-line no-param-reassign
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

function App({ Component, pageProps, err }) {
  const router = useRouter();
  const showFooter = !(
    router.route === '/[username]/[sid]/edit' || router.route === '/new'
  );

  const routeExcempts = [
    { route: '/reppl/[rid]/episode/new', title: 'Create A Studio - 1TM' },
    { route: '/agora/[tribe]' },
    { route: '/[username]/projects' },
    { route: '/[username]/collections' },
    { route: '/reppl/[rid]' },
    { route: '/reppl/[rid]/about' },
    { route: '/reppl/[rid]/collections' },
    { route: '/reppl/[rid]/[episodeType]' },
    { route: '/reppl/[rid]/submissions' },
  ];

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      const isRoute = routeExcempts.filter(
        (excempts) => excempts.route === Router.pathname
      );
      if (isRoute.length > 0) {
        const data = {
          title: isRoute[0].title || url,
          location: Router.pathname,
        };
        ga.pageviewtitle(data);
      } else {
        ga.pageview(url);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', () => {
      start.cancel();
      nprogress.done();
    });
    router.events.on('routeChangeError', () => {
      start.cancel();
      nprogress.done();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useIOSResizeHeight();

  const Layout = Component.Layout || DefaultLayout;
  const themeMode = getLocalStorage('theme') || 'light';
  switchThemeMode(themeMode);

  return (
    <Provider>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <Hydrate state={pageProps.dehydratedState}>
            <DefaultSEO />
            <UserProvider>
              <ToastProvider>
                <NotificationContextProvider>
                  <Layout
                    header={Component.Header}
                    footer={Component.Footer}
                    {...pageProps}
                    footerVisible={showFooter}
                  >
                    {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
                    <Component err={err} {...pageProps} />
                    <style jsx global>{`
                      body {
                        height: var(--app-height);
                      }
                    `}</style>
                  </Layout>
                </NotificationContextProvider>
              </ToastProvider>
            </UserProvider>
          </Hydrate>
        </QueryClientProvider>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
