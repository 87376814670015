// import { useColorMode } from '@chakra-ui/react';
import { useDisclosure, Box } from '@chakra-ui/react';
import { useState, useContext, useEffect } from 'react';
// import { switchThemeMode } from 'src/heplers/theme';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
// import SearchPanel from 'src/components/common/search/searchPanel';
import SearchPanelModal from 'src/components/common/search/search-panel-modal';
import { Transition } from 'react-transition-group';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
// import SearchProvider from 'src/contexts/search/SearchContext';
// import SuggestProvider from 'src/contexts/search/RecommendContext';
import SvgSearch from 'src/components/icons/search';
import SvgMenu from 'src/components/icons/menu';
// import SvgClose from 'src/components/icons/close';
import SvgNote from 'src/components/icons/note';
import SvgTip from 'src/components/icons/tip';
import SvgHeart from 'src/components/icons/heart';
import SvgCube from 'src/components/icons/cube';
import SvgPacman from 'src/components/icons/pacman';
import SvgPacmanBorder from 'src/components/icons/pacman-border';
import SvgFeather from 'src/components/icons/feather';

import SvgPenCircles from 'src/components/icons/pen-circles';
import SvgMarkbook from 'src/components/icons/markbook';
// import SvgCompass from 'src/components/icons/compass';
import SvgConcentricCircles from 'src/components/icons/concentric-circles';
import NavigationPanelMobile from 'src/components/mobile/navigationPanel';
import NavigationPanelDesktop from 'src/components/desktop/navigationPanel';
import TutorialChecklistModal from 'src/components/tutorial/tutorial-checklist-modal';
import { getTutorial } from 'src/app/explore/service';
import WarningModal from 'src/app/article/warning-modal';
import { useRouter } from 'next/router';
import SubmissionHistory from 'src/components/drafts-submissions/submission-history-modal';
import SignInModal from 'src/app/auth/sign-in/sign-in-modal';
import { getLocalStorage, removeLocalStorage } from 'src/heplers/localStorage';
import { useIsTablet, useIsMobile } from 'src/hooks/use-is-mobile';
import { isMobile } from 'react-device-detect';
import { Avatar } from '../avatar';
import Dropdowns from '../common/dropdowns/dropdowns';
import { NotificationDrawer } from '../notification/notification-drawer';
import PostingOptionModal from '../../app/studio/side-widget/posting-option-modal';
import SvgLogout from '../icons/logout';
import SvgLogoV1 from '../icons/logo-beta'; // '../icons/logo-v1'
import TutorialProgressButton from '../tutorial/tutorial-progress-button';

// interface SiteNavigationProps {
//   customNav?: ReactNode;
// }
// { customNav }: SiteNavigationProps
const SiteNavigation = () => {
  const isTablet = useIsTablet();
  const isMobileView = useIsMobile();
  const warningModal = useDisclosure();
  const localDraftModal = useDisclosure();
  const { user, isLogin, logout } = useContext(UserContext) as UserContextType;
  const router = useRouter();

  // const [navPanel, setNavPanel] = useState('');
  const [dispalyNavPanel, setDispalyNavPanel] = useState(false);
  const [isHeaderTransparent, setHeaderTransparent] = useState(true);
  const [localDraft, setLocalDraft] = useState<any>({});
  // const [searchPanel, setSearchPanel] = useState(false);
  const { t } = useTranslation();
  const [fillStudio, setFillStudio] = useState('white');
  const [currColor, setCurrColor] = useState('#000');

  // const closeSearchPanel = () => {
  //   setSearchPanel(false);
  // };

  // const searchPanelHandle = () => {
  //   document.body.style.overflow = !searchPanel ? 'hidden' : 'auto';
  //   setSearchPanel(!searchPanel);
  // };

  useEffect(() => {
    const clickHandle = () => {
      window.addEventListener(
        'scroll',
        () => {
          setNavStyle('scroll');
        },
        { passive: true }
      );
    };
    clickHandle();
    if (isLogin) {
      setLocalDraft(getLocalStorage('localPostDraft'));
      if (getLocalStorage('localPostDraft') && router.pathname === '/') {
        localDraftModal.onOpen();
      }
      setNavStyle('firstRender');
    }
    return () => clickHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  // const { toggleColorMode, colorMode } = useColorMode();

  // const themeModeDefault = getLocalStorage('theme');
  // const [themeMode, setThemeMode] = useState(themeModeDefault || 'light');
  // const handleThemeMode = () => {
  //   const selectThemeMode = themeMode === 'light' ? 'dark' : 'light';
  //   setLocalStorage('theme', selectThemeMode);
  //   switchThemeMode(selectThemeMode);
  //   setThemeMode(selectThemeMode);
  //   toggleColorMode();
  // };
  const [displayPanel, setDisplayPanel] = useState(false);

  const setNavStyle = (from, dispalyPanel = null) => {
    const nav = document.getElementById('nav');
    const isOffest = document.getElementsByClassName('div-offest').length > 0;
    const isInitial =
      document.getElementsByClassName('header-initial').length > 0;
    const isSticky = window.scrollY > 60;

    if (nav) {
      if (
        from === 'scroll' &&
        nav.style.backgroundColor === 'rgb(36, 37, 38)'
      ) {
        return;
      }
      let bgColor = '#242526';
      let color = '#fff';

      if (!dispalyPanel) {
        if (isInitial && !isSticky) {
          bgColor = 'initial';
          color = '#000';
          setHeaderTransparent(false);
        } else if (isSticky && !isInitial) {
          setFillStudio('#242526');
          bgColor = '#fff';
          color = '#000';
          setHeaderTransparent(false);
        } else if (isInitial && isSticky) {
          bgColor = '#fff';
          color = '#000';
          setHeaderTransparent(false);
        } else {
          bgColor = isOffest ? 'initial' : 'rgb(244, 247, 246)';
          color = isOffest ? '#fff' : '#000';
          setHeaderTransparent(isOffest);
          setFillStudio('white');
        }
      }
      nav.style.backgroundColor = bgColor;
      setCurrColor(color);
      nav.style.color = color;
    }
  };

  // useEffect(() => {
  //   const clickListener = (panelStatus) => {
  //     if (isMobile) return;
  //     document.addEventListener('click', (e) => {
  //       const clickNav = e['path'].some((path) => {
  //         if (!path['className'] || typeof path['className'] !== 'string')
  //           return false;
  //         return path['className'].includes('nav-panel');
  //       });
  //       let status = panelStatus;
  //       if (!clickNav) {
  //         setDispalyNavPanel(false);
  //         status = false;
  //       }
  //       setNavStyle('click', status);
  //     });
  //   };
  //   clickListener(dispalyNavPanel);
  //   return () => {
  //     clickListener(dispalyNavPanel);
  //   };
  // }, [dispalyNavPanel]);

  const siteItems = [
    {
      type: 'link', // 'click',
      path: '/',
      displayName: 'EXPLORE',
      icon: null,
      action: () => {}, // setNavPanel('COMMUNITY'),
    },
    {
      type: 'link',
      path: '/agora',
      displayName: 'UPDATES',
      icon: null,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
    {
      type: 'link',
      path: '/tribes',
      displayName: 'TOPICS',
      icon: null,
      action: () => {}, // setNavPanel('EXPLORE'),
    },
  ];
  const postingOptionModal = useDisclosure();
  const navItems = [
    {
      type: 'link',
      path: `/${user.username}`,
      displayName: t('My Studio'),
      icon: <SvgPacmanBorder className="inline text-xl" />,
      action: () => {},
    },
    {
      type: 'click',
      path: `/projects`,
      displayName: t('Create a Studio Post'),
      icon: <SvgNote className="inline text-xl" />,
      action: postingOptionModal.onOpen,
    },
    {
      type: 'link',
      path: `/${user.username}/drafts-submissions`,
      displayName: t('Drafts and Submissions'),
      icon: <SvgFeather className="inline text-xl" />,
      action: () => {},
    },
    {
      type: 'click',
      path: '',
      displayName: 'Log Out',
      icon: (
        <SvgLogout className="inline text-black dark:text-gray-500 text-xl" />
      ),
      action: logout,
    },
  ];

  const navItemsMobile = [
    {
      type: 'link',
      path: `/${user.username}`,
      displayName: t('My Studio'),
      icon: <SvgPacmanBorder className="inline text-xl" />,
      action: () => {},
    },
    {
      type: 'desktop-only',
      displayName: t('Create a Studio Post'),
      icon: <SvgFeather className="inline text-xl" />,
      action: () => {},
    },
    {
      type: 'desktop-only',
      displayName: t('Drafts & Submissions'),
      icon: <SvgFeather className="inline text-xl" />,
      action: () => {},
    },
    {
      type: 'click',
      displayName: 'Sign Out',
      icon: (
        <SvgLogout className="inline text-black dark:text-gray-500 text-xl" />
      ),
      action: logout,
    },
  ];

  const subItems = {
    EXPLORE: [
      // {
      //   link: '/explore',
      //   title: 'Explore',
      //   icon: <SvgCompass className="inline text-xl" />,
      //   describe:
      //     'Your one stop shop for all that is going on in 1TM.  Curated works and featured creators are shown here.',
      // },
      {
        link: '/creations',
        title: 'Creations',
        icon: <SvgTip className="inline text-xl" />,
        describe: 'Browse all the creative works from creators on 1TM',
      },
      {
        link: '/collections',
        title: 'Collections',
        icon: <SvgMarkbook className="inline text-xl" />,
        describe:
          'A collection is a curated gathering of creations by an individual, where their inspiration and imagination drive the topic.',
      },
      {
        link: '/creators',
        title: 'Creators',
        icon: <SvgPacman className="inline text-xl" />,
        describe: 'Meet all the best creators and talents on 1TM!',
      },
    ],
    REPPL: [
      {
        link: '/projects',
        title: 'Join a RE:PPL',
        icon: <SvgConcentricCircles className="inline text-xl" />,
        describe:
          "RE:PPL's are community based collectives that consists of episodes from groups of creators, the Relay.",
      },
      // {
      //   link: '/projects',
      //   title: 'Official RE:PPLs',
      //   icon: (
      //     <span style={{ color: '#E4B202' }}>
      //       <SvgConcentricCircles className="inline text-xl" />
      //     </span>
      //   ),
      //   describe: 'Browse all the RE:PPLs started by 1TM editing team.',
      // },
      {
        link: '/reppl/start',
        title: 'Start a RE:PPL',
        icon: <SvgPenCircles className="inline text-xl" />,
        describe: 'Create your own RE:PPL here now!',
      },
    ],
    COMMUNITY: [
      {
        link: '/agora',
        title: 'Agora',
        icon: <SvgHeart className="inline text-xl" />,
        describe:
          'Agora is a place to showcase all the related things you might like.',
      },
      {
        link: '/tribes/category',
        title: 'Tribe',
        icon: <SvgCube className="inline text-xl" />,
        describe:
          'A Tribe is a curated gathering of creations by an individual, where their inspiration and imagination drive the topic.',
      },
    ],
  };

  const handleMenuClick = () => {
    setNavStyle('firstRender');
    setDispalyNavPanel(false);
  };

  const tutorialChecklistModal = useDisclosure();

  const [tutorialData, setTutorialData] = useState([]);

  useEffect(() => {
    const getTutorialData = async () => {
      if (isLogin) {
        const data = await getTutorial();
        setTutorialData(data);
      }
    };

    getTutorialData();
  }, [tutorialChecklistModal.isOpen]);

  let progress = 0;

  tutorialData.forEach((step) => {
    if (step.step !== 'whats_agora') {
      progress += 12.5;
    }
  });

  // const panelHandle = (type) => {
  //   const flag = type === navPanel ? !dispalyNavPanel : true;
  //   setNavPanel(type);
  //   setDispalyNavPanel(flag);
  // };

  useEffect(() => {
    if (isMobile) {
      if (
        router.pathname === '/reppl/[rid]/team' ||
        router.pathname === '/reppl/[rid]/submissions' ||
        router.pathname === '/search/[squery]' ||
        router.pathname === '/[username]/notification' ||
        router.pathname === '/following' ||
        router.pathname === '/new'
      ) {
        warningModal.onOpen();
      }
    }
    if (router.query.tutorialStep && !isMobile) {
      tutorialChecklistModal.onOpen();
    }
  }, [router]);

  const searchModal = useDisclosure();

  const signInModal = useDisclosure();

  useEffect(() => {
    if (router.query.page) {
      if (router.query.page === 'signIn') {
        signInModal.onOpen();
      }
    }
  }, [router.query]);

  const greyOrWhite = () => {
    if (currColor === '#fff') {
      return 'text-white';
    }
    return 'text-gray-900';
  };

  if (!isLogin) {
    return (
      <>
        <Box
          id="nav"
          className={`sticky top-0 relative h-15 w-full md:text-center font-semibold ${
            (isMobileView || isTablet) && 'flex justify-between items-center'
          }`}
        >
          {isTablet && !searchModal.isOpen && (
            <a href="/" className="">
              <SvgLogoV1
                alt="1TM Logo"
                className="inline"
                height="60px"
                width="85px"
                ml="15px"
                fill={router?.query?.username && fillStudio}
              />
            </a>
          )}
          {!isTablet && !searchModal.isOpen && (
            <Box className="absolute left-6" alignItems="center" display="flex">
              {!searchModal.isOpen && (
                <a href="/">
                  <SvgLogoV1
                    alt="1TM Logo"
                    className="inline"
                    height="60px"
                    width="85px"
                    ml="15px"
                    fill={router?.query?.username && fillStudio}
                  />
                </a>
              )}
              <Box display="inline" ml="46px">
                {siteItems.map((item, key) => {
                  const mkey = `m-${key}`;
                  return (
                    <Link href={`${item.path}`}>
                      <div key={mkey} className="relative inline">
                        <span
                          aria-hidden="true"
                          onClick={() => {
                            // panelHandle(item['displayName']);
                          }}
                          key={mkey}
                          className={`${greyOrWhite()} text-sm font-bold cursor-pointer mr-11 nav-panel`}
                        >
                          {t(item['displayName'])}
                        </span>
                      </div>
                    </Link>
                  );
                })}
              </Box>
            </Box>
          )}

          {!searchModal.isOpen && (
            <SignInModal
              modal={{
                ...signInModal,
                onClose: () => {
                  router.push({ query: { ...router.query, page: '' } });
                  signInModal.onClose();
                },
              }}
            />
          )}

          {!isTablet && !searchModal.isOpen && (
            <div className="absolute right-4 md:right-10 top-3 flex items-center">
              {/* eslint-disable  */}
              <div aria-hidden="true" className="cursor-pointer">
                {!searchModal.isOpen && router.pathname !== '/search' && (
                  <div onClick={searchModal.onOpen}>
                    <SvgSearch
                      fontSize="24px"
                      className={`${isMobile && 'hidden'} text-lg inline mr-3`}
                    />
                  </div>
                )}
              </div>
              {/* eslint-enable */}
              <button type="button" onClick={signInModal.onOpen}>
                Sign In
              </button>
              <a
                href="/activate?noCode=true"
                className="ml-6 bg-denim-500 text-white px-8 py-2 rounded-full"
              >
                Get Started
              </a>
            </div>
          )}
          <SearchPanelModal modal={searchModal} />

          <div
            className="md:hidden cursor-pointer mr-4"
            aria-hidden="true"
            onClick={() => setDisplayPanel(!displayPanel)}
          >
            <SvgMenu className="text-2xl inline mt-2" />
          </div>

          <WarningModal
            {...warningModal}
            title={t('Oops!')}
            text={t(
              'This page is not mobile ready yet, please view it on the desktop. We’re working hard to develop it, please bear with us. '
            )}
            warningType="warning3"
            noCancel
            fullScreenOnMobile
            onOk={router.back}
            okBtnText={t('Bring me back')}
            onOkBtnColor="#0278E4"
          />
        </Box>
        <Transition in={displayPanel} timeout={500} unmountOnExit>
          {(state) => (
            <div className={`fade-default fade-${state}`}>
              <NavigationPanelMobile
                siteItems={[...siteItems]}
                navItems={navItemsMobile}
                siteSubItems={subItems}
                setDisplayPanel={setDisplayPanel}
                isLoggedIn={isLogin}
              />
            </div>
          )}
        </Transition>
      </>
    );
  }

  return (
    <>
      {!displayPanel && (
        <Box
          id="nav"
          className="sticky top-0 relative h-15 w-full md:text-center font-semibold text-white z-50"
        >
          {isTablet && !searchModal.isOpen && (
            <a href="/" className="">
              <SvgLogoV1
                alt="1TM Logo"
                className="inline"
                height="60px"
                width="85px"
                ml="15px"
                fill={router?.query?.username && fillStudio}
              />
            </a>
          )}
          {!isTablet && !searchModal.isOpen && (
            <Box className="absolute left-6" alignItems="center" display="flex">
              {!searchModal.isOpen && (
                <a href="/" className="">
                  <SvgLogoV1
                    alt="1TM Logo"
                    className="inline"
                    height="60px"
                    width="85px"
                    ml="15px"
                    fill={router?.query?.username && fillStudio}
                  />
                </a>
              )}
              <Box display="inline" ml="46px">
                {siteItems.map((item, key) => {
                  const mkey = `m-${key}`;
                  return (
                    <Link href={`${item.path}`}>
                      <div key={mkey} className="relative inline">
                        <span
                          aria-hidden="true"
                          onClick={() => {
                            // panelHandle(item['displayName']);
                          }}
                          key={mkey}
                          className={`${greyOrWhite()} text-sm font-bold cursor-pointer mr-11 nav-panel`}
                        >
                          {t(item['displayName'])}
                        </span>
                      </div>
                    </Link>
                  );
                })}
              </Box>
            </Box>
          )}

          <div className="absolute right-4 md:right-10 top-3 flex items-center">
            {/* <div
            aria-hidden="true"
            onClick={() => searchPanelHandle()}
            className="cursor-pointer"
          >
            {searchPanel && <SvgClose className="text-lg inline mt-2" />}
            {!searchPanel && <SvgSearch className="text-2xl inline mt-2" />}
          </div> */}
            {!isTablet && (
              <>
                <TutorialChecklistModal
                  username={user.firstName || user?.username}
                  user={user?.username}
                  modal={{ ...tutorialChecklistModal }}
                  tutorialStep={router.query.tutorialStep}
                />
                {progress !== 100 && !searchModal.isOpen && (
                  <div className="">
                    <TutorialProgressButton
                      headerTransparent={isHeaderTransparent}
                      onClick={() => {
                        tutorialChecklistModal.onOpen();
                      }}
                      progress={progress}
                      isChecked={tutorialChecklistModal.isOpen}
                    />
                  </div>
                )}
              </>
            )}
            {/* eslint-disable  */}
            <div aria-hidden="true" className="cursor-pointer">
              {!searchModal.isOpen && router.pathname !== '/search' && (
                <div onClick={searchModal.onOpen} className="md:ml-5">
                  <SvgSearch
                    fontSize="24px"
                    className={`${isMobile && 'hidden'} text-lg inline`}
                  />
                </div>
              )}
            </div>
            {/* eslint-enable */}
            <SearchPanelModal modal={searchModal} />
            {!isTablet && (
              <>
                {!searchModal.isOpen && (
                  <div className="md:ml-8">
                    <NotificationDrawer loginUser={user?.username} />
                  </div>
                )}
                <div className="md:ml-12">
                  <Dropdowns
                    items={[navItems]}
                    width="19rem"
                    className="nav"
                    isClicked={() => handleMenuClick()}
                    buttonComponent={
                      <Avatar
                        name={user ? user?.username : ''}
                        data-testid={user ? user?.avatar : ''}
                        width={40}
                        height={40}
                        src={user ? user?.avatar : ''}
                        mr={1}
                        author={user}
                      />
                    }
                  />
                </div>
              </>
            )}
            {/* mobile */}
            <div
              className="md:hidden cursor-pointer ml-4"
              aria-hidden="true"
              onClick={() => setDisplayPanel(!displayPanel)}
            >
              <SvgMenu className="text-2xl inline mt-2" />
            </div>
          </div>
        </Box>
      )}

      {/* <Transition in={searchPanel} timeout={500} unmountOnExit>
        {(state) => (
          <div className={`fade-default fade-${state}`}>
            <SuggestProvider>
              <SearchProvider>
                <SearchPanel closePanel={closeSearchPanel} />
              </SearchProvider>
            </SuggestProvider>
          </div>
        )}
      </Transition> */}
      <Transition in={dispalyNavPanel} timeout={500} unmountOnExit>
        {(state) => (
          <NavigationPanelDesktop
            items={subItems}
            type="REPPL"
            className={`nav-panel fade-default fade-${state}`}
          />
        )}
      </Transition>

      <Transition in={displayPanel} timeout={500} unmountOnExit>
        {(state) => (
          <div className={`fade-default fade-${state}`}>
            <NavigationPanelMobile
              siteItems={[
                // {
                //   type: 'link',
                //   path: `/`,
                //   displayName: 'HOME',
                //   icon: null,
                //   action: () => {},
                // },
                ...siteItems,
                {
                  type: 'link',
                  path: `/${user.username}/notification`,
                  displayName: 'NOTIFICATIONS',
                  icon: null,
                  action: () => {},
                },
              ]}
              navItems={navItemsMobile}
              siteSubItems={subItems}
              setDisplayPanel={setDisplayPanel}
              isLoggedIn={isLogin}
            />
          </div>
        )}
      </Transition>
      <PostingOptionModal modal={postingOptionModal} />
      {router.query?.submissionHistoryModal === 'open' && (
        <SubmissionHistory id={`${router.query.episode}`} />
      )}
      <WarningModal
        {...warningModal}
        title={t('Oops!')}
        text={t(
          'This page is not mobile ready yet, please view it on the desktop. We’re working hard to develop it, please bear with us. '
        )}
        warningType="warning3"
        noCancel
        fullScreenOnMobile
        onOk={router.back}
        okBtnText={t('Bring me back')}
        onOkBtnColor="#0278E4"
      />
      <WarningModal
        {...localDraftModal}
        title={t('Warning!')}
        text={t('You have unsaved changes. Do you want to continue editing ?')}
        onOk={() => {
          if (JSON.parse(localDraft).postID) {
            router.push(`
              /${JSON.parse(localDraft).username}/${
              JSON.parse(localDraft).postID
            }/edit`);
          } else {
            router.push(`/new`);
          }
        }}
        okBtnText={t('Continue')}
        cancelBtnText={t('Discard')}
        onDecline={() => removeLocalStorage('localPostDraft')}
      />
    </>
  );
};

export default SiteNavigation;
