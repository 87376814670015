import * as React from 'react';
import { Svg } from '../svg';

function SvgSignInLogo({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="url(#sign-in-logo_svg__pattern0)" d="M0 0h80v80H0z" />
      <defs>
        <pattern
          id="sign-in-logo_svg__pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use xlinkHref="#sign-in-logo_svg__image0" transform="scale(.0125)" />
        </pattern>
        <image
          id="sign-in-logo_svg__image0"
          width={80}
          height={80}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAADtklEQVR4Ae2cTWsTURSGu3GpP8CV/8Gtf8FlMil+LPzaieKm4EbBRSbVqrWrUhRRESwoaAWhWrUzCa22llpaxQQNxtpFbGtEqo0hXJnRM5Rpzs2ZTJLSzBsYTnPu7STz5HlvshhuVxceIBB5AgfOT+4yTPuUkbIKhmmpWkfkIdUCkEi+2BNPjZuGaX+vBW1jr9b/b8vexotq5t89N2fVk9mi8j+a+RqSc7X8Q5G8Cemc7l5bXRh+pzLZkiqUqu4RGYB0wfVqfqWiHrxaUicHp7y17VBfRvU/zqm5xV8eODoPB5DGW1XpQ2+bgfUuJFssqzvWF3V8YNIDd2xgQg09/aQ+FNc3gaPzRR7gTGHNtevgpbQH7vTQG9fC/GqFBRd5gJncD2Xef68SvbYH7uztOfeLgeBIamQNpDWk+6Ltgnyd/1nXtlpAOYB0/mZV/2vTebdsDaQ3sLD0uyFwdEGRB0ggGq0cwFtTZVXrGMtVVJCDPmj/+6P+lhvof2NBnwPg/x/EQcHRfAAEQP0qQGsFGUOV69O4tMJAGAgD9QRCjnJR5frS6NI8RBgR1ivKmcb1ySxphYEwEAbqCYQc5aLK9aXRpXmIMCKsV5QzjeuTWdIKA2EgDNQTCDnKRZXrS6NL8xBhRFivKGca1yezpBUGwkAYqCcQcpSLKteXRpfmIcKIsF5RzjSuT2ZJKwyEge01sO9hVl15lPVuB4GBAQ10brY8fDkDgI2ugQ48ACxVvXsCpV8WNM+J79URRLhhgASSKtbAgGsggaMKgADY3p8xZB5VGAgDYaCeQMhR7vce16doSisijAjrFeVM4/pS82geDISBMFBPIOQoF1WuT9GUVkQYEdYrypnG9aXm0TwYCANhoJ5AyFEuqlyfoimtiDAirFeUM43rS82jeTAQBsJAPYGQo1xUuT5FU1oRYURYryhnGteXmkfzYCAMhIF6AiFHuahyfYqmtCLCiLBeUc40ri81j+bBQBgIA/UEQo5yUeX6FE1pjUyECZi/3hjLq+cLKyr3rezdtiuF58zzP/znb/XzkH7V/3fpBSRStrvxrLOr5b3MV+VsyPhZsD52PEAOcSI1ujtu2vvdjbVTVtowrXU/7KPXJtS5u/PuRrTP5pfVx+U/myzlAHKv27H9E4PTO2JJe++/Ld7tYcO0in6gzn6rZ67PuJvWOlslv11c8/Pzbh3uWFBBLsyx1Ei+jMWT4/2GaU0bplX1Q+WeB3mdyMw9kkrvjJn2vnjS7oklrRHDtFa3G8C/zeCk/4jKwO0AAAAASUVORK5CYII="
        />
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgSignInLogo);
export default ForwardRef;
