import * as React from 'react';
import { Svg } from '../svg';

function SvgArrow({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M6.667 7.834H5.333A6 6 0 00.021 11.04a6.667 6.667 0 016.645-7.207V.168l7 5.667-7 5.666V7.834zM5.333 6.5H8v2.206l3.547-2.872L8 2.962v2.205H6.667a5.321 5.321 0 00-4.038 1.849A7.325 7.325 0 015.333 6.5z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrow);
export default ForwardRef;
