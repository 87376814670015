import algoliasearch from 'algoliasearch/lite';
import is from 'is_js';
import categories from 'src/configs/categories';

const client = algoliasearch(
  process.env.ALGOLIA_SEARCH_KEY,
  process.env.ALGOLIA_SEARCH_SECRET
);

const suggestionClient = algoliasearch(
  process.env.ALGOLIA_SEARCH_KEY,
  process.env.ALGOLIA_SUGGESTION_SECRET
);

const searchIndex = client.initIndex(process.env.ALGOLIA_SEARCH_INDEX);
const suggestionIndex = suggestionClient.initIndex(
  process.env.ALGOLIA_SEARCH_SUGGESTION_INDEX
);

const isCategory = (data) => {
  const fetched = categories.filter((cat) => cat.value === data);
  return fetched.length > 0;
};

const search = async (
  keyword: string,
  hitsPerPage: number = 1000,
  filters: string = '',
  facet: string = '',
  maxValuesPerFacet: number = 5
) => {
  const options = {
    hitsPerPage,
  };
  if (facet === 'facetFilters') {
    if (isCategory(keyword)) {
      options['facetFilters'] = [[`categories:${keyword}`, `tags:${keyword}`]];
    } else {
      options['facetFilters'] = [`tags:${keyword}`];
    }
  }
  if (filters !== '') {
    options['filters'] = filters;
  }
  if (facet !== '' && facet !== 'facetFilters') {
    options['facets'] = facet;
    options['maxValuesPerFacet'] = maxValuesPerFacet;
  }
  let searchKeyword = keyword;
  if (facet === 'facetFilters') {
    searchKeyword = '';
  }
  return searchIndex.search(searchKeyword, options).then(({ hits, facets }) => {
    if (facets) {
      if (is.object(facets.tags)) {
        const newFacets = [];
        Object.keys(facets.tags).forEach(function (key, index) {
          newFacets[index] = { title: key, posts: facets.tags[key] };
        });
        return newFacets;
      }
      if (is.object(facets.categories)) {
        const newFacets = [];
        Object.keys(facets.categories).forEach(function (key, index) {
          newFacets[index] = { title: key, posts: facets.categories[key] };
        });
        return newFacets;
      }
    } else {
      return is.array(hits) ? hits : [];
    }
  });
};
const suggestions = async (keyword: string, limit = 30) => {
  return suggestionIndex
    .search(keyword, {
      hitsPerPage: limit,
    })
    .then(({ hits }) => {
      if (is.array(hits) && is.not.empty(hits)) {
        hits.sort((a, b) => (a['popularity'] >= b['popularity'] ? -1 : 1));
        return hits.map((hit) => hit['query']);
      }
      return [];
    });
};
const algolia = {
  search,
  suggestions,
};
export default algolia;
