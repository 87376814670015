import type EditorJS from '@editorjs/editorjs';
import type { EditorConfig } from '@editorjs/editorjs/types';
import { useEffect, useRef, useState } from 'react';
import { ContentEditableHandler } from 'src/components/content-editable';
import { isBrowser, NEXT_PUBLIC_UNSPLASH_ACCESS_KEY } from 'src/constants/env';
import { importAllEditorTools } from 'src/lib/editor/editor-tool';
import { logger } from 'src/lib/logger';
import {
  ImgUploadAreaType,
  ImgUploadType,
  postUploadImage,
} from 'src/service/image';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

function uploadImage(
  file: File,
  uniqueId: string,
  areaType?: ImgUploadAreaType,
  imgType?: ImgUploadType
) {
  const formData = new FormData();

  formData.append('file', file);

  return postUploadImage({
    data: formData,
    id: uniqueId,
    areaType,
    imgType,
  });
}
type UseEditorProps = {
  editorId?: string;
  allow?: string[];
  uniqueId: string;
  imageUpLoader?: (
    file: File
  ) => Promise<{ success: number; file: { url: string } }>;
} & Omit<EditorConfig, 'readOnly'> &
  (
    | {
        readOnly?: boolean;
        imgType?: ImgUploadType;
        areaType?: ImgUploadAreaType;
      }
    | {
        readOnly: true;
        imgType?: ImgUploadType;
        areaType?: ImgUploadAreaType;
      }
  );

declare global {
  interface Window {
    // for better debugging
    __editor?: EditorJS;
  }
}

function whiteListDefaultEditorConfigTool(
  config: EditorConfig,
  allow?: string[],
  router?: any
) {
  if (!allow) {
    if (router.pathname === '/agora') {
      // eslint-disable-next-line no-param-reassign
      delete config.tools.gallery;

      // eslint-disable-next-line no-param-reassign
      delete config.tools.warning;
    }
    return config;
  }
  const whiteListConfig: EditorConfig = {
    ...config,
    tools: {},
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const tool of allow) {
    const toolName = tool;
    whiteListConfig.tools[toolName] = config.tools[toolName];
  }

  return whiteListConfig;
}

const EDITOR_ID = 'editor';
export function useEditor({
  editorId,
  uniqueId,
  readOnly,
  allow,
  areaType,
  imgType,
  ...editorConfig
}: UseEditorProps) {
  const router = useRouter();
  const titleRef = useRef<ContentEditableHandler>(null);
  const editorRef = useRef<EditorJS>(null);
  const [isReady, setIsReady] = useState(false);
  const browser = isBrowser();

  const { t } = useTranslation();
  const write = t('Write something...');

  useEffect(() => {
    if (!browser) {
      return;
    }
    importAllEditorTools().then((tools) => {
      if (!tools) {
        return;
      }
      const DEFAULT_EDITOR_CONFIG: EditorConfig = {
        placeholder: write,
        i18n: {
          messages: {
            toolNames: {
              Warning: 'Highlight',
            },
          },
        },
        tools: {
          mention: {
            class: tools.Mention,
          },
          header: {
            class: tools.Header,
            config: {
              placeholder: 'Headline',
              levels: [2, 3, 4],
              defaultLevel: 3,
            },
            inlineToolbar: true,
          },
          paragraph: {
            class: tools.Paragraph,
            inlineToolbar: allow ? ['mention', 'link'] : true,
          },
          list: {
            class: tools.List,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered',
            },
          },
          gallery: {
            class: tools.Gallery,
            config: {
              unsplash: {
                appName: '1tm-demo',
                clientId: NEXT_PUBLIC_UNSPLASH_ACCESS_KEY,
              },
              buttonContent: '🖼 Select an image',
              uploader: {
                uploadByFile(file) {
                  return uploadImage(file, uniqueId, areaType, imgType)
                    .then((response) => {
                      if (response.code === 1000) {
                        return {
                          success: 1,
                          file: {
                            url: response.imgURL,
                          },
                        };
                      }
                    })
                    .catch((err) => {
                      logger.error(err);
                    });
                },
              },
            },
          },
          embedLink: {
            class: tools.EmbedLink,
            config: {
              endpoint: '/api/fetch-url', // Your backend endpoint for url data fetching
              services: {
                youtube: true,
                codepen: true,
                imgur: true,
                gfycat: true,
                vimeo: true,
                twitter: true,
                instagram: true,
                spotify: true,
              },
            },
          },
          warning: {
            class: tools.Warning,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+W',
            config: {
              titlePlaceholder: 'Write a title here',
              messagePlaceholder: 'Write something...',
            },
          },
          code: tools.CodeTool,
          underline: {
            class: tools.UnderLine,
            shortcut: 'CMD+U',
            inlineToolbar: true,
          },
          marker: {
            class: tools.Marker,
            inlineToolbar: true,
          },
          quote: {
            class: tools.Quote,
            inlineToolbar: true,
          },
          inlineCode: {
            class: tools.InlineCode,
          },
          delimiter: tools.Delimiter,
        },
      };

      (async () => {
        if (!isReady && !editorRef.current && uniqueId) {
          const editor = new tools.EditorJS({
            onReady: () => {
              new tools.Undo({ editor });
            },
            ...whiteListDefaultEditorConfigTool(
              DEFAULT_EDITOR_CONFIG,
              allow,
              router
            ),
            holder: editorId || EDITOR_ID,
            ...editorConfig,
            readOnly,
          });
          editorRef.current = editor;
          // eslint-disable-next-line no-underscore-dangle
          window.__editor = editor;
          try {
            if (editorRef.current.isReady instanceof Promise) {
              await editorRef.current.isReady;
              setIsReady(true);
            }
          } catch (err) {
            logger.error(err);
          }
        }
      })();
    });

    return () => {
      if (editorRef.current && isReady) {
        try {
          editorRef.current.destroy?.();
          editorRef.current = null;
          // eslint-disable-next-line no-underscore-dangle
          window.__editor = null;
          setIsReady(false);
        } catch (err) {
          logger.error(err);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId, isReady, readOnly]);

  return {
    titleRef,
    editor: editorRef.current,
    isReady,
  };
}
