import * as React from 'react';
import { Svg } from '../svg';

function SvgTip({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M5.31 14H8.69c.11-1.002.62-1.828 1.45-2.73.094-.102.693-.723.764-.812a4.999 4.999 0 10-7.808-.001c.072.089.672.71.765.811.83.904 1.34 1.73 1.45 2.732zm3.357 1.667H5.333v.833h3.334v-.833zM1.795 11.5a6.667 6.667 0 1110.408.002c-.516.643-1.87 1.665-1.87 2.915V16.5a1.667 1.667 0 01-1.666 1.667H5.333A1.666 1.666 0 013.667 16.5v-2.083c0-1.25-1.355-2.272-1.872-2.917zm6.038-4.163h2.084l-3.75 5V9.003H4.083L7.833 4v3.337z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTip);
export default ForwardRef;
