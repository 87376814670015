import * as React from 'react';
import { Svg } from '../svg';

function SvgDiscussion({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M4.546 12.5L.834 15.417V2.5a.833.833 0 01.833-.833h12.5a.833.833 0 01.834.833v10H4.546zm-.576-1.666h9.364v-7.5H2.501v8.654l1.469-1.154zm2.697 3.333h8.531l1.47 1.154V6.667h.833a.833.833 0 01.833.833v11.25l-3.713-2.916h-7.12A.833.833 0 016.667 15v-.833z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgDiscussion);
export default ForwardRef;
