import React from 'react';
import SvgLogo from 'src/components/icons/logo-beta'; // 'src/components/icons/logo'
import SvgLogo2 from 'src/components/icons/logo-2';

export interface FormLayoutProps {
  /** for meta title */
  title: string;
  children: React.ReactNode;
}

function FormLayoutV2({ children }: FormLayoutProps) {
  return (
    <div
      className="w-full h-screen"
      style={{
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: 'auto 1fr auto',
        background: 'url(/images/assets/login/signInBG.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="bg-gray-100 sm:bg-transparent px-9 py-5 sm:py-1.5 flex justify-center sm:justify-start">
        <div className="hidden sm:block">
          <SvgLogo
            alt="1TM Logo"
            className="text-6xl"
            color="#E4EEFF"
            height="60px"
            width="85px"
          />
        </div>
        <div className="block sm:hidden relative">
          <SvgLogo2
            alt="1TM Logo"
            className="text-xl text-black"
            color="#E4EEFF"
          />
          <div
            style={{ fontSize: '8px', top: '-3px', left: '23px' }}
            className="absolute font-semibold"
          >
            BETA
          </div>
        </div>
      </div>
      <div>
        <div className="w-full h-full flex justify-center items-center px-0 sm:px-9 bg-gray-100 sm:bg-transparent">
          {children}
        </div>
      </div>
      <div className="flex items-center flex-col text-xs sm:text-sm pb-6 sm:pb-10 pt-5 px-6 sm:px-9 bg-gray-100 sm:bg-transparent text-gray-600 sm:text-gray-300">
        <p className="mb-1.5 text-center">
          Connecting Creativity and People, Making Ideas Happen!
        </p>
        <p>Copyright @ 1TM</p>
      </div>
    </div>
  );
}

export default FormLayoutV2;
