import {
  Box,
  Center,
  CenterProps,
  CircularProgress,
  CloseButton,
  Image,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { DropzoneState } from 'react-dropzone';
import { LIMIT_UPLOAD_IMAGE_FILE_ACCEPT } from 'src/constants/limit';
import SvgClose from 'src/components/icons/close-button';

export interface UploaderProps {
  dropzone: DropzoneState;
  src?: string;
  enable?: boolean;
  uploading?: boolean;
  onDelete?: () => void;
  children: ReactNode;
  overlay?: ReactNode;
  isGeneral?: boolean;
  radius?: string;
}

export function ImageUploader({
  dropzone,
  children,
  onDelete,
  src,
  uploading = false,
  enable = true,
  overlay,
  isGeneral = false,
  radius,
  ...props
}: UploaderProps & CenterProps) {
  return (
    <div {...dropzone.getRootProps()}>
      <Box
        position="relative"
        sx={{
          '&:hover > button': {
            opacity: 1,
          },
        }}
        {...props}
      >
        <Center
          height="100%"
          width="100%"
          position="relative"
          flexDir="column"
          cursor={enable && uploading ? 'not-allowed' : 'default'}
        >
          <input
            {...dropzone.getInputProps({
              accept: LIMIT_UPLOAD_IMAGE_FILE_ACCEPT,
            })}
            data-testid="hidden-upload-input"
          />
          {!src && children}
          {src && (
            <Image
              w="100%"
              height="100%"
              src={src}
              borderRadius={radius}
              objectFit="cover"
            />
          )}
          {overlay && (
            <Center
              pos="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              className="image-upload-cover-overlay"
              opacity={0}
              _hover={{
                opacity: 1,
              }}
            >
              {overlay}
            </Center>
          )}
          {uploading && (
            <CircularProgress position="absolute" size="24px" isIndeterminate />
          )}
        </Center>
        {src && onDelete && !isGeneral && (
          <CloseButton
            onClick={onDelete}
            opacity={0}
            position="absolute"
            top={0}
            right={0}
          />
        )}
        {src && onDelete && isGeneral && (
          <div
            className="absolute top-1 right-1 rounded-full bg-white h-5 w-5 cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={onDelete}
            onKeyDown={onDelete}
          >
            <SvgClose fontSize="20px" fontWeight="800" margin="0" />
          </div>
        )}
      </Box>
    </div>
  );
}
