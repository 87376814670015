import {
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';

import SvgSearch from 'src/components/icons/search';
import SvgClearCircle from 'src/components/icons/clear-circle';

interface SearchInputProps extends InputProps {
  clear?: () => void;
  wrapperProps?: InputGroupProps;
}

export function SearchInput({
  wrapperProps,
  clear,
  ...props
}: SearchInputProps) {
  return (
    <InputGroup variant="flushed" {...wrapperProps}>
      <InputLeftElement>
        <SvgSearch fontSize="24px" color="$mode.900" />
      </InputLeftElement>

      <Input _focus={{ borderColor: 'black' }} {...props} />

      {!!props.value && clear && (
        <InputRightElement onClick={clear}>
          <SvgClearCircle fontSize="20px" color="$mode.400" />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
