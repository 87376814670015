import {
  Box,
  Button,
  Heading,
  Stack,
  StackProps,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { Author } from 'src/app/article/types';
import { useFollowUserMutation } from 'src/app/profile/use-follow-user.mutation';
import useProfileQuery from 'src/app/profile/use-profile-query';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { useFollow, useRepplQuery } from 'src/app/reppl/hooks';
import { Avatar } from 'src/components/avatar';
import { NextChakraLinkBox } from 'src/components/next-chakra-link';
import { CacheKey } from 'src/constants/cache-key';
import { formatNumber } from 'src/lib/formatNumber';
import { logger } from 'src/lib/logger';
import { useTranslation } from 'react-i18next';
import SvgEllipse from 'src/components/icons/ellipse';

export function Publisher({
  author,
  isMe = false,
  ...props
}: StackProps & { contentID?: string; isMe?: boolean; author?: Author }) {
  const { mutateAsync: followUser } = useFollowUserMutation([CacheKey.article]);
  const { data: profile, refetch } = useProfileQuery(author?.username);
  let followers: number | null = profile?.numOfFollowers;
  let creations: number | null = null;
  const isFollowing = !!(author?.isFollowed || profile?.isFollowed);

  if (author && 'count' in author) {
    creations = author.count.episode + author.count.studio;
    followers = followers || author.count.follower;
  }

  return (
    <PublishInfo
      aria-label="publisher-info"
      avatar={author?.profileImgURL}
      followerCount={followers}
      creationCount={creations}
      href={`/${author?.username}`}
      username={author?.username}
      about={author?.about}
      name={getProfileFullNameOrUsername(profile || author)}
      isFollowing={isFollowing}
      showFollowButton={!isMe}
      onFollowClick={async () => {
        try {
          await followUser({
            targetID: author?.userID,
            group: 'USER',
            follow: !author?.isFollowed,
          });
          refetch();
        } catch (err) {
          logger.error(err);
        }
      }}
      {...props}
      type="Publisher"
    />
  );
}

export function PublishIn({ reppl }: { reppl: any }) {
  const rid = reppl;
  const { data: repplData } = useRepplQuery(rid);
  const { mutateAsync: follow } = useFollow(rid);
  const { user } = useContext(UserContext) as UserContextType;

  if (!repplData?.reppl) {
    return null;
  }

  const isMe = repplData.creator.userID === user?.id;

  return (
    <PublishInfo
      aria-label="publish-in-info"
      avatar={repplData.reppl.avatarImgURL}
      about={repplData.reppl.summary}
      heading="Published in RE:PPL"
      followerCount={repplData.reppl.followerCount}
      creationCount={repplData.reppl.episodeCount}
      name={repplData.reppl.title}
      href={`/reppl/${repplData.reppl.id}`}
      isFollowing={repplData.reppl.isFollowed}
      onFollowClick={() => {
        follow({
          targetID: repplData.reppl.id,
          group: 'REPPL',
          follow: !repplData.reppl.isFollowed,
        });
      }}
      showFollowButton={!isMe}
      type="PublishIn"
    />
  );
}

interface Props {
  followerCount?: number;
  creationCount?: number;
  href?: string;
  heading?: string;
  about?: string;
  avatar?: string;
  username?: string;
  name?: string;
  isFollowing?: boolean;
  onFollowClick?: () => void;
  type?: string;
}

export function PublishInfo({
  heading = 'PUBLISHER',
  href,
  followerCount,
  creationCount,
  about,
  avatar,
  name,
  username,
  isFollowing,
  showFollowButton = true,
  onFollowClick,
  type,
  ...props
}: StackProps & Props & { showFollowButton?: boolean }) {
  const { t } = useTranslation();
  return (
    <>
      {type === 'Publisher' && (
        <Stack as="section" role="region" m="auto" spacing={0} {...props}>
          <Heading
            textStyle="h6"
            color="$mode.400"
            pb="24px"
            textTransform="uppercase"
            textAlign={{
              base: 'left',
              md: 'left',
            }}
          >
            {t(heading)}
          </Heading>

          <Stack direction="column" spacing={3} alignItems="center">
            <NextChakraLinkBox
              textAlign="center"
              href={href}
              _hover={{ cursor: 'pointer' }}
            >
              <Avatar
                src={avatar}
                name={username || name}
                width={56}
                height={56}
                wrapperProps={{
                  m: 'auto',
                }}
              />
              <Text textStyle="h5" pt="16px" textAlign="center" noOfLines={2}>
                {name}
              </Text>
            </NextChakraLinkBox>
            <StatGroup>
              {!Number.isNaN(followerCount) && (
                <Stat whiteSpace="nowrap">
                  <StatNumber textStyle="body0" display="inline-block" mr={1}>
                    {formatNumber({ number: followerCount })}
                  </StatNumber>
                  <StatLabel textStyle="body0" display="inline-block">
                    {t('Followers')}
                  </StatLabel>
                </Stat>
              )}
              {!Number.isNaN(followerCount) && !Number.isNaN(creationCount) && (
                <Box as="span" mx="12px">
                  |
                </Box>
              )}
              {!Number.isNaN(creationCount) && (
                <Stat whiteSpace="nowrap">
                  <StatNumber textStyle="body0" display="inline-block" mr={1}>
                    {formatNumber({ number: creationCount })}
                  </StatNumber>
                  <StatLabel textStyle="body0" display="inline-block">
                    {t('Creations')}
                  </StatLabel>
                </Stat>
              )}
            </StatGroup>
            <Text textStyle="body3" textAlign="center" color="$mode.500">
              {about}
            </Text>
            {showFollowButton && (
              <Stack direction={{ base: 'column', md: 'row' }} spacing={3}>
                <Button
                  onClick={onFollowClick}
                  size="sm"
                  borderRadius="2px"
                  fontWeight="bold"
                >
                  {isFollowing ? t('Following') : t('Follow')}
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
      {type === 'PublishIn' && (
        <Stack as="section" role="region" m="auto" spacing={0} {...props}>
          <h1 className="text-xs font-bold text-gray-400 mb-3">{heading}</h1>

          <Stack direction="column" spacing={3}>
            <NextChakraLinkBox
              textAlign="center"
              href={href}
              _hover={{ cursor: 'pointer' }}
              mb={2}
            >
              <div className="flex items-center">
                <Avatar
                  src={avatar}
                  name={username || name}
                  width={40}
                  height={40}
                  wrapperProps={{
                    m: 'auto',
                  }}
                />
                <p className="text-sm font-bold text-gray-900 ml-2 text-left">
                  {name}
                </p>
              </div>
            </NextChakraLinkBox>
            <p className="text-xs mt-0 text-gray-900 margin-top-0 mb-2">
              {about}
            </p>
            <div className="flex items-center text-xs text-gray-500 margin-top-0 mb-2">
              {!Number.isNaN(followerCount) && (
                <span>
                  {formatNumber({ number: followerCount })} {t('Followers')}
                </span>
              )}
              {!Number.isNaN(followerCount) && !Number.isNaN(creationCount) && (
                <SvgEllipse fontSize="3px" color="gray.300" mx="6px" />
              )}
              {!Number.isNaN(creationCount) && (
                <span>
                  {formatNumber({ number: creationCount })} {t('Creations')}
                </span>
              )}
            </div>

            {showFollowButton && (
              <Stack
                direction={{ base: 'column', md: 'row' }}
                className="margin-top-0"
                spacing={3}
              >
                <Button
                  onClick={onFollowClick}
                  size="sm"
                  borderRadius="2px"
                  fontWeight="bold"
                  variant="outline"
                  borderColor="#D3D6D8"
                  color="blue.500"
                >
                  {isFollowing ? t('Following') : t('Follow')}
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
      <style jsx global>{`
        .margin-top-0 {
          margin-top: 0 !important;
        }
      `}</style>
    </>
  );
}
