export const CreationsStartBody = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 text-center mb-6">
        Create with 1TM
      </h1>
      <img
        src="/images/assets/tutorial/creations-start.png"
        alt="creations start"
        className="w-full"
      />
    </>
  );
};

export const CreationsStartFooter = ({
  setNextModal,
}: {
  setNextModal?: any;
}) => {
  return (
    <>
      <h5 className="text-gray-900 font-medium text-center mb-3">
        Create a Post
      </h5>
      <p
        className="text-sm font-normal text-gray-700 text-center mb-6"
        style={{ maxWidth: '608px' }}
      >
        The post editor is built to encompass all aspects of storytelling: text,
        photos, videos, embedded code, and much more. Tell stories, express
        thoughts, and share ideas freely without limitations with 1TM’s
        intuitive and easy-to-use creation features.
      </p>
      <button
        type="button"
        onClick={() => {
          setNextModal('creations1');
        }}
        className="px-9 py-2.5 mb-6 rounded-3xl bg-blue-500 font-semibold text-white"
      >
        Start
      </button>
    </>
  );
};
