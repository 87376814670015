import * as React from 'react';
import { Svg } from '../svg';

function SvgMessage2({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M18.333 10h-5l-1.666 2.5H8.333L6.667 10h-5"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.542 4.258L1.667 10v5a1.667 1.667 0 001.666 1.667h13.334A1.667 1.667 0 0018.333 15v-5l-2.875-5.742a1.665 1.665 0 00-1.491-.925H6.033a1.667 1.667 0 00-1.491.925v0z"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMessage2);
export default ForwardRef;
