import * as React from 'react';
import { Svg } from '../svg';

function SvgIcSuccess({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M62.17 8.75a2.411 2.411 0 00-3.41-.005l-28.937 28.86-10.44-11.338a2.413 2.413 0 00-3.55 3.267l12.141 13.185a2.41 2.41 0 003.477.075L62.165 12.16c.944-.94.946-2.469.005-3.412z"
        fill="#2BC3A8"
      />
      <path
        d="M61.588 29.588A2.412 2.412 0 0059.176 32c0 14.985-12.19 27.176-27.176 27.176-14.985 0-27.176-12.19-27.176-27.176C4.824 17.015 17.015 4.824 32 4.824A2.412 2.412 0 1032 0C14.355 0 0 14.355 0 32c0 17.644 14.355 32 32 32 17.644 0 32-14.356 32-32a2.412 2.412 0 00-2.412-2.412z"
        fill="#2BC3A8"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgIcSuccess);
export default ForwardRef;
