import { useMutation, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { CacheKey } from 'src/constants/cache-key';
import { axios } from 'src/lib/axios';
import { logger } from 'src/lib/logger';
import { RestResponse } from 'src/types';
import { useContext } from 'react';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { standaloneToast } from 'src/components/toast';
import { CommentAction } from '../enum';
import { Comment, FullArticle } from '../types';

interface CommentRequest {
  objectId?: string;
  objectType?: CommentAction;
  parentId?: string;
  content: string;
  assets?: any; // TODO: images or embed objects
  commentID?: any;
  Assets?: any;
}

export function useArticleCommentMutation(id: string) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { isLogin: isAuth } = useContext(UserContext) as UserContextType;

  return useMutation(
    (values: CommentRequest) => {
      if (!isAuth) {
        router.push({ query: { ...router.query, page: 'signIn' } });
        return;
      }
      return axios
        .post<RestResponse<Comment>>('/comment', values)
        .then((res) => res.data);
    },
    {
      onSuccess: (response) => {
        if (response?.code === 1000) {
          const updater = (prevCache: FullArticle) => {
            if (response.data.parentID === '') {
              if (prevCache) {
                return {
                  ...prevCache,
                  commentCount: prevCache?.commentCount + 1,
                  comments: [...prevCache.comments, response.data],
                };
              }
              return {};
            }

            if (response.data.parentID !== '') {
              const nextComments = prevCache?.comments.map((comment) => {
                if (comment.id === response.data.parentID) {
                  // eslint-disable-next-line no-param-reassign
                  comment.child = [...comment.child, response.data];
                }
                return comment;
              });

              return {
                ...prevCache,
                commentCount: prevCache?.commentCount + 1,
                comments: nextComments,
              };
            }
          };

          queryClient.setQueryData([CacheKey.article, id], updater);
        }
      },
      onError: (err) => {
        logger.error(err);
      },
    }
  );
}

export function useArticleEditCommentMutation(id: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (values: CommentRequest) => {
      return axios
        .put<RestResponse<Comment>>('/comment', values)
        .then((res) => {
          if (res.data.code === 1000) {
            const updater = (prevCache: FullArticle) => {
              if (prevCache) {
                const updatedData = prevCache.comments.map((comment) => {
                  if (comment.id === values.commentID) {
                    return { ...comment, content: values.content };
                  }
                  if (
                    comment.id === values.parentId &&
                    values.parentId !== values.commentID
                  ) {
                    const updatetSubcomment = comment.child.map(
                      (subcomment) => {
                        if (subcomment.id === values.commentID) {
                          return { ...subcomment, content: values.content };
                        }
                        return subcomment;
                      }
                    );
                    return { ...comment, child: updatetSubcomment };
                  }
                  return comment;
                });

                return {
                  ...prevCache,
                  comments: updatedData,
                };
              }
              return {};
            };

            queryClient.setQueryData([CacheKey.article, id], updater);
          }
        });
    },
    {
      onError: (err) => {
        logger.error(err);
      },
    }
  );
}

export function useGeneralCommentMutation() {
  return useMutation(
    (values: CommentRequest) => {
      return axios
        .post<RestResponse<Comment>>('/comment', values)
        .then((res) => res.data);
    },
    {
      onError: (err) => {
        logger.error(err);
      },
    }
  );
}

export function deleteComment({ commentId }: { commentId: string }) {
  return axios
    .delete<RestResponse>(`/comment/${commentId}`)
    .then((res) => res.data);
}

export function useDeleteCommentMutation() {
  return useMutation(deleteComment, {
    onSuccess: () => {
      standaloneToast({
        title: 'Comment deleted',
      });
    },
    onError: (err) => {
      logger.error(err);
    },
  });
}
