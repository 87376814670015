import SvgCcBy from 'src/components/icons/cc-by';
import SvgCcCc from 'src/components/icons/cc-cc';
import SvgCcNc from 'src/components/icons/cc-nc';
import SvgCcNd from 'src/components/icons/cc-nd';
import SvgCcSa from 'src/components/icons/cc-sa';

export type RepplType = 'SOLO' | 'TEAM' | 'OPEN';

export type Options = 'yes' | 'no' | 'others';

export type Access = 'PUBLIC' | 'PAID';

/**
 * Schedule Alias.
 *
 * W = Week
 * BW = BiWeek
 * M = Month
 * Q = Quarter
 * Y = Year
 */
export type Schedule = 'W' | 'BW' | 'M' | 'Q' | 'Y';

export const LICENSE_FEATURES = {
  'Attribution 4.0 International': {
    icons: [SvgCcCc, SvgCcBy],
    license: 'Attribution 4.0 International',
    url: 'http://creativecommons.org/licenses/by/4.0/',
    adaptations: 'yes',
    commercial: 'yes',
  },
  'Attribution-NonCommercial 4.0 International': {
    icons: [SvgCcCc, SvgCcBy, SvgCcNc],
    license: 'Attribution-NonCommercial 4.0 International',
    url: 'http://creativecommons.org/licenses/by-nc/4.0/',
    adaptations: 'yes',
    commercial: 'no',
  },
  'Attribution-NoDerivatives 4.0 International': {
    icons: [SvgCcCc, SvgCcBy, SvgCcNd],
    license: 'Attribution-NoDerivatives 4.0 International',
    url: 'http://creativecommons.org/licenses/by-nd/4.0/',
    adaptations: 'no',
    commercial: 'yes',
  },
  'Attribution-NonCommercial-NoDerivatives 4.0 International': {
    icons: [SvgCcCc, SvgCcBy, SvgCcNc, SvgCcNd],
    license: 'Attribution-NonCommercial-NoDerivatives 4.0 International',
    url: 'http://creativecommons.org/licenses/by-nc-nd/4.0/',
    adaptations: 'no',
    commercial: 'no',
  },
  'Attribution-ShareAlike 4.0 International': {
    icons: [SvgCcCc, SvgCcBy, SvgCcSa],
    license: 'Attribution-ShareAlike 4.0 International',
    url: 'http://creativecommons.org/licenses/by-sa/4.0/',
    adaptations: 'others',
    commercial: 'yes',
  },
  'Attribution-NonCommercial-ShareAlike 4.0 International': {
    icons: [SvgCcCc, SvgCcBy, SvgCcNc, SvgCcSa],
    license: 'Attribution-NonCommercial-ShareAlike 4.0 International',
    url: 'http://creativecommons.org/licenses/by-nc-sa/4.0/',
    adaptations: 'others',
    commercial: 'no',
  },
} as const;
