import * as React from 'react';
import { Svg } from '../svg';

function SvgCcCc({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.178.084c3.356 0 6.214 1.172 8.571 3.515a11.542 11.542 0 012.572 3.868c.585 1.45.879 2.989.879 4.617 0 1.643-.29 3.183-.868 4.618a11.015 11.015 0 01-2.56 3.803 12.331 12.331 0 01-3.986 2.658 11.946 11.946 0 01-4.608.921c-1.585 0-3.103-.303-4.553-.91a12.07 12.07 0 01-3.9-2.636 11.953 11.953 0 01-2.625-3.89c-.6-1.442-.9-2.963-.9-4.564 0-1.585.303-3.11.91-4.575a12.05 12.05 0 012.647-3.932C6.042 1.249 8.849.084 12.177.084zm.043 2.165c-2.743 0-5.05.957-6.922 2.871a10.317 10.317 0 00-2.175 3.225 9.452 9.452 0 00-.76 3.74c0 1.286.253 2.525.76 3.717A9.941 9.941 0 005.3 18.996a9.89 9.89 0 003.194 2.143 9.619 9.619 0 003.728.74c1.286 0 2.532-.25 3.74-.75a10.365 10.365 0 003.268-2.165c1.87-1.828 2.806-4.121 2.806-6.879a9.852 9.852 0 00-.729-3.771 9.595 9.595 0 00-2.12-3.172c-1.93-1.929-4.25-2.893-6.965-2.893zm-.15 7.843l-1.608.836c-.171-.357-.382-.607-.632-.75-.25-.143-.482-.214-.696-.214-1.071 0-1.608.707-1.608 2.121 0 .643.136 1.157.407 1.543.272.385.672.579 1.2.579.7 0 1.194-.344 1.48-1.03l1.478.75a3.525 3.525 0 01-3.15 1.887c-1.072 0-1.936-.328-2.593-.986-.657-.657-.986-1.571-.986-2.742 0-1.143.332-2.05.997-2.722.664-.671 1.503-1.007 2.518-1.007 1.486 0 2.55.578 3.193 1.735zm6.921 0l-1.585.836c-.172-.357-.383-.607-.633-.75a1.457 1.457 0 00-.718-.214c-1.07 0-1.607.707-1.607 2.121 0 .643.136 1.157.407 1.543.271.385.67.579 1.2.579.7 0 1.193-.344 1.478-1.03l1.5.75a3.684 3.684 0 01-1.328 1.383 3.463 3.463 0 01-1.82.504c-1.087 0-1.954-.328-2.604-.986-.65-.657-.976-1.571-.976-2.742 0-1.143.332-2.05.997-2.722.664-.671 1.503-1.007 2.518-1.007 1.485 0 2.543.578 3.171 1.735z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCcCc);
export default ForwardRef;
