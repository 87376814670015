import * as React from 'react';
import { Svg } from '../svg';

function SvgPen({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.985 6.457l-.942-.942-6.21 6.209v.943h.943l6.21-6.21zm.943-.942l.943-.943-.943-.943-.943.943.943.943zM5.328 14H2.5V11.17l8.957-8.956a.667.667 0 01.942 0L14.285 4.1a.667.667 0 010 .942L5.33 14h-.001z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPen);
export default ForwardRef;
