import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  Textarea,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { atom, useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useEffect, useReducer, useContext } from 'react';
import { Controller, useForm, UseFormMethods } from 'react-hook-form';
import type { CollectionDetailResponse } from 'src/app/label/types';
import {
  FORM_ERROR_MSG_MAX,
  FORM_ERROR_MSG_REQUIRED,
} from 'src/constants/valid';
import { logger } from 'src/lib/logger';

import { UserContext, UserContextType } from 'src/contexts/UserContext';

import SvgClose from 'src/components/icons/close';
import { isMobile } from 'react-device-detect';
import { useCreateLabelMutation, useUpdateLabelMutation } from '../hooks';
import { AreaType } from '../services';

interface LabelSettingModalProps {
  title?: string;
  areaID?: string;
  data?: CollectionDetailResponse;
  modal: UseDisclosureReturn;
  areaType: AreaType;
  redirectAfterCreate?: boolean;
}

type FormValues = {
  name: string;
  description: string;
  visible: boolean;
  coverImgURL?: string;
};

type LabelImageSetting = 'setting' | 'unsplash' | 'cropper';

const stepAtom = atom<LabelImageSetting>('setting');

export function LabelMutationModal({
  modal,
  areaType,
  areaID,
  redirectAfterCreate = false,
  ...props
}: LabelSettingModalProps) {
  const [step, setStep] = useAtom(stepAtom);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const methods = useForm<FormValues>({
    defaultValues: {
      name: props.data?.name,
      visible:
        typeof props.data?.visible === 'boolean' ? props.data.visible : true,
      description: props.data?.description,
    },
    mode: 'onChange',
  });

  // reset step before unmount
  useEffect(() => {
    return () => {
      setStep('setting');
    };
  }, [setStep]);

  useEffect(() => {
    if (step === 'cropper') {
      // hack way to trigger dimension check
      requestAnimationFrame(() => {
        forceUpdate();
      });
    }
  }, [step]);

  return (
    <Modal isCentered scrollBehavior="inside" autoFocus={false} {...modal}>
      <ModalOverlay>
        <ModalContent
          maxW="100%"
          w="640px"
          minH={{
            base: '100vh',
            md: 'auto',
          }}
          justifyContent="space-between"
        >
          <Box
            px={isMobile && '6.6%'}
            className="relative bg-gray-100 py-10 px-6 text-gray-900 "
          >
            <Text
              fontSize={isMobile ? 'md' : 'lg'}
              fontWeight={isMobile ? '600' : 'bold'}
            >
              {props.data ? 'Settings' : 'New collection'}
            </Text>
            <Text className="text-sm mt-2">Setup your collection here</Text>
            <span
              aria-hidden="true"
              onClick={modal.onClose}
              className="absolute right-6 top-6"
            >
              <SvgClose
                alt="close"
                className="cursor-pointer dark:text-gray-500 text-lg"
              />
            </span>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            flex={1}
            overflow="hidden"
          >
            <SettingForm
              redirectAfterCreate={redirectAfterCreate}
              id={props.data?.id}
              areaID={areaID}
              methods={methods}
              areaType={areaType}
              onClose={modal.onClose}
            />
          </Stack>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

function SettingForm(props: {
  areaID?: string;
  areaType: AreaType;
  methods: UseFormMethods<FormValues>;
  onClose: () => void;
  id?: number;
  previousCoverImageURL?: string;
  redirectAfterCreate: boolean;
}) {
  const { user } = useContext(UserContext) as UserContextType;
  const { methods, areaID, areaType, id } = props;
  const { control, errors, register, watch } = methods;

  const router = useRouter();

  const {
    isLoading: isCreating,
    mutateAsync: createLabel,
  } = useCreateLabelMutation();
  const {
    mutateAsync: updateLabel,
    isLoading: isUpdating,
  } = useUpdateLabelMutation();
  const {
    mutateAsync: updateLabelSilence,
    isLoading: isUpdating2,
  } = useUpdateLabelMutation(false);

  return (
    <Flex
      flex={1}
      flexDir="column"
      justify="space-between"
      as="form"
      overflow="hidden"
      onSubmit={methods.handleSubmit(async (values) => {
        try {
          if (!id) {
            // create
            const createResult = await createLabel({
              areaID,
              areaType,
              ...values,
            });
            if (createResult.data) {
              await updateLabelSilence({
                id: createResult.data.id,
                ...values,
              });

              if (props.redirectAfterCreate) {
                if (areaType === 'REPPL') {
                  router.push(
                    `/reppl/${areaID}/collections/${createResult.data.uuid}`
                  );
                }

                if (areaType === 'STUDIO') {
                  router.push(
                    `/${user.username}/collections/${createResult.data.uuid}`
                  );
                }
              }
            }
          } else {
            // update
            updateLabel({
              id,
              ...values,
            });
          }
          props.onClose();
        } catch (error) {
          logger.error(error);
        }
      })}
    >
      <Box
        pl={{
          base: '6.6%',
          sm: '40px',
        }}
        py="24px"
        overflowY="scroll"
        mt={{ base: '0', md: '2' }}
      >
        <FormControl isInvalid={!!errors.name}>
          <FormLabel mb={isMobile && '4'}>
            <Flex
              display={{ base: 'block', md: 'flex' }}
              alignItems="center"
              justifyContent="space-between"
              pr={{
                base: '6.6%',
                sm: '40px',
              }}
            >
              <Text
                mb={{ base: '2', md: '0' }}
                fontWeight={isMobile && '500'}
                lineHeight={isMobile && '20px'}
                width={194}
              >
                * Title
              </Text>
              <div className="relative flex-1">
                <Input
                  name="name"
                  placeholder="Name your collection"
                  _placeholder={{ fontSize: { base: 'sm', md: 'md' } }}
                  ref={register({
                    maxLength: {
                      value: 30,
                      message: FORM_ERROR_MSG_MAX,
                    },
                    required: FORM_ERROR_MSG_REQUIRED,
                  })}
                  maxLength={30}
                />
                <Text
                  textStyle="labelLight"
                  color="$mode.500"
                  className="absolute right-4 bottom-3"
                >
                  {30 - watch('name')?.length}
                </Text>
              </div>
            </Flex>
          </FormLabel>
          {isMobile && <Box h="4" borderTop="1px solid #E6E8E9" />}
        </FormControl>
        <FormControl isInvalid={!!errors.description}>
          <FormLabel mb={isMobile && '0'} mt={{ base: '0', md: '5' }}>
            <Flex
              display={{ base: 'block', md: 'flex' }}
              fontWeight={isMobile && '500'}
              lineHeight={isMobile && '20px'}
              alignItems="center"
              justifyContent="space-between"
              pr={{
                base: '6.6%',
                sm: '40px',
              }}
            >
              <Text mb={{ base: '2', md: '0' }} width={194}>
                Description
              </Text>
              <div className="relative flex-1">
                <Textarea
                  placeholder="Briefly introduce your collection"
                  _placeholder={{ fontSize: { base: 'sm', md: 'md' } }}
                  rows={2}
                  borderRadius="4px"
                  name="description"
                  ref={register({
                    maxLength: {
                      value: 200,
                      message: FORM_ERROR_MSG_MAX,
                    },
                  })}
                  maxLength={200}
                />
                <Text
                  textStyle="labelLight"
                  color="$mode.500"
                  className="absolute right-4 bottom-4"
                >
                  {200 - watch('description')?.length}
                </Text>
              </div>
            </Flex>
          </FormLabel>
          {isMobile && <Box h="4" borderBottom="1px solid #E6E8E9" />}
        </FormControl>
        <Flex
          mt={{ base: '24px', md: '32px' }}
          mb="10px"
          justifyContent="space-between"
          pr={{
            base: '6.6%',
            sm: '40px',
          }}
        >
          {isMobile ? (
            <Box lineHeight="20px" fontSize="sm" mr="3">
              <Text fontWeight="500" mb="2" color="gray.600">
                Keep this collection private?
              </Text>
              <Text color="$mode.500">
                Private collection is only visible to users with you and your
                editing team.
              </Text>
            </Box>
          ) : (
            <Heading textStyle="h5" as="h5" color="$mode.500">
              Keep this collection private?
            </Heading>
          )}

          <Controller
            control={control}
            name="visible"
            render={({ value, onChange }) => (
              <Switch
                name="visible"
                isChecked={!value}
                onChange={(e) => onChange(!e.target.checked)}
              />
            )}
          />
        </Flex>
        {!isMobile && (
          <Text color="$mode.500">
            Private collection is only visible to users with you and your
            editing team.
          </Text>
        )}
      </Box>
      <ButtonGroup
        spacing={2}
        p={{ base: '3', md: '4' }}
        display="flex"
        justifyContent="flex-end"
        borderTopColor="gray.200"
        borderTopWidth="1px"
      >
        <Button
          rounded="ellipse"
          variant="ghost"
          colorScheme="gray"
          onClick={props.onClose}
          className={isMobile && 'button-collections_mobile'}
          pr="12px"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          rounded="ellipse"
          className={isMobile && 'button-collections_mobile'}
          isLoading={isCreating || isUpdating || isUpdating2}
        >
          Create
        </Button>
        <style jsx global>{`
          .button-collections_mobile {
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            height: 32px;
            padding: 7px 28px;
            weight: 104px;
          }
        `}</style>
      </ButtonGroup>
    </Flex>
  );
}
