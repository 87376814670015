import * as React from 'react';
import { Svg } from '../svg';

function SvgLogo2White({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M14.903 0v31.594H5.907V6.905H0V0h14.903zM26.82 16.88c-1.454 0-2.68-.481-3.68-1.444s-1.5-2.182-1.5-3.656c0-1.505.485-2.723 1.454-3.656 1-.933 2.242-1.399 3.726-1.399 1.515 0 2.757.466 3.726 1.4.97.932 1.454 2.15 1.454 3.655 0 1.474-.5 2.693-1.5 3.656-.969.963-2.195 1.444-3.68 1.444zm0 15.12c-1.454 0-2.68-.481-3.68-1.444s-1.5-2.182-1.5-3.656c0-1.505.485-2.723 1.454-3.656 1-.933 2.242-1.4 3.726-1.4 1.515 0 2.757.467 3.726 1.4.97.933 1.454 2.151 1.454 3.656 0 1.474-.5 2.693-1.5 3.656-.969.963-2.195 1.444-3.68 1.444z"
        fill="#fff"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLogo2White);
export default ForwardRef;
