import * as React from 'react';
import { Svg } from '../svg';

function SvgEmailSent({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#email-sent_svg__clip0)">
        <path
          d="M58 8H6c-3.309 0-6 2.691-6 6v36c0 3.309 2.691 6 6 6h52c3.309 0 6-2.691 6-6V14c0-3.309-2.691-6-6-6zm0 4c.272 0 .53.056.767.155L32 35.353 5.233 12.155C5.47 12.056 5.728 12 6 12h52zm0 40H6c-1.103 0-2-.897-2-2V16.38l26.69 23.132a1.998 1.998 0 002.62 0L60 16.38V50c0 1.103-.897 2-2 2z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="email-sent_svg__clip0">
          <path fill="#fff" d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEmailSent);
export default ForwardRef;
