import * as React from 'react';
import { Svg } from '../svg';

function SvgPenCircles({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M15.026 1.132L7.69 8.466l.008 4.247 4.238-.007 7.331-7.332A9.956 9.956 0 0120.4 10c0 5.523-4.477 10-10 10S.4 15.523.4 10s4.477-10 10-10a9.96 9.96 0 014.626 1.132zm3.86-1.031l1.413 1.414-9.192 9.192-1.412.003-.002-1.417L18.885.1l.001.001z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPenCircles);
export default ForwardRef;
