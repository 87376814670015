import { CircularProgress } from '@chakra-ui/react';

const TutorialProgressButton = ({
  headerTransparent,
  isChecked = false,
  progress,
  onClick,
}: {
  headerTransparent?: boolean;
  isChecked?: boolean;
  progress: number;
  onClick: any;
}) => {
  /* eslint-disable */
    const progressColor = progress < 25 ? 'red.400' : (progress < 60 ? 'orange.400' : 'green.400');
    /* eslint-enable */
  return (
    <button
      type="button"
      style={{ borderRadius: '20px' }}
      className={`py-2.5 px-4 h-auto flex items-center ${
        isChecked ? 'bg-gray-100' : 'bg-transparent'
      }`}
      onClick={async () => {
        await onClick();
      }}
    >
      <CircularProgress
        value={progress}
        color={progressColor}
        size="18px"
        thickness="15px"
        trackColor="gray.400"
        marginRight={2}
      />
      <span
        className={`text-xs font-medium ${
          headerTransparent ? 'text-white' : 'text-gray-900'
        }`}
      >
        {progress}%
      </span>
    </button>
  );
};

export default TutorialProgressButton;
