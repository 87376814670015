import * as React from 'react';
import { Svg } from '../svg';

function SvgCheckedCircle({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.667 10.001c0-4.6 3.733-8.333 8.333-8.333s8.333 3.733 8.333 8.333S14.6 18.335 10 18.335A8.336 8.336 0 011.667 10zm6.666 1.809l5.492-5.492L15 7.5l-6.667 6.667L5 10.835 6.175 9.66l2.158 2.15z"
        fill="#2BC3A8"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCheckedCircle);
export default ForwardRef;
