import * as React from 'react';
import { Svg } from '../svg';

function SvgDiscord({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M0 10C0 4.476 4.476 0 10 0s10 4.476 10 10-4.476 10-10 10c-5.524-.012-10-4.487-10-10z"
        fill="currentColor"
      />
      <path
        d="M14.499 5.932a10.63 10.63 0 00-2.658-.815c-.116.21-.244.477-.337.699a9.621 9.621 0 00-2.95 0 6.518 6.518 0 00-.349-.7c-.92.152-1.818.432-2.657.828-1.679 2.506-2.133 4.942-1.912 7.354A10.49 10.49 0 006.9 14.942c.268-.362.5-.735.699-1.13a6.3 6.3 0 01-1.096-.525c.094-.07.175-.14.269-.21 2.062.979 4.463.979 6.526 0 .094.07.175.14.268.21a6.41 6.41 0 01-1.107.524c.198.396.431.77.7 1.13a10.618 10.618 0 003.263-1.643c.256-2.797-.466-5.221-1.923-7.366zm-6.62 5.875c-.641 0-1.154-.583-1.154-1.294 0-.711.513-1.306 1.154-1.306.652 0 1.165.583 1.165 1.306-.011.71-.513 1.294-1.165 1.294zm4.289 0c-.641 0-1.154-.583-1.154-1.294 0-.711.513-1.306 1.154-1.306.652 0 1.165.583 1.154 1.306-.012.723-.513 1.294-1.154 1.294z"
        fill="#242526"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgDiscord);
export default ForwardRef;
