import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  Slider,
  SliderThumb,
  SliderTrack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { forwardRef, useRef, useState, CSSProperties } from 'react';
import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { isFileTypeNotResizable } from 'src/utils/file-type';

import SvgZoomIn from 'src/components/icons/zoom-in';
import SvgZoomOut from 'src/components/icons/zoom-out';
import { ConditionalWrap, ConditionalWrapProps } from '../conditional-wrap';

interface ImageCropProps extends AvatarEditorProps {
  initialScale?: number;
  wrap?: ConditionalWrapProps['wrap'];
  editorStyles?: CSSProperties;
  title?: string;
}

export const ImageCrop = forwardRef<AvatarEditor, ImageCropProps>(
  ({ initialScale = 2, wrap, title, editorStyles, ...props }, ref) => {
    const [scale, setScale] = useState(initialScale);
    const isMobile = useIsMobile();
    const positionDragImage = {
      top: 0,
      bottom: 0,
    };

    const [isActivePostCardArea, setIsActivePostCardArea] = useState(true);
    const [isActiveDetailViewArea, setIsActiveDetailViewArea] = useState(false);

    function handlePostCardBtnClick() {
      setIsActivePostCardArea(true);
      setIsActiveDetailViewArea(false);
    }

    function handleDetailAreaBtnClick() {
      setIsActivePostCardArea(false);
      setIsActiveDetailViewArea(true);
    }

    return (
      <>
        <Box
          w="100%"
          borderRadius="4px"
          overflow="hidden"
          mt="22px"
          pos="relative"
        >
          <ConditionalWrap condition={!!wrap} wrap={wrap}>
            <>
              {isActiveDetailViewArea && (
                <>
                  <div
                    className="flex flex-col absolute h-40 w-full top-0"
                    style={{
                      background: 'rgba(0, 0, 0, 0.7)',
                      borderBottom: '2px dashed #FFFFFF',
                    }}
                  >
                    {' '}
                  </div>
                  <div
                    className="flex flex-col absolute h-40 w-full bottom-0"
                    style={{
                      background: 'rgba(0, 0, 0, 0.7)',
                      borderTop: '2px dashed #FFFFFF',
                    }}
                  >
                    {' '}
                  </div>
                </>
              )}
              <AvatarEditor
                style={editorStyles}
                ref={ref}
                color={[0, 0, 0, 0.66]}
                scale={scale}
                crossOrigin="anonymous"
                {...props}
              />
            </>
          </ConditionalWrap>
          <motion.div
            animate={{ opacity: !!wrap && isMobile ? 1 : 0 }}
            transition={{ delay: 5 }}
          >
            <Center
              pos="absolute"
              left={0}
              right={0}
              bottom="14px"
              pointerEvents="none"
              {...(isMobile && !!wrap && positionDragImage)}
            >
              {!!wrap && isMobile && (
                <Center
                  pos="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  bg="linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #C4C4C4"
                  opacity={0.3}
                  sx={{
                    mixBlendMode: 'multiply',
                  }}
                />
              )}
              <Text textStyle="labelLight" color="white">
                Drag image to reposition
              </Text>
            </Center>
          </motion.div>
        </Box>
        <div
          className={`${
            title === 'Update profile image' ? 'hidden' : 'flex'
          } mt-6 mb-10`}
        >
          <button
            className={`rounded-full py-2 px-4 font-semibold leading-4 mr-0.5 bg-white ${
              isActivePostCardArea ? 'text-gray-700' : 'text-gray-500'
            }`}
            style={{
              border: `${isActivePostCardArea ? '1px solid #282828' : 'none'}`,
              fontSize: '14px',
            }}
            type="button"
            onClick={() => handlePostCardBtnClick()}
          >
            Post Card Area
          </button>
          <button
            className={`rounded-full py-2 px-4 font-semibold leading-4 ml-0.5 bg-white ${
              isActiveDetailViewArea ? 'text-gray-700' : 'text-gray-500'
            }`}
            style={{
              border: `${
                isActiveDetailViewArea ? '1px solid #282828' : 'none'
              }`,
              fontSize: '14px',
            }}
            type="button"
            onClick={() => handleDetailAreaBtnClick()}
          >
            Detail View Area
          </button>
        </div>
        <Flex
          w="100%"
          fontSize="24px"
          pt="20px"
          maxW="400px"
          justify="center"
          m="auto"
        >
          <SvgZoomOut mr="14px" />
          <Slider value={scale} onChange={setScale} max={4} min={1} step={0.1}>
            <SliderTrack bg="$mode.200" height="2px" />
            <SliderThumb
              layerStyle="layer3"
              border="2px solid"
              borderColor="blue.500"
              width="20px"
              height="20px"
            />
          </Slider>
          <SvgZoomIn ml="14px" />
        </Flex>
      </>
    );
  }
);

export type UseImageCropModalReturn = ReturnType<typeof useImageCropModal>;

export type ImageCropModalProps = UseImageCropModalReturn['cropper'];

export function useImageCropModal(props: {
  onSave?: (file: File | Blob) => void;
  editor: Partial<ImageCropProps>;
}) {
  const editorRef = useRef<AvatarEditor>();
  const modal = useDisclosure();
  const [croppingFile, setCroppingFile] = useState<File | string>();
  const [mime, setMime] = useState<string>();

  const onClickSave = () => {
    const image = editorRef.current?.getImage();
    image.toBlob(
      (blob) => {
        props.onSave?.(blob);
        modal.onClose();
      },
      mime?.includes('webp') ? 'image/png' : mime,
      1
    );
  };

  const getFileToCrop = async (file: File | string) => {
    setMime(undefined);
    if (typeof file === 'string') {
      setCroppingFile(file);
      modal.onOpen();
      return;
    }
    // skip gif cropping
    if (isFileTypeNotResizable(file.type)) {
      setMime(file.type);
      props.onSave?.(file);
      return;
    }
    setMime(file.type);
    setCroppingFile(file);
    modal.onOpen();
  };

  return {
    cropper: {
      editorRef,
      onSave: onClickSave,
      editor: {
        initialScale: 1.4,
        image: croppingFile,
        ...props.editor,
      },
      modal,
    },
    mime,
    getFileToCrop,
  };
}

export function ImageCropModal(
  props: ImageCropModalProps & {
    content?: ModalContentProps;
    title?: string;
    displayAspectRatio?: number;
  }
) {
  return (
    <Modal isCentered {...props.modal}>
      <ModalOverlay>
        <ModalContent
          {...props.content}
          px={{
            base: '24px',
            md: '40px',
          }}
          py={{
            base: '16px',
            md: '40px',
          }}
          justifyContent="space-between"
        >
          <Box>
            <Heading as="h3" textStyle="h3">
              {props.title}
            </Heading>
            <Center flexDir="column" w="100%">
              <ImageCrop
                title={props.title}
                wrap={
                  props.displayAspectRatio
                    ? (c) => (
                        <AspectRatio
                          sx={{
                            '& > canvas': {
                              transform: 'translate(-50%, -50%)',
                              top: '50%',
                              left: '50%',
                            },
                          }}
                          ratio={props.displayAspectRatio}
                        >
                          {c}
                        </AspectRatio>
                      )
                    : null
                }
                ref={props.editorRef}
                {...props.editor}
              />
            </Center>
          </Box>
          <ButtonGroup justifySelf="flex-end" alignSelf="flex-end" mt="40px">
            <Button
              variant="ghost"
              onClick={props.modal.onClose}
              style={{
                color: '#9B9FA4',
                fontWeight: 600,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={props.onSave}
              className="py-2.5 px-8 rounded-2xl"
              style={{
                borderRadius: '20px',
                fontWeight: 600,
              }}
            >
              Crop and Save
            </Button>
          </ButtonGroup>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
