import * as React from 'react';
import { Svg } from '../svg';

function SvgSortDesc({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.167 2.334v7h1.75l-2.334 2.916L9.25 9.334H11v-7h1.167zM7.5 9.917v1.167H2.25V9.917H7.5zm1.167-3.5v1.167H2.25V6.417h6.417zm0-3.5v1.167H2.25V2.917h6.417z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgSortDesc);
export default ForwardRef;
