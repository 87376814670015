import * as React from 'react';
import { Svg } from '../svg';

function SvgMarkbook({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M17.4 20H3.9a3.5 3.5 0 01-3.5-3.5V3a3 3 0 013-3h14a1 1 0 011 1v18a1 1 0 01-1 1zm-1-2v-3H3.9a1.5 1.5 0 100 3h12.5zm-9-16v8l3.5-2 3.5 2V2h-7z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgMarkbook);
export default ForwardRef;
