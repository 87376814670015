import * as React from 'react';
import { Svg } from '../svg';

function SvgEnthuseWhite({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8 15.333a5.45 5.45 0 003.771-1.498c1-.96 1.562-2.261 1.562-3.618 0-.59-.163-1.158-.355-1.685-1.186 1.124-2.086 1.685-2.703 1.685 2.841-4.775 1.28-6.822-2.986-9.55.355 3.41-1.989 4.962-2.943 5.823a5.07 5.07 0 00-1.552 2.614c-.232 1-.146 2.045.248 2.998a5.162 5.162 0 001.961 2.347A5.495 5.495 0 008 15.333zm.505-12.118c2.304 1.876 2.316 3.333.535 6.326-.541.91.144 2.04 1.235 2.04.49 0 .985-.136 1.507-.405a3.708 3.708 0 01-.843 1.516c-.4.437-.9.778-1.46.997a4.062 4.062 0 01-3.48-.25 3.844 3.844 0 01-1.288-1.195 3.61 3.61 0 01-.38-3.327c.21-.544.548-1.035.99-1.433.089-.08.543-.468.563-.485.302-.259.55-.489.795-.74.875-.9 1.504-1.897 1.825-3.044z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEnthuseWhite);
export default ForwardRef;
