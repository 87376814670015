import * as React from 'react';
import { Svg } from '../svg';

function SvgLogoSm({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M11.097 0v23.695H4.398V5.18H0V0h11.097zM19.97 12.66c-1.083 0-1.997-.361-2.74-1.083-.745-.722-1.117-1.636-1.117-2.742 0-1.128.36-2.042 1.082-2.742.744-.7 1.67-1.05 2.774-1.05 1.128 0 2.053.35 2.774 1.05.722.7 1.083 1.614 1.083 2.742 0 1.106-.372 2.02-1.116 2.742-.722.722-1.636 1.083-2.74 1.083zm0 11.34c-1.083 0-1.997-.361-2.74-1.083-.745-.722-1.117-1.636-1.117-2.742 0-1.128.36-2.042 1.082-2.742.744-.7 1.67-1.05 2.774-1.05 1.128 0 2.053.35 2.774 1.05.722.7 1.083 1.614 1.083 2.742 0 1.106-.372 2.02-1.116 2.742-.722.722-1.636 1.083-2.74 1.083z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgLogoSm);
export default ForwardRef;
