import * as React from 'react';
import { Svg } from '../svg';

function SvgPreview({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.667 8C1.82 5.073 4.667 3 8 3s6.18 2.073 7.333 5c-1.153 2.927-4 5-7.333 5S1.82 10.927.667 8zM13.88 8A6.513 6.513 0 008 4.333 6.513 6.513 0 002.12 8 6.513 6.513 0 008 11.667c2.527 0 4.78-1.42 5.88-3.667zM8 6.333a1.667 1.667 0 11-.001 3.335 1.667 1.667 0 010-3.335zM5 8c0-1.653 1.346-3 3-3 1.653 0 3 1.347 3 3s-1.347 3-3 3c-1.654 0-3-1.347-3-3z"
        fill="#9B9FA4"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPreview);
export default ForwardRef;
