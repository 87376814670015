import * as React from 'react';
import { Svg } from '../svg';

function SvgPoll({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.95 11.517a.83.83 0 001.175 0l5.3-5.3a.83.83 0 000-1.175L9.3.917A.813.813 0 008.133.91L2.825 6.217a.83.83 0 000 1.175l4.125 4.125zm4.983-1.683h.567l2.5 2.5v3.333c0 .925-.75 1.667-1.667 1.667H1.658c-.916 0-1.658-.75-1.658-1.667v-3.333l2.5-2.5h.692L4.858 11.5H3.15l-1.483 1.667h11.666L11.858 11.5h-1.591l1.666-1.666zM1.667 15.667h11.666v-.833H1.667v.833zm10-10.042l-2.95-2.95L4.592 6.8l2.95 2.95 4.125-4.125z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgPoll);
export default ForwardRef;
