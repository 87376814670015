import {
  Box,
  BoxProps,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  SystemStyleObject,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import SvgIcSettings from 'src/components/icons/ic-settings';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { useRouter } from 'next/router';
import { ConditionalWrap } from '../conditional-wrap';

export function IconMenu({
  opacity,
  children,
  icon,
  buttonSx,
  sx,
  portal,
  height = '40px',
  isOpenEpisode = false,
  ...props
}: {
  opacity?: number;
  children: ReactNode;
  icon?: ReactNode;
  portal?: boolean;
  buttonSx?: SystemStyleObject;
  sx?: SystemStyleObject;
  isOpenEpisode?: boolean;
  height?: string;
} & BoxProps) {
  const isMobile = useIsMobile();
  const router = useRouter();

  return (
    <Box
      className={
        isMobile && router.pathname.includes('collections') ? 'menu-icon' : ''
      }
      w={isMobile && '100%'}
      {...props}
    >
      <Menu isLazy placement="bottom-end">
        <MenuButton
          aria-label="studio-menu-button"
          className="menu-button"
          onClick={(e) => e.stopPropagation()}
          colorScheme="gray.900"
          as={IconButton}
          width="40px"
          height={height}
          fontSize="28px"
          rounded="full"
          sx={buttonSx}
          color={props.color || 'white'}
          icon={icon || <SvgIcSettings />}
        />
        <ConditionalWrap condition={portal} wrap={(s) => <Portal>{s}</Portal>}>
          <MenuList
            mr={isOpenEpisode && '25px'}
            mt={isOpenEpisode && '-10px'}
            textStyle="body2"
            sx={sx}
            p={0}
            transform={isMobile && 'none'}
            minW={!isMobile && '270px'}
          >
            {children}
          </MenuList>
        </ConditionalWrap>

        {isMobile && (
          <style jsx global>{`
          .css-r6z5ec {
            transform: none !important;
            width: 100%;
            padding: 16px;
            margin-top: 24px;
        }
          .menu-icon {
            text-align: end;
          }
          }
        `}</style>
        )}
      </Menu>
    </Box>
  );
}
