import { Button, Grid, Heading, Stack, Text, Box } from '@chakra-ui/react';
import { useContext } from 'react';
import { useFollowUserMutation } from 'src/app/profile/use-follow-user.mutation';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { Avatar } from 'src/components/avatar';
import { NextChakraLinkBox } from 'src/components/next-chakra-link';
import { CacheKey } from 'src/constants/cache-key';
import { useTranslation } from 'react-i18next';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { CoCreator } from '../../types';

export type Creator = {
  role: CreatorRole;
} & CoCreator;

export enum CreatorRole {
  publisher = 'Creator',
  coCreator = 'Co-creator',
  contributor = 'Contributor',
}

export function CreatorsGrid(props: { creators; contentID: string }) {
  const { user } = useContext(UserContext) as UserContextType;
  const { mutate: followUser } = useFollowUserMutation([
    CacheKey.article,
    props?.contentID,
  ]);
  const { t } = useTranslation();
  return (
    <>
      <Heading
        textStyle="h6"
        as="h6"
        color="$mode.400"
        pb="32px"
        textAlign={{
          base: 'center',
          md: 'left',
        }}
      >
        CREATORS
      </Heading>
      <Grid
        w="100%"
        templateColumns="repeat(auto-fill, 130px)"
        gap="24px"
        aria-label="creators-info"
      >
        {props.creators.map((creator) => {
          const username = getProfileFullNameOrUsername(creator);

          return (
            <NextChakraLinkBox
              key={creator.userID}
              href={`/${creator.username}`}
            >
              <Stack spacing={2}>
                <Box _hover={{ cursor: 'pointer' }} w="max-content">
                  <Avatar
                    src={creator.profileImgURL}
                    name={username}
                    author={creator}
                    width={72}
                    height={72}
                  />
                </Box>
                <Box _hover={{ cursor: 'pointer' }} w="max-content">
                  <Text textStyle="h5" color="$mode.900" noOfLines={1}>
                    {getProfileFullNameOrUsername(creator)}
                  </Text>
                </Box>
                <Stack direction="row" spacing={1}>
                  <Text color="$mode.500" textStyle="caption">
                    {creator.role}
                  </Text>
                  {user?.username !== creator.username && (
                    <Button
                      pos="relative"
                      zIndex={1}
                      size="sm"
                      textStyle="caption"
                      fontWeight="bold"
                      variant="link"
                      onClick={(e) => {
                        e.preventDefault();
                        followUser({
                          group: 'USER',
                          targetID: creator.userID,
                          follow: !creator.isFollowed,
                        });
                      }}
                    >
                      {creator.isFollowed ? t('Following') : t('Follow')}
                    </Button>
                  )}
                </Stack>
              </Stack>
            </NextChakraLinkBox>
          );
        })}
      </Grid>
    </>
  );
}
