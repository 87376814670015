import { useUnloadPrompt } from 'src/hooks/use-unload-prompt';
import { useTimer } from 'use-timer';
import { useDebouncedCallback } from 'use-debounce';

export function useAutoSave({
  enabled,
  onSave,
  interval = 1,
}: {
  enabled: boolean;
  onSave: () => void;
  interval?: number;
}) {
  // Debounce callback
  const debounced = useDebouncedCallback(
    // function
    () => onSave(),
    // delay in ms
    2000
  );

  const timer = useTimer({
    endTime: interval,
    onTimeOver: async () => {
      if (enabled) {
        debounced();
      }
      timer.reset();
    },
  });

  useUnloadPrompt(enabled, debounced);

  const triggerTimer = () => {
    if (timer.status !== 'RUNNING') {
      timer.start();
    } else {
      timer.reset();
      timer.start();
    }
  };

  return {
    triggerTimer,
    isCounting: timer.status === 'RUNNING',
  };
}
