import * as React from 'react';
import { Svg } from '../svg';

function SvgCcBy({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.178.084c3.37 0 6.214 1.157 8.529 3.472 2.328 2.328 3.493 5.171 3.493 8.528 0 3.372-1.144 6.179-3.43 8.421-2.428 2.386-5.292 3.58-8.592 3.58-3.243 0-6.057-1.18-8.442-3.537C1.379 18.191.2 15.37.2 12.084.2 8.8 1.379 5.956 3.736 3.556 6.05 1.241 8.864.084 12.178.084zm.043 2.165c-2.728 0-5.035.957-6.921 2.871-1.958 2-2.936 4.322-2.936 6.965 0 2.657.971 4.957 2.914 6.899 1.942 1.943 4.257 2.914 6.942 2.914 2.671 0 5-.978 6.986-2.936 1.886-1.814 2.829-4.107 2.829-6.878 0-2.729-.957-5.05-2.871-6.964-1.914-1.914-4.229-2.871-6.943-2.871zm3.215 6.857v4.906h-1.371v5.829h-3.73v-5.828h-1.37V9.106c0-.215.075-.397.224-.547a.747.747 0 01.547-.225h4.929c.2 0 .378.075.535.225a.73.73 0 01.236.547zM10.528 6.02c0-1.128.557-1.693 1.672-1.693 1.115 0 1.671.564 1.671 1.693 0 1.114-.557 1.671-1.671 1.671-1.114 0-1.672-.557-1.672-1.67z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCcBy);
export default ForwardRef;
