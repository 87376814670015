import {
  Button,
  ButtonGroup,
  ButtonProps,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalProps,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export function OneTmModal({
  showCancel = true,
  showOk = true,
  hasBottomBorder = true,
  isDisabled = false,
  ...props
}: {
  modalProps?: Partial<ModalProps>;
  modal?: UseDisclosureReturn;
  children: ReactNode;
  title: string;
  contentProps?: ModalContentProps;
  okText?: string;
  cancelText?: string;
  onCancel?: () => void;
  okButtonProps?: ButtonProps;
  onOk?: () => void;
  showOk?: boolean;
  showCancel?: boolean;
  hasBottomBorder?: boolean;
  loading?: boolean;
  isDisabled?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Modal {...props.modal} isCentered {...props.modalProps}>
      <ModalOverlay>
        <ModalContent
          maxH={{ base: '100%', md: '90%' }}
          h={{ base: '100vh', md: 'auto' }}
          pt="40px"
          w="640px"
          maxW="100%"
          justifyContent="space-between"
          {...props.contentProps}
        >
          <Flex flexDirection="column" overflowY="auto">
            <Heading as="h3" textStyle="h3" px={{ base: '24px', md: '40px' }}>
              {props.title}
            </Heading>
            {props.children}
          </Flex>
          <ButtonGroup
            justifyContent="flex-end"
            borderTop={hasBottomBorder ? '1px solid' : 'none'}
            borderTopColor="$mode.200"
            layerStyle="layer3"
            py="24px"
            px={{ base: '24px', md: '40px' }}
          >
            {showCancel && (
              <Button
                colorScheme="gray"
                onClick={props.onCancel || props?.modal?.onClose}
                variant="ghost"
                rounded="ellipse"
              >
                {t(props.cancelText) || t('Cancel')}
              </Button>
            )}
            {showOk && (
              <Button
                type="submit"
                disabled={isDisabled}
                onClick={props.onOk}
                rounded="ellipse"
                isLoading={props.loading || false}
                {...props.okButtonProps}
              >
                {t(props.okText) || t('Confirm')}
              </Button>
            )}
          </ButtonGroup>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

OneTmModal.px = { base: '24px', md: '40px' };
