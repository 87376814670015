import { Stack, StackProps, useDisclosure } from '@chakra-ui/react';
import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';

import { toRem } from 'src/theme/tools';
import { useEditor } from 'src/lib/editor';
import SvgCamera from 'src/components/icons/camera';
import { UploadModal } from 'src/app/article/agora-post-modal';
import { useUploadImage } from 'src/hooks';
import {
  ImageCropModal,
  useImageCropModal,
} from 'src/components/image-crop/image-crop';
import WarningModalWithoutCancel from 'src/app/article/warning-modal-without-cancel';
import { OneTmModal } from 'src/components/1tm-modal';
import { UnsplashSelect } from 'src/components/unsplash/unsplash-select';
import { ImageUploader } from 'src/components/image-uploader';
import { useIsTablet, useIsMobile } from 'src/hooks/use-is-mobile';
import { useDropzone } from 'react-dropzone';
import { LIMIT_UPLOAD_IMAGE_FILE_ACCEPT } from 'src/constants/limit';
import { UserContext, UserContextType } from 'src/contexts/UserContext';
import { useRouter } from 'next/router';
import { Avatar } from '../avatar';

export type CommenterProps = {
  /**
   * disable comment
   * @default false
   */
  disabled?: boolean;
  /**
   * Display user information.
   */
  user?: any;

  /**
   * Custom stack styles.
   */
  stackStyle?: StackProps;

  /**
   * Apply to sub comment style.
   * @deprecated // not in used
   */
  asSubcomment?: boolean;

  /**
   * Custom input placeholder $mode.
   */
  placeholder?: string;

  /**
   * Handling input onKeyDown event.
   */
  onKeyDown?: (value: any) => void;
  onReply?: boolean;
  commenter?: any;
  editorIndex?: string;
  userReply?: any;
  commentContent?: any;
  commentUploadedFiles?: any;
};

export type CommenterHandler = {
  focus: (scrollSmooth?: boolean) => void;
};

export const Commenter = forwardRef<CommenterHandler, CommenterProps>(
  function Commenter(props, forwardedRef) {
    const inputRef = useRef<HTMLInputElement>();
    const {
      stackStyle,
      user,
      onKeyDown,
      editorIndex,
      onReply,
      commenter,
      asSubcomment,
      userReply,
      commentContent,
      commentUploadedFiles,
    } = props;
    const uploadModal = useDisclosure();
    const unsplashModal = useDisclosure();
    const warningModal = useDisclosure();
    const router = useRouter();
    const [warningModalText, setWarningModalText] = useState([]);
    const [warningModalTitle, setWarningModalTitle] = useState('');
    const [lastUploadedFile, setLastUploadedFile] = useState<any>();
    const { isLogin } = useContext(UserContext) as UserContextType;
    const isTablet = useIsTablet();
    const isMobile = useIsMobile();

    useImperativeHandle(forwardedRef, () => ({
      focus: (scrollSmooth = true) => {
        if (scrollSmooth) {
          inputRef?.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
        inputRef.current.focus({ preventScroll: scrollSmooth });
      },
    }));
    const newData = { blocks: [] };

    const [uploadedFiles, setUploadedFiles] = useState(
      commentUploadedFiles ? [...commentUploadedFiles] : []
    );

    async function checkFunc(e) {
      if (e.code === 'Enter' && !e.shiftKey) {
        const content = await editor.save();
        // @ts-ignore
        content.uploadedFiles = uploadedFiles;
        if (content.blocks.length > 0) {
          onKeyDown({ event: e, value: content });
          setUploadedFiles([]);
          setLastUploadedFile('');
          editor?.blocks?.clear();
        } else {
          editor.blocks.clear();
        }
      }
    }

    useEffect(() => {
      if (onReply) {
        let replytoUser = commenter.username;
        if (userReply?.username) {
          replytoUser = userReply.username;
        }
        const blockData = {
          blocks: [
            {
              data: {
                text: `<a href="/${replytoUser}">@${replytoUser} </a>`,
              },
              type: 'paragraph',
            },
          ],
        };
        editor?.blocks?.insert(
          'paragraph',
          blockData.blocks[0].data,
          {},
          0,
          true
        );
        editor?.blocks?.delete(1);
      }
    }, [onReply, userReply]);

    const imageUpload = () => {
      uploadModal.onOpen();
    };

    const { editor } = useEditor({
      editorId: editorIndex,
      uniqueId: user.id,
      data: commentContent || newData,
      allow: ['mention', 'embedLink', 'paragraph'],
    });

    const dropzone = useDropzone({
      noClick: true,
      onDrop: async (files) => {
        if (files[0]) {
          const newImg = new Image();
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = function (e) {
            newImg.src = `${e.target.result}`;
          };
          newImg.onload = function () {
            if (
              files[0].type === 'image/webp' ||
              files[0].type === 'image/gif'
            ) {
              setLastUploadedFile(files[0]);
            } else if (newImg.height < 600 || newImg.width < 900) {
              setWarningModalTitle('Image is too small');
              setWarningModalText([
                'Image should be larger than 900x600 px.',
                'Please resize and try again, or select another image.',
              ]);
              warningModal.onOpen();
            } else {
              setLastUploadedFile(files[0]);
            }
          };
        }
      },
    });

    const { mutateAsync: uploadCover, isLoading: isUploading } = useUploadImage(
      {
        areaType: 'EPISODE',
        imgType: 'episode_img',
      },
      (response) => {
        if (setUploadedFiles) {
          setUploadedFiles([
            {
              type: 'image',
              id: generateUniqueID(),
              imgURL: response.imgURL,
              file: lastUploadedFile,
              title: null,
              description: null,
            },
            ...uploadedFiles,
          ]);
        }
      }
    );

    useEffect(() => {
      if (lastUploadedFile) {
        getFileToCrop(lastUploadedFile);
      }
    }, [lastUploadedFile]);

    const { cropper, getFileToCrop } = useImageCropModal({
      editor: {
        width: 900 / (isTablet ? 2 : 1),
        height: 600 / (isTablet ? 2 : 1),
        border: [0, 0],
        editorStyles: {
          margin: 'auto',
          width: '100%',
        },
      },
      onSave: async (file) => {
        if (
          file.type === 'image/png' ||
          file.type === 'image/gif' ||
          file.type === 'image/jpeg' ||
          file.type === 'image/webp'
        ) {
          if (lastUploadedFile?.size > 5000000) {
            setWarningModalTitle('Image file size is too large');
            setWarningModalText([
              'Image file size should be under 5MB.',
              'Please check file size and try again.',
            ]);
            warningModal.onOpen();
          } else {
            uploadModal.onClose();
            const formData = new FormData();
            formData.set('file', file);
            await uploadCover({ data: formData });
          }
        } else {
          setWarningModalTitle('Image format not supported');
          setWarningModalText([
            'We supported images in JPEG/GIF/PNG/WebP.',
            'Please convert the image format and try again, or select another image in the supported formats.',
          ]);
          warningModal.onOpen();
        }
      },
    });

    const handleUpload = (data) => {
      uploadModal.onClose();
      if (data.value) {
        getFileToCrop(data.value);
      } else {
        dropzone.open();
      }
    };

    return (
      <Stack
        direction="row"
        spacing="12px"
        // px={3}
        pb={isMobile ? '12px' : toRem(10)}
        borderLeft={isMobile ? 'none' : asSubcomment && '4px solid #ECEEED'}
        pt={asSubcomment && 3}
        ml={isMobile ? '0' : asSubcomment && 7}
        // borderBottom={{ base: !asSubcomment && '1px solid #E6E8E9', md: 0 }}
        {...stackStyle}
      >
        {!commentContent && (
          <Avatar
            href={`/${user?.username}`}
            isLoading={!user}
            name={getProfileFullNameOrUsername(user)}
            src={user?.avatar}
            width={40}
            height={40}
            author={user}
          />
        )}
        <div className="comment-box-content relative rounded-3xl border border-gray-200 w-full break-all">
          {uploadedFiles?.length === 0 && (
            <>
              <input
                {...dropzone.getInputProps({
                  accept: LIMIT_UPLOAD_IMAGE_FILE_ACCEPT,
                })}
                data-testid="hidden-upload-input"
              />
              <div
                role="button"
                tabIndex={0}
                className={`absolute ${
                  isMobile ? 'right-3' : 'right-4'
                } top-2 z-50`}
                onClick={imageUpload}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    imageUpload();
                  }
                }}
              >
                <SvgCamera className="text-gray-500" width={20} height={20} />
              </div>
            </>
          )}
          {isLogin ? (
            <div
              role="button"
              style={{ minHeight: '40px' }}
              className="relative w-full pl-1.5 pr-8  pt-0.5 z-10 m-auto"
              id={editorIndex}
              ref={inputRef}
              onKeyDown={checkFunc}
              tabIndex={0}
              aria-label="comment input"
              onPaste={(e) => e.stopPropagation()}
            />
          ) : (
            <div
              role="button"
              tabIndex={0}
              className="relative w-full px-4 pt-2 z-10 m-auto text-gray-600"
              onClick={() =>
                router.push({ query: { ...router.query, page: 'signIn' } })
              }
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  router.push({ query: { ...router.query, page: 'signIn' } });
                }
              }}
            >
              Write something...
            </div>
          )}
          {uploadedFiles.map((files) => (
            <div key={files.imgURL} className="h-auto w-min bg-gray-200 m-2">
              <ImageUploader
                dropzone={dropzone}
                uploading={isUploading}
                bg="$mode.50"
                radius="10px"
                fontSize="32px"
                color="$mode.700"
                isGeneral
                src={files.imgURL}
                style={{
                  height: '6rem',
                  width: '8.7rem',
                }}
                onDelete={() => {
                  if (setUploadedFiles) {
                    setUploadedFiles(
                      uploadedFiles.filter((item) => item.id !== files.id)
                    );
                  }
                }}
              >
                <></>
              </ImageUploader>
            </div>
          ))}
        </div>
        <style jsx global>{`
          .codex-editor {
            padding-left: 10px;
            padding-right: 10px;
          }
          .ce-toolbar__plus,
          .ce-toolbar__actions {
            visibility: hidden;
          }
          .codex-editor__redactor {
            padding-bottom: 0 !important;
          }
          .css-ivgeko-menu {
            margin: 0;
          }
          .comment-box-content .ce-toolbar {
            display: none !important;
          }
        `}</style>
        <UploadModal modal={uploadModal} clickHandle={(e) => handleUpload(e)} />
        <ImageCropModal
          title="Cover Image"
          {...cropper}
          content={{ maxW: 1020, maxH: '100%' }}
        />
        <WarningModalWithoutCancel
          {...warningModal}
          title={warningModalTitle}
          text={warningModalText}
          okBtnText="Upload again"
        />
        <OneTmModal
          modal={unsplashModal}
          okText="Confirm"
          title="Add Image"
          showOk={false}
        >
          <UnsplashSelect
            height="420px"
            onChange={async (us) => {
              if (us) {
                unsplashModal.onClose();
                getFileToCrop(us.url);
              }
            }}
          />
        </OneTmModal>
      </Stack>
    );
  }
);

function generateUniqueID() {
  const day = new Date();
  return day.getTime();
}
