import { Modal, ModalContent, ModalBody } from '@chakra-ui/react';
import SearchProvider from 'src/contexts/search/SearchContext';
import SuggestProvider from 'src/contexts/search/RecommendContext';
import SearchPanel from './searchPanel';

const SearchPanelModal = ({ modal }: { modal?: any }) => {
  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
      <ModalContent
        maxW="626px"
        borderRadius="0"
        boxShadow="none"
        background="none"
      >
        <ModalBody p={0}>
          <SuggestProvider>
            <SearchProvider>
              <SearchPanel modal={modal} />
            </SearchProvider>
          </SuggestProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchPanelModal;
