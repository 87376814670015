import * as React from 'react';
import { Svg } from '../svg';

function SvgBackArrow({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 36 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <rect
        x={0.5}
        y={0.5}
        width={33}
        height={33}
        rx={16.5}
        stroke="currentColor"
      />
      <path
        d="M22.333 16.333H14.22l3.727-3.726-.947-.94L11.667 17 17 22.333l.94-.94-3.72-3.726h8.113v-1.334z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgBackArrow);
export default ForwardRef;
