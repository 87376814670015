import * as React from 'react';
import { Svg } from '../svg';

function SvgCamera({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.22 3.333h2.113c.734 0 1.334.6 1.334 1.334v8c0 .733-.6 1.333-1.334 1.333H2.667c-.734 0-1.334-.6-1.334-1.333v-8c0-.734.6-1.334 1.334-1.334H4.78L6 2h4l1.22 1.333zm-8.553 9.334h10.666v-8h-2.7l-1.22-1.334H6.587l-1.22 1.334h-2.7v8zM8 5.333A3.335 3.335 0 008 12a3.335 3.335 0 000-6.667zM6 8.667c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCamera);
export default ForwardRef;
