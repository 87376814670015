import * as React from 'react';
import { Svg } from '../svg';

function SvgEnthuseV1({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      <path
        d="M4.08342 11.0833C4.63595 11.0833 5.16585 10.8638 5.55655 10.4731C5.94726 10.0824 6.16675 9.55254 6.16675 9C6.16675 7.85 5.75008 7.33333 5.33342 6.5C4.44008 4.71417 5.14675 3.12167 7.00008 1.5C7.41675 3.58333 8.66675 5.58333 10.3334 6.91667C12.0001 8.25 12.8334 9.83333 12.8334 11.5C12.8334 12.266 12.6825 13.0246 12.3894 13.7323C12.0962 14.4401 11.6665 15.0831 11.1249 15.6248C10.5832 16.1665 9.94014 16.5961 9.2324 16.8893C8.52467 17.1825 7.76613 17.3333 7.00008 17.3333C6.23404 17.3333 5.47549 17.1825 4.76776 16.8893C4.06003 16.5961 3.41697 16.1665 2.87529 15.6248C2.33362 15.0831 1.90394 14.4401 1.61078 13.7323C1.31763 13.0246 1.16675 12.266 1.16675 11.5C1.16675 10.5392 1.52758 9.58833 2.00008 9C2.00008 9.55254 2.21957 10.0824 2.61028 10.4731C3.00098 10.8638 3.53088 11.0833 4.08342 11.0833V11.0833Z"
        stroke="#9B9FA4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgEnthuseV1);
export default ForwardRef;
