import * as React from 'react';
import { Svg } from '../svg';

function SvgTexOutlined({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6 15.5l.9-2.2h5l.9 2.2h2.1l-4.75-11h-1.5l-4.75 11h2.1zm-3.6 2v2h14v-2H5zm8.87-6L12 6.48l-1.87 5.02h3.74z"
        fill="#9B9FA4"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgTexOutlined);
export default ForwardRef;
