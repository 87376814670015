export const genreSort = (data, genreKey, sortTypeSelect) => {
  let sortType = 'updatedAt';
  if (genreKey === 'creators') {
    sortType = sortTypeSelect === 'Recently' ? 'username' : 'numOfFollowers';
  }
  if (genreKey === 'creations') {
    sortType = sortTypeSelect === 'Recently' ? 'updatedAt' : 'enthuseCount';
  }
  if (genreKey === 'projects') {
    sortType = sortTypeSelect === 'Recently' ? 'title' : 'title';
  }
  // if (genreKey === 'collections') {
  //   sortType = sortTypeSelect === 'Recently' ? 'updatedAt' : 'enthuseCount';
  // }
  // if (genreKey === 'tribes') {
  //   sortType = sortTypeSelect === 'Recently' ? 'updatedAt' : 'enthuseCount';
  // }

  data.sort((a, b) => (a[sortType] >= b[sortType] ? -1 : 1));
  return data;
};
