import * as React from 'react';
import { Svg } from '../svg';

function SvgHeart({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M18.243 1.757a6 6 0 01.236 8.236l-8.48 8.492-8.478-8.492A6.002 6.002 0 018.261.44L4.343 4.358l1.414 1.415L10 1.53l-.013-.014.014.013a5.998 5.998 0 018.242.228z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgHeart);
export default ForwardRef;
