import * as React from 'react';
import { Svg } from '../svg';

function SvgChevronDownIcon({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M1.32 5.16l2.677-2.67 2.678 2.67.822-.822-3.5-3.5-3.5 3.5.823.823z"
        fill="#BBC0C5"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronDownIcon);
export default ForwardRef;
