import * as React from 'react';
import { Svg } from '../svg';

function SvgComment({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#comment_svg__clip0)">
        <path d="M1.094.758h23v23h-23v-23z" stroke="#E6E8E9" />
        <path
          d="M14.594 22.758l-2.8-3.5h-5.2a1 1 0 01-1-1V7.36a1 1 0 011-1h16a1 1 0 011 1v10.897a1 1 0 01-1 1h-5.2l-2.8 3.5zm1.839-5.5h5.16V8.36h-14v8.897h5.162l1.839 2.298 1.839-2.298zm-13.84-15h17v2h-16v11h-2v-12a1 1 0 011-1z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="comment_svg__clip0">
          <path
            fill="#fff"
            transform="translate(.594 .258)"
            d="M0 0h24v24H0z"
          />
        </clipPath>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgComment);
export default ForwardRef;
