import * as React from 'react';
import { Svg } from '../svg';

function SvgVector({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M3.2 8.414L1.787 9.829l1.885 1.885 7.542-7.543-1.885-1.884L7.913 3.7l.944.943-.944.944-.942-.944-.943.944.943.942-.943.943-.943-.943-.943.943.943.943-.943.943-.942-.945zM9.8.872L12.628 3.7a.667.667 0 010 .943l-8.485 8.485a.667.667 0 01-.943 0L.372 10.3a.667.667 0 010-.943L8.857.872a.667.667 0 01.943 0zm-1.887 10.37l.944-.943 1.494 1.495h.943v-.942L9.799 9.357l.943-.943L12.5 10.17V13H9.672l-1.758-1.758zM2.257 5.585L.371 3.7a.667.667 0 010-.943L2.257.87a.667.667 0 01.943 0l1.887 1.886-.944.943-1.414-1.415-.943.944L3.2 4.642l-.943.943z"
        fill="#4F5356"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgVector);
export default ForwardRef;
