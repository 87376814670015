import * as React from 'react';
import { Svg } from '../svg';

function SvgCcNc({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.178.084c3.371 0 6.214 1.157 8.529 3.471 2.328 2.315 3.493 5.158 3.493 8.53 0 3.371-1.143 6.178-3.43 8.421-2.428 2.386-5.292 3.578-8.592 3.578-3.257 0-6.071-1.186-8.442-3.557C1.379 18.17.2 15.357.2 12.084c0-3.286 1.179-6.128 3.536-8.528C6.05 1.24 8.864.084 12.178.084zM2.9 8.85c-.357.985-.536 2.064-.536 3.236 0 2.657.971 4.957 2.914 6.9 1.957 1.929 4.271 2.893 6.942 2.893 2.7 0 5.029-.978 6.987-2.936.7-.671 1.25-1.372 1.649-2.1l-4.521-2.015a3.093 3.093 0 01-1.147 1.853c-.608.479-1.325.754-2.154.825v1.843h-1.392v-1.843c-1.329-.013-2.544-.492-3.643-1.435l1.65-1.671c.785.728 1.679 1.092 2.679 1.092.414 0 .768-.092 1.06-.278.293-.186.44-.492.44-.922 0-.3-.107-.543-.321-.729l-1.157-.493-1.414-.643-1.908-.836L2.9 8.85zm9.321-6.622c-2.728 0-5.035.964-6.921 2.893A11.484 11.484 0 003.97 6.727l4.586 2.057a2.81 2.81 0 011.136-1.51c.556-.379 1.207-.59 1.95-.633V4.798h1.393v1.843c1.1.058 2.1.43 3 1.115l-1.565 1.607c-.672-.472-1.357-.707-2.057-.707-.371 0-.703.072-.996.214-.293.143-.44.386-.44.729 0 .1.036.2.108.3l1.521.686 1.05.471 1.929.857 6.149 2.743c.2-.843.3-1.7.3-2.571 0-2.758-.957-5.079-2.87-6.965-1.9-1.929-4.216-2.893-6.944-2.893z"
        fill="currentColor"
      />
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgCcNc);
export default ForwardRef;
